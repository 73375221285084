import React from "react";

const DeleteAccountSurvey = () => {
  return (
    <div className="flex ">
      <iframe
        className="fixed top-0 left-0 bottom-0 w-full h-full border-none m-0 p-0 overflow-hidden z-50"
        src="https://o44jesxx7d6.typeform.com/to/f1OrsLlq"
      ></iframe>
    </div>
  );
};

export default DeleteAccountSurvey;
