import { useState } from "react";
import ErrorMessage from "../ui/primitives/ErrorMessage";

const useError = () => {
	const [error, setError] = useState<React.ReactNode>(null);

	// takes a catch error from an HTTP request
	function mountError(err : any) {
		const errors: string = err.response.data.issues ? err.response.data.issues : err.response.data.errorMessage;
		setError(<ErrorMessage errors={errors} />);
	}

	return { error, mountError };
};

export default useError;
