import React, { useState, useEffect } from "react";
import ExpandableTable from "../../../primitives/atoms/GenericAntdTable/GenericAntdTable";
import { data_table_one, svg } from "../components/TableData";
import { StatusActionButtons } from "../components/StatusActionButtons";
import MobileSubdomainAntdTable from "../../../primitives/atoms/MobileSubdomainAntdTable/MobileSubdomainAntdTable";

interface Records {
  key: string;
  name?: string;
  email: string;
  phone: string;
  reason: string;
  relationship: string;
  contactID: string;
  platform_ref: string;
  option?: any;
  status?: string;
  domain?: string;
  parentKey?: string;
  count?: number;
  subject: string;
  subDomain?: any;
}
interface SubDomainRecord {
  parentId: string;
  child?: Records[];
}

const getData = () => {
  // adding parentKey to subdomain doc for chaging the status of subdomain
  //hopefully data that comes from backend will have this property
  const array = data_table_one.map((data) => {
    if (data.subDomain.length) {
      return {
        ...data,
        subDomain: data.subDomain.map((sub) => ({
          ...sub,
          parentKey: data.key,
        })),
        email: "",
        phone: "",
        reason: "",
        relationship: "",
        contactID: "",
        platform_ref: "",
        subject: "",
      };
    } else {
      return {
        ...data,
        email: "",
        phone: "",
        reason: "",
        relationship: "",
        contactID: "",
        platform_ref: "",
        subject: "",
      };
    }
  });

  return [...array];
};

function TableOne() {
  //fetching the data
  const [data, setData] = useState<Records[]>([]);
  // In order to expand the rows. We save the keys of the rows we want to expand
  const [expanded, setexpanded] = useState<string[]>([]);

  // fetch the data
  useEffect(() => {
    const data = getData();
    setData(data);
    //expand all the rows initally
    const expandedRows = data.map((item) => {
      return item.key;
    });
    setexpanded(expandedRows);
    return () => {
      //cleanup
      setData([]);
    };
  }, []);

  /* This function is going to expand a row if its not expanded and collapse an expanded row*/
  const expandableHandler = (record: Records) => {
    const found = expanded.find((item) => item == record.key);
    if (!!found) {
      //row is already expanded
      setexpanded((expanded) => expanded.filter((item) => item !== found));
    } else {
      //expand this row
      setexpanded((expanded) => [...expanded, record.key]);
    }
  };

  /* This code block will basically be used to select/unselect a subdomain. Right now we are only doing it inside the state, later on here we will call the API*/
  const setSubdomainToCheckUncheck = (
    docs: Records,
    value: Records,
    option: any,
  ) => {
    if (docs.subDomain?.length) {
      const x = docs.subDomain.map((subdoc: any) => {
        if (subdoc.key === value.key) {
          return { ...subdoc, status: option };
        } else {
          return subdoc;
        }
      });
      return x;
    }
  };
  /* This code block will be used to select/unselect all the "empty" subdomains. It will only work if the any of the subdomain has no status. */
  const setAllUncheckedSubDomainsToThis = (docs: Records) => {
    if (docs.subDomain?.length) {
      const x = docs.subDomain.map((subdoc: any) => {
        return subdoc;
      });
      return x;
    }
  };

  //Action button handler. If we select/unselect a domain or subdomain this function runs
  const actionButtonClickHandler = (value: Records, option: string) => {
    if (value?.parentKey) {
      //this code part will be used to select/unselect a subdomain
      const updated = data.map((doc) =>
        doc.key === value.parentKey
          ? {
              ...doc,
              subDomain: setSubdomainToCheckUncheck(doc, value, option),
            }
          : doc,
      );
      setData(updated);
    } else {
      //this code part will be used to select/unselect a domain
      setData((data) =>
        data.map((data) => {
          if (data.key === value.key) {
            return {
              ...data,
              status: option,
              subDomain: setAllUncheckedSubDomainsToThis(data),
            };
          } else {
            return data;
          }
        }),
      );
    }
  };

  /* setting up the columns for displaying data. 
        title: "Heading of the column"
        width: "specify the width of the column"
        dataIndex: "Which field of the data object should be chosen"
        key: key of the column
        render: "Display column data in any desireable way"
    */

  const columns = [
    {
      title: () => (
        <p className="font-custom font-bold text-base text-black_primary leading-6 ">
          Domain name
        </p>
      ),
      width: "25%",
      dataIndex: "domain",
      key: "domain",
      render: (text: string, key: any) => (
        <a
          className={
            key.parentKey
              ? `ml-7 font-custom font-normal text-base text-black_primary leading-6`
              : "font-custom font-bold text-base text-black_primary leading-6 "
          }
          onClick={(record) => expandableHandler(key)}
        >
          {text}
        </a>
      ),
    },
    {
      title: () => (
        <div className="flex gap-x-2 items-center">
          <p className="font-custom font-bold text-base text-black_primary leading-6 ">
            Number of emails
          </p>{" "}
          <p>{svg()}</p>
        </div>
      ),
      width: "25%",
      dataIndex: "count",
      key: "count",
      render: (text: string, key: any) => (
        <a
          onClick={(record) => expandableHandler(key)}
          className={
            key.parentKey
              ? `ml-3 font-custom font-normal text-base text-black_primary leading-6`
              : "font-custom font-normal text-base text-black_primary leading-6 "
          }
        >
          {text}
        </a>
      ),
    },
    {
      title: () => (
        <div className="font-custom font-bold text-base text-black_primary leading-6 ">
          Last email
        </div>
      ),
      width: "25%",
      dataIndex: "timeStamp",
      key: "timeStamp",
      render: (text: string, key: any) => (
        <a
          onClick={(record) => expandableHandler(key)}
          className={
            key.parentKey
              ? `ml-1 font-custom font-normal text-base text-black_primary leading-6`
              : "font-custom font-normal text-base text-black_primary leading-6 "
          }
        >
          {text}
        </a>
      ),
    },
    {
      title: () => (
        <div className="font-custom font-bold text-base text-black_primary leading-6 ">
          Status
        </div>
      ),
      key: "status",
      width: "25%",
      render: (_: any, record: Records) => {
        return (
          <div className="py-4 font-custom font-bold text-base text-black_primary leading-6">
            <StatusActionButtons
              value={record}
              onClickHandler={actionButtonClickHandler}
              options={["approve", "block"]}
            />
          </div>
        );
      },
    },
  ];

  const dataOfSubDomains: SubDomainRecord[] = data?.map(function (item) {
    return {
      child: item.subDomain,
      parentId: item.key,
    };
  });

  return (
    <>
      <div className="block sm:hidden ">
        <MobileSubdomainAntdTable
          data={data}
          columns={columns}
          dataForChildTable={dataOfSubDomains}
          actionButtonClickHandler={actionButtonClickHandler}
          deleteOptionVisible={false}
        />
      </div>
      <div className="w-full sm:flex hidden  justify-center px-10 ">
        <ExpandableTable
          data={data}
          columns={columns}
          dataForChildTable={dataOfSubDomains}
          expanded={expanded}
          pagination={false}
        />
      </div>
    </>
  );
}

export default TableOne;
