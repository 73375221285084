import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";

import PublicNav from "../landingPage/components/publicNav";
import Label from "../../primitives/Label";
import Loader from "../../primitives/Loader/Loader";
import { fetcher, post, update } from "../../../logic/util/hooks";
import Button from "../../primitives/Button";
import { icons } from "../../assets/icons";
import toast from "react-hot-toast";
import mixpanel from "mixpanel-browser";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface PlatformData {
  ACCOUNT_REF: string;
  id: string;
  eval_paused: boolean;
  has_send_survey_enabled: boolean;
  is_domain_review_complete: boolean;
  is_folder_settings_set: boolean;
  is_request_form_settings_set: boolean;
  is_video_step_complete: boolean;
  email: string;
  platformID: string;
  platform: string;
}

const FolderSettings = ({
  setRefetch,
}: {
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  // folder settings has platform id as a url parameter, so that each platform has its own settings page
  const { id } = useParams();
  const { state: navState } = useLocation();
  const { user } = useSelector((state: any) => state.auth);

  const [platformData, setPlatformData] = useState<PlatformData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isEvalPaused, setIsEvalPaused] = useState<boolean>(false);
  const [loading, seLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSavePreference = (isPaused: boolean) => {
    setIsEvalPaused(isPaused);
    post(
      "platforms",
      `account/${platformData?.ACCOUNT_REF}/platform/${platformData?.id}/updateSetting`,
      {
        platformSettings: isPaused
          ? {
              eval_paused: isPaused,
              has_send_survey_enabled: false,
            }
          : {
              eval_paused: isPaused,
            },
      },
    )
      .then(() => {
        update("platforms", `${user?.id}/platform/${platformData?.id}`, {
          is_folder_settings_set: true,
        })
          .then(() => {
            console.log("post: success");
            // navigate(`/app/email-settings/${platformData?.id}`, {
            //   state: { prevPage: "Folder Settings" },
            // });
            setRefetch(true);
            toast.success("Settings saved");
          })
          .catch((err) => {
            console.log(err);
            toast.error("Please try again");
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useLayoutEffect(() => {
    setIsLoading(true);
    fetcher(`/resources/platforms/${user?.id}`)
      .then((response) => {
        console.log("fetch: success");
        const data = response.filter(
          (platform: PlatformData) => platform.id === id,
        )[0];
        setPlatformData(data);
        setIsEvalPaused(data?.eval_paused);
        // setPlatformData(response.at(0));
        // setIsEvalPaused(response.at(0).eval_paused);
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user, id]);

  // console.log(platformData);

  // let bodyTitle = "How would you like to sort email from unknown senders?";
  // let bodySubtitle = (
  //   <p className="text-center text-base text-gray1">
  //     Choose how email from unknown senders should be sorted within your email
  //   </p>
  // );

  // if (isLoading) {
  //   return (
  //     <>
  //       <PublicNav
  //         title="Filter preference"
  //         secondaryText={platformData?.email}
  //         back={{
  //           text: `Back ${navState ? "to " + navState.prevPage : ""}`,
  //         }}
  //       />
  //       <div className="absolute h-screen w-screen flex justify-center items-center z-0">
  //         <Loader />
  //       </div>
  //     </>
  //   );
  // }

  // if (isError) {
  //   return (
  //     <>
  //       <p className="absolute h-screen w-screen flex justify-center items-center z-0">
  //         Something went wrong.
  //       </p>
  //     </>
  //   );
  // }

  return (
    <div className="w-auto flex flex-col gap-6 sm:gap-10 sm:flex-row items-start justify-between">
      <div className="flex flex-col shadow-nav items-start px-6 py-6 gap-6 rounded-md bg-white w-full max-w-400">
        <div className="flex flex-col gap-2 flex-1">
          <div className="bg-green_bg py-1 px-2 rounded w-min">
            <p className="text-green_primary text-xs">Recommended</p>
          </div>
          <h3 className="text-black_primary text-xl font-bold">
            Send to evaluating folder
          </h3>
          <p className="text-base text-gray1 md:pr-2">
            Emails that are not approved or blocked will be filtered to a folder{" "}
            <span className="font-bold">Bp: Evaluating</span>
          </p>
        </div>
        <button
          className={`${
            isEvalPaused
              ? "bg-white text-green_primary border border-green_primary"
              : "bg-green_primary text-white"
          }  h-10 rounded-md w-full`}
          onClick={() => {
            mixpanel.track(`Selected: 'Send to evaluating folder'`, {
              user_Id: user?.id,
              user_Email: user?.email,
              user_FirstName: user?.first,
              user_LastName: user?.last,
              user_Phone: user?.phone,
              user_Tier: user?.tier,
            });
            handleSavePreference(false);
          }}
        >
          <div className="flex flex-row items-center justify-center gap-3">
            {!isEvalPaused && (
              <>
                <LazyLoadImage
                  src={icons.checkWhite}
                  alt="lock icon"
                  width={15}
                />
              </>
            )}
            {!isEvalPaused ? "Selected" : "Select"}
          </div>
        </button>
      </div>
      <div className="flex flex-col shadow-nav items-start px-6 py-6 gap-6 rounded-md bg-white w-full max-w-400">
        <div className="flex flex-col gap-2 flex-1">
          <div className="bg-white py-1 px-2 rounded w-min hidden md:block">
            <p className="text-white text-xs">Blank</p>
          </div>
          <h3 className="text-black_primary text-xl font-bold">
            Send to your inbox
          </h3>
          <p className="text-base text-gray1">
            Emails that are not approved or blocked will remain in your inbox.
          </p>
        </div>
        <button
          className={`${
            isEvalPaused
              ? "bg-green_primary text-white"
              : "bg-white text-green_primary border border-green_primary"
          }  h-10 rounded-md w-full`}
          onClick={() => {
            mixpanel.track(`Selected: 'Send to your inbox'`, {
              user_Id: user?.id,
              user_Email: user?.email,
              user_FirstName: user?.first,
              user_LastName: user?.last,
              user_Phone: user?.phone,
              user_Tier: user?.tier,
            });
            handleSavePreference(true);
          }}
        >
          <div className="flex flex-row items-center justify-center gap-3">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                {isEvalPaused && (
                  <LazyLoadImage
                    src={icons.checkWhite}
                    alt="lock icon"
                    width={15}
                  />
                )}
                {isEvalPaused ? "Selected" : "Select"}
              </>
            )}
          </div>
        </button>
      </div>
    </div>
  );
};

export default FolderSettings;
