import React from "react";

interface Props { color?: string; }

const PlusIcon: React.FC<Props> = ({ color= "#181920" }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.5 12C22.5 12.5523 22.0523 13 21.5 13H13.5V21C13.5 21.5523 13.0523 22 12.5 22C11.9477 22 11.5 21.5523 11.5 21V13H3.5C2.94772 13 2.5 12.5523 2.5 12C2.5 11.4477 2.94772 11 3.5 11H11.5V3C11.5 2.44772 11.9477 2 12.5 2C13.0523 2 13.5 2.44772 13.5 3V11H21.5C22.0523 11 22.5 11.4477 22.5 12Z" fill={color}/>
    </svg>
  );
};

export default PlusIcon;
