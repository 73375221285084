import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { mountDialog } from "../../../features/util/utilSlice";
import { fetcher, post, update } from "../../../logic/util/hooks";
import { logLinkedInConversion } from "../../../logic/util/linkedConversion";
import Button from "../../primitives/Button";
import ExpandableTable from "../../primitives/atoms/GenericAntdTable/GenericAntdTable";
import MobileSubdomainAntdTable from "../../primitives/atoms/MobileSubdomainAntdTable/MobileSubdomainAntdTable";
import WhatIsApprovedSenderList from "../../primitives/dialogContent/whatIsApprovedSenderList";
import WhatIsDomain from "../../primitives/dialogContent/whatIsDomain";
import { StatusActionButtons } from "../ComponentLibrary/components/StatusActionButtons";
import PublicNav from "../landingPage/components/publicNav";
import Loader from "../../primitives/Loader/Loader";

interface DomainType {
  key: string;
  name?: string;
  email: string;
  phone: string;
  reason: string;
  relationship: string;
  contactID: string;
  platform_ref: string;
  option?: any;
  status?: string;
  domain?: string;
  parentKey?: string;
  count?: number;
  subject: string;
  subDomains?: any;
  platformID?: string;
  parentDomain?: string;
}
interface DataType {
  status?: string | null;
  list: DomainType[];
  total?: number;
}
interface DomainProps {
  id?: string;
  domain?: string;
  mailCount?: number;
  platformID?: string;
  status?: string;
  lastEmailDate?: Date;
  subDomains?: DomainType[];
  subject: string;
  parentDomain?: string;
  email: string;
  phone: string;
  reason: string;
  relationship: string;
  contactID: string;
  platform_ref: string;
}

interface PlatformData {
  ACCOUNT_REF: string;
  id: string;
  eval_paused: boolean;
  has_send_survey_enabled: boolean;
  is_domain_review_complete: boolean;
  is_folder_settings_set: boolean;
  is_request_form_settings_set: boolean;
  is_video_step_complete: boolean;
  email: string;
  platformID: string;
  platform: string;
}

// based on logic used in the ReviewDomains component
const DomainsSettings = () => {
  const navigate = useNavigate();
  const ConversionID = "12833044";

  const { user } = useSelector((state: any) => state.auth);
  const { id } = useParams();
  const { state: navState } = useLocation();
  // console.log({ navState });

  const [domains, setDomains] = useState<any[]>([]);
  const [expanded, setexpanded] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [platformData, setPlatformData] = useState<PlatformData | null>(null);
  const email = platformData?.email;
  const platformID = platformData?.platformID;
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<DataType | null>(null);

  const handleSavePreference = () => {
    update("platforms", `${user?.id}/platform/${platformData?.id}`, {
      is_domain_review_complete: true,
    })
      .then(() => {
        console.log("post: success");
        localStorage.setItem("isDomainsToastOn", JSON.stringify(true));
        if (platformID) {
          localStorage.setItem("newAccountConnectedEmail", email ?? "");
        }
        logLinkedInConversion(ConversionID);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        if (navState.prevPage === "Email Settings") {
          navigate(`/app/email-settings/${platformData?.id}`, {
            state: { prevPage: "Domains Review" },
          });
        } else if (
          ["Approved Sender List", "Rereview Domains"].includes(
            navState.prevPage,
          )
        ) {
          navigate(`/app/approved-sender-list`, {
            state: { prevPage: "Domains Review" },
          });
        } else {
          window.history.back();
        }
      });
  };

  // get domains data which has been pre-fetched in the email settings page (to minimise load times)
  useEffect(() => {
    const storedInboxData = localStorage.getItem(
      `inboxData_${platformData?.id}`,
    );
    const storedInboxFetchError = localStorage.getItem(
      `inboxFetchError_${platformData?.id}`,
    );
    setData(storedInboxData ? JSON.parse(storedInboxData) : null);
    setError(storedInboxFetchError ? JSON.parse(storedInboxFetchError) : null);
  }, [platformData?.id]);

  const dispatch = useDispatch();
  const [domainsRemainingCount, setDomainsRemainingCount] = useState<number>(
    data?.list?.length || 0,
  );

  // get platform data
  useLayoutEffect(() => {
    setIsLoading(true);
    fetcher(`/resources/platforms/${user?.id}`)
      .then((response) => {
        console.log("fetch: success");
        const data = response.filter(
          (platform: PlatformData) => platform.id === id,
        )[0];
        setPlatformData(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user, id]);

  const whatIsDomainDialog = () =>
    dispatch(
      mountDialog({
        component: <WhatIsDomain />,
        headline: "What’s a domain?",
      }),
    );
  const whatIsApprovedSenderListDialog = () =>
    dispatch(
      mountDialog({
        component: <WhatIsApprovedSenderList />,
        headline: "What’s the approved sender list?",
      }),
    );

  useEffect(() => {
    if (data && data.list) {
      let count = 0;
      let domainKeys: any[] = [];
      const domainsData = data.list
        .filter(({ domain }) => domain !== "bulletproofinbox.com")
        .map(
          ({
            id,
            domain,
            mailCount,
            platformID,
            status,
            lastEmailDate,
            subDomains,
            subject,
          }: DomainProps) => {
            const [_, ...day] = new Date(lastEmailDate || 0)
              .toDateString()
              .split(" ");
            count++;
            domainKeys.push(id ? id : count);

            return {
              key: id || count,
              domain: `@${domain}`,
              count: mailCount,
              lastEmail: day.join(" "),
              status,
              platformID,
              subDomains: subDomains?.map(
                ({
                  id,
                  domain,
                  mailCount,
                  platformID,
                  status,
                  parentDomain,
                  lastEmailDate,
                  subject,
                }: DomainProps) => {
                  const [_, ...day] = new Date(lastEmailDate || 0)
                    .toDateString()
                    .split(" ");
                  count++;
                  return {
                    key: id || count,
                    domain: `@${domain}`,
                    count: mailCount,
                    status,
                    platformID,
                    parentDomain,
                    subject,
                    lastEmail: day.join(" "),
                  };
                },
              ),
              subject,
            };
          },
        );
      // domains and subdomains count
      setDomainsRemainingCount(count);

      // Creating parentKey property in subdomains
      const serializedDomains = domainsData.map((data: any) => {
        if (data.subDomains?.length) {
          return {
            ...data,
            subDomains: data.subDomains?.map((sub: any) => ({
              ...sub,
              parentKey: data.key,
            })),
          };
        } else {
          return data;
        }
      });
      setDomains(serializedDomains);
      setexpanded(domainKeys);
    }
  }, [data, data?.status]);

  useEffect(() => {
    let count = 0;
    domains.forEach(({ status, subDomains }) => {
      if (status === "evaluating") {
        count++;
        count +=
          subDomains?.filter(
            ({ status }: { status?: string }) => status === "evaluating",
          )?.length ?? 0;
      }
    });
    setDomainsRemainingCount(count);
  }, [domains]);

  // This function calls APIs for adding data in database from radis
  const addDomainInDB = async (domain: DomainType) => {
    if (domain.parentKey) {
      // for subDomain
      await post(
        "onboarding",
        `appointDomain/account/${user?.id}/platformID/${domain.platformID}`,
        {
          id: domain.key,
          domain: domain.domain?.replace("@", ""),
          status: domain.status,
          parentDomain: domain.parentDomain,
        },
      );
    } else {
      // for domain
      await post(
        "onboarding",
        `appointDomain/account/${user?.id}/platformID/${domain.platformID}`,
        {
          id: domain.key,
          domain: domain.domain?.replace("@", ""),
          status: domain.status,
        },
      );
    }
  };
  /* This function is going to expand a row if its not expanded and collapse an expanded row*/
  const expandableHandler = (record: DomainType) => {
    const found = expanded.find((item) => item == record.key);
    if (!!found) {
      //row is already expanded
      setexpanded((expanded) => expanded.filter((item) => item !== found));
    } else {
      //expand this row
      setexpanded((expanded) => [...expanded, record.key]);
    }
  };

  /* This code block will basically be used to select/unselect a subdomain. Right now we are only doing it inside the state, later on here we will call the API*/
  const setSubdomainToCheckUncheck = (
    docs: DomainType,
    value: any,
    option: string,
  ) => {
    if (docs.subDomains?.length) {
      const x = docs.subDomains.map((subdoc: DomainType) => {
        if (subdoc.key === value.key) {
          addDomainInDB({ ...subdoc, status: option });
          return { ...subdoc, status: option };
        } else {
          return subdoc;
        }
      });
      return x;
    }
  };

  //Action button handler. If we select/unselect a domain or subdomain this function runs
  const actionButtonClickHandler = (value: any, option: string) => {
    if (value?.parentKey) {
      //this code part will be used to select/unselect a subdomain
      const updated = domains.map((doc: any) =>
        doc.key === value.parentKey
          ? {
              ...doc,
              subDomains: setSubdomainToCheckUncheck(doc, value, option),
            }
          : doc,
      );
      setDomains(updated);
    } else {
      //this code part will be used to select/unselect a domain
      setDomains((data) =>
        data.map((data: DomainType) => {
          if (data.key === value.key) {
            addDomainInDB({ ...data, status: option });
            return { ...data, status: option };
          } else {
            return data;
          }
        }),
      );
    }
  };

  function truncateText(text: string, maxWords: number) {
    // Split the text into an array of words
    var words = text ? text?.split(" ") : "";

    // If the number of words is less than or equal to the maximum, return the original text
    if (words?.length <= maxWords) {
      return text;
    }

    // Otherwise, join the first `maxWords` words and append ellipsis
    var truncatedText =
      (words as string[])?.slice(0, maxWords)?.join(" ") + "...";
    return truncatedText;
  }

  const columns = [
    {
      title: () => <p>Domain name</p>,
      width: "20%",
      dataIndex: "domain",
      key: "domain",
      render: (text: string, key: any) => (
        <a
          className={key.parentKey ? `ml-7 p-1` : "font-bold"}
          onClick={(record) => expandableHandler(key)}
        >
          {text}
        </a>
      ),
    },
    {
      title: () => <p className="flex justify-center">Number of emails</p>,
      width: "15%",
      dataIndex: "count",
      key: "count",
      render: (text: string, key: any) => (
        <a
          onClick={(record) => expandableHandler(key)}
          className={
            key.parentKey ? `ml-5 flex justify-center` : "flex justify-center"
          }
        >
          {text}
        </a>
      ),
    },
    {
      title: () => <p>Last Email</p>,
      width: "14%",
      dataIndex: "lastEmail",
      key: "lastEmail",
      render: (text: string, key: any) => (
        <a
          onClick={(record) => expandableHandler(key)}
          className={key.parentKey ? `ml-2` : ""}
        >
          {text}
        </a>
      ),
    },
    {
      title: () => <p>Subject</p>,
      width: "30%",
      dataIndex: "subject",
      key: "subject",
      render: (text: string, key: any) => (
        <a
          onClick={(record) => expandableHandler(key)}
          className={key.parentKey ? `ml-2` : ""}
        >
          {truncateText(text, 10)}
        </a>
      ),
    },
    {
      title: () => <p>Status</p>,
      key: "status",
      width: "20%",
      render: (_: any, record: DomainType) => {
        return (
          <div className={record.parentKey ? "py-4 pl-1" : "py-4"}>
            <StatusActionButtons
              value={record}
              onClickHandler={actionButtonClickHandler}
              options={["approved", "blocked"]}
            />
          </div>
        );
      },
    },
  ];

  const dataOfSubDomains = domains?.map(function (item: any) {
    return {
      child: item.subDomains,
      parentId: item.key,
    };
  });

  if (isLoading) {
    return (
      <>
        <PublicNav
          title="Review senders"
          secondaryText={platformData?.email}
          back={{
            text: ``,
          }}
        />
        <div className="absolute h-screen w-screen flex flex-col gap-4 justify-center items-center z-0">
          <Loader />
        </div>
      </>
    );
  }

  if (error) {
    return (
      <div>
        <PublicNav
          title="Review senders"
          secondaryText={platformData?.email}
          back={{
            text: ``,
          }}
        />
        <h1>There was an error!</h1>
      </div>
    );
  }

  if (data?.total === 0) {
    return (
      <>
        <PublicNav
          title="Review senders"
          secondaryText={platformData?.email}
          back={{
            text: ``,
          }}
        />
        <div className="pt-32 justify-center items-center flex">
          <div className="flex justify-center w-2/3 md:w-1/3 items-center flex-col">
            <p className="text-black_primary text-2xl font-bold text-center">
              Way to go! There are no new domains for you to review at this
              time.
            </p>
            <Button
              format={{
                id: "DomainReviewBtn",
                type: "primary",
                width: "w-fit mt-5 hidden md:flex",
                content: "Back to email settings",
                isFilled: true,
                onclick: handleSavePreference,
              }}
            />
            <Button
              format={{
                id: "DomainReviewBtn",
                type: "primary",
                width: "w-fit mt-5 px-10 md:hidden",
                content: "Back",
                isFilled: true,
                onclick: handleSavePreference,
              }}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <PublicNav
        title="Review senders"
        secondaryText={platformData?.email}
        back={{
          text: ``,
        }}
      />
      <div className="w-full pt-24 px-8 sm:px-10">
        <div className="flex flex-col lg:flex-row gap-4 justify-between sm:pb-8">
          <div className="flex flex-col gap-2">
            <p className="text-black_primary text-3xl xs:text-2xl font-bold">
              Add to your approved sender list
            </p>
            <div>
              <p className="text-gray1 text-base font-normal">
                Approve the domains (websites) you want to receive emails from.
                Block ones you don’t.
              </p>
              <p className="text-gray1 text-base font-normal">
                You can also skip any domains you aren’t sure about and decide
                on them later.
                <span
                  className="underline decoration-current cursor-pointer ml-2"
                  onClick={() => whatIsApprovedSenderListDialog()}
                >
                  Learn more.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center w-full pb-32">
          <div className="w-full">
            <div className="block lg:hidden max-w-lg lg:max-w-none m-auto">
              <MobileSubdomainAntdTable
                data={domains}
                columns={columns}
                dataForChildTable={dataOfSubDomains}
                actionButtonClickHandler={actionButtonClickHandler}
                deleteOptionVisible={false}
              />
            </div>
            <div className="w-full lg:flex hidden  justify-center">
              <ExpandableTable
                data={domains}
                columns={columns}
                dataForChildTable={dataOfSubDomains}
                expanded={expanded}
                pagination={false}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 flex flex-col sm:flex-row sm:justify-between w-full bg-white shadow-nav py-4 px-8 sm:px-12 gap-3">
        <div className="flex flex-row gap-5 items-center justify-between">
          <p className="text-black_primary text-lg font-normal whitespace-nowrap">
            {domainsRemainingCount} remaining
          </p>
          <p
            className="text-green_primary text-lg font-normal whitespace-nowrap underline decoration-current cursor-pointer sm:text-base"
            onClick={() => whatIsDomainDialog()}
          >
            What’s a domain?
          </p>
        </div>
        <Button
          format={{
            id: "DomainReviewBtn",
            type: "primary",
            width: "w-full sm:w-72",
            content: "Save preferences",
            isFilled: true,
            onclick: handleSavePreference,
          }}
        />
      </div>
    </>
  );
};

export default DomainsSettings;
