import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ROUTE_TITLES } from "../../../constants/constants";
import Footer from "../../primitives/Footer";
import PublicNav from "../landingPage/components/publicNav";

const PublicRoute = () => {
  const location = useLocation();
  const { pathname } = location;
  const title = Object.keys(ROUTE_TITLES).find((title) =>
    pathname.includes(title),
  );
  const isFooterHidden = pathname === "/personalize";

  return (
    <div className="flex flex-col h-full justify-between">
      <div>
        {!!title ? <PublicNav title={ROUTE_TITLES[title]} /> : null}
        <Outlet />
      </div>
      <div className={`${isFooterHidden ? "hidden" : ""}`}>
        <Footer />
      </div>
    </div>
  );
};

export default PublicRoute;
