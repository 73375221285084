import React, { useEffect, useMemo, useRef, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";

import { updateUserData } from "../../../../features/auth/authSlice";
import useError from "../../../../hooks/useError";
import { post, update } from "../../../../logic/util/hooks";
import Button from "../../Button";
import Label from "../../Label";
import StaticAlert from "../../atoms/StaticAlert";
import Field from "../Field";
import ReferFriendField from "../ReferFriendField";
import { personalize, initialPersonalize } from "./schema.personalize";
import { sanitizeObject } from "../../../../logic/util/helpers";
import mixpanel from "mixpanel-browser";

interface PersonalizeBulletproofProps {
  content?: string;
}

interface DecodedToken {
  sub: string;
  exp: number;
}

function PersonalizeBulletproof({
  content,
  ...props
}: PersonalizeBulletproofProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);
  const { error, mountError } = useError();
  const { state } = useLocation();
  const formikRef = useRef<FormikProps<typeof initialPersonalize>>(null);

  const [jwtDecoded, setJwtDecoded] = useState<DecodedToken>(
    jwtDecode<DecodedToken>(localStorage.getItem("jwtToken") || ""),
  );
  const isAuth0Signup = useMemo(
    () => jwtDecoded.sub.startsWith("auth0"),
    [jwtDecoded],
  );
  // console.log({isAuth0Signup});

  useEffect(() => {
    if (user?.id) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'personalize_page',
        'user_id': user.id
      });
    }
  }, [])

  useEffect(() => {
    const jwtDecoded = jwtDecode<DecodedToken>(
      localStorage.getItem("jwtToken") || "",
    );
    setJwtDecoded(jwtDecoded);
  }, []);
  // console.log({ jwtDecoded });

  const addPlatform = async () => {
    try {
      const addPlatformResponse = await post(
        "platforms",
        `${user?.id}/platform`,
        {
          platformType: "google",
          accountID: user.id,
          email: user.email,
          platformID: user?.email,
        },
      );
      if (addPlatformResponse.statusText !== "OK") {
        throw new Error(
          `${addPlatformResponse?.status} ${addPlatformResponse.statusText}`,
        );
      }
      console.log("platform added successfully!");
      return addPlatformResponse.data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (formikRef.current && state?.first && state?.last) {
      formikRef.current.setFieldValue("first", state.first);
      formikRef.current.setFieldValue("last", state.last);
    }
  }, [state, formikRef]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialPersonalize}
      validationSchema={personalize}
      // validator={() => ({})}
      onSubmit={async (values) => {
        try {
          const payload = {
            first: values.first,
            last: values.last,
            phone: values.phone,
            referral_email: values.referral_email,
            user_id: user.id
          };
          const moddedPayload = sanitizeObject(payload); //Removing undefined and empty keys
          // ANCHOR - need for future use
          //   if (process.env.REACT_APP_SHOW_PIN === 'true') {
          // 	await post("pins", "use", { pin: values.pin });
          //   }
          const { data } = await post(
            "accounts",
            `${user.id}/personalize`,
            moddedPayload,
          );
          const addName = localStorage.getItem("personalize_user");
          // clicking on submit button takes to you connect email page
          if (data && state.token) {
            localStorage.setItem("userData", JSON.stringify(data));
            dispatch(updateUserData(data));
            mixpanel.track("Clicked: 'Save & continue' on Personalize Page", {
              user_Id: data?.id,
              user_Email: data?.email,
              user_FirstName: data?.first,
              user_LastName: data?.last,
              user_Phone: data?.phone,
              user_Tier: data?.tier,
            });
            mixpanel.identify(data.email);
            mixpanel.people.set({
              $email: data?.email,
              $first_name: data?.firstName,
              $last_name: data?.lastName,
              $user_id: data.id,
              // Add more properties as needed
            });
            if (isAuth0Signup) {
              navigate("/app/connect-account", {
                state: { isWelcomeBannerAuth0: true },
              });
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                'event': 'new_registration',
                'user_id': data.id
              });
            } else if (!isAuth0Signup && addName) {
              localStorage.removeItem("personalize_user");
              if (state?.newUser) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  'event': 'new_registration',
                  'user_id': data.id
                });
              }
              navigate("/app/email-accounts");
            } else {
              const platformData = await addPlatform();
              console.log({ platformData });
              localStorage.setItem(
                `isSwitchedOn_${platformData.id}`,
                JSON.stringify(false),
              );
              navigate(`/app/email-settings/${platformData.id}`);
              localStorage.setItem("lastPlatformCreated", `${user?.email}`);
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                'event': 'new_registration',
                'user_id': data.id
              });
            }
          }
        } catch (error) {
          mountError(error);
        }

      }}
    >
      {(props) => {
        return (
          <React.Fragment>
            {/* <div className="sticky top-0 z-50 shadow-lg bg-white flex flex-col items-center justify-center sm:hidden">
              <div className="py-2">
                <Label
                  format={{
                    color: "text-black_primary",
                    text: "Personal info",
                    fontSize: "text-2xl",
                    textAlign: "center",
                    fontWeight: "font-bold",
                  }}
                />
              </div>
            </div> */}
            <div className="sticky top-0 z-40 shadow-lg bg-white flex flex-col items-center justify-center sm:hidden">
              <div className="py-2">
                <Label
                  format={{
                    color: "text-black_primary",
                    text: "Personal info",
                    fontSize: "text-2xl",
                    textAlign: "center",
                    fontWeight: "font-bold",
                  }}
                />
              </div>
            </div>
            <Form>
              <input type="hidden" value="something" />
              <div className="flex flex-col items-center justify-start mt-6 mb-16 h-full gap-6 px-6 sm:px-0">
                {error && <StaticAlert type="error" message={error} />}
                <div className="flex flex-col gap-6">
                  <div>
                    <Label
                      format={{
                        color: "text-black_primary",
                        text: "Tell us about yourself",
                        fontSize: "text-2xl",
                        textAlign: "center",
                        fontWeight: "font-bold",
                      }}
                    />
                    <Label
                      format={{
                        color: "text-gray1",
                        text: "This info will be used to improve your experience.",
                        fontSize: "text-base",
                        textAlign: "center",
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-3 w-full">
                    <Field
                      name="first"
                      key="first"
                      label="First name"
                      placeholder="Your first name"
                    />
                    <Field
                      name="last"
                      key="last"
                      label="Last name"
                      placeholder="Your last name"
                    />
                    <Field
                      name="phone"
                      key="phone"
                      label="Phone number (optional)"
                      placeholder="xxx-xxx-xxxx"
                    />
                  </div>
                </div>
                <div className=" bg-orange_bg border-2 p-4 sm:py-4 sm:px-7 mb-6 border-orange_bg rounded-md">
                  <p className="text-orange_dark font-bold pl-2 pt-2 text-base">
                    Did someone refer you?
                  </p>
                  <p className="text-black_primary font-normal pl-2 text-sm ">
                    Add their email address or promo code and you’ll both get a
                    month free!
                  </p>
                  <p className="text-gray1 font-normal text-sm mt-3 pl-2 mb-1">
                    Email/promo code of the person who referred you (optional)
                  </p>
                  <ReferFriendField
                    name="referral_email"
                    key="referral_email"
                    placeholder="john@email.com"
                    width="w-full"
                  />
                </div>
              </div>
              <div className="fixed bottom-0 w-full items-center justify-center flex flex-col bg-white shadow overflow-hidden">
                <div className="w-full h-px bg-gray_Xlight"></div>
                <div className="mt-4 mb-4">
                  <Button
                    format={{
                      type: "submit",
                      id: "signIn",
                      isFilled: true,
                      content: `Save & continue`,
                      isLoading: props.isSubmitting,
                      width: "w-72",
                    }}
                  />
                </div>
              </div>
            </Form>
          </React.Fragment>
        );
      }}
    </Formik>
  );
}

export default PersonalizeBulletproof;
