import React from "react";
import Button from "../Button";
import { useDispatch } from "react-redux";
import { unMountDialog } from "../../../features/util/utilSlice";

function WhatIsDomain() {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col gap-6">
      <p className="text-black_primary text-base font-normal">
        A domain is a website url associated with a specific company. For
        example, @amazon.com, @gmail.com, @LinkedIn.com are domains.
      </p>
      <p className="text-black_primary text-base font-normal">
        Every email account is a specific address that’s part of a larger
        domain. For example, john.doe@amazon.com, bob@gmail.com.{" "}
      </p>
      <p className="text-black_primary text-base font-normal">
        Bulletproof Inbox gives you the power to block or approve individual
        email accounts (john.doe@amazon.com) or approve or block entire domains
        (@amazon.com)
      </p>
      <div className={`flex justify-start items-center xs:flex-col flex-row`}>
        <div className={`xs:w-full`}>
          <Button
            format={{
              id: "gotItButton",
              type: "primary",
              isFilled: true,
              content: "Got it",
              onclick: () => dispatch(unMountDialog()),
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default WhatIsDomain;
