/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../../../features/auth/authSlice";
import bpLogo from "../../../assets/graphics/Bulletproof_Logo.png";
import Label from "../../../primitives/Label";
import logoutLogo from "./../../../assets/icons/arrow_out.svg";
import logoutLogoMedium from "./../../../assets/icons/arrow_out_medium.svg";
import barsLogoMedium from "./../../../assets/icons/bars_medium.svg";
import cross from "./../../../assets/icons/cross.svg";
import emptyArrowLeft from "./../../../assets/icons/empty_arrow_left.svg";
import ProfileLink from "./profileLink";
import { fetcher } from "../../../../logic/util/hooks";
import mixpanel from "mixpanel-browser";
import HelpLink from "./helpLink";
import { IoMdHelpCircleOutline, IoIosHelpCircleOutline } from "react-icons/io";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface BpiNavbarProps {
  items: {
    to: string;
    icon: string;
    title: string;
    shortTitle?: string;
  }[];
  openDrawer?: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function BpiNavbar({
  items,
  openDrawer = false,
  setOpenDrawer,
}: BpiNavbarProps) {
  const { user } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.first === undefined) {
      fetcher(`/auth/bpi/logout/${user?.id}`)
        .then(() => {
          dispatch(logout());
        })
        .catch((e) => console.log(e));
    }
  }, []);

  const handleLogout = () => {
    fetcher(`/auth/bpi/logout/${user.id}`)
      .then(() => {
        dispatch(logout());
      })
      .catch((e) => console.log(e));
  };

  return (
    <div
      className={`flex items-center justify-between min-h-fit w-full overflow-x-hidden mb-0.5 ${
        openDrawer ? "h-full" : "h-16"
      } isolate font-custom shadow-nav ${
        openDrawer ? "bg-transparent" : "bg-white"
      } px-4 xs:px-0 overflow-y-hidden`}
    >
      {!openDrawer && (
        <img
          className="sm:hidden sm:w-0 px-4"
          src={barsLogoMedium}
          alt="menu"
          role="button"
          onClick={() => setOpenDrawer(true)}
        />
      )}
      {!openDrawer && (
        <>
          <div className=" flex flex-row justify-start items-center sm:gap-6 md:gap-16">
            <div className="flex flex-row w-full sm:w-auto justify-center sm:justify-start">
              <Link to={"/app/email-accounts"}>
                <span className="flex flex-row h-16 w-40 justify-center items-center">
                  <LazyLoadImage src={bpLogo} alt="bullet proof inbox logo" />
                </span>
              </Link>
            </div>
            <ul className="tab-header hidden lg:flex justify-center items-center text-black_primary w-0 sm:w-auto">
              {items.map((item, idx) => (
                <NavLink
                  key={idx}
                  to={item.to}
                  className={({ isActive }) =>
                    isActive
                      ? "active cursor-pointer px-1 border-b-2 bg-green_bg rounded-t-2xl border-green_primary text-green_primary h-16 flex flex-row"
                      : undefined
                  }
                >
                  <li
                    key={idx}
                    className="cursor-pointer flex flex-row px-1 mt-2"
                  >
                    <div
                      className={
                        "flex flex-row px-4 place-items-center place-content-center w-max"
                      }
                    >
                      <LazyLoadImage src={item.icon} alt="menu icon" width={17} height={10} />
                      {item.title}
                    </div>
                  </li>
                </NavLink>
              ))}
            </ul>
            <ul className="tab-header lg:hidden sm:flex gap-2 justify-center items-center text-black_primary hidden w-0 sm:w-auto">
              {items.map((item, idx) => (
                <NavLink
                  key={idx}
                  to={item.to}
                  className={({ isActive }) =>
                    isActive
                      ? "active cursor-pointer px-1 border-b-2 bg-green_bg rounded-t-2xl border-green_primary text-green_primary h-16 flex flex-row"
                      : undefined
                  }
                >
                  <li
                    key={idx}
                    className="cursor-pointer flex flex-row px-1 mt-2"
                  >
                    <div
                      className={
                        "flex flex-col px-2 md:px-4 place-items-center place-content-center w-max"
                      }
                    >
                      <LazyLoadImage src={item.icon} alt="menu icon" />
                      {item.shortTitle}
                    </div>
                  </li>
                </NavLink>
              ))}
            </ul>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div className="xs:hidden xs:w-0 mr-10">
              <HelpLink />
            </div>

            <div className="xs:hidden w-36 xs:w-0">
              <ProfileLink key={user?.id} user={user} />
            </div>

            <div
              className="flex items-center px-2 xs:w-10 xs:h-16"
              onClick={() => {
                mixpanel.track(`Clicked: 'Logout' `);
                handleLogout();
              }}
            >
              <LazyLoadImage src={logoutLogoMedium} alt="logout" width={20} />
              <div className="hidden mdHeader:block w-0 sm:w-auto">
                <Label
                  format={{
                    color: "text-green_primary",
                    fontFamily: "font-custom",
                    fontSize: "text-base",
                    text: `Logout`,
                  }}
                  isClickable={true}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {openDrawer && (
        <div className="fixed h-screen max-h-screen bg-white w-full items-start">
          <div className="px-4 pt-16 pb-6 flex flex-row justify-between items-center">
            <Label
              format={{
                text: "Menu",
                color: "text-black_primary",
                fontSize: "text-2xl",
                fontWeight: "font-bold",
              }}
            />
            <LazyLoadImage
              src={cross}
              alt="Close menu"
              role="button"
              onClick={() => setOpenDrawer(false)}
            />
          </div>
          <div
            className="px-4 py-6 flex flex-row items-center justify-between"
            onClick={() => setOpenDrawer(false)}
          >
            <ProfileLink key={user?.id} user={user} />
            <LazyLoadImage src={emptyArrowLeft} alt="empty_arrow_left" />
          </div>
          <div className="h-3/6">
            <ul className="tab-header w-full h-full justify-start items-center text-black_primary">
              {items.map((item, idx) => (
                <NavLink
                  key={idx}
                  to={item.to}
                  className={({ isActive }) =>
                    isActive
                      ? "active flex cursor-pointer bg-green_bg w-full text-green_primary border-green_primary"
                      : undefined
                  }
                >
                  <li
                    key={idx}
                    className="cursor-pointer flex px-4 py-6 w-full"
                    onClick={() => setOpenDrawer(false)}
                  >
                    <LazyLoadImage src={item.icon} alt="menu icon" />
                    <Label
                      format={{
                        text: item.title,
                        fontSize: "font-base",
                      }}
                    />
                  </li>
                </NavLink>
              ))}
            </ul>
            <div className="flex flex-col gap-3">
              <div className="flex px-4 items-center" onClick={handleLogout}>
                <LazyLoadImage src={logoutLogo} alt="logout" />
                <Label
                  format={{
                    color: "text-green_primary",
                    fontFamily: "font-custom",
                    fontSize: "text-base",
                    text: `Logout`,
                  }}
                  isClickable={true}
                />
              </div>
              <a
                className="flex items-center gap-1 px-3"
                href="https://www.bulletproofinbox.com/new-member-onboarding-guide"
                target="_blank"
                rel="noreferrer"
              >
                <IoIosHelpCircleOutline className="w-5 h-5 text-green_primary" />
                <p className="text-green_primary font-custom text-base underline">
                  Help
                </p>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
