import { Form, Formik } from "formik";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { logout } from "../../../../features/auth/authSlice";
import useError from "../../../../hooks/useError";
import { fetcher, post } from "../../../../logic/util/hooks";
import Button from "../../Button";
import StaticAlert from "../../atoms/StaticAlert";
import PasswordField from "../PasswordField";
import PasswordTick from "../registration/PasswordTick";
import {
  changePasswordValidation,
  initialChangePassword,
  changePasswordValidationGoogle,
  // initialChangePasswordGoogle,
} from "./schema.ChangePassword";
import { unMountDialog } from "../../../../features/util/utilSlice";
import * as Sentry from "@sentry/react";
interface TokenType {
  name: string;
  exp: number;
  iss?: string;
}
interface Values {
  currentPassword?: string;
  password: string;
  confirm: string;
}

function ChangePasswordForm() {
  const { user } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const token = localStorage.getItem("jwtToken") || "";
  const { error, mountError } = useError();
  const [googleAuth, setGoogleAuth] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(
    initialChangePassword,
  );
  const [formSchema, setFormSchema] = useState(changePasswordValidation);
  const [disableChangePassword, setDisableChangePassword] = useState(false);
  const navigate = useNavigate();
  const isTokenValid = () => {
    const decodedToken = jwtDecode<TokenType>(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    const decodedToken = jwtDecode<TokenType>(token);
    if (decodedToken.iss === "https://accounts.google.com") {
      setGoogleAuth(true);
    }
  }, []);

  useEffect(() => {
    if (!isTokenValid()) {
      mountError({
        response: {
          data: {
            errorMessage: "Something went wrong! please sign in again...",
          },
        },
      });
      setDisableChangePassword(true);
    }
  }, []);

  const onSubmitHandler = async (values: Values) => {
    const propsValues: Values = values;
    try {
      if (!isTokenValid()) {
        mountError({
          response: { data: { errorMessage: "Password reset link expired!" } },
        });
        setDisableChangePassword(true);
      } else {
        if (googleAuth === true) {
          delete propsValues.currentPassword;
        }
        const { data } = await post(
          "auth",
          `bpi/resetPassword?token=${token}`,
          { email: user.email, ...propsValues },
        );
        dispatch(unMountDialog());
        fetcher(`/auth/bpi/logout/${user.id}`)
          .then(() => {
            dispatch(logout());
          })
          .catch((e) => console.log(e));
        navigate("/login");
      }
    } catch (error) {
      Sentry.captureException(error);
      mountError(error);
    }
  };

  return (
    <Formik
      initialValues={initialChangePassword}
      validationSchema={
        googleAuth === true
          ? changePasswordValidationGoogle
          : changePasswordValidation
      }
      onSubmit={onSubmitHandler}
    >
      {(props) => {
        return (
          <Form className="flex flex-col max-w-md w-full">
            {error && <StaticAlert type="error" message={error} />}
            <div className={`flex flex-col`}>
              <div className="gap-6 flex flex-col">
                {googleAuth === false && (
                  <PasswordField
                    name="currentPassword"
                    key="currentPassword"
                    label="Current Password"
                    placeholder=""
                    subLabel=""
                    confirmField={true}
                  />
                )}
                <div>
                  <PasswordField
                    name="password"
                    key="password"
                    label="New Password"
                    placeholder=""
                    subLabel="8+ characters, at least one upper case, lower case, number, and special character"
                    additionalFields={props}
                  />
                  {/* <div className={`${!props.touched.password && 'h-0'}`}>
                        <PasswordTick field={props} />
                      </div> */}
                </div>
                <PasswordField
                  name="confirm"
                  key="confirm"
                  label="Confirm Password"
                  placeholder=""
                  subLabel=""
                  confirmField={true}
                />
              </div>
            </div>
            <div className="flex flex-row xs:flex-col xs:mt-6 gap-2 xs:gap-0 items-center justify-start xs:w-full">
              <div className="xs:w-full">
                <Button
                  format={{
                    type: "submit",
                    id: "saveChanges",
                    isFilled: true,
                    content: "Save changes",
                    isLoading: props.isSubmitting,
                  }}
                  isDisabled={disableChangePassword}
                />
              </div>
              <div className="xs:w-full xs:-mt-3">
                <Button
                  format={{
                    type: "primary",
                    id: "resetPassword",
                    isFilled: false,
                    content: "Cancel",
                    onclick: () => dispatch(unMountDialog()),
                  }}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ChangePasswordForm;
