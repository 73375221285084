import { Space, Table, Tag, TablePaginationConfig } from "antd";
import { ColumnType as AntColumnType, ColumnGroupType } from "antd/lib/table";
import PropTypes from "prop-types";
import "./antdTable.css";

interface ChildSubDomain {
  key: string;
  domain: string;
  count: number;
  subject: string;
  status: string;
}

interface DataForChildTableType {
  parentId: string;
  child?: DataType[];
}
interface DataType {
  key: string;
  name?: string;
  email: string;
  phone: string;
  reason: string;
  relationship: string;
  contactID: string;
  platform_ref: string;
  option?: any;
  status?: string;
  domain?: string;
  parentKey?: string;
  count?: number;
  subject?: string;
}
interface ExpandableTableProps {
  errorInRowKey?: string;
  data?: DataType[];
  columns?: (AntColumnType<any> | ColumnGroupType<any>)[];
  dataForChildTable?: DataForChildTableType[];
  expanded?: string[];
  pagination?: false | TablePaginationConfig | undefined;
}

const ExpandableTable = ({
  errorInRowKey,
  data,
  columns,
  dataForChildTable,
  expanded,
  pagination = false,
}: ExpandableTableProps) => {

  return (
    <div className="w-full flex justify-center ">
      <div className="w-full">
        <Table
          className="w-full text-left pt-3 "
          rowClassName={(record, index) =>
            record.key === errorInRowKey
              ? "bg-red10 font-custom border-b border-gray3 "
              : "border-b font-custom border-gray3 "
          }
          tableLayout="auto"
          expandable={{
            rowExpandable: (record) => true,
            expandedRowRender: (record) => {
              const found = dataForChildTable?.find(
                (domain) => domain.parentId === record.key,
              );
              if (found && found.child?.length) {
                return (
                  <Table
                    rowClassName={
                      " border-l-2 border-b font-custom w-fit border-gray3 "
                    }
                    columns={columns}
                    scroll={{ x: "max-content" }}
                    dataSource={found.child}
                    pagination={false}
                    showHeader={false}
                  />
                );
              } else return null;
            }, //inside the expanded row
            // expandRowByClick: true, //expandable on clicking anywhere
            expandedRowKeys: expanded,
          }}
          columns={columns}
          dataSource={data}
          pagination={pagination}
        />
      </div>
    </div>
  );
};

ExpandableTable.propTypes = {
  data: PropTypes.array.isRequired,
  dataForChildTable: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.any,
      width: PropTypes.string,
      dataIndex: PropTypes.string,
      key: PropTypes.string,
      render: PropTypes.any,
    }),
  ),
  expanded: PropTypes.arrayOf(PropTypes.string),
  pagination: PropTypes.bool.isRequired,
};

export default ExpandableTable;
