import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { del } from "../../../logic/util/hooks";
import Button from "../Button";
import { unMountDialog } from "../../../features/util/utilSlice";
import { RootState } from "../../../types/Redux";

interface DeleteContactProps {
  contactId?: string;
  refetchContacts: Function;
}

const DeleteContact: React.FC<DeleteContactProps> = ({
  contactId,
  refetchContacts,
}) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      <p className="text-gray1">
        Deleting this sender will treat them like a new sender. If they email
        you, we’ll send a survey to them asking why they want to contact you (If
        you have the survey feature enabled).
      </p>
      <div
        className={`flex justify-end items-center sm:gap-2 xs:flex-col flex-row`}
      >
        <div className={`xs:w-full xs:mt-6`}>
          <Button
            format={{
              id: "DeleteContactButton",
              type: "delete",
              content: "Yes, delete this sender",
              isFilled: true,
              isLoading,
              width: !isLoading ? "w-fit" : "w-40",
              onclick: () => {
                setIsLoading(true);
                del("contacts", `${user?.id}/${contactId}`)
                  .then(() => {
                    setIsLoading(false);
                    dispatch(unMountDialog());
                    refetchContacts();
                  })
                  .catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                  });
              },
            }}
          />
        </div>
        <div className={`xs:w-full xs:-mt-3`}>
          <Button
            format={{
              id: "cancelButton",
              type: "primary",
              isFilled: false,
              content: "Cancel",
              onclick: () => dispatch(unMountDialog()),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DeleteContact;
