import React from "react";
import { useNavigate } from "react-router";
import Button from "../../../primitives/Button";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center h-fit mt-24 sm:mt-0 sm:h-screen px-6 justify-center gap-y-3">
      <svg
        width="54"
        height="54"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M53.6673 8.33301V45.6663C53.6673 50.0846 50.0856 53.6663 45.6673 53.6663H8.33398C3.91571 53.6663 0.333984 50.0846 0.333984 45.6663V8.33301C0.333984 3.91473 3.91571 0.333008 8.33398 0.333008H45.6673C50.0856 0.333008 53.6673 3.91473 53.6673 8.33301ZM21.0007 13.1063C23.2353 11.2805 26.1807 10.5736 29.0007 11.1863L29.0273 11.1063C33.1508 11.9903 36.174 15.5226 36.4107 19.7331C36.6474 23.9437 34.0391 27.7925 30.0406 29.133C29.0334 29.6501 28.3817 30.6685 28.334 31.7997V32.333C28.334 33.8058 27.1401 34.9997 25.6673 34.9997C24.1946 34.9997 23.0007 33.8058 23.0007 32.333V31.7197C23.0755 28.3275 25.1775 25.3111 28.334 24.0663C30.0714 23.5132 31.2149 21.8551 31.1144 20.0345C31.014 18.2139 29.695 16.6917 27.9073 16.333C26.64 16.0621 25.3188 16.3974 24.334 17.2397C23.4132 18.0017 22.8843 19.1378 22.894 20.333C22.894 21.8058 21.7001 22.9997 20.2273 22.9997C18.7546 22.9997 17.5606 21.8058 17.5606 20.333C17.5523 17.5272 18.8176 14.8691 21.0007 13.1063ZM28.4407 40.5463C28.434 40.1895 28.3617 39.837 28.2273 39.5063C28.1058 39.1622 27.9048 38.8515 27.6407 38.5997C26.6006 37.5657 24.9208 37.5657 23.8807 38.5997C23.6312 38.8431 23.4319 39.133 23.294 39.453C23.1676 39.785 23.1043 40.1377 23.1073 40.493C23.1171 41.1991 23.3934 41.8753 23.8807 42.3863C24.3839 42.8855 25.0652 43.1638 25.774 43.1597H26.2807L26.7873 42.9997C26.9465 42.9359 27.0984 42.8555 27.2407 42.7597L27.6407 42.4397C28.1496 41.9408 28.4377 41.259 28.4407 40.5463ZM45.6673 5.66634C47.1401 5.66634 48.334 6.86025 48.334 8.33301V45.6663C48.334 47.1391 47.1401 48.333 45.6673 48.333H8.33398C6.86123 48.333 5.66732 47.1391 5.66732 45.6663V8.33301C5.66732 6.86025 6.86123 5.66634 8.33398 5.66634H45.6673Z"
          fill="#D47C4A"
        />
      </svg>
      <div className="flex flex-col gap-y-5 md:gap-y-7">
        <p className="text-black_primary text-2xl font-bold text-center font-custom">
          Hmm... we can’t seem to find this page
        </p>
        <div className="w-full flex justify-center">
          <Button
            format={{
              id: `go-back-btn`,
              type: "primary",
              content: "Back to sign in",
              isFilled: true,
              width: "100%",
              onclick: () => navigate("/login"),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
