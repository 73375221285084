import { motion } from "framer-motion";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { unMountDialog } from "../../features/util/utilSlice";
import cross from "./../assets/icons/cross.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Dialog() {
  const { toggle, dialogComponent, headline, close, customStyle } = useSelector(
    (state: any) => state.util,
  );
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(unMountDialog());
  };
  if (toggle) {
    return (
      <motion.div
        initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
        animate={{ opacity: 1 }}
        className={`w-full px-2 bg-black_modal h-full fixed top-0 left-0 flex items-center justify-center overflow-y-hidden z-50 `}
      >
        <motion.div
          initial={{ opacity: 0, scaleY: 0.5 }}
          animate={{ opacity: 1, scaleY: 1 }}
          transition={{ delay: 0.4 }}
          className={`bg-white rounded-lg w-fit max-w-xl xs:min-w-full px-8 py-2 shadow-nav ${
            customStyle ? customStyle : ""
          }`}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8 }}
          >
            <div
              className="flex justify-between items-center mb-4 mt-1 gap-x-1"
              id="dialogheading"
            >
              <h2 className="text-2xl text-black_primary font-bold font-custom mt-2 pr-4">
                {headline}
              </h2>
              {close ? (
                <LazyLoadImage
                  src={cross}
                  alt="close"
                  className="cursor-pointer mt-3 mb-auto"
                  onClick={handleClick}
                />
              ) : (
                ""
              )}
            </div>
            {dialogComponent}
          </motion.div>
        </motion.div>
      </motion.div>
    );
  }

  return <></>;
}

export default Dialog;
