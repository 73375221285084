/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useOutletContext } from "react-router-dom";
import { setLoading, unsetLoading } from "../../../features/loader/loaderSlice";
import useError from "../../../hooks/useError";
import { fetcher, update } from "../../../logic/util/hooks";
import BellIcon from "../../assets/icons/bell_xl";
import ThumbsUpIcon from "../../assets/icons/thumbsup_xxl";
import ExpiryMessageCardExpired from "../../primitives/ExpiryMessageCardExpired";
import ExpiryMessageTrialExpired from "../../primitives/ExpiryMessageTrialExpired";
import Footer from "../../primitives/Footer";
import ReconnectEmailAccount from "../../primitives/ReconnectEmailAccount";
import ExpandableTable from "../../primitives/atoms/GenericAntdTable/GenericAntdTable";
import MobileTagActionAntdTable from "../../primitives/atoms/MobileTagActionAntdTable/MobileTagActionAntdTable";
import StaticAlert from "../../primitives/atoms/StaticAlert";
import NoData from "../../primitives/atoms/noData";
import { StatusActionButtons } from "../ComponentLibrary/components/StatusActionButtons";

interface SurveyData {
  key: string;
  name?: string;
  email: string;
  phone: string;
  reason: string;
  relationship: string;
  contactID: string;
  platform_ref: string;
  option?: any;
  status?: string;
  domain?: string;
  parentKey?: string;
  count?: number;
  subject?: string;
  subDomain?: any;
}
interface DataType {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone_number: string;
  reason_for_contacting: string;
  relationship_to_contact: string;
  CONTACT_REF: string;
  PLATFORM_REF: string;
}
interface ReducerStateType {
  showTrialExpired: boolean;
  showCardExpired: boolean;
  showReconnectText: boolean;
  isWhiteListed: boolean;
}
export default function SurveyView() {
  const [surveys, setSurveys] = useState<SurveyData[]>([]);
  const { user } = useSelector((state: any) => state.auth);
  const [currentSurvey, setCurrentSurvey] = useState<SurveyData | null>(null);
  const [data, setData] = useState<DataType[] | null>(null);

  const { error, mountError } = useError();

  const [paymentStatusObject, reducerState] =
    useOutletContext<ReducerStateType[]>();
  const { showTrialExpired, showCardExpired, showReconnectText } = reducerState;

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setLoading());
    fetcher(`/resources/responses/${user?.id}`)
      .then((res) => {
        if (res) setData(res);
        dispatch(unsetLoading());
      })
      .catch((err) => {
        console.log(err);
        dispatch(unsetLoading());
      });
  }, []);

  const location = useLocation();

  useEffect(() => {
    window.dataLayer?.push({
      event: "pageview",
      page: {
        url: location,
        title: "Requests",
      },
    });
  });
  useEffect(() => {
    if (data) {
      let count = 0;
      setSurveys(
        data.map((info) => {
          return {
            key: info.id,
            name:
              !info.firstName || !info.lastName
                ? "--"
                : `${info.firstName} ${info.lastName}`,
            email: info.email,
            phone: info.phone_number,
            reason: info.reason_for_contacting,
            relationship: info.relationship_to_contact,
            contactID: info.CONTACT_REF,
            platform_ref: info.PLATFORM_REF,
          };
        }),
      );
    }
  }, [data]);

  useEffect(() => {
    const updateStatus = async () => {
      const prevSurveys = [...(surveys || [])];
      try {
        if (currentSurvey) {
          setSurveys((data) =>
            data ? data.filter((data) => data.key !== currentSurvey?.key) : [],
          );
          await update(
            "contacts",
            `updateStatus/${user?.id}/${currentSurvey?.contactID}`,
            { status: currentSurvey?.option, requestFrom: "surveyform" },
          );
        }
      } catch (err) {
        console.log("Error: ", err);
        setSurveys(prevSurveys);
        mountError({
          response: {
            data: {
              errorMessage:
                "We couldn’t approve or block this sender. Please try again later",
            },
          },
        });
      }
    };
    updateStatus();
  }, [currentSurvey]);

  const actionButtonClickHandler = (value: SurveyData, option: any) => {
    setCurrentSurvey({ ...value, option });
  };

  const columns = [
    {
      title: () => "Name",
      width: "15%",
      dataIndex: "name",
      key: "name",
      render: (text: string, key: any) => (
        <a className={key.parentKey ? `ml-4 p-1` : "font-bold ml-2"}>{text}</a>
      ),
    },
    {
      title: () => <div>Email</div>,
      width: "20%",
      dataIndex: "email",
      key: "email",
      render: (text: string, key: any) => (
        <a className={key.parentKey ? `ml-1` : ""}>{text}</a>
      ),
    },
    {
      title: () => <div>Phone</div>,
      key: "phone",
      dataIndex: "phone",
      width: "15%",
      render: (text: string, key: any) => (
        <a className={key.parentKey ? `ml-1` : ""}>{text}</a>
      ),
    },

    {
      title: () => <div>Reason</div>,
      key: "reason",
      dataIndex: "reason",
      width: "15%",
      render: (text: string, key: any) => (
        <a className={key.parentKey ? `ml-1` : ""}>{text}</a>
      ),
    },
    {
      title: () => <div>Relationship</div>,
      key: "relationship",
      dataIndex: "relationship",
      width: "15%",
      render: (text: string, key: any) => (
        <div className="text-sm px-4 py-2 flex justify-center w-1/2 bg-green_bg text-green_primary rounded">
          {text}
        </div>
      ),
    },
    {
      title: () => <div>Actions</div>,
      // key: "status",
      width: "20%",
      render: (_: string, record: { status: string }) => {
        return (
          <div className="py-4">
            <StatusActionButtons
              value={record}
              onClickHandler={actionButtonClickHandler}
              options={["approved", "blocked"]}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
    <div className={`text-black_primary grid place-items-center pt-9 h-full mt-2`}>
      {/** card has expired */}
      <div
        className={`text-black_primary bg-white grid place-items-center h-full ${
          showCardExpired ? "" : "hidden"
        } `}
      >
        <ExpiryMessageCardExpired />
      </div>
      {/** free trail has expired */}
      <div
        className={`text-black_primary bg-white grid place-items-center h-full ${
          showTrialExpired && !paymentStatusObject?.isWhiteListed ? "" : "hidden"
        } `}
      >
        <ExpiryMessageTrialExpired />
      </div>
      {/** Reconnect email account */}
      <div
        className={`text-black_primary bg-white grid place-items-center h-full ${
          showReconnectText ? "" : "hidden"
        } `}
      >
        <ReconnectEmailAccount />
      </div>
      {/** everything correct - trail and card fine */}
      <div
        className={`${
          !showReconnectText && !showTrialExpired && !showCardExpired
            ? "w-full px-24 xs:px-8 xs:pb-20 h-full md:min-h-screen"
            : "hidden"
        }`}
      >
        <div>
          <div
            className={`flex flex-row items-center xs:justify-center gap-3 pb-2`}
          >
            <BellIcon color="#B95C28" />
            <p className="text-black_primary text-3xl xs:text-2xl font-bold">
              Requests
            </p>
          </div>
          <p className="text-gray1 text-base font-normal sm:px-8">
            This page shows requests to contact you.
          </p>
          <p className="text-gray1 text-base font-normal sm:px-8">
            We’ve sent a survey to each sender to understand why they would like
            to contact you.
          </p>
          <p className="text-gray1 text-base font-normal sm:px-8">
            You may choose to Approve or Block any of the senders below.
          </p>
          {error && <StaticAlert type="error" message={error} />}
        </div>
        {!data || data?.length === 0 || surveys?.length === 0 ? (
          <div className="pt-8">
            <NoData
              image={<ThumbsUpIcon color="#D47C4A" />}
              title="You’re all set!"
              description={`There are no new requests for you to review at this time.
            Try checking back here a little later.`}
              button={null}
            />
          </div>
        ) : (
          <>
            <div className="block sm:hidden ">
              <MobileTagActionAntdTable
                data={surveys}
                actionButtonClickHandler={actionButtonClickHandler}
                deleteOptionVisible={false}
              />
            </div>
            <div className="w-full sm:flex hidden  justify-center ">
              <ExpandableTable
                data={surveys as SurveyData[] | undefined}
                columns={columns}
                pagination={false}
              />
            </div>
          </>
        )}
      </div>
    </div>
    <div className="w-full"> <Footer /> </div>
    </>
  );
}
