import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import { monthInWord } from "../../../../../logic/util/calculatePaymentStatus";
import { post } from "../../../../../logic/util/hooks";
import { logLinkedInConversion } from "../../../../../logic/util/linkedConversion";
import Button from "../../../../primitives/Button";
import CardDeclined from "../../../../primitives/dialogContent/CardDeclined";
import CardPaymentSuccessful from "../../../../primitives/dialogContent/CardPaymentSuccessful";
import SomethingWrong from "../../../ErrorViews/500";
import {
  mountDialog,
  unMountDialog,
} from "../../../../../features/util/utilSlice";

interface CheckoutFormProps {
  priceId: string;
}
interface AutomaticallyBilledOnType {
  month: number | string;
  day: number | string;
  year: number | string;
}
interface planNamesType {
  nameCapital: string;
  nameSmall: string;
  amount: string;
}

interface billedAgainOnType {
  againDay: number | string;
  againMonth: number | string;
  againYear: number | string;
}

function CheckoutForm({ priceId }: CheckoutFormProps) {
  const { user } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const conversionID: string = "12833020";
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentStatusObject, reducerState] = useOutletContext<any>();
  const location = useLocation();
  useEffect(() => {
    window.dataLayer?.push({
      event: "pageview",
      page: {
        url: location,
        title: "Payment",
      },
    });
  });
  const { next_bill_occurance } = Object(paymentStatusObject);

  const [message, setMessage] = useState<string | undefined>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [billedAgainOn, setBilledAgainOn] = useState<billedAgainOnType>({
    againDay: "",
    againMonth: "",
    againYear: "",
  });

  const [automaticallyBilledOn, setAutomaticallyBilledOn] =
    useState<AutomaticallyBilledOnType>({
      month: "",
      day: "",
      year: "",
    });

  const [planNames, setPlanNames] = useState<planNamesType>({
    nameCapital: "",
    nameSmall: "",
    amount: "",
  });

  const cardDeclinedDialogue = () =>
    dispatch(
      mountDialog({
        component: (
          <CardDeclined
            onClose={() => {
              dispatch(unMountDialog());
            }}
          />
        ),
        headline: "It appears there is an issue with your card",
      }),
    );
  const cardPaymentSuccessful = () =>
    dispatch(
      mountDialog({
        component: <CardPaymentSuccessful />,
        headline: "Congratulations!",
      }),
    );
  const serverErrorModal = (message?: string) =>
    dispatch(
      mountDialog({
        component: <SomethingWrong message={message} />,
        headline: "Error!",
      })
    );

  useEffect(() => {
    filterBillingDate();
    getPlanDetails();
  }, [priceId, next_bill_occurance]);

  const getPlanDetails = () => {
    if (priceId === process.env.REACT_APP_MONTHLY_PRODUCT) {
      setPlanNames({
        nameCapital: "Monthly",
        nameSmall: "month",
        amount: "10",
      });
    } else if (priceId === process.env.REACT_APP_YEARLY_PRODUCT) {
      setPlanNames({
        nameCapital: "Yearly",
        nameSmall: "year",
        amount: "100",
      });
    }
  };

  const filterBillingDate = () => {
    if (next_bill_occurance) {
      const date = new Date(next_bill_occurance).getDate();
      const month = new Date(next_bill_occurance).getMonth() + 1;
      const year = new Date(next_bill_occurance).getFullYear();
      setAutomaticallyBilledOn({
        ...automaticallyBilledOn,
        month: month,
        day: date,
        year: year,
      });
      let billedAgain;
      if (priceId === process.env.REACT_APP_YEARLY_PRODUCT) {
        billedAgain = new Date(year + 1, month, date);
      } else if (priceId === process.env.REACT_APP_MONTHLY_PRODUCT) {
        billedAgain = new Date(year, month + 1, date);
      }
      setBilledAgainOn({
        ...billedAgainOn,
        againDay: billedAgain?.getDate() as number,
        againMonth: billedAgain?.getMonth() as number,
        againYear: billedAgain?.getFullYear() as number,
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'subscription_confirmed',
      'user_id': user.id
    });

    if (!stripe || !elements) {
      return;
    }
    setIsProcessing(true);
    try {
      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        redirect: "if_required",
        confirmParams: {
          payment_method_data: {
            billing_details: {
              email: user?.email,
            },
          },
        },
      });

      if (
        error &&
        (error?.type === "card_error" || error?.type === "validation_error")
      ) {
        setMessage(error?.message);
        if (
          error?.code === "card_declined" ||
          error?.code === "incorrect_cvc" ||
          error?.code === "processing_error" ||
          error?.code === "expired_card"
        ) {
          cardDeclinedDialogue();
        } else {
          serverErrorModal(error?.message);
        }
        setIsProcessing(false);
      } else if (setupIntent && setupIntent?.status === "succeeded") {
        if (!priceId) {
          //Edit payment case
        // Show dialogue that card payment succeded and navigate to profile
          const response = await post(
            "stripe",
            `updatePaymentMethod/${user?.id}`,
            { paymentMethod: setupIntent?.payment_method },
            );
          if (response.data) {
            logLinkedInConversion(conversionID);
            cardPaymentSuccessful();
            setIsProcessing(false);
          }
        } else if (priceId) {
          const response = await post("stripe", `paymentCharge/${user?.id}`, {
            price: priceId,
            paymentMethod: setupIntent?.payment_method,
          });
          if (response?.data) {
            cardPaymentSuccessful();
          } else {
            serverErrorModal(response.data.errorMessage);
          }
        }
        setIsProcessing(false);
      } else {
        serverErrorModal(error?.message);
        setIsProcessing(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        serverErrorModal("An error occurred. Please review your billing details and try again.");
      }
      setIsProcessing(false);
    }
  };

  const BackClick = () => {
    navigate("/app/profile");
  };

  const { againDay, againMonth, againYear } = billedAgainOn;
  const { nameCapital, nameSmall, amount } = planNames;
  const { month, day, year } = automaticallyBilledOn;
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="flex flex-col items-center h-fit xs:px-6 justify-center pt-24 bg-white">
        <div className="max-w-md mt-8 flex flex-col  items-center">
          <div
            className={`px-4  py-3 flex xs:flex-col xs gap-y-4 bg-orange_bg rounded-lg justify-between items-center ${priceId ? "" : "hidden"
              }`}
          >
            <div className="flex flex-col gap-x-1 ">
              <p className="m-0 p-0 font-custom font-bold text-xl text-orange_dark">
                {nameCapital} payment
              </p>
              <p className="m-0 p-0 font-custom font-normal text-sm text-orange_dark text-left">
                You'll be billed ${amount} on {monthInWord(month as number)}{" "}
                {day}, {year} and won't be billed again until{" "}
                {monthInWord(againMonth as number)} {againDay}, {againYear}.
              </p>
            </div>
          </div>
          <div
            className={`flex flex-col justify-center items-center mt-1 mb-6 ${priceId ? "" : "px-14"
              }`}
          >
            <p className="font-custom font-bold text-2xl text-black_primary">
              Select payment method
            </p>
            <p className="font-custom font-normal text-gray1">
              We'll bill your payment method automatically.
            </p>
          </div>

          <form className="flex flex-col gap-y-3 w-full">
            <PaymentElement id="payment-element" />
          </form>

          <div className=" flex xs:flex-col-reverse justify-between items-center mt-5 w-full mb-2">
            <button
              onClick={() => BackClick()}
              className=" font-custom font-normal cursor-pointer text-base text-green_primary underline"
            >
              Back
            </button>
            <Button
              format={{
                type: "primary",
                isFilled: true,
                content: "Activate Membership",
                width: "xs:w-full",
                isLoading: isProcessing,
              }}
              isDisabled={isProcessing || !stripe || !elements}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default CheckoutForm;
