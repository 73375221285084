
import React from "react";

interface Props { color?: string; }

const ThumbsUpIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M50.6666 16H39.9999V10.0267C40.0043 7.7766 38.5961 5.7658 36.4801 5.0007C34.3641 4.23559 31.9956 4.88077 30.5599 6.61334L23.1999 15.4667C21.9977 16.9023 21.3371 18.7141 21.3333 20.5867V21.3333H7.99992C6.52716 21.3333 5.33325 22.5272 5.33325 24V56C5.33325 57.4728 6.52716 58.6667 7.99992 58.6667H50.6666C55.0849 58.6667 58.6666 55.0849 58.6666 50.6667V24C58.6666 19.5817 55.0849 16 50.6666 16ZM10.6666 26.6667H21.3333V53.3333H10.6666V26.6667ZM50.6666 53.3333C52.1393 53.3333 53.3333 52.1394 53.3333 50.6667V24C53.3333 22.5272 52.1393 21.3333 50.6666 21.3333H37.3333C35.8605 21.3333 34.6666 20.1394 34.6666 18.6667V10.0267L27.2799 18.88C26.8824 19.3598 26.6654 19.9636 26.6666 20.5867V53.3333H50.6666Z" fill={color}/>
    </svg>
  );
};

export default ThumbsUpIcon;
