import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { del, fetcher, post } from "../../../logic/util/hooks";
import Button from "../Button";
import { unMountDialog } from "../../../features/util/utilSlice";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";

interface DeleteAccountProps {
  paymentStatusObject: Record<string, unknown>;
  reducerState: {
    isWhiteListedFlag: boolean;
  };
}

interface PlatformData {
  ACCOUNT_REF: string;
  id: string;
  eval_paused: boolean;
  has_send_survey_enabled: boolean;
  email: string;
  platformID: string;
  platform: string;
}

function DeleteAccount({
  paymentStatusObject,
  reducerState,
}: DeleteAccountProps) {
  const { user } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isWhiteListedFlag } = reducerState;
  const { bill_status, plan } = Object(paymentStatusObject);
  const [isLoading, setIsLoading] = useState(false);
  const [platforms, setPlatforms] = useState<PlatformData[] | null>(null);

  // console.log({ platforms });

  useLayoutEffect(() => {
    setIsLoading(true);
    fetcher(`/resources/platforms/${user?.id}`)
      .then((response) => {
        console.log("fetch platform info: success");
        setPlatforms(response);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user]);

  const handleDelete = () => {
    setIsLoading(true);
    if (isWhiteListedFlag) {
      del("accounts", `${user?.id}`)
        .then(() => {
          setIsLoading(false);
          localStorage.removeItem("sso_signup");
          dispatch(unMountDialog());
          navigate("/feedback");
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          Sentry.captureException(err);
        })
        .finally(() => {
          if (platforms) {
            for (const platform of platforms) {
              localStorage.removeItem(`isFolderSettingsSet_${platform.id}`);
              localStorage.removeItem(
                `isRequestFormSettingsSet_${platform.id}`,
              );
              localStorage.removeItem(`isVideoStepComplete_${platform.id}`);
              localStorage.removeItem(`inboxData_${platform.id}`);
              localStorage.removeItem(`inboxFetchError_${platform.id}`);
            }
          }
        });
    } else {
      post("stripe", `subscription/cancel/${user?.id}`)
        .then((res) => {
          console.log("Subscription Cancelled", res);
          del("accounts", `${user?.id}`)
            .then(() => {
              setIsLoading(false);
              localStorage.removeItem("jwtToken");
              dispatch(unMountDialog());
              navigate("/feedback");
            })
            .catch((err) => {
              setIsLoading(false);
              Sentry.captureException(err);
            });
        })
        .catch((err) => {
          console.log("Failed to cancel subscription", err);
          Sentry.captureException(err);
        })
        .finally(() => {
          if (platforms) {
            for (const platform of platforms) {
              localStorage.removeItem(`inboxData_${platform.id}`);
              localStorage.removeItem(`inboxFetchError_${platform.id}`);
              localStorage.removeItem(`isStep3Skipped_${platform.id}`);
              localStorage.removeItem(`isStep4Skipped_${platform.id}`);
              localStorage.removeItem(`isSwitchedOn_${platform.id}`);
            }
          }
          localStorage.removeItem("isOnboardBannerDismissed");
          localStorage.removeItem("sso_signup");
          localStorage.removeItem("lastPlatformCreated");
          localStorage.removeItem("isDomainsToastOn");
        });
    }
  };

  return (
    <div>
      <p className="text-gray1">
        Deleting your account will disconnect Bulletproof from your email
        accounts and delete your Bulletproof account including all personal
        data.
      </p>
      <p className="text-gray1 mt-6">
        {!isWhiteListedFlag && plan == "year" && bill_status
          ? "You’ll receive a prorated refund based on the monthly rate."
          : plan == "month" && bill_status
            ? "You will not be billed again."
            : null}
      </p>
      <p className="text-gray1 mt-6">This action cannot be undone.</p>
      <div
        className={`flex justify-end items-center sm:gap-2 xs:flex-col flex-row`}
      >
        <div className={`xs:w-full xs:mt-6`}>
          <Button
            format={{
              id: "DeleteAccountButton",
              type: "delete",
              content: "Yes, delete my account",
              isFilled: true,
              isLoading,
              width: !isLoading ? "w-fit" : "w-40",
              onclick: ()=>{
                mixpanel.track(`Clicked: 'Yes, delete my account' (Delete account modal)`);
                handleDelete();}
            }}
          />
        </div>
        <div className={`xs:w-full xs:-mt-3`}>
          <Button
            format={{
              id: "cancelButton",
              type: "primary",
              isFilled: false,
              content: "Cancel",
              onclick: () => {
                mixpanel.track(`Clicked: 'Cancel' (Delete account modal)`);
                dispatch(unMountDialog())
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default DeleteAccount;
