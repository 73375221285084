import React from "react";
import { useNavigate } from "react-router";
import Button from "../Button";
import { useDispatch } from "react-redux";
import { unMountDialog } from "../../../features/util/utilSlice";

function CardPaymentSuccessful() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="mb-6" id="Subscription-confirmed">
      <div className="flex justify-center flex-row md:pr-8 pr-10 items-center gap-x-3 w-full xs:items-start content-center">
        <p className={"font-custom text-base text-black_primary "}>
          Your Membership to Bulletproof Inbox is now active.
        </p>
      </div>
      <div className="flex justify-center pt-4">
        <Button
          format={{
            type: "primary",
            isFilled: true,
            content: "Got it",
            width: "w-48",
            onclick: () => {
              dispatch(unMountDialog());
              navigate("/app/profile");
              window.location.reload(); //////////// this is equal to window.location.reload(false)
            },
          }}
        />
      </div>
    </div>
  );
}

export default CardPaymentSuccessful;
