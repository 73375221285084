import React from "react";
import { useDispatch } from "react-redux";
import Button from "../Button";
import { unMountDialog } from "../../../features/util/utilSlice";

function WhatIsApprovedSenderList() {
	const dispatch = useDispatch()
	return (
		<div>
			<p className="text-black_primary text-base font-normal">Bulletproof Inbox allows you to control which senders are allowed in your inbox.</p>
			<ul className="list-disc pl-6 font-custom text-black_primary font-normal text-base">
				<li>When you “approve” a domain, emails will end up in your inbox.</li>
				<li>When you “block” a domain, emails will end up in a folder called “Bp: Blocked”</li>
				<li>Any emails from a sender that is neither approved nor blocked will go to a folder called “Bp: Evaluating”. You can decide at any time to approve or block these senders. We’ll also automatically send a survey to the sender to ask them why they want to contact you.</li>
			</ul>
			<p className="text-black_primary text-base pt-4 font-normal">You can also change the status of any specific email or domain at any time on the “Approved sender list” page.</p>
			<div className={`flex justify-start items-center xs:flex-col flex-row`}>
				<div className={`xs:w-full`}>
					<Button
						format={{
							id: "gotItButton",
							type: "primary",
							isFilled: true,
							content: "Got it",
							onclick: () => dispatch(unMountDialog())
						}}
					/>
				</div>
			</div>
		</div>
	);
}

export default WhatIsApprovedSenderList;
