import React, { useState } from "react";
import { useField } from "formik";
import { AnimatePresence } from "framer-motion";
import ErrorMessage from "../ErrorMessage";
import Eye from "../../assets/icons/eye";
import EyeStrike from "../../assets/icons/eye-strike";

interface ReferFriendFieldProps {
  label?: string;
  subLabel ?: string;
  width?: string;
  name:string;
  placeholder?: string;
}

const ReferFriendField: React.FC<ReferFriendFieldProps> = ({ label, subLabel,width ,...props }) => {
  const [field, meta, height] = useField(props);
  // Helpers => {setValue: ƒ, setTouched: ƒ, setError: ƒ}
  return (
    <div className="">
      {/* <label
        className={`w-full transition-all duration-300 font-normal text-sm ${
          meta.touched && meta.error ? "text-red_error" : "text-gray1"
        }`}
      >
        {" "}
        {label}{" "}
      </label> */}
      <div className="">
        <input
          className={`field xs:w-full transition-all duration-300 ${
            meta.touched && meta.error ? "border-red_error" : "border-gray2"
          } bg-white ${width ? width : "w-full"} ${
            height ? height : ""
          } px-4 py-2 text-black_primary border font-custom border-solid`}
          {...field}
          {...props}
          autoComplete="referral"
        />
      </div>
      <AnimatePresence initial={false}>
        {meta.touched && meta.error ? (
          <ErrorMessage errors={meta.error} />
        ) : subLabel !== "" ? (
          <label className="text-gray1 mb-3 flex flex-wrap">{subLabel}</label>
        ) : null}
      </AnimatePresence>
    </div>
  );
}

export default ReferFriendField;
