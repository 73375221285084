import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unMountDialog } from "../../../../features/util/utilSlice";
import useError from "../../../../hooks/useError";
import { fetcher, post } from "../../../../logic/util/hooks";
import { StatusActionButtons } from "../../../views/ComponentLibrary/components/StatusActionButtons";
import Button from "../../Button";
import StaticAlert from "../../atoms/StaticAlert";
import Field from "../Field";
import { addContactValidation, initialAddContact } from "./schema.AddContact";

interface AddContactFormProps {
  refetchContacts: () => void;
}
interface ContactPayload {
  status: string;
  firstName: string;
  lastName: string;
  email: string;
  PLATFORM_REF?: number;
  requestFrom: string;
}

function AddContactForm({ refetchContacts }: AddContactFormProps) {
  const { user } = useSelector((state: any) => state.auth);
  const { error, mountError } = useError();
  const [status, setStatus] = useState("");
  const [data, setData] = useState<any[]>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    fetcher(`/resources/platforms/${user?.id}`)
      .then((res) => {
        if (res) setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleStatusChange: (
    value: any,
    option: string,
    
    event: React.MouseEvent<any>,
  ) => void = (value, option, event) => {
    event.preventDefault();
    setStatus(option);
  };

  return (
    <Formik
      initialValues={initialAddContact}
      validationSchema={addContactValidation}
      onSubmit={async (values) => {
        try {
          if (!status) {
            mountError({
              response: { data: { errorMessage: "Please select a status." } },
            });
            return;
          }
          const payload: ContactPayload = { ...values, status: status, requestFrom: "manually added" };
          payload.PLATFORM_REF = data?.[0].id;
          await post("contacts", user?.id, payload);
          dispatch(unMountDialog());
          refetchContacts();
        } catch (error) {
          console.log("error", error);
          mountError(error);
        }
      }}
    >
      {(props) => {
        return (
          <Form className="flex flex-col gap-2 sm:w-125">
            {error && <StaticAlert type="error" message={error} />}
            <Field
              name="email"
              key="email"
              label="Email"
              type="email"
              placeholder=""
            />
            <Field
              name="firstName"
              label="First name (optional)"
              placeholder=""
              type="text"
            />
            <Field
              name="lastName"
              label="Last name (optional)"
              placeholder=""
              type="text"
            />
            <p className="text-lg font-base flex flex-row xs:flex-col whitespace-pre items-center text-black_primary">
              {"I want to "}
              <StatusActionButtons
                value={{ status }}
                onClickHandler={handleStatusChange}
                options={["approved", "blocked"]}
              />
              {" all emails from this sender"}
            </p>
            <div className="flex flex-row xs:flex-col gap-2 xs:gap-0 items-center justify-start xs:w-full">
              <div className="xs:w-full">
                <Button
                  format={{
                    type: "submit",
                    id: "addContactBtn",
                    isFilled: true,
                    content: "Add sender",
                    isLoading: props.isSubmitting,
                  }}
                />
              </div>
              <div className="xs:w-full xs:-mt-3">
                <Button
                  format={{
                    type: "primary",
                    id: "resetPassword",
                    isFilled: false,
                    content: "Cancel",
                    onclick: () => dispatch(unMountDialog()),
                  }}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default AddContactForm;
