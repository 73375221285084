import React, { useEffect } from "react";
import Label from "../../../primitives/Label";
import ResetPasswordForm from "../../../primitives/forms/resetPassword/ResetPasswordForm";

export default function ResetPassword() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <PublicNav title="Reset password" /> */}
      <div className="text-black_primarybg-white grid place-items-center h-screen">
        <div
          className={`bg-white py-12 xs:h-full xs:w-full xs:pt-24 flex flex-col items-center shadow-nav rounded-lg`}
        >
          <div className={`w-125 px-12 xs:w-full xs:px-6`}>
            <Label
              format={{
                color: "text-green_primary",
                text: "Reset your password",
                fontSize: "text-4xl",
                flex: "",
              }}
            />
            <div className="inline-block w-10 mb-5 ml-1 h-1 bg-orange1" />
            <div className="flex flex-col justify-items-center items-center">
              <ResetPasswordForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
