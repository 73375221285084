import React from "react";
import { useNavigate } from "react-router";
import plusIcon from "../../assets/icons/plus.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ConnectPlatformCard() {
  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-row w-full h-full xs:w-full shadow-nav bg-[#F7F6F5] justify-center items-center px-6 py-24 rounded-md`}
    >
      <LazyLoadImage src={plusIcon} alt="plus_icon" />
      <p
        className="text-green_primary underline decoration-current cursor-pointer"
        onClick={() => navigate("/app/connect-account")}
      >
        Connect another account
      </p>
    </div>
  );
}

export default ConnectPlatformCard;
