import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { FaPlay } from "react-icons/fa6";
import { FaPause } from "react-icons/fa";
import Player from "@vimeo/player";

import Button from "../../primitives/Button";
import interfaceVisual from "../../assets/icons/interface_visual.svg";
import gSecurityAssessment from "../../assets/icons/google-security-assessment.svg";
import gdprCompliant from "../../assets/icons/gdpr-compliant.svg";
import { useSearchParams } from "react-router-dom";
import Loader from "../../primitives/Loader/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";

type ContactData = {
  userName: string;
  contact: {
    id: string;
    ACCOUNT_REF: string;
    PLATFORM_REF: string;
    email: string;
    firstName: string;
    lastName: string;
    status: string;
    survey_sent: boolean;
    survey_sent_at: Date | null;
  };
  message: string;
};

export default function MessageDelivered() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isInitialPulse, setIsInitialPulse] = useState(true);
  const [isIdleState, setIsIdleState] = useState(false);
  const [contactData, setContactData] = useState<ContactData | null>(null);
  // console.log({ contactData });

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const playerRef = useRef<Player | null>(null);
  // console.log({ iframeRef, playerRef });

  const iframeRefMob = useRef<HTMLIFrameElement>(null);
  const playerRefMob = useRef<Player | null>(null);

  let [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const status = searchParams.get("status");
  // console.log({ token, status });

  const handlePlayClick = ({ isMobile }: { isMobile: boolean }) => {
    if (!isPlaying) {
      setIsPlaying(true);
      if (isMobile) {
        playerRefMob.current?.play();
      } else {
        playerRef.current?.play();
      }
    } else {
      setIsPlaying(false);
      if (isMobile) {
        playerRefMob.current?.pause();
      } else {
        playerRef.current?.pause();
      }
    }

    if (isInitialPulse) setIsInitialPulse(false);
  };

  let idleTimer: NodeJS.Timeout;
  const handlePlayParentHover = () => {
    clearTimeout(idleTimer);
    setIsIdleState(false);
  };

  useLayoutEffect(() => {
    const getContactData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/resources/contacts/updateContactStatusWithToken?token=${token}&status=${status}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        setContactData(await response.json());
      } catch (error) {
        console.error(error);
      }
    };

    getContactData();
  }, [token, status]);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      const player = new Player(iframe);
      playerRef.current = player;
      return () => {
        player.unload();
      };
    }
  }, [iframeRef.current]);

  useEffect(() => {
    const iframeMob = iframeRefMob.current;
    if (iframeMob) {
      const playerMob = new Player(iframeMob);
      playerRefMob.current = playerMob;
      return () => {
        playerMob.unload();
      };
    }
  }, [iframeRefMob.current]);

  useEffect(() => {
    const idleTimer = setTimeout(() => {
      if (!isIdleState) {
        setIsIdleState(true);
      }
    }, 2000);

    return () => clearTimeout(idleTimer);
  }, [isIdleState]);

  const handleFreeTrialClick = () => {
    window.location.href = "https://app.bulletproofinbox.com/register";
  };
  const handlePolicyPrivacyClick = () => {
    window.location.href = "https://www.bulletproofinbox.com/privacy-policy";
  };
  const handleViewFAQClick = () => {
    window.location.href = "https://www.bulletproofinbox.com/frequently-asked-questions";
  };

  if (!contactData) {
    return (
      <div className="absolute h-screen w-screen flex justify-center items-center z-10 bg-white">
        <Loader />
      </div>
    );
  }

  return (
    <main className="text-base md:text-lg">
      {
        <section className="py-14 px-4">
          <div className="mx-auto flex flex-col items-center justify-center gap-6">
            <h1 className="text-xl md:text-2xl font-normal w-full text-center md:text-left max-w-xl">
              {`Thanks! Your email has been moved to ${contactData?.userName}'s inbox.`}
            </h1>
            <p className="hidden md:block text-2xl font-bold w-full text-left max-w-md">
              {`Learn why ${contactData?.userName} uses Bulletproof Inbox ↓`}
            </p>
            <p className="md:hidden text-xl font-bold w-full text-center max-w-md">
              {`Learn why ${contactData?.userName} uses`} <br /> Bulletproof
              Inbox ↓
            </p>
          </div>
        </section>
      }
      <section className="flex flex-col justify-center items-center gap-4 py-10 px-4 bg-green_bg">
        <div className="max-w-md flex flex-col gap-6 w-full mb-6">
          <h2 className="text-xl md:text-2xl font-bold">
            Modern email is broken
          </h2>
          <p>
            Spam blockers haven’t helped. You still get 100s of time-stealing
            emails everyday. All without your permission. And with no end in
            sight.
          </p>
          <p className="font-bold">Enter Bulletproof Inbox.</p>
        </div>
        <div className="flex flex-col md:flex-row gap-6 max-w-3xl mb-6">
          <div className="pt-8 pb-12 px-5 flex flex-col items-start bg-white rounded-lg shadow-nav w-full">
            <p className="font-bold text-lg">Let important emails through</p>
            <p>
              Send the rest to subfolders. We don't delete, just de-prioritize.
              You won't miss anything.
            </p>
          </div>
          <div className="pt-8 pb-12 px-5 flex flex-col items-start bg-white rounded-lg shadow-nav w-full">
            <p className="font-bold text-lg">
              Make unknown senders prove they're worth your time
            </p>
            <p>
              Automated sales and marketing emails refill your inbox everyday.
              If you want, send them an inbox request survey. See if they truly
              want to reach you.
            </p>
          </div>
          <div className="pt-8 pb-12 px-5 flex flex-col items-start bg-white rounded-lg shadow-nav w-full">
            <p className="font-bold text-lg">Stop annoying email forever</p>
            <p>
              There’s always someone who just won’t quit. Send their stuff to
              “blocked” and you’ll never see them again. (And they’ll never know
              😉)
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center w-full">
          <Button
            format={{
              id: "freeTrial",
              type: "primary",
              isFilled: true,
              width: "xs:w-full sm:w-fit sm:px-14",
              content: "Start your Free Trial",
              onclick: handleFreeTrialClick,
            }}
          />
          <p className="text-sm font-bold">
            no credit card required - pay after you know it works.
          </p>
        </div>
      </section>
      <section className="py-10 pl-12 pr-4 border-b-4 border-gray4">
        <div className="max-w-lg mx-auto flex flex-col items-center justify-center gap-6">
          <h2 className="text-xl md:text-2xl font-bold w-full">
            Get started with a 30 day free trial
          </h2>
          <div className="flex flex-col gap-6">
            <div className="relative flex flex-col gap-1">
              <span className="absolute -left-8 text-3xl text-green_light font-bold">
                1
              </span>
              <div className="flex flex-col gap-1 pr-28">
                <p className="font-bold">Connect your inbox</p>
                <p>
                  Sign up, then link Bulletproof Inbox to your existing gmail or
                  Outlook account.
                </p>
              </div>
            </div>
            <div className="p-4 bg-gray_Xlight relative rounded-lg flex flex-col gap-1 pr-28">
              <p className="font-bold">
                You won't have to learn a new interface
              </p>
              <p>So keep using your favorite app. Bulletproof sits on top.</p>
              <LazyLoadImage
                src={interfaceVisual}
                alt="interface graphic"
                className="absolute right-0 -top-16"
              />
            </div>
            <div className="relative max-w-lg">
              <span className="absolute -left-8 text-3xl text-green_light font-bold">
                2
              </span>
              <div className="flex flex-col gap-1 sm:pr-28">
                <p className="font-bold">Personalize how it works</p>
                <p>
                  Friends & colleagues are automatically approved. <br />
                  Set up Bulletproof to work best for you - you’re in control!
                </p>
              </div>
            </div>
            <div className="relative max-w-lg">
              <span className="absolute -left-8 text-3xl text-green_light font-bold">
                3
              </span>
              <div className="flex flex-col gap-1 sm:pr-28">
                <p className="font-bold">Refine over time</p>
                <p>
                  Train Bulletproof Inbox by moving emails to "approved" or
                  "blocked" folders.
                </p>
              </div>
            </div>
            <div className="relative max-w-lg">
              <span className="absolute -left-8 text-3xl text-green_light font-bold">
                4
              </span>
              <div className="flex flex-col gap-1 sm:pr-28">
                <p className="font-bold">Turn off in 2 clicks</p>
                <p>
                  Rest assured - if for whatever reason you don't want to use
                  Bulletproof anymore, you can go back to your old inbox at
                  anytime.
                </p>
              </div>
            </div>
          </div>
          <div className="hidden md:block w-full">
            <Button
              format={{
                id: "freeTrial",
                type: "primary",
                isFilled: true,
                width: "w-full",
                content: "Get started for free",
                onclick: handleFreeTrialClick,
              }}
            />
          </div>
          <div className="md:hidden w-full">
            <Button
              format={{
                id: "freeTrial",
                type: "primary",
                isFilled: true,
                width: "w-full",
                content: "I'm in - let's get started",
                onclick: handleFreeTrialClick,
              }}
            />
          </div>
          <p className="text-xl font-bold text-green_primary">
            See Bulletproof in action ↓
          </p>
        </div>
      </section>
      <section>
        {/* desktop video */}
        <div
          className="overflow-hidden relative hidden sm:block"
          style={{ height: "44.6vw" }}
        >
          <iframe
            ref={iframeRef}
            src="https://player.vimeo.com/video/915342918?controls=0&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            // frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            className="absolute"
            style={{
              top: "-13vw",
              left: "-1vw",
              width: "calc(100% + 2vw)",
              height: "calc(70vw)",
            }}
            title="Introduction to Bulletproof Inbox"
          ></iframe>
          {iframeRef.current && iframeRef.current !== null ? (
            <div
              className="h-full w-full absolute top-0 left-0"
              onMouseEnter={handlePlayParentHover}
              onMouseMove={handlePlayParentHover}
              // onClick={handlePlayParentHover}
            >
              <button
                id="playBtn"
                onClick={() => handlePlayClick({ isMobile: false })}
                className={`absolute flex justify-center items-center border-none cursor-pointer h-8 w-16 lg:h-10 lg:w-20 rounded-md md:rounded-lg
              ${
                isPlaying
                  ? "bg-gray1 hover:bg-brown"
                  : "bg-brown hover:bg-gray1"
              } 
              ${!isPlaying && isInitialPulse && "pulse"}
              ${isPlaying && isInitialPulse && "outline-none opacity-0"} 
              ${!isIdleState ? "opacity-100" : "opacity-0"}
              ${!isPlaying ? "opacity-100" : "opacity-0"}
              `}
                style={{
                  top: "32vw",
                  left: "50%",
                  transform: "translateX(-50%) translateY(-50%)",
                }}
              >
                {isPlaying ? (
                  <FaPause className="md:w-5 lg:w-6 md:h-5 lg:h-6 text-white" />
                ) : (
                  <FaPlay className="md:w-5 lg:w-6 md:h-5 lg:h-6 text-white" />
                )}
              </button>
              <p
                id="playBtnPrompt"
                className={`absolute text-xs lg:text-sm font-medium m-0 p-0 whitespace-nowrap 
              ${!isPlaying && isInitialPulse ? "opacity-100" : "opacity-0"}
              `}
                style={{
                  top: "35.5vw",
                  left: "50%",
                  transform: "translateX(-50%) translateY(-50%)",
                }}
              >
                ^ Bulletproof Inbox in 1 minute 👀
              </p>
            </div>
          ) : null}
        </div>
        {/* mobile video */}
        <div
          className="overflow-hidden relative sm:hidden"
          style={{ height: "155vw" }}
        >
          <iframe
            ref={iframeRefMob}
            src="https://player.vimeo.com/video/915684707?controls=0&badge=0&amp;autopause=0&amp"
            // frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            className="absolute"
            style={{
              top: "-13vw",
              left: "-1vw",
              width: "calc(100% + 2vw)",
              height: "calc(179vw)",
            }}
            title="Introduction to Bulletproof Inbox"
          ></iframe>
          {iframeRefMob.current && iframeRefMob.current !== null ? (
            <div
              className="h-full w-full absolute top-0 left-0"
              // onMouseEnter={handlePlayParentHover}
              onClick={handlePlayParentHover}
              onTouchStart={handlePlayParentHover}
              onMouseOver={handlePlayParentHover}
            >
              <button
                id="playBtnMob"
                onClick={() => handlePlayClick({ isMobile: true })}
                className={`absolute flex justify-center items-center border-none cursor-pointer h-10 w-20 rounded-md md:rounded-lg bg-brown hover:bg-gray1 focus:bg-gray1               
              ${
                isPlaying
                  ? "bg-gray1 hover:bg-brown"
                  : "bg-brown hover:bg-gray1"
              } 
              ${!isPlaying && isInitialPulse && "pulse"}
              ${isPlaying && isInitialPulse && "outline-none opacity-0"} 
              ${!isIdleState ? "opacity-100" : "opacity-0"}
              ${!isPlaying ? "opacity-100" : "opacity-0"}`}
                style={{
                  top: "76%",
                  left: "50%",
                  transform: "translateX(-50%) translateY(-50%)",
                }}
              >
                {isPlaying ? (
                  <FaPause className="w-5 h-5 text-white" />
                ) : (
                  <FaPlay className="w-5 h-5 text-white" />
                )}
              </button>
              <p
                id="playBtnPromptMob"
                className={`absolute md:text-xs lg:text-sm m-0 p-0 whitespace-nowrap font-medium 
              ${!isPlaying && isInitialPulse ? "opacity-100" : "opacity-0"}
              `}
                style={{
                  top: "83%",
                  left: "60%",
                  transform: "translateX(-50%) translateY(-50%)",
                }}
              >
                ^ Bulletproof Inbox in 1 minute 👀
              </p>
            </div>
          ) : null}
        </div>
      </section>
      <section className="py-10 px-4 border-t-4 border-gray4">
        <div className="max-w-lg mx-auto flex flex-col items-center justify-center gap-6 w-full">
          <h2 className="text-xl md:text-2xl font-bold w-full">Pricing</h2>
          <div className="w-full">
            <p>
              Spend more than 15 minutes of unproductive time in email a week?
            </p>
            <p className="font-bold">Consider Bulletproof Inbox.</p>
          </div>
          <div className="flex gap-12 items-center justify-center bg-green_bg w-full py-4 px-2 rounded-lg">
            <p>
              <span className="text-3xl text-green_primary font-bold">$10</span>{" "}
              /month
            </p>
            <p>
              <span className="text-3xl text-green_primary font-bold">
                $100
              </span>{" "}
              /year
            </p>
          </div>
          <p>
            We charge a premium because we want you to be our customer, not our
            product.
          </p>
          <div className="flex flex-col gap-0 w-full items-center">
            <Button
              format={{
                id: "freeTrial",
                type: "primary",
                isFilled: true,
                width: "w-full",
                content: "Get started for free",
                onclick: handleFreeTrialClick,
              }}
            />
            <p className="hidden sm:block text-base text-gray2">
              30 day free trial, no credit card required - pay after you know it
              works.
            </p>
            <p className="sm:hidden text-base text-gray2 mx-2 text-center">
              30 day free trial, no credit card required - <br /> pay after you
              know it works.
            </p>
          </div>
          <div className="flex flex-col ml-9 sm:ml-0 gap-2 py-4 px-8 shadow-nav relative rounded-lg mt-10">
            <LazyLoadImage
              src="/brittney-yarbrough-avatar.jpeg"
              alt="Brittney Yarbrough Avatar"
              className="rounded-full w-14 h-14 absolute bottom-6 -left-8 sm:-left-10"
            />

            <p>
              "<span className="font-bold">It's easy</span>. It plugs directly
              into my Gmail so there is no fuss. From there it works in the
              background to keep unwanted email from my inbox. It is{" "}
              <span className="font-bold">saving me hours each week</span>."
            </p>
            <div>
              <p className="font-bold">Brittney Yarbrough</p>
              <p className="text-gray2">VP of Marketing</p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-10 px-4 border-t-4 border-gray4">
        <div className="max-w-lg mx-auto flex flex-col items-center justify-center gap-6 w-full">
          <h2 className="text-2xl font-bold w-full">Security & Privacy</h2>
          <p>
            Bulletproof Inbox is approved for both Google and Microsoft
            applications. We have gone through rigorous audits to ensure your
            email is protected with the highest level of security.{" "}
          </p>
          <div className="flex gap-2 w-full">
            <LazyLoadImage src={gdprCompliant} alt="GDPR Compliant Badge" />
            <LazyLoadImage
              src={gSecurityAssessment}
              alt="Google Security Assessment passed badge"
            />
          </div>
          <div className="w-full">
            We take the trust of our members very seriously so we never:
            <ul className="list-disc pl-7">
              <li>Read the body content of emails</li>
              <li>Store the body content of your emails</li>
              <li>Sell, use or share your data</li>
            </ul>
          </div>
          <div className="space-y-2 mt-4">
            <p>
              For more details about how we take privacy and security seriously,
              view our Privacy policy.
            </p>
            <div className="flex flex-col md:flex-row gap-0 md:gap-2">
              <Button
                format={{
                  id: "privacyPolicy",
                  type: "primary",
                  isFilled: true,
                  width: "w-full",
                  content: "Privacy policy",
                  onclick: handlePolicyPrivacyClick,
                  // link: "/privacy-policy",
                }}
              />
              <Button
                format={{
                  id: "faq",
                  type: "primary",
                  isFilled: false,
                  width: "w-full",
                  content: "View FAQ",
                  onclick: handleViewFAQClick,
                  // link: "https://www.bulletproofinbox.com/#FAQ",
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-10 px-4 bg-green_bg">
        <div className="max-w-lg mx-auto flex flex-col items-center justify-center gap-6 w-full">
          <h2 className="text-xl md:text-2xl font-bold w-full">
            What our members are saying...
          </h2>
          <div className="w-full space-y-6">
            <div className="flex flex-col ml-9 sm:ml-0 gap-2 py-4 px-8 shadow-nav bg-white relative rounded-lg">
              <LazyLoadImage
                src="/mike-oneil-avatar.jpeg"
                alt="Mike O'Neil Avatar"
                className="rounded-full w-14 h-14 absolute bottom-6 -left-8 sm:-left-10"
              />

              <p>
                "I don't have to clear out 30 junk emails from my inbox every
                morning and evening - I now only get the stuff I actually want
                to see."
              </p>
              <div>
                <p className="font-bold">Mike O'Neil</p>
                <p className="text-gray2">CEO</p>
              </div>
            </div>
            <div className="flex flex-col ml-9 sm:ml-0 gap-2 py-4 px-8 shadow-nav bg-white relative rounded-lg">
              <LazyLoadImage
                src="/connor-sutton-avatar.jpeg"
                alt="Connor Sutton Avatar"
                className="rounded-full w-14 h-14 absolute bottom-6 -left-8 sm:-left-10"
              />
              <p>
                "Boy this is the fix. No spam has arrived in my inbox for 3 days
                - I'm sad to say that's a record."
              </p>
              <div>
                <p className="font-bold">Connor Sutton</p>
                <p className="text-gray2">Flex developer</p>
              </div>
            </div>
            <div className="flex flex-col ml-9 sm:ml-0 gap-2 py-4 px-8 shadow-nav bg-white relative rounded-lg">
              <LazyLoadImage
                src="/colin-mckintosh-avatar.jpg"
                alt="Colin Mckintosh Avatar"
                className="rounded-full w-14 h-14 absolute bottom-6 -left-8 sm:-left-10"
              />
              <p>"Email is useless without Bulletproof Inbox"</p>
              <div>
                <p className="font-bold">Colin McIntosh</p>
                <p className="text-gray2">Founder & CEO</p>
              </div>
            </div>
            <div className="flex flex-col ml-9 sm:ml-0 gap-2 py-4 px-8 shadow-nav bg-white relative rounded-lg">
              <LazyLoadImage
                src="/rodney-rice-avatar.jpeg"
                alt="Rodney Rice Avatar"
                className="rounded-full w-14 h-14 absolute bottom-6 -left-8 sm:-left-10"
              />
              <p>
                "Bulletproof dramatically reduced the noise and spam emails that
                used to fill my inbox. Thanks to Bulletproof, I get to focus on
                the most important emails right away without having to sift
                through all the junk."
              </p>
              <div>
                <p className="font-bold">Rodney Rice</p>
                <p className="text-gray2">Founder & CEO</p>
              </div>
            </div>
            <div className="flex flex-col ml-9 sm:ml-0 gap-2 py-4 px-8 shadow-nav bg-white relative rounded-lg">
              <LazyLoadImage
                src="/christine-jones-avatar.jpeg"
                alt="Christine Jones Avatar"
                className="rounded-full w-14 h-14 absolute bottom-6 -left-8 sm:-left-10"
              />
              <p>
                "So far I'm loving BP Inbox! It has saved me an enormous amount
                of time and I feel less frazzled."
              </p>
              <div>
                <p className="font-bold">Christine Jones</p>
                <p className="text-gray2">Executive coach</p>
              </div>
            </div>
            <div className="flex flex-col ml-9 sm:ml-0 gap-2 py-4 px-8 shadow-nav bg-white relative rounded-lg">
              <LazyLoadImage
                src="/ben-beyer-avatar.jpeg"
                alt="Ben Beyer Avatar"
                className="rounded-full w-14 h-14 absolute bottom-6 -left-8 sm:-left-10"
              />
              <p>
                "Bulletproof Inbox helps me easily sort through my spam
                messages. The easy filtering process has drastically reduced the
                time I spend managing and cleaning out my inbox. I’ve thoroughly
                enjoyed the product and would highly recommend this to anyone
                who is looking for a streamlined solution to manage spam emails"
              </p>
              <div>
                <p className="font-bold">Ben Beyer</p>
                <p className="text-gray2">Founder & CFO</p>
              </div>
            </div>
            <div className="flex flex-col ml-9 sm:ml-0 gap-2 py-4 px-8 shadow-nav bg-white relative rounded-lg">
              <LazyLoadImage
                src="/patrick-kelley-avatar.png"
                alt="Patrick Kelley Avatar"
                className="rounded-full w-14 h-14 absolute bottom-6 -left-8 sm:-left-10"
              />
              <p>
                "With Bulletproof Inbox I get all my work done using email and
                get my life back. No more sifting through invasive emails and
                personal intrusions that burn up my time. Email is a viable
                communication tool for me again!"
              </p>
              <div>
                <p className="font-bold">Patrick Kelley, M.D.</p>
                <p className="text-gray2">Plastic Surgeon</p>
              </div>
            </div>
            <div className="flex flex-col ml-9 sm:ml-0 gap-2 py-4 px-8 shadow-nav bg-white relative rounded-lg">
              <LazyLoadImage
                src="/sean-laverty-avatar.jpeg"
                alt="Sean Laverty Avatar"
                className="rounded-full w-14 h-14 absolute bottom-6 -left-8 sm:-left-10"
              />
              <p>
                "I love how Bulletproof Inbox makes reviewing my emails a smooth
                and stress free experience. I don’t have to waste time trying to
                figure out what emails deserve my attention; Bulletproof Inbox
                does it for me."
              </p>
              <div>
                <p className="font-bold">Sean Laverty</p>
                <p className="text-gray2">Training Specialist</p>
              </div>
            </div>
            <div className="flex flex-col ml-9 sm:ml-0 gap-2 py-4 px-8 shadow-nav bg-white relative rounded-lg">
              <LazyLoadImage
                src="/madelyn-clemmons-avatar.jpeg"
                alt="Madelyn Clemmons Avatar"
                className="rounded-full w-14 h-14 absolute bottom-6 -left-8 sm:-left-10"
              />
              <p>
                "With a ton of emails from random websites, I get dozens of
                spam/promotion emails that I really don’t want, but would hate
                to have to go through and unsubscribe. Not only that, but it’s
                annoying to have my phone going off constantly with pointless
                emails. Thanks to Bulletproof I’m able to see what I want to see
                with ease & little disturbance."
              </p>
              <div>
                <p className="font-bold">Madelyn Clemmons</p>
                <p className="text-gray2">Production Assistant</p>
              </div>
            </div>
            <div className="flex flex-col ml-9 sm:ml-0 gap-2 py-4 px-8 shadow-nav bg-white relative rounded-lg">
              <LazyLoadImage
                src="/yesai-tovar-avatar.jpeg"
                alt="Yesai Tovar Avatar"
                className="rounded-full w-14 h-14 absolute bottom-6 -left-8 sm:-left-10"
              />
              <p>
                "I liked how easy it was to organize my email and select which
                domains go to each folder. Having a tool that scans your email
                and gives you all the domains in your inbox was super useful
                when trying to clear your email from spam or old subscriptions."
              </p>
              <div>
                <p className="font-bold">Yesai Tovar</p>
                <p className="text-gray2">Data Specialist</p>
              </div>
            </div>
            <div className="flex flex-col ml-9 sm:ml-0 gap-2 py-4 px-8 shadow-nav bg-white relative rounded-lg">
              <LazyLoadImage
                src="/erik-nicholson-avatar.jpeg"
                alt="Erik Nicholson Avatar"
                className="rounded-full w-14 h-14 absolute bottom-6 -left-8 sm:-left-10"
              />
              <p>
                "It cleans out your inbox to only things you actually care about
                which allows you to be more focused about managing your email."
              </p>
              <div>
                <p className="font-bold">Erik Nicholson</p>
                <p className="text-gray2">CIO</p>
              </div>
            </div>
            <div className="flex flex-col ml-9 sm:ml-0 gap-2 py-4 px-8 shadow-nav bg-white relative rounded-lg">
              <LazyLoadImage
                src="/harris-baumann-avatar.jpeg"
                alt="Harris Baumann Avatar"
                className="rounded-full w-14 h-14 absolute bottom-6 -left-8 sm:-left-10"
              />
              <p>
                "Bulletproof is great! It's really helped me not need to sort
                through the spam I usually get."
              </p>
              <div>
                <p className="font-bold">Harris Baumann</p>
                <p className="text-gray2">Creative Writer</p>
              </div>
            </div>
            <div className="flex flex-col ml-9 sm:ml-0 gap-2 py-4 px-8 shadow-nav bg-white relative rounded-lg">
              <LazyLoadImage
                src="/alyssa-gioscia-avatar.png"
                alt="Alyssa Gioscia Avatar"
                className="rounded-full w-14 h-14 absolute bottom-6 -left-8 sm:-left-10"
              />
              <p>
                “I choose to keep my content, my website, my socials public so
                that clients can find me. However, that creates an, ‘If it's out
                there in the public, I'm going to use it to reach out to you
                moment for sales people.’ I didn't invite that. My email is not
                for your sales. When I'm trying to do things for my business,
                those distractions are costly. If I'm constantly distracted,
                it's going to take me three hours to write something that should
                take 15 minutes. Bulletproof Inbox solves all of these
                problems.”
              </p>
              <div>
                <p className="font-bold">Alyssa Gioscia</p>
                <p className="text-gray2">Executive Coach</p>
              </div>
            </div>
            <div className="flex flex-col ml-9 sm:ml-0 gap-2 py-4 px-8 shadow-nav bg-white relative rounded-lg">
              <LazyLoadImage
                src="/tido-lozano-avatar.png"
                alt="Tido Lozano Avatar"
                className="rounded-full w-14 h-14 absolute bottom-6 -left-8 sm:-left-10"
              />
              <p>
                "I've unsubscribed from an ungodly amount of emails but I still
                get messages. Bulletproof has been perfect because it takes care
                of the ones I can't seem to get rid of even though I unsubscribe
                every 7 days or every week, I keep getting emails. Bulletproof
                has been keeping it out of my face so it's been awesome."
              </p>
              <div>
                <p className="font-bold">Tido Lozano</p>
                <p className="text-gray2">Founder & CEO</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
