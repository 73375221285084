import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import { del, fetcher, post } from "../../../logic/util/hooks";
import ListIcon from "../../assets/icons/list-xl";
import ExpiryMessageCardExpired from "../../primitives/ExpiryMessageCardExpired";
import ExpiryMessageTrialExpired from "../../primitives/ExpiryMessageTrialExpired";
import Footer from "../../primitives/Footer";
import ReconnectEmailAccount from "../../primitives/ReconnectEmailAccount";
import TabItems from "../../primitives/atoms/TabItems";
import Tabs from "../../primitives/atoms/Tabs";
import NoEmailAccountsApprovedSenderList from "../landingPage/components/NoEmailAccountsApprovedSenderList";
import Contacts from "./atoms/contacts";
import Domains from "./atoms/domains";
import ContactTabs from "./ContactTabs";
import emailIcon from "../../assets/icons/email_xxl.svg";
import Label from "../../primitives/Label";
import gmailIcon from "../../assets/graphics/gmailIcon.svg";
import outlookIcon from "../../assets/graphics/outlookIcon.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface PlatformData {
  ACCOUNT_REF: string;
  id: string;
  eval_paused: boolean;
  has_send_survey_enabled: boolean;
  is_domain_review_complete: boolean;
  is_folder_settings_set: boolean;
  is_request_form_settings_set: boolean;
  is_video_step_complete: boolean;
  email: string;
  platformID: string;
  platform: string;
  status: "configuration" | "connected";
}

interface DomainType {
  key: string;
  name?: string;
  email: string;
  phone: string;
  reason: string;
  relationship: string;
  contactID: string;
  platform_ref: string;
  option?: any;
  status?: string;
  domain?: string;
  parentKey?: string;
  count?: number;
  subject: string;
  subDomains?: any;
  platformID?: string;
  parentDomain?: string;
}

interface inboxData {
  status?: string | null;
  list: DomainType[];
  total?: number;
}

interface inboxDataWithId {
  id: string;
  data: inboxData;
}

const removeDomainDuplications = (domainsData: any[] = []) => {
  const uniqueDomains: { [key: string]: boolean } = {};
  return domainsData.filter((domain) => {
    if (!uniqueDomains[domain.id]) {
      uniqueDomains[domain.id] = true;
      return true;
    }
    return false;
  });
};

const removeSubDomainDuplications = (domainsData: any[] = []) => {
  const domainsDeduped = removeDomainDuplications(domainsData);
  return domainsDeduped.map((domain: DomainType) => {
    return {
      ...domain,
      subDomains: domain.subDomains?.filter(
        (subDomain: DomainType) => subDomain.domain !== domain.domain,
      ),
    };
  });
};

export default function ContactView() {
  const navigate = useNavigate();
  const [paymentStatusObject, reducerState] = useOutletContext<any>();
  const [displayFooter, setDisplayFooter] = useState<boolean>(false);
  const [fetchComplete, setFetchComplete] = useState<boolean>(false);
  const { user } = useSelector((state: any) => state.auth);
  const [data, setData] = useState<any[]>([]);
  const [allPlatformsData, setAllPlatformsData] = useState<
    PlatformData[] | null
  >(null);
  const [isTurningOn, setIsTurningOn] = useState<boolean>(false);
  const [inboxFetchCompleted, setInboxFetchCompleted] =
    useState<boolean>(false);
  const [error, setError] = useState("");
  const { showTrialExpired, showCardExpired, showReconnectText } = reducerState;

  const connnectedPlatforms = allPlatformsData?.filter(
    (platform) => platform.status === "connected",
  );
  const configPlatform =
    allPlatformsData?.filter((platform) => platform.status === "configuration")
      .length === 1
      ? allPlatformsData?.filter(
          (platform) => platform.status === "configuration",
        )[0]
      : null;

  const [isConfigPlatformOn, setIsConfigPlatformOn] = useState<boolean>(false);
  const showTurnOnCard =
    configPlatform &&
    connnectedPlatforms &&
    connnectedPlatforms.length === 0 &&
    !isConfigPlatformOn;

  const handleTurnOn = () => {
    setIsTurningOn(true);
    post("auth", `${configPlatform?.platform}/confirmConnection`, {
      platformID: configPlatform?.platformID,
      accountID: user?.id,
      email: configPlatform?.email,
    })
      .then((response: any) => {
        if (response.status === 200) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "email_connected",
            user_id: user.id,
          });
        }
        console.log("platform connected successfully!");
        window.localStorage.removeItem("sso_signup");
        setIsConfigPlatformOn(true);
        localStorage.setItem(
          `isSwitchedOn_${configPlatform?.id}`,
          JSON.stringify(true),
        );
        setIsTurningOn(false);
      })
      .catch((error: any) => {
        setIsTurningOn(false);
        console.log(error);
      });
  };
  const handleDifferentEmail = async () => {
    window.location.href = `/app/connect-account`;
  };

  useEffect(() => {
    const savedIsSwitchedOn = localStorage.getItem(
      `isSwitchedOn_${configPlatform?.id}`,
    );
    if (savedIsSwitchedOn !== null) {
      setIsConfigPlatformOn(JSON.parse(savedIsSwitchedOn));
    }
  }, [configPlatform?.id]);

  useEffect(() => {
    const fetchPlatform = async () => {
      window.scrollTo(0, 0);
      setDisplayFooter(true);
      setTimeout(() => {}, 3000);

      fetcher(`/resources/platforms/${user?.id}`)
        .then((res) => {
          setData(res);
          setFetchComplete(true);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchPlatform();
  }, []);

  const location = useLocation();

  useEffect(() => {
    window.dataLayer?.push({
      event: "pageview",
      page: {
        url: location,
        title: "Approved Sender List",
      },
    });
  });

  useEffect(() => {
    if (user?.email)
      fetcher(`/resources/platforms/${user?.id}`)
        .then((response) => {
          console.log("fetch platform info: success");
          setAllPlatformsData(response);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [user]);

  // pre-fetch inbox domains data, to display in the domains settings page (to avoid excessive load time when navigating to domains settings page)
  useEffect(() => {
    const getInboxData = async (allPlatformsData: PlatformData[] | null) => {
      if (allPlatformsData) {
        allPlatformsData.forEach((platformData, key) => {
          if (
            platformData &&
            platformData.platform &&
            platformData.platformID &&
            platformData.id
          ) {
            fetcher(
              `/onboarding/${platformData.platform}/reviewInboxForDomains/account/${user?.id}/platformID/${platformData.platformID}`,
            )
              .then((response) => {
                if (response && response.list) {
                  const list = response?.list?.map((item: DomainType) => {
                    return {
                      ...item,
                      platformID: platformData.platformID,
                      status: item.status || "evaluating",
                      subDomains: item?.subDomains?.map(
                        (subItem: DomainType) => {
                          return {
                            ...subItem,
                            platformID: platformData.platformID,
                            status: subItem.status || "evaluating",
                          };
                        },
                      ),
                    };
                  });
                  // save inbox domains data in local storage
                  const inboxData = {
                    status: "complete",
                    list: removeSubDomainDuplications(list),
                    total: response?.total,
                  };
                  localStorage.setItem(
                    `inboxData_${platformData?.id}`,
                    JSON.stringify(inboxData),
                  );
                  localStorage.setItem(
                    `inboxFetchError_${platformData?.id}`,
                    JSON.stringify(null),
                  );
                  console.log("fetch inbox data: success");
                } else {
                  throw Error(JSON.stringify(response));
                }
              })
              .catch((err) => {
                localStorage.setItem(
                  `inboxData_${platformData?.id}`,
                  JSON.stringify(null),
                );
                localStorage.setItem(
                  `inboxFetchError_${platformData?.id}`,
                  JSON.stringify(err),
                );
                console.log(err);
                setError(err);
              });
            // .finally(() => {
            //   setInboxFetchCompleted(true);
            // });
          }
        });
      }
    };

    const getInboxDataWithFetchStatus = async () => {
      await getInboxData(allPlatformsData);
      setInboxFetchCompleted(true);
    };

    getInboxDataWithFetchStatus();
  }, [user.id, allPlatformsData, error]);

  if (!allPlatformsData) return null;

  return (
    <div
      className={`text-black_primary bg-white flex flex-col justify-between h-full min-h-screen xs:pb-10`}
    >
      {/** card has expired */}
      <div
        className={`text-black_primary bg-white grid place-items-center h-full ${
          showCardExpired ? "" : "hidden"
        } `}
      >
        <ExpiryMessageCardExpired />
      </div>
      {/** free trail has expired */}
      <div
        className={`text-black_primary bg-white grid place-items-center h-full ${
          showTrialExpired ? "" : "hidden"
        } `}
      >
        <ExpiryMessageTrialExpired />
      </div>
      {/** Reconnect email account */}
      <div
        className={`text-black_primary bg-white grid place-items-center h-full ${
          showReconnectText ? "" : "hidden"
        } `}
      >
        <ReconnectEmailAccount />
      </div>
      {/** everything correct - trail and card fine */}

      <div
        className={
          !showReconnectText && !showTrialExpired && !showCardExpired
            ? " w-full pt-10 xs:px-0"
            : "hidden"
        }
      >
        {showTurnOnCard ? (
          <div className={`w-full -mt-4`}>
            <div className={`flex flex-row px-32 3xl:px-40 xs:px-6`}>
              <LazyLoadImage
                src={emailIcon}
                alt="email_icon"
                height={16}
                width={20}
              />
              <Label
                format={{
                  id: "Email-connected",
                  color: "text-black_primary",
                  text: "Connected email accounts",
                  fontSize: `text-3xl xs:text-2xl`,
                  fontWeight: "font-bold",
                  flex: "",
                }}
              />
            </div>

            <div className="px-32 3xl:px-40 xs:px-6 pt-10 xs:pb-20">
              <div className="w-full p-6 rounded-lg shadow border-2 border-green_primary flex-col justify-start items-center gap-6 inline-flex">
                <div className="self-stretch h-14 flex-col justify-start items-start gap-2 flex flex-1 xs:order-2">
                  <div className="self-stretch text-xl font-bold leading-7 xs:hidden">
                    Next, turn on Bulletproof
                  </div>
                  <div className="self-stretch text-base font-normal leading-normal">
                    To start filtering out the noise, simply turn on Bulletproof
                    on your email account. You can turn off Bulletproof at any
                    time.
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-3 inline-flex xs:flex-col">
                  <div className="text-center text-xl font-bold leading-7 overflow-hidden">
                    {configPlatform?.email}
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex xs:flex-col flex-1 order-3">
                  {isTurningOn ? (
                    <button className="w-48 h-11 border-1 bg-green_primary border-green_primary text-green_primary font-bold rounded-full flex justify-center items-center">
                      <div className="dot-falling"></div>
                    </button>
                  ) : (
                    <div className="h-11 w-48 py-2 border-1 border-green_primary text-green_primary  hover:text-white hover:bg-green_primary rounded-3xl justify-center items-center flex xs:w-full">
                      <button
                        className="text-lg font-bold xs:leading-4 leading-7 px-12"
                        onClick={handleTurnOn}
                      >
                        Turn on
                      </button>
                    </div>
                  )}
                  <div className="flex flex-col gap-2 justify-center items-center sm:flex-row sm:gap-6">
                    <button
                      className="text-green_primary text-base font-normal leading-normal underline"
                      onClick={() =>
                        navigate(`/app/email-settings/${configPlatform?.id}`)
                      }
                    >
                      Review settings
                    </button>
                    <button
                      className="text-green_primary text-base font-normal leading-normal underline"
                      onClick={() => {
                        del(
                          "platforms",
                          `account/${configPlatform?.ACCOUNT_REF}/platform/${configPlatform?.id}`,
                        )
                          .then(() => {
                            localStorage.removeItem(
                              `inboxData_${configPlatform?.id}`,
                            );
                            localStorage.removeItem(
                              `inboxFetchError_${configPlatform?.id}`,
                            );
                            localStorage.removeItem(
                              `isSwitchedOn_${configPlatform?.id}`,
                            );
                            window.location.reload(); //////////////////// this is eaqual to window.location.reload(false); as it stores the cache when we reload the browser
                          })
                          .catch((err: any) => {
                            console.log(err);
                          });
                      }}
                    >
                      Remove email
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="lg:px-24 md:px-8 xs:px-8 h-full">
            <div
              className={`flex flex-row items-center xs:justify-center gap-3 pb-2`}
            >
              <ListIcon color="#B95C28" />
              <p className="text-black_primary text-3xl xs:text-2xl font-bold">
                Approved sender list
              </p>
            </div>
            <div className="hidden lg:inline">
              <p className="text-gray1 text-base font-normal sm:px-8">
                To see emails from new senders, check your ‘Bp: Evaluating’
                folder in your email app.
              </p>
              <p className="text-gray1 text-base font-normal sm:px-8">
                You can change settings for either an individual email address
                or a whole domain, and add new senders as well.
                <a
                  target="_blank"
                  className="text-base font-normal text-decoration-line: underline text-green_primary ml-1"
                  href="https://www.youtube.com/watch?t=1&v=4mXFw_4_gkM&feature=youtu.be"
                >
                  Learn how
                </a>{" "}
                to update your
              </p>
              <p className="text-gray1 text-base font-normal sm:px-8 hidden md:inline">
                Approved sender list.
              </p>
            </div>

            <div className="md:hidden">
              <p className="text-gray1 text-base font-normal sm:px-8">
                To see emails from new senders, check your ‘Bp: Evaluating’
                folder in your email app.
              </p>
              <p className="text-gray1 text-base font-normal sm:px-8">
                You can{" "}
                <a
                  className="text-base font-normal text-decoration-line: underline text-green_primary ml-1"
                  target="_blank"
                  href="https://www.youtube.com/watch?t=1&v=4mXFw_4_gkM&feature=youtu.be"
                >
                  change settings
                </a>{" "}
                for either an individual email address or a whole domain, and
                add new senders as well.
              </p>
            </div>
            <div>
              {data && data?.length > 0 ? (
                <div>
                  <ContactTabs inboxFetchCompleted={inboxFetchCompleted} />
                </div>
              ) : (
                <>{fetchComplete && <NoEmailAccountsApprovedSenderList />}</>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="flex w-full">
        <Footer />
      </div>
    </div>
  );
}
