import * as Yup from "yup";

interface User {
    first : string;
    last : string;
}

export const initialEditProfile = (user: User) => {
    return {
    first:user.first || "",
    last: user.last || "",
    }
};
export const editProfileValidation = Yup.object().shape({
    first: Yup.string().required("Required"),
    last: Yup.string().required("Required"),
});
