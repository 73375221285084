import { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { fetcher } from "../logic/util/hooks";
import {
  bannerActions,
  initialState,
  trialBannerReducer,
} from "../logic/util/reducers/trialBannerReducer";
import { RootState } from "../types/Redux";

function useCustomStripe() {
  const { user } = useSelector((state: RootState) => state.auth);
  const [reducerState, dispatch] = useReducer(trialBannerReducer, initialState);
  const [state, setState] = useState({
    paymentStatusObject: {},
  });
  const [isLoadingFlag, setIsLoadingFlag] = useState(false);
  const {
    freeTrialActive,
    paymentMethodAttachedFreeTrial,
    bill_status,
    card_exp_month,
    trial_days,
    cardExpired,
    cardAboutToExpire,
    welcomeBack,
    isWhiteListed,
  } = Object(state.paymentStatusObject);

  useEffect(() => {
    if (user?.id) {
      getPaymentInfo();
    }
  }, []);

  useEffect(() => {
    stateMachine();
  }, [
    freeTrialActive,
    isWhiteListed,
    welcomeBack,
    paymentMethodAttachedFreeTrial,
    cardExpired,
    cardAboutToExpire,
    trial_days,
    bill_status,
  ]);

  useEffect(() => {
    return () => {
      setState({ paymentStatusObject: {} });
    };
  }, []);

  const stateMachine = () => {
    // Trial is Running
    if (freeTrialActive) {
      dispatch({ type: bannerActions.trialRunning as "trialRunning" });
    }
    // Trial is running and payment method has been attached
    else if (paymentMethodAttachedFreeTrial) {
      dispatch({ type: bannerActions.trialRunningPaid as "trialRunningPaid" });
    }
    // Trial has expired
    else if (
      !isWhiteListed &&
      freeTrialActive !== undefined &&
      !freeTrialActive &&
      !paymentMethodAttachedFreeTrial &&
      !bill_status &&
      card_exp_month == null &&
      trial_days < 1
    ) {
      dispatch({ type: bannerActions.trialExpired as "trialExpired" });
    }
    // Credit card has expired
    else if (
      cardExpired &&
      !freeTrialActive &&
      !paymentMethodAttachedFreeTrial
    ) {
      dispatch({ type: bannerActions.cardExpired as "cardExpired" });
    }
    // Credit Card is about to expire
    else if (
      cardAboutToExpire &&
      !freeTrialActive &&
      !paymentMethodAttachedFreeTrial
    ) {
      dispatch({
        type: bannerActions.cardAboutToExpire as "cardAboutToExpire",
      });
    }
    // Show reconnect your account
    else if (
      welcomeBack &&
      !freeTrialActive &&
      !paymentMethodAttachedFreeTrial &&
      bill_status
    ) {
      dispatch({ type: bannerActions.reconnectText as "reconnectText" });
    }
    // Hide completely if Whitelisted
    else if (isWhiteListed) {
      dispatch({
        type: bannerActions.isWhiteListedAccount as "isWhiteListedAccount",
      });
    }
  };

  function getPaymentInfo() {
    setIsLoadingFlag(true);
    return fetcher(`/stripe/customer/getPaymentInfo/${user?.id}`)
      .then((paymentInfo) => {
        setState({ ...state, paymentStatusObject: paymentInfo });
        setIsLoadingFlag(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingFlag(false);
      });
  }

  return { state, reducerState, isLoadingFlag };
}

export default useCustomStripe;
