import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useError from "../../../../hooks/useError";
import { post } from "../../../../logic/util/hooks";
import { StatusActionButtons } from "../../../views/ComponentLibrary/components/StatusActionButtons";
import Button from "../../Button";
import StaticAlert from "../../atoms/StaticAlert";
import Field from "../Field";
import { addDomainValidation, initialAddDomain } from "./schema.AddDomain";
import { unMountDialog } from "../../../../features/util/utilSlice";

interface AddDomainForm {
  refetchContacts: () => void;
}

function AddDomainForm({ refetchContacts }: AddDomainForm) {
  const { user } = useSelector((state: any) => state.auth);
  const { error, mountError } = useError();
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();

  const handleStatusChange: (
    value: any,
    option: string,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void = (value, option, event) => {
    event.preventDefault();
    setStatus(option);
  };

  return (
    <Formik
      initialValues={initialAddDomain}
      validationSchema={addDomainValidation}
      onSubmit={async (values) => {
        try {
          if (!status) {
            mountError({
              response: { data: { errorMessage: "Please select a status." } },
            });
            return;
          }
          let atTheRateSymbol = values.value.substring(0, 1);
          if (atTheRateSymbol == "@") {
            values.value = values.value.substring(1);
          }
          const payload = { ...values, status: status };
          await post("domains", `account/${user?.id}`, payload);
          dispatch(unMountDialog());
          refetchContacts();
        } catch (error) {
          console.log("error", error);
          mountError(error);
        }
      }}
    >
      {(props) => {
        return (
          <Form className="flex flex-col gap-2 sm:w-125">
            {error && <StaticAlert type="error" message={error} />}
            <Field
              name="value"
              key="value"
              label="Domain URL"
              type="text"
              placeholder=""
              subLabel="The part after the @ in an email address. For example the domain for bob@gmail.com would be gmail.com"
            />
            <p className="text-lg font-base flex flex-row xs:flex-col items-center w-full whitespace-pre text-black_primary">
              {"I want to "}
              <StatusActionButtons
                value={{ status }}
                onClickHandler={handleStatusChange}
                options={["approved", "blocked"]}
              />
              {" all emails from this domain"}
            </p>
            <div className="flex flex-row xs:flex-col gap-2 xs:gap-0 items-center justify-start xs:w-full">
              <div className="xs:w-full">
                <Button
                  format={{
                    type: "submit",
                    id: "addDomainBtn",
                    isFilled: true,
                    content: "Add domain",
                    isLoading: props.isSubmitting,
                  }}
                />
              </div>
              <div className="xs:w-full xs:-mt-3">
                <Button
                  format={{
                    type: "primary",
                    id: "cancelAddDomain",
                    isFilled: false,
                    content: "Cancel",
                    onclick: () => dispatch(unMountDialog()),
                  }}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default AddDomainForm;
