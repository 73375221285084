import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import emailLogoXXL from "../../../assets/icons/email_xxl.svg";
import Button from "../../../primitives/Button";
import AccountCreationSuccessModal from "../../../primitives/dialogContent/AccountCreationSuccessModal";
import {
  mountDialog,
  unMountDialog,
} from "../../../../features/util/utilSlice";
import { useDispatch } from "react-redux";
import mixpanel from "mixpanel-browser";
import { LazyLoadImage } from "react-lazy-load-image-component";

const NoEmailAccounts = ({ firstTime = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dialog = () =>
    dispatch(
      mountDialog({
        component: (
          <AccountCreationSuccessModal
            onSubmit={() => {
              dispatch(unMountDialog());
              navigate("/app/connect-account");
            }}
            onClose={() => {
              dispatch(unMountDialog());
            }}
          />
        ),
        headline: "Welcome to your 10 day free trial",
      }),
    );

  useEffect(() => {
    if (firstTime) dialog();
  }, []);

  const onClickHandler = () => {
    navigate("/app/connect-account");
  };
  return (
    <div className="h-full xs:pt-0">
      <div className={` text-black_primary bg-white h-screen pt-20`}>
        <div>
          <div className="flex flex-col items-center text-center px-20 justify-center">
            <LazyLoadImage src={emailLogoXXL} alt="email" width={54} height={45} />
            <p className="text-2xl font-bold text-black_primary mt-3">
              No email addresses <br /> connected yet
            </p>
          </div>
          <div
            className={`items-center text-center mx-2 mt-16 justify-center sm:flex sm:flex-col`}
          >
            <p className="text-lg font-normal text-gray1">
              Let’s connect your first email account!
            </p>
            <div className="w-fit m-auto">
              <Button
                format={{
                  type: "primary",
                  isFilled: true,
                  onclick: () => {
                    mixpanel.track(
                      "Clicked: 'Get started' button email accounts",
                    );
                    onClickHandler();
                  },
                  content: "Get started",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoEmailAccounts;
