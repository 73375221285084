import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { fetcher } from "../../../logic/util/hooks";
import gmailIcon from "../../assets/graphics/gmailIcon.svg";
import outlookIcon from "../../assets/graphics/outlookIcon.svg";
import Button from "../../primitives/Button";
import Footer from "../../primitives/Footer";
import PublicNav from "../landingPage/components/publicNav";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface EmailAccountCardProps {
  data: any;
  platformTitle?: string;
  platform: string;
  connectedSince: string;
  platformId: string;
}

interface PlatformData {
  ACCOUNT_REF: string;
  id: string;
  eval_paused: boolean;
  has_send_survey_enabled: boolean;
  is_domain_review_complete: boolean;
  is_folder_settings_set: boolean;
  is_request_form_settings_set: boolean;
  is_video_step_complete: boolean;
  email: string;
  platformID: string;
  platform: string;
}

const EmailAccountCard: React.FC<EmailAccountCardProps> = ({
  data,
  platformTitle = "Gmail",
  platform,
  connectedSince,
  platformId,
}) => {
  const navigate = useNavigate();
  const config = {
    icon: platform === "microsoft" ? outlookIcon : gmailIcon,
    description:
      platform === "microsoft"
        ? "Outlook email account"
        : "Gmail email account",
  };
  const [_, date, day, year] = new Date(connectedSince)
    .toDateString()
    .split(" ");
  return (
    <div
      className={`flex flex-col w-125 xs:w-full shadow-bgShadow px-6 rounded-lg bg-white p-6 gap-y-9 `}
    >
      <div className="flex w-full flex-col text-center justify-center gap-y-6 ">
        <p className="text-xl font-bold text-black_primary text-left font-custom xs:text-center">
          {platformTitle}
        </p>
        <div className="flex flex-row justify-start xs:justify-center items-center gap-x-3">
          <LazyLoadImage
            src={config.icon}
            width="21px"
            height="21px"
            alt={`${platformTitle}-icon`}
          />
          <p className="text-sm font-normal font-custom text-gray1">
            {config.description}
          </p>
        </div>
      </div>
      <div className="flex xs:flex-col-reverse justify-between items-center  ">
        <Button
          format={{
            id: `${platformTitle}btn`,
            content: "Select account",
            onclick: () => {
              localStorage.setItem("domainRereview", "true");
              navigate(`/app/domains/${platformId}`, {
                state: { data, prevPage: "Rereview Domains" },
              });
            },
            isFilled: false,
          }}
        />
        <div className="py-1 px-2 bg-green_bg rounded">
          <p className=" font-custom font-normal text-sm text-tag_green ">{`Connected since ${date} ${year}`}</p>
        </div>
      </div>
    </div>
  );
};

export default function ReReviewDomains() {
  const { user } = useSelector((state: any) => state.auth);
  const [data, setData] = useState<any[] | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetcher(`/resources/platforms/${user?.id}`)
      .then((res) => {
        if (res) setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(data);

  const location = useLocation();

  useEffect(() => {
    window.dataLayer?.push({
      event: "pageview",
      page: {
        url: location,
        title: "Rereview Domain",
      },
    });
  });

  return (
    <div className="flex flex-col justify-between h-full">
      <PublicNav
        title="Review new domains"
        back={{
          text: "Back to Approved sender list",
          to: "/app/approved-sender-list",
        }}
      />
      <div className="flex flex-col items-center h-fit xs:px-6 justify-center pt-16 bg-white mt-16 ">
        <p className="text-black_primary text-2xl font-bold mb-2">
          Which email account would you like to review?
        </p>
        <div className="text-center">
          <p className="text-gray1 text-base font-normal max-w-xl">
            Way to go! You’re using Bulletproof Inbox on two or more email
            accounts.
            <br />
            Before we collect new domains for you to review, please select which
            email account you’d like to review. You can only review one account
            at a time.
          </p>
        </div>
        <div
          className={`grid grid-cols-2 gap-6 mt-6 xs:grid-cols-1 xs:px-6 xs:break-all xs:mb-5`}
        >
          {Array.isArray(data) &&
            data.length > 0 &&
            data.map((item) => {
              return (
                <EmailAccountCard
                  data={item}
                  platformTitle={item?.email}
                  platform={item?.platform}
                  connectedSince={item?.connected_since}
                  platformId={item?.id}
                />
              );
            })}
        </div>
      </div>
      <Footer />
    </div>
  );
}
