import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mountDialog } from "../../../../features/util/utilSlice";
import { compareValues } from "../../../../logic/util/helpers";
import { fetcher, update } from "../../../../logic/util/hooks";
import PlusIcon from "../../../assets/icons/plus_lg";
import Button from "../../../primitives/Button";
import Loader from "../../../primitives/Loader/Loader";
import ExpandableTable from "../../../primitives/atoms/GenericAntdTable/GenericAntdTable";
import MobileActionAntdTable from "../../../primitives/atoms/MobileActionAntdTable/MobileActionAntdTable";
import NoData from "../../../primitives/atoms/noData";
import DeleteDomain from "../../../primitives/dialogContent/deleteDomain";
import SearchField from "../../../primitives/forms/SearchField";
import AddDomainForm from "../../../primitives/forms/approvedSenderList/AddDomainForm";
import { StatusActionButtons } from "../../ComponentLibrary/components/StatusActionButtons";
import { MemoizedReviewDomainsBanner } from "./reviewDomainsBanner";

interface DomainsType {
  key: string;
  name: string;
  email: string;
  phone: string;
  reason: string;
  relationship: string;
  contactID: string;
  platform_ref: string;
  option?: any;
  status?: string;
  domain?: string;
}

interface DomainsProps {
  inboxFetchCompleted: boolean;
}

const Domains: React.FC<DomainsProps> = ({inboxFetchCompleted}) => {
  const [domains, setDomains] = useState<DomainsType[]>([]);
  const [search, setSearch] = useState<{ value: string }>({ value: "" });
  const [errorInRowKey, seterrorInRowKey] = useState<string>("");
  const { user } = useSelector((state: any) => state.auth);
  const { toggle } = useSelector((state: any) => state.util);
  const [data, setData] = useState<any[] | null>(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const addDomainDialog = () =>
    dispatch(
      mountDialog({
        component: <AddDomainForm refetchContacts={refetchContacts} />,
        headline: "Add a new domain",
      }),
    );
  const deleteDomainDialog = (id: string) =>
    dispatch(
      mountDialog({
        component: (
          <DeleteDomain domainId={id} refetchContacts={refetchContacts} />
        ),
        headline: "Are you sure you want to delete this domain?",
      }),
    );
  function ignoreAtTheRateSymbol(text = "") {
    if (text && text.includes("@")) {
      return text.replaceAll("@", "");
    }
    return text;
  }
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    refetchContacts();
  }, []);

  useEffect(() => {
    if (data) {
      const seralizeData = data.reduce((structure, { value, id, status }) => {
        if (search.value) {
          if (compareValues(value, ignoreAtTheRateSymbol(search.value))) {
            structure.push({
              key: id,
              domain:
                value?.charAt(0) === "@"
                  ? value?.replaceAll('"', "")
                  : `@${value}`?.replaceAll('"', ""),
              status,
            });
          }
        } else {
          structure.push({
            key: id,
            domain:
              value?.charAt(0) === "@"
                ? value?.replaceAll('"', "")
                : `@${value}`?.replaceAll('"', ""),
            status,
          });
        }
        return structure;
      }, []);

      setDomains(seralizeData);
    }
  }, [data, search]);

  const refetchContacts = () => {
    setIsLoading(true);
    fetcher(`/resources/domains/account/${user?.id}`)
      .then((res) => {
        if (res) setData(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
        setIsLoading(false);
      });
  };

  const actionButtonClickHandler = async (value: DomainsType, option: string) => {
    // updating domains for immediate visual effect
    setDomains((data) =>
      data.map((data) => {
        if (data.key === value.key) {
          return { ...data, status: option };
        } else {
          return data;
        }
      }),
    );
    // API call to set status
    await update("domains", `/account/${user?.id}/domain/${value.key}`, {
      status: option,
    });
  };

  const deleteRecord = (record: DomainsType) => {
    deleteDomainDialog(record.key);
    // seterrorInRowKey(record.key);
  };

  const columns = [
    {
      title: () => <p>Domain name</p>,
      width: "60%",
      dataIndex: "domain",
      key: "domain",
      render: (text: string, key: any) => (
        <a className={key.parentKey ? `ml-4 p-1` : "font-bold ml-2"}>{text}</a>
      ),
    },
    {
      title: () => <p>Status</p>,
      key: "status",
      width: "25%",
      render: (_: any, record: DomainsType) => {
        return (
          <div className="py-4">
            <StatusActionButtons
              value={record}
              onClickHandler={actionButtonClickHandler}
              options={["approved", "blocked"]}
            />
          </div>
        );
      },
    },
    {
      title: () => <p>Actions</p>,
      key: "actions",
      width: "15%",
      render: (_: any, record: DomainsType) => {
        return (
          <div>
            <Button
              format={{
                content: "",
                icon: "delete",
                type: "iconButton",
                onclick: () => deleteRecord(record),
              }}
            />
          </div>
        );
      },
    },
  ];

  if (data && domains) {
    if (!toggle)
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="h-full"
        >
          <div>
            <div className="flex justify-end gap-8 items-center sm:float-right sm:-mt-18 xs:mt-4 w-1/2 xs:invisible xs:h-0 xs:w-0">
              <SearchField
                id="contactSearch"
                name="value"
                placeholder="Search domains"
                state={search}
                setState={setSearch}
              />
              <Button
                format={{
                  id: "NewContactButton",
                  type: "primary",
                  icon: <PlusIcon color="#36854C" />,
                  content: "Add domain",
                  isFilled: false,
                  onclick: addDomainDialog,
                }}
              />
            </div>
            <div className="flex justify-center items-center mt-4 w-full sm:invisible sm:w-0 sm:h-0">
              <SearchField
                id="contactSearch"
                name="value"
                placeholder="Search domains"
                state={search}
                setState={setSearch}
              />
              <div className="bottom-0 w-full items-center justify-center flex flex-col fixed bg-white">
                <div className="w-full h-px bg-gray_Xlight shadow "></div>
                <Button
                  format={{
                    id: "NewContactButton",
                    type: "primary",
                    icon: <PlusIcon color="#36854C" />,
                    content: "Add domain",
                    isFilled: false,
                    onclick: addDomainDialog,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mt-2 w-full">
            <MemoizedReviewDomainsBanner inboxFetchCompleted={inboxFetchCompleted} />
          </div>
          <div className="flex flex-col flex-grow overflow-y-auto justify-center items-center pb-20 xs:pb-28 h-full text-black_primary">
            {data.length > 0 && domains.length > 0 ? (
              <>
                <div className="block sm:hidden w-full">
                  <MobileActionAntdTable
                    data={domains}
                    actionButtonClickHandler={actionButtonClickHandler}
                    deleteOptionVisible={true}
                    deleteButtonClickHandler={deleteRecord}
                  />
                </div>
                <div className="w-full sm:flex hidden  justify-center ">
                  <ExpandableTable
                    errorInRowKey={errorInRowKey}
                    data={domains}
                    columns={columns}
                    pagination={false}
                  />
                </div>
              </>
            ) : (
              <NoData
                title="No domains"
                description="Try adding a domain for a company whose emails you want to block,
								or use our “review domains” experience above."
                button={
                  <Button
                    format={{
                      content: "Add domain",
                      isFilled: true,
                      type: "primary",
                      icon: <PlusIcon color="white" />,
                      onclick: addDomainDialog,
                    }}
                  />
                }
              />
            )}
          </div>
          <div className="flex flex-col fixed bottom-0 justify-center xs:mb-0 items-center w-screen bg-white xs:-mx-6 sm:invisible sm:h-0 sm:w-0 xs:hidden">
            {/* horizontal line */}
            <div className={`bg-gray3 h-px xs:w-full`} />
            <Button
              format={{
                id: "NewContactButtonMobileView",
                type: "primary",
                icon: <PlusIcon color="#36854C" />,
                content: "Add domain",
                onclick: addDomainDialog,
              }}
            />
          </div>
        </motion.div>
      );

    return <div></div>;
  }
  if (isLoading) {
    // return <Loader />;
  }
  if (error) {
    return <h1> There was an error</h1>;
  }

  return <div></div>;
}

export default Domains;