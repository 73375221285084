export function compareValues(value: string, comparer: string): boolean {
  if (!value) {
    return false;
  }

  value = value.toLowerCase();
  comparer = comparer.toLowerCase();

  return value.includes(comparer);
}

export function toPascalCase(text = "") {
  const wordArray = text.trim().split(" ");
  if (wordArray.length > 0) {
    return wordArray
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }
}

export function sanitizeObject(obj: Record<string, any>): Record<string, any> {
  const newObj: Record<string, any> = {};

  for (const key in obj) {
    if (obj[key] !== undefined && obj[key] !== "" && obj[key] !== null) {
      newObj[key] = obj[key];
    }
  }

  return newObj;
}
