import { Form, Formik, FormikProps } from "formik";
import React, { useRef, useState } from "react";
import useError from "../../../../hooks/useError";
import { post } from "../../../../logic/util/hooks";
import Button from "../../Button";
import StaticAlert from "../../atoms/StaticAlert";
import Field from "../Field";
import {
  forgetPasswordValidation,
  initialForgetPassword,
} from "./schema.ForgetPassword";

function ForgetPasswordForm() {
  const formRef = useRef<FormikProps<typeof initialForgetPassword>>(null);
  const { error, mountError } = useError();
  const [emailSent, setEmailSent] = useState<boolean>(false);

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialForgetPassword}
      validationSchema={forgetPasswordValidation}
      onSubmit={async (values) => {
        try {
          const { data } = await post("auth", "bpi/forgotPassword", values);
          setEmailSent(true);
        } catch (error) {
          mountError(error);
        }
      }}
    >
      {(props) => {
        return (
          <Form className="flex flex-col gap-2 max-w-md w-full">
            {error && <StaticAlert type="error" message={error} />}
            <Field
              name="email"
              key="email"
              label="Email"
              type="email"
              placeholder=""
            />
            <div className={`sm:flex sm:flex-col sm:justify-end`}>
              {emailSent ? (
                <div>
                  <p className="text-green_primary text-xl font-bold">
                    Email sent!
                  </p>
                  <p className="text-green_primary text-base">
                    Check your email and click the link to reset your password.
                  </p>
                  <div
                    className={`flex flex-col w-full items-center mt-2 xs:flex-col`}
                  >
                    <p className="whitespace-normal text-black_primary text-base">
                      Don’t see an email from us? It may take a few minutes to
                      show up in your inbox.
                    </p>
                    <p
                      className="whitespace-nowrap text-green_primary text-base underline decoration-current cursor-pointer mt-2"
                      role="button"
                      onClick={() => formRef.current?.handleSubmit()}
                    >
                      Resend email
                    </p>
                  </div>
                </div>
              ) : (
                <div className="w-full sm:flex sm:justify-end">
                  <Button
                    format={{
                      type: "submit",
                      id: "sentEmail",
                      isFilled: true,
                      content: "Send password reset email",
                      isLoading: props.isSubmitting,
                      width: props.isSubmitting ? "w-40" : "w-fit",
                    }}
                  />
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ForgetPasswordForm;
