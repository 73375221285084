import React from "react";
import { useNavigate } from "react-router";
import profileLogoMedium from "./../../../assets/icons/profile_medium.svg";
import mixpanel from "mixpanel-browser";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface UserObject {
  id?: string;
  first?: string;
  last?: string;
  email?: string;
  platform?: any;
}
interface User {
  user: UserObject;
}

const ProfileLink: React.FC<User> = ({ user }) => {
  const navigate = useNavigate();
  const email = user?.email?.split("@");
  const userEmail = email?.[0];

  return (
    <div
      className="flex items-center h-5 cursor-pointer"
      onClick={() => {
        mixpanel.track(`Clicked: 'Profile icon' (Top nav-bar)`);
        navigate("/app/profile");
      }}
    >
      <LazyLoadImage src={profileLogoMedium} alt="profile" width={20} height={10} />
      <div className="h-5 -mt-2 xs:leading-3 xs:-mt-6">
        <p className="text-black_primary sm:text-green_primary font-custom capitalize pl-2 xs:pl-5 text-base sm:underline">
          {user?.first || user?.last ? `${user?.first} ${user?.last}` : "-"}
        </p>
        <div className="xs:-mt-1 sm:invisible sm:w-0">
          <p className="text-green_primary font-custom pl-5 text-xs underline">
            View my profile
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileLink;
