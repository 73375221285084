/**
         * If customer credit card is expired
         * If customer credit card is about to expire (45 days but since we're just getting month and year we can only apply condition to month and year)
         * Time remaining on trail period
         * Trail ended but user didnt enter payment info
         * In case of monthly - time remaining in next recharrge
         * 
         * Customer enters incorrect credit card
         * No refund in case of monthly, only annual.
         */


interface PaymentStatus {
    card_exp_month: number;
    card_exp_year: number;
    bill_status?: string;
    plan: string;
    trial_days: number;
    next_bill_occurance: string;
    billed_on: string;
    isWhiteListed?: boolean;
    last_4_digit?: string;
  }

  
export function calculatePaymentStatus(paymentInfo : PaymentStatus) {
    const cardExpiryStatus = ifCreditCardExpired(paymentInfo?.card_exp_month, paymentInfo?.card_exp_year); //expired, not_expired, about_to_expire
    const billStatus = paymentInfo?.bill_status; //trialing , paid
    const billType = paymentInfo?.plan; //month, year, trial
    const trailDaysLeft = getDateDifference(paymentInfo?.trial_days); //number of days remaining
    const next_billing = returnStatusToUser(parseInt(paymentInfo?.next_bill_occurance) * 1000, paymentInfo?.plan);
    const billed_on = returnStatusToUser(parseInt(paymentInfo?.billed_on) * 1000, paymentInfo?.plan);
    const wont_be_billed_till = returnNextBilling(parseInt(paymentInfo?.next_bill_occurance) * 1000, paymentInfo?.plan)

    if (paymentInfo?.isWhiteListed) {                
        return {
            cardExpiryStatus:'not_expired', billStatus:'paid', billType:'month', trailDaysLeft:0, billed_on, next_billing, wont_be_billed_till, last_four_digits: paymentInfo?.last_4_digit
        }
    }
    else{
        return {
            cardExpiryStatus, billStatus, billType, trailDaysLeft, billed_on, next_billing, wont_be_billed_till, last_four_digits: paymentInfo?.last_4_digit
        }
    }
}

//Next occurence in case of trail
function returnNextBilling(next_bill_occurance : number, type : string) {
    const timestamp = new Date(next_bill_occurance); //timestamp rn
    const day = timestamp.getUTCDate(); //today's date - 24, 25
    const month = timestamp.getUTCMonth() + 1;
    const year = timestamp.getUTCFullYear(); //current year - 2023

    if (type == "year") {
        return { day, month, monthInText: monthInWord(month), year: year + 1, type };
    } else if (type == "month") {
        return { day, month: month + 1, monthInText: monthInWord(month + 1), year, type };
    }
}

// Calculate the 
function returnStatusToUser(next_bill_occurance : number, type : string) {
    //find next payment date - Automatically billed on
    const timestamp = new Date(next_bill_occurance); //timestamp rn
    const day = timestamp.getUTCDate(); //today's date - 24, 25
    const month = timestamp.getUTCMonth() + 1;
    const year = timestamp.getUTCFullYear(); //current year - 2023
    return { day, month, monthInText: monthInWord(month), year, type };
    //Find the Plan - Monthly billing, $10 per month, Billed on the 12th of each month.
}

export const monthInWord = (month: number) => {
    switch (month) {
        case 1: return 'january';
        case 2: return 'february';
        case 3: return 'march';
        case 4: return 'april';
        case 5: return 'may';
        case 6: return 'june';
        case 7: return 'july';
        case 8: return 'august';
        case 9: return 'september';
        case 10: return 'october';
        case 11: return 'november';
        case 12: return 'december';
        default: return 'invalid'
    }
}

function ifCreditCardExpired(card_expiry_month : number, card_expiry_year : number) : string {
    const timestamp = new Date(new Date().getTime()); //timestamp rn
    // const dateToday = currentDate.getDate(); //today's date - 24, 25
    const currentMonth = timestamp.getUTCMonth() + 1;
    const currentYear = timestamp.getUTCFullYear(); //current year - 2023

    if (!card_expiry_month || !card_expiry_year) return 'invalid_card_info';

    if ((currentYear) > (card_expiry_year)) {
        return 'expired'
    } else if ((currentYear) == (card_expiry_year)) {
        if ((currentMonth) > (card_expiry_month)) {
            return 'expired'
        } else if ((card_expiry_month) - (currentMonth) == 0 || (card_expiry_month) - (currentMonth) == 1) {
            return 'about_to_expire'
        } else {
            return 'not_expired'
        }
    } else {
        return 'not_expired'
    }
}

function getDateDifference(info: number) : number {
    const timestamp = new Date().getTime();
    const currentDate:any = new Date(timestamp);
    const expiryDate:any = new Date(info * 1000)
    const time = Math.abs(expiryDate - currentDate)
    const daysRemaining = Math.round(time / (1000 * 60 * 60 * 24));
    return daysRemaining;
}