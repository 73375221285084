import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { fetcher } from "../../logic/util/hooks";

interface DecodedToken {
  id: string;
  exp: number;
}
interface UserData {
  id: string;
  first: string;
  last: string;
  email: string;
  platform: any;
}

interface AuthState {
  user: UserData | null;
}

interface LoginPayload {
  token: string;
  userData: UserData;
}
const initialState: AuthState = {
  user: null,
};

if (localStorage.getItem("jwtToken")) {
  const decodedToken: DecodedToken = jwtDecode(
    localStorage.getItem("jwtToken") || "",
  );
  const userDataFromStorage = JSON.parse(
    localStorage.getItem("userData") || "",
  );
  if (decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem("jwtToken");
  } else {
    initialState.user = { ...userDataFromStorage };
  }
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<LoginPayload>) => {
      localStorage.setItem("jwtToken", action.payload.token);
      localStorage.setItem("userData", JSON.stringify(action.payload.userData));
      state.user = { ...action.payload.userData };
    },
    logout: (state) => {
      localStorage.removeItem("showInfoDialogue");
      localStorage.removeItem("contactsAlreadyAdded");
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("userData");
      // localStorage.clear();
      state.user = null;
      window.location.assign("/login");
    },
    updateUserData: (state, action) => {
      state.user = { ...action.payload };
    },
    checkAuthentication: (state) => {
      const userDataFromStorage = JSON.parse(
        localStorage.getItem("userData") || "",
      );
      if (localStorage.getItem("jwtToken")) {
        const decodedToken: DecodedToken = jwtDecode(
          localStorage.getItem("jwtToken") || "",
        );
        if (decodedToken.exp * 1000 < Date.now()) {
          localStorage.removeItem("jwtToken");
          state.user = null;
        } else {
          state.user = { ...userDataFromStorage };
        }
      } else {
        state.user = null;
      }
    },
  },
});

export const { login, logout, updateUserData, checkAuthentication } =
  authSlice.actions;

export default authSlice.reducer;
