import accept from "./icons/accept.svg";
import decline from "./icons/decline.svg";
import emptyBox from "./icons/empty-mailbox.svg";
import emptyMail from "./icons/mailbox-empty.svg";
import noPhone from "./icons/no-phone.svg";
import noDomain from "./icons/no-domain.svg";
import noEmail from "./icons/no-email.svg";
import close from "./icons/close.svg";
import check from "./icons/check.svg";
import block from "./icons/block.svg";
import neutral from "./icons/neutral.svg";
import table from "./graphics/table.svg";
import disconnect from "./icons/disconnect.svg";
import amexCard from "./icons/AMEX.svg";
import visaCard from "./icons/Visa.svg";
import masterCard from "./icons/Mastercard.svg";
import discoverCard from "./icons/DiscoverCard_icon.svg"
import arrowDown from "./icons/arrow_down.svg"
import lock from "./icons/lock.svg"
import checkWhite from "./icons/checkWhite.svg"

export const icons = {
  neutral: neutral,
  block: block,
  check: check,
  accept: accept,
  decline: decline,
  emptyBox: emptyBox,
  emptyMail: emptyMail,
  noPhone: noPhone,
  noDomain: noDomain,
  noEmail: noEmail,
  close: close,
  disconnect: disconnect,
  amexCard: amexCard,
  visaCard:visaCard,
  masterCard: masterCard,
  discoverCard: discoverCard,
  arrowDown: arrowDown,
  lock: lock,
  checkWhite: checkWhite,
};

export const graphics = {
  table: table,
};
