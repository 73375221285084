import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  unsetLoading,
} from "../../../../features/loader/loaderSlice";
import { login } from "../../../../features/auth/authSlice";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { fetcher, post } from "../../../../logic/util/hooks";
import bpLogo from "../../../assets/graphics/Bulletproof_Logo.png";
import Button from "../../../primitives/Button";
import Label from "../../../primitives/Label";
import Toast from "../../../primitives/Toast";
import SignInForm from "../../../primitives/forms/login/SignInForm";
import signInBackgroundImage from "./../../../assets/graphics/sign_in_background.png";
import { useLocation } from "react-router";
import Loader from "../../../primitives/Loader/Loader";
import mixpanel from "mixpanel-browser";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface UserData {
  email: string;
  firstName: string;
  lastName: string;
  id: string;
}

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  const sscid = searchParam.get("sscid");
  const email = searchParam.get("email");
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: any) => state.loader);
  const [data, setData] = useState<UserData | null>(null);
  // const context = useContext(AuthContext);
  // const { isLoading, setIsLoading } = useContext(LoaderContext);
  let [searchParams] = useSearchParams();

  useEffect(() => {
    if (sscid) {
      localStorage.setItem("sscid", sscid);
    }
    window.scrollTo(0, 0);
  }, []);

  // mix-panel Tracking
  const identifyUser = (userData: UserData) => {
    mixpanel.identify(userData.email);
    mixpanel.people.set({
      $email: userData?.email,
      $first_name: userData?.firstName,
      $last_name: userData?.lastName,
      $user_id: userData?.id,
      // Add more properties as needed
    });
  };

  useLayoutEffect(() => {
    const accessTokenFromGoogle = searchParams.get("access_token");
    if (accessTokenFromGoogle) {
      dispatch(setLoading());
      post("auth", "bpi/social_Signin", {
        access_token: accessTokenFromGoogle,
        email,
      })
        .then((res) => {
          const { data } = res;
          setData(data);
          dispatch(login({ userData: data, token: data.token }));
          if (
            window.localStorage.getItem("sso_signup") &&
            data.newUser === true
          ) {
            navigate("/personalize", { state: data });
          } else {
            localStorage.removeItem("sso_signup");
            dispatch(unsetLoading());
            if (data?.first && data?.last) {
              navigate("/app/email-accounts");
            } else {
              localStorage.setItem(`personalize_user`, "true");
              navigate("/personalize", { state: data });
            }
          }
          // mixpanel-Identify
          identifyUser(data);
        })
        .catch((err) => {
          console.log("Err in signin after access token", err);
          dispatch(unsetLoading());
        });
    }
  }, []);

  const onGoogleSignIn = () => {
    dispatch(setLoading());
    fetcher("/auth/google/google_social_signin?state=googleSignIn")
      .then((res) => {
        if (res) {
          window.location.href = res.url;
        }
      })
      .catch((err) => {
        console.log("Err in Google signin inside Login", err);
        dispatch(unsetLoading());
      });
  };

  const onResetPasswordClick = () => {
    navigate("/forgot-password");
  };

  const onSignUpClick = () => {
    navigate("/register");
  };
  if (isLoading) {
    return (
      <div
        className={"flex flex-col justify-center items-center h-screen w-full"}
      >
        <Loader />
      </div>
    );
  }
  return (
    <>
      {/* <PublicNav title="Sign in" /> */}
      <div
        className="text-black_primary bg-center bg-no-repeat bg-cover bg-orange_bg flex justify-center items-start 3xl:items-center h-full min-h-screen xs:h-full"
        style={{ backgroundImage: `url(${signInBackgroundImage})` }}
      >
        <div
          className={`bg-white mt-24 p-5 m-5 pb-1 sm:pb-0 w-full sm:w-auto flex flex-col items-center shadow-none sm:shadow-nav rounded-none sm:rounded-lg gap-y-10 sm:gap-y-1`}
        >
          <div className="text-base flex gap-x-2 text-black100">
            Don’t have an account?
            <button
              onClick={onSignUpClick}
              className="text-base text-green_primary underline "
            >
              Create an account
            </button>
          </div>
          <div className={`w-125 xs:w-full xs:px-6`}>
            <div>
              <div className="flex justify-center flex-col items-center xs:gap-y-3.5 w-full mt-10 xs:mt-0">
                <LazyLoadImage
                  src={bpLogo}
                  width={"200px"}
                  height={"54px"}
                  alt="bullet proof inbox logo"
                />
                <Label
                  format={{
                    color: "text-green_primary",
                    text: "Sign in to your account.",
                    fontSize: "text-xl",
                    flex: "",
                    textAlign: "center",
                    // margin: "16px 12px"
                  }}
                />
              </div>
              <div>
                <div className="flex flex-col gap-2 w-full">
                  <Button
                    format={{
                      type: "signup",
                      isFilled: false,
                      content: "Sign in with Google",
                      // isLoading: props.isSubmitting,
                      width: "w-full",
                      icon: "google",
                      onclick: () => {
                        onGoogleSignIn();
                        mixpanel.track("Clicked: 'Google Sign-In' button", {
                          data,
                        }); // Track Google Sign-In button click
                      },
                    }}
                  />
                </div>
                <div className="flex items-center mt-8">
                  <div className="flex-grow border-t border-gray3"></div>
                  <div className="px-2 text-gray1">
                    or sign in with your email address
                  </div>
                  <div className="flex-grow border-t border-gray3"></div>
                </div>
              </div>
              {/* <div className="inline-block w-10 mb-8 h-1 bg-orange1" /> */}
              <div className="flex flex-col justify-items-center items-center mt-6 w-full">
                <SignInForm content="Sign in" />
                <Toast />
              </div>
              <div className="font-custom font-normal text-sm text-gray1 mt-6 text-center">
                By signing in, you agree to our{" "}
                <a
                  href="https://www.bulletproofinbox.com/privacy-policy"
                  target={"_blank"}
                  className="underline"
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a
                  href="https://www.bulletproofinbox.com/terms-of-service"
                  target={"_blank"}
                  className="underline"
                >
                  Terms of Service
                </a>
              </div>
            </div>
            {/* horizontal line */}
            {/* <div
            className={`bg-gray3 h-px w-125 mt-6 xs:w-full xs:mx-6 `}
          /> */}
            <div className={`xs:w-full xs:px-0 xs:pb-4 flex justify-center`}>
              <div
                className={`flex flex-row xs:flex-col xs:justify-center mt-3`}
              >
                <Label
                  format={{
                    fontWeight: "font-normal",
                    fontSize: "text-base",
                    color: "text-black_primary",
                    text: "Having trouble with your password?",
                    link: "",
                    // margin: "0px"
                  }}
                  isClickable={false}
                />
                <Label
                  format={{
                    fontWeight: "font-normal",
                    fontSize: "text-base",
                    color: "text-green_primary",
                    text: "Reset password",
                    link: "",
                    // margin: "8px 0px"
                  }}
                  onClick={onResetPasswordClick}
                  isClickable={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
