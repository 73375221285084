import React from "react";
import Button from "../../Button";

interface Record {
  key: string;
  name?: string;
  email: string;
  phone: string;
  reason: string;
  relationship: string;
  contactID: string;
  platform_ref: string;
  option?: any;
  status?: string;
  domain?: string;
  parentKey?: string;
  count?: number;
  subject?: string;
  subDomain?: any;
}

interface MobileTagActionAntdTableProps {
  data: Record[] | null;
  actionButtonClickHandler: (record: Record, action: string) => void;
  deleteOptionVisible?: boolean;
}

function MobileTagActionAntdTable({
  data,
  actionButtonClickHandler,
}: MobileTagActionAntdTableProps) {
  if (data)
    return (
      <div>
        {data?.map((record) => {
          const status = record?.status
            ? record?.status?.toLowerCase()
            : "unknown";

          return (
            <div className="mt-8">
              {/** main content of the table */}
              <div className="flex flex-col">
                <div className="flex flex-col justify-between p-2\5">
                  <div className="flex flex-col justify-between">
                    {/**Displaying data */}

                    <div>
                      <div className="flex flex-row">
                        <div className="font-bold">{record.name}</div>
                        <div className="text-xs bg-green_bg text-green_primary rounded px-2 py-1 ml-2">
                          {record.relationship}
                        </div>
                      </div>
                      <div className="flex flex-row mt-2">
                        <div className="text-gray1">{record.email}</div>
                        <div className="text-gray1 ml-3">{record.phone}</div>
                      </div>
                      <div className="text-gray1">{record.reason}</div>
                    </div>
                  </div>
                  {/** For the action buttons */}
                  <div className="w-23 flex flex-row mt-4 ">
                    <Button
                      format={{
                        id: "approveButton",
                        onclick: () =>
                          actionButtonClickHandler(record, "approved"),
                        type: "table",
                        content: "Approve",
                        color: "green",
                        isFilled: true,
                        isSelected: status == "approved",
                      }}
                    />
                    <div className="ml-1"></div>
                    <Button
                      format={{
                        id: "blockButton",
                        onclick: () =>
                          actionButtonClickHandler(record, "blocked"),
                        type: "table",
                        content: "Block",
                        color: "red",
                        isFilled: true,
                        isSelected: status == "blocked",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );

  return <></>;
}

export default MobileTagActionAntdTable;
