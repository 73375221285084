import React from "react";
import { Link } from "react-router-dom";
import BpiAppFooter from "../landingPage/components/BpiAppFooter";

function Tos() {
  return (
    <div>
      <div
        className={
          localStorage.getItem("jwtToken")
            ? "relative -mt-[5.75rem] overflow-hidden pt-[5.75rem]"
            : " -mt-[5.75rem] overflow-hidden pt-[5.75rem]"
        }
      >
        <div className="px-4 sm:px-6 lg:px-8">
          <div
            className={
              localStorage.getItem("jwtToken")
                ? "relative mx-auto max-w-[37.5rem] pt-20 text-center pb-24"
                : "mx-auto max-w-[37.5rem] pt-20 text-center pb-24"
            }
          >
            <h1 className="text-4xl font-extrabold tracking-tight text-white100 sm:text-5xl">
              Terms of Service
            </h1>
            <p className="mt-4 text-base leading-7 text-white100">
              Last Updated: July, 2022
            </p>
          </div>
        </div>
        <div className="relative px-24 sm:px-6 lg:px-24 text-white100 leading-10">
          <div className="mx-auto max-w-[40rem] prose-sm prose prose-white100 prose-a:font-semibold prose-a:text-white100 hover:prose-a:text-sky-600 flex flex-col">
            <p className="my-4">
              The following terms and conditions (the “Agreement”) govern all
              use of our email inbox productivity application, and our other
              websites, applications, and services (collectively, the
              “Service”). The Service is owned and operated by Bulletproof
              Technologies, Inc. (“us”, “we”, or “our”). The Service is offered
              subject to your acceptance without modification of all the terms
              and conditions contained herein. BY USING OR ACCESSING ANY PART OF
              THE SERVICE, YOU AGREE TO ALL OF THE TERMS AND CONDITIONS
              CONTAINED HEREIN; IF YOU DO NOT AGREE, YOU MUST NOT USE THE
              SERVICE. Bulletproof Inbox reserves the right, at its sole
              discretion, to modify or replace any of the terms or conditions of
              this Agreement at any time. We will post notice of changes on
              www.bulletproofinbox.com. We will also update the “Last Updated”
              date above.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              1. ARBITRATION AND CLASS ACTION WAIVER
            </h2>
            <p className="my-4">
              WE HAVE CERTAIN REQUIREMENTS THAT YOU AGREE TO ARBITRATE DISPUTES
              YOU MAY HAVE WITH US – AND WAIVE RIGHTS TO BRING className ACTION
              LAWSUITS. PLEASE SEE SECTION 14 (“DISPUTE RESOLUTION”) BELOW FOR
              MORE INFORMATION. BY ENTERING THIS AGREEMENT, YOU EXPRESSLY
              ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL OF THE TERMS AND
              CONDITIONS OF THIS AGREEMENT AND HAVE TAKEN TIME TO CONSIDER THE
              CONSEQUENCES OF ENTERING THIS AGREEMENT.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              2. Scope{" "}
            </h2>
            <p className="my-4">
              The Service is a tool to help you (as a “Recipient”) cut down on
              unwanted emails from unknown people and businesses trying to
              contact you (“Senders”). By registering to use the Service, the
              Service creates a new folder in the Recipient’s inbox and routes
              emails from unknown Senders from the Recipient’s general inbox to
              this folder. The Service will also send, on Recipient’s behalf
              from anonymous Bulletproof Inbox address, a ‘survey email’ to
              unknown Senders asking them to explain why they want to contact
              the Recipient. For clarity, this Agreement applies to both
              Recipients and Senders.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              3. Privacy Policy{" "}
            </h2>
            <p className="my-4">
              Your use of the Service is subject to our privacy policy located
              at{" "}
              <Link to="/privacy-policy" className="text-blue100">
                https://www.bulletproofinbox.com/privacy-policy
              </Link>
              . We strongly encourage you to review it closely.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              4. User Account; Access
            </h2>
            <p className="my-4">
              ‍In order to use the Service you will be required to create a
              Service account (“Account”). You represent and warrant that: (a)
              all required registration information you submit is truthful and
              accurate; (b) you will maintain the accuracy of such information.
              You will not share your Account login credentials (such as your
              passwords) with any third party. You must immediately notify us of
              any unauthorized use, or suspected unauthorized use of your
              Account or any other breach of security with respect to your
              Account.
            </p>
            <p className="my-4">
              Bulletproof Inbox may change, suspend or discontinue any aspect of
              the Service at any time. Bulletproof Inbox may also impose limits
              on certain features and services or restrict your access to parts
              or all of the Service without notice or liability.
            </p>
            <p className="my-4">
              The Service is available only to individuals who are at least 18
              years old. You represent and warrant that if you are an
              individual, you are at least 18 years old. You also certify that
              you take full responsibility for the selection and use of the
              Service. This Agreement is void where prohibited by law, and the
              right to access the Service is revoked in such jurisdictions.
            </p>
            <p className="my-4">
              You shall be responsible for obtaining and maintaining any
              equipment or ancillary services needed to connect to, access the
              Service, including, without limitation, modems, hardware,
              software, and long distance or local telephone service. You shall
              be responsible for ensuring that such equipment or ancillary
              services are compatible with the Service.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              5. Connecting Your Email Account
            </h2>
            <p className="my-4">
              ‍To use the Service, you will need to connect your email account
              and grant us certain access. To connect your email account, you
              will signup via your Google and/or Outlook login. The Service will
              not collect your passwords and you can revoke access at anytime.
              As part of such connection, you grant Bulletproof Inbox access to
              add/remove labels in Gmail and/or folders in Outlook, read header
              information and contacts, move emails between folders or add
              labels to emails, delete emails, send the ‘survey email’ (see
              Section 1 above) on your behalf, and other actions required to
              provide the Service.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              6. Your Warranties; No Support
            </h2>
            <p className="my-4">
              ‍You represent and warrant (i) your use of the Service will comply
              with all laws and regulations (including with respect to privacy),
              (ii) if you are a Recipient, the email account you connect to the
              Service is owned by you, or you otherwise have sufficient rights
              to it for all purposes of this Agreement, (iii) if you use the
              Service on behalf of any third party, you have all necessary
              authorizations, and (iv) your use of the Service will not conflict
              with any obligations you have to any third party. Bulletproof
              Inbox has no obligation to provide any support hereunder.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              7. Other Restrictions
            </h2>
            <p className="my-4">
              You shall not submit any content or information to the Service
              that is false, misleading or inaccurate.
            </p>
            <p className="my-4">
              You shall not use any “deep-link”, “page-scrape”, “robot”,
              “spider” or other automatic device, program, algorithm or
              methodology, or any similar or equivalent manual process, to
              access, acquire, copy or monitor any portion of the Service, or in
              any way reproduce or circumvent the navigational structure or
              presentation of the Service, to obtain or attempt to obtain any
              materials, documents or information through any means not
              purposely made available through the Service. Bulletproof Inbox
              reserves the right to bar any such activity.
            </p>
            <p className="my-4">
              You shall not attempt to gain unauthorized access to any portion
              or feature of the Service, or any other systems or networks
              connected to the Service or to any Bulletproof Inbox server, or to
              any of the services offered on or through the Service, by hacking,
              password “mining”, or any other illegitimate means.
            </p>
            <p className="my-4">
              You shall not probe, scan or test the vulnerability of the Service
              or any network connected to the Service, nor breach the security
              or authentication measures on the Service or any network connected
              to the Service. You shall not reverse look-up, trace or seek to
              trace any information on any other user of or visitor to the
              Service, or any other customer of Bulletproof Inbox, including any
              Bulletproof Inbox account not owned by you, to its source, or
              exploit the Service or any service or information made available
              or offered by or through the Service, in any way where the purpose
              is to reveal any information, including but not limited to
              personal identification or information, other than your own
              information, as provided for by the Service.
            </p>
            <p className="my-4">
              You shall not take any action that imposes an unreasonable or
              disproportionately large load on the infrastructure of the Service
              or Bulletproof Inbox’s systems or networks, or any systems or
              networks connected to the Service or to Bulletproof Inbox.
            </p>
            <p className="my-4">
              You shall not use any device, software or routine to interfere or
              attempt to interfere with the proper working of the Service or any
              transaction being conducted on the Service, or with any other
              person’s use of the Service.
            </p>
            <p className="my-4">
              You shall not forge headers or otherwise manipulate identifiers in
              order to disguise the origin of any message or transmittal you
              send to Bulletproof Inbox on or through the Service. You shall
              not, in connection with the Service, pretend (e.g. through
              impersonation) that you are any other person.
            </p>
            <p className="my-4">
              You shall not use the Service for any purpose that is unlawful or
              prohibited by this Agreement, or which infringes the rights of
              Bulletproof Inbox or others.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              8. Feedback
            </h2>
            <p className="my-4">
              ‍If you provide ideas, suggestions, or other feedback to
              Bulletproof Inbox in connection with the Service (such as
              suggestions for improvements to the Service) ("Feedback"), you
              acknowledge that the Feedback is not confidential and you grant
              Bulletproof Inbox (and our successors and assigns) a perpetual,
              irrevocable, worldwide, sublicensable, transferrable,
              royalty-free, fully paid-up right and license to use, reproduce,
              display, perform, distribute, sell, and otherwise fully exploit
              all Feedback.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              9. Indemnification
            </h2>
            <p className="my-4">
              You shall defend, indemnify, and hold harmless Bulletproof Inbox
              and each of our employees, contractors, directors, providers,
              suppliers and representatives from all liabilities, damages,
              losses, settlements, claims, actions, suits, demands, proceedings,
              costs and expenses, including attorneys' fees, that arise from or
              in connection with your misuse of the Service or breach of this
              Agreement.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              10. Warranty Disclaimer
            </h2>
            <p className="my-4">
              THE SERVICE IS PROVIDED ON AN "AS IS" BASIS, WITHOUT WARRANTIES OF
              ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT
              LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE OR NON-INFRINGEMENT. BULLETPROOF INBOX MAKES NO
              WARRANTY THAT (I) THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL
              COMPONENTS, OR (II) THE RESULTS OF USING THE SERVICE WILL MEET
              YOUR REQUIREMENTS.
            </p>
            <p className="my-4">
              WITHOUT LIMITING THE FOREGOING, IF YOU ARE A RECIPIENT,
              BULLETPROOF INBOX MAKES NO WARRANTIES THAT THE SERVICE WILL
              PROPERLY IDENTIFY, BLOCK, OR OTHERWISE FILTER EMAILS.
            </p>
            <p className="my-4">
              THE FOREGOING DISCLAIMERS SHALL NOT APPLY TO THE EXTENT PROHIBITED
              BY APPLICABLE LAW.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              11. Limitation of Liability
            </h2>
            <p className="my-4">
              ‍IN NO EVENT SHALL BULLETPROOF INBOX, ITS OFFICERS, DIRECTORS,
              EMPLOYEES, AGENTS, OR PROVIDERS BE LIABLE UNDER CONTRACT, TORT,
              STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL THEORY WITH
              RESPECT TO THE SERVICE: (I) FOR ANY LOST PROFITS OR SPECIAL,
              INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY
              KIND WHATSOEVER, EVEN IF FORESEEABLE, (II) FOR ANY BUGS, VIRUSES,
              TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE OF
              ORIGINATION), (III) FOR ANY ERRORS OR OMISSIONS IN THE SERVICE, OR
              (IV) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) $5.00
              (U.S.).
            </p>
            <p className="my-4">
              WITHOUT LIMITING THE FOREGOING, IF YOU ARE A RECIPIENT, YOU
              ACKNOWLEDGE AND AGREE THAT BULLETPROOF INBOX IS NOT LIABLE FOR ANY
              FAILURE OF THE SERVICE TO IDENTIFY, BLOCK, OR OTHERWISE FILTER
              EMAILS.
            </p>
            <p className="my-4">
              ‍YOU ALSO ACKNOWLEDGE AND AGREE THAT BULLETPROOF INBOX SHALL NOT
              BE LIABLE FOR ANY MATTERS BEYOND BULLETPROOF INBOX’S REASONABLE
              CONTROL, INCLUDING, WITHOUT LIMITATION, MECHANICAL, ELECTRONIC, OR
              COMMUNICATIONS FAILURE OR DEGRADATION.
            </p>
            <p className="my-4">
              ‍THE FOREGOING LIMITATIONS SHALL NOT APPLY TO THE EXTENT
              PROHIBITED BY APPLICABLE LAW.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              12. Term and Termination
            </h2>
            <p className="my-4">
              ‍Bulletproof Inbox may terminate your access to all or any part of
              the Service at any time, with or without cause. You can terminate
              this Agreement by permanently ceasing to use the Service. All
              provisions of this Agreement which by their nature should survive
              termination shall survive termination, including, without
              limitation, warranty disclaimers, and limitations of liability.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              13. Export and Trade Controls
            </h2>
            <p className="my-4">
              You agree not to import, export, re-export, or transfer, directly
              or indirectly, any part of the Service or any information provided
              on or through the Service except in full compliance with all
              United States, foreign and other applicable laws and regulations.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              14. Notices
            </h2>
            <p className="my-4">
              ‍All legal notices to Bulletproof Inbox must be addressed in
              writing, and sent by registered or certified mail, to: Bulletproof
              Technologies, Inc. 2121 Lohmans Crossing Rd Suite 504-308 Lakeway
              TX 78734
            </p>
            <p className="my-4">
              If you have general questions regarding this Agreement or the
              Service, you may also email us at protect@bulletproofinbox.com.
              Bulletproof Inbox may provide notices to you via email and to any
              other address you provide.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              15. Dispute Resolution
            </h2>
            <p className="my-4">
              ‍Ideally, if you have any concerns or complaints against
              Bulletproof Inbox, we would like to resolve the issue without
              resorting to formal court or arbitration proceedings. Therefore,
              before filing a claim against Bulletproof Inbox, you agree to try
              to resolve the dispute informally by contacting us as set forth in
              Section 15 above. Bulletproof Inbox will attempt to resolve the
              dispute informally (and will contact you via email). If a dispute
              is not resolved within thirty (30) days of submission, you may
              bring a formal proceeding.
            </p>
            <p className="my-4">
              ‍‍Arbitration. Any disputes that are not settled informally as
              contemplated above shall be settled by binding arbitration in
              accordance with the rules and procedures (“Rules”) of the American
              Arbitration Association (“AAA”). The Rules will be AAA’s Consumer
              Arbitration Rules if available. Arbitration shall be conducted by
              one (1) arbitrator selected in accordance with the Rules. In the
              event any telephonic or other streamlined procedures are available
              – they will be used. The written decision of the arbitrator shall
              be final and binding on the parties and enforceable in any court.
              The arbitration proceeding shall take place in Austin, Texas using
              the English language. Notwithstanding the foregoing, either party
              may bring claims for equitable or injunctive relief before a court
              (see the “Going to Court” section below) at any time.
            </p>
            <p className="my-4">
              ‍Process. A form for initiating arbitration proceedings is
              available on the AAA's site at www.adr.org. In addition to filing
              this form with the Rules, the party initiating the arbitration
              must mail a copy of the completed form to the opposing party. You
              must send a copy to Bulletproof Inbox at: Bulletproof
              Technologies, Inc. 2121 Lohmans Crossing Rd Suite 504-308 Lakeway
              TX 78734 (Attn: Arbitration). In the event we initiate arbitration
              against you, we will send a copy of the completed form to the
              email address or physical address we have on file.
            </p>
            <p className="my-4">
              ‍Evidence. If your claim does not exceed $10,000, then the
              arbitration will be conducted solely on the basis of documents we
              and you submit to the arbitrator, unless you request a hearing or
              the arbitrator determines that a hearing is necessary. If your
              claim exceeds $10,000, your right to a hearing will be determined
              by the Rules. Subject to the Rules, the arbitrator will have the
              discretion to direct a reasonable exchange of information by the
              parties, consistent with the expedited nature of the arbitration.
            </p>
            <p className="my-4">
              ‍Fees. The parties’ will each bear their own costs and expenses
              associated with arbitration – and responsibility to pay any AAA
              filing, administrative and arbitrator fees will be solely as set
              forth in the AAA Rules.
            </p>
            <p className="my-4">
              ‍No className Actions. You may only resolve disputes with
              Bulletproof Inbox on an individual basis, and may not bring a
              claim as a plaintiff or a className member in a className,
              consolidated, or representative action. className arbitrations,
              className actions, private attorney general actions, and
              consolidation with other arbitrations are not allowed.
            </p>
            <p className="my-4">
              ‍Modifications. Notwithstanding any provision in this Agreement to
              the contrary, you and we agree that if we make any amendment to
              the foregoing arbitration terms or conditions (other than an
              amendment to any notice address or site link) in the future, such
              amendment shall not apply to any claim that was filed in a legal
              proceeding against Bulletproof Inbox prior to the effective date
              of the amendment. In addition, you shall have to reject any such
              amendment by notifying Bulletproof Inbox in writing within thirty
              (30) days of the date that you are first notified of the amendment
              (according to the procedures set forth above in this Section 16).
              In the event you so reject an amendment, the amendment will not
              apply to you – but this Section 16 will otherwise remain in fully
              force and effect (according to the pre-amendment version you had
              already agreed to).
            </p>
            <p className="my-4">
              ‍Going to Court. Subject to the arbitration provision above, you
              agree that the exclusive jurisdiction and venue for all disputes
              arising in connection with this Agreement shall be in the state
              and Federal courts located in Austin, Texas. You hereby submit to
              such jurisdiction and venue.
            </p>
            <p className="my-4">
              ‍THE TERMS AND CONDITIONS IN THIS SECTION 16 WILL NOT APPLY TO THE
              EXTENT PROHIBITED OR OTHERWISE.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              16. General
            </h2>
            <p className="my-4">
              The failure of either party to exercise in any respect any right
              provided for herein shall not be deemed a waiver of any further
              rights hereunder. If any provision of this Agreement is found to
              be unenforceable or invalid, that provision shall be limited or
              eliminated to the minimum extent necessary so that this Agreement
              shall otherwise remain in full force and effect and enforceable.
              This Agreement is not assignable, transferable or sublicensable by
              you except with Bulletproof Inbox’s prior written consent.
              Bulletproof Inbox may transfer, assign or delegate this Agreement
              and its rights and obligations without consent. This Agreement
              shall be governed by and construed in accordance with the laws of
              the state of Texas, as if made within Texas between two residents
              thereof. Both parties agree that this Agreement is the complete
              and exclusive statement of the mutual understanding of the parties
              and supersedes and cancels all previous written and oral
              agreements, communications and other understandings relating to
              the subject matter of this Agreement, and that all modifications
              must be in a writing signed by both parties, except as otherwise
              provided herein. No agency, partnership, joint venture, or
              employment is created as a result of this Agreement and you do not
              have any authority of any kind to bind Bulletproof Inbox in any
              respect whatsoever.
            </p>
          </div>
        </div>
      </div>
      <BpiAppFooter />
    </div>
  );
}

export default Tos;
