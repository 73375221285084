import React from "react";

interface Props { color: string}

const EditIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.2267 12.5997L14.0467 4.77968C14.5818 4.28109 14.802 3.5302 14.6211 2.82158C14.4401 2.11296 13.8868 1.55962 13.1781 1.37865C12.4695 1.19768 11.7186 1.41794 11.22 1.95302L3.38003 9.79302C3.32989 9.84457 3.28725 9.90292 3.25337 9.96635L1.40003 13.7063C1.29869 13.9132 1.31129 14.1577 1.43337 14.353C1.55544 14.5483 1.7697 14.6668 2.00003 14.6663C2.11003 14.6638 2.21768 14.634 2.31337 14.5797L6.05337 12.7263C6.1168 12.6925 6.17515 12.6498 6.2267 12.5997ZM14 14.6663C14.3682 14.6663 14.6667 14.3679 14.6667 13.9997C14.6667 13.6315 14.3682 13.333 14 13.333H8.00003C7.63184 13.333 7.33337 13.6315 7.33337 13.9997C7.33337 14.3679 7.63184 14.6663 8.00003 14.6663H14ZM10.88 6.05968L9.94003 5.11968L4.42003 10.6663L3.49337 12.533L5.33337 11.5797L10.88 6.05968ZM12.6434 2.66551C12.8261 2.66551 13.0008 2.74053 13.1267 2.87302C13.2566 3.0016 13.328 3.17789 13.3243 3.36064C13.3205 3.5434 13.2418 3.71658 13.1067 3.83968L11.82 5.11968L10.88 4.17968L12.16 2.87302C12.2859 2.74053 12.4606 2.66551 12.6434 2.66551Z" fill={color}/>
    </svg>
  );
};

export default EditIcon;
