import { Form, Formik, Field as FormikField } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../../features/auth/authSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import useError from "../../../../hooks/useError";
import { post, fetcher } from "../../../../logic/util/hooks";
import StaticAlert from "../../atoms/StaticAlert";
import Button from "../../Button";
import Field from "../Field";
import PasswordFieldCopy from "../PasswordFieldCopy";
import { logLinkedInConversion } from "../../../../logic/util/linkedConversion";
import mixpanel from "mixpanel-browser";

const initialSignIn = {
  email: "",
  password: "",
  // staySignIn: true
};
const signInValidation = Yup.object().shape({
  email: Yup.string()
    .email("Error: Enter a valid email address, for example mike@gmail.com")
    .required("Required"),
  password: Yup.string().required("Required"),
  // staySignIn: Yup.boolean().required()
});

interface SignUpFormProps {
  content: string;
}

function SignUpForm({ content }: SignUpFormProps) {
  const conversionID: string = "12833036";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, mountError } = useError();
  return (
    <Formik
      initialValues={initialSignIn}
      validationSchema={signInValidation}
      onSubmit={async (values) => {
        try {
          //   if (process.env.REACT_APP_SHOW_PIN === 'true') {
          // 	await post("pins", "use", { pin: values.pin });
          //   }
          const { data } = await post("auth", "createAccount", values);
          if (data) {
            mixpanel.track("Clicked: Sign-up with your email address 'Continue' button", {
              user_Id: data?.id,
              user_Email: data?.email,
              user_FirstName: data?.first,
              user_LastName: data?.last,
              user_Phone: data?.phone,
              user_Tier: data?.tier,
            });
            mixpanel.identify(data.email);
            mixpanel.people.set({
              $email: data?.email,
              $first_name: data?.firstName,
              $last_name: data?.lastName,
              $user_id: data.id,
              // Add more properties as needed
            });
            dispatch(login({ userData: data.account, token: data.token }));
            logLinkedInConversion(conversionID);
            navigate("/personalize", { state: { token: data.token } });
          }
        } catch (error) {
          mountError(error);
        }
      }}
    >
      {(props) => {
        return (
          <React.Fragment>
            <Form className="flex flex-col w-full">
              {error && <StaticAlert type="error" message={error} />}
              <Field
                name="email"
                key="email"
                label="Email"
                type="email"
                placeholder="Email"
              />
              <PasswordFieldCopy
                name="password"
                key="password"
                label="Password"
                subLabel="8+ characters, at least one upper case, lower case, number, and special character"
                placeholder="Password"
                additionalFields={props}
              />
              <div className="mt-4">
                <Button
                  format={{
                    type: "submit",
                    id: "signIn",
                    isFilled: true,
                    content: `${content}`,
                    isLoading: props.isSubmitting,
                    width: "w-full",
                  }}
                />
              </div>
            </Form>
          </React.Fragment>
        );
      }}
    </Formik>
  );
}

export default SignUpForm;
