import React from "react";

interface AttentionProps {
  color?: string | undefined;
}

const Attention: React.FC<AttentionProps> = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="#731414"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00004 13.6673C3.31814 13.6673 0.333374 10.6825 0.333374 7.00065C0.333374 3.31875 3.31814 0.333984 7.00004 0.333984C8.76815 0.333984 10.4638 1.03636 11.7141 2.28661C12.9643 3.53685 13.6667 5.23254 13.6667 7.00065C13.6667 10.6825 10.6819 13.6673 7.00004 13.6673ZM7.66671 7.66732V3.66732C7.66671 3.29913 7.36823 3.00065 7.00004 3.00065C6.63185 3.00065 6.33337 3.29913 6.33337 3.66732V7.66732C6.33337 8.03551 6.63185 8.33398 7.00004 8.33398C7.36823 8.33398 7.66671 8.03551 7.66671 7.66732ZM7.25337 9.72065C7.29586 9.73524 7.33621 9.75542 7.37337 9.78065C7.4083 9.80526 7.44169 9.83198 7.47337 9.86065C7.5952 9.98841 7.66425 10.1575 7.66671 10.334C7.66773 10.5112 7.59817 10.6815 7.47337 10.8073C7.40857 10.8662 7.33416 10.9136 7.25337 10.9473C7.09201 11.0186 6.90807 11.0186 6.74671 10.9473C6.66592 10.9136 6.59151 10.8662 6.52671 10.8073C6.40192 10.6815 6.33235 10.5112 6.33337 10.334C6.33235 10.1568 6.40192 9.98646 6.52671 9.86065C6.68431 9.70457 6.90922 9.6371 7.12671 9.68065C7.17086 9.68683 7.21368 9.70035 7.25337 9.72065ZM7.00004 1.66732C9.94556 1.66732 12.3334 4.05513 12.3334 7.00065C12.3334 8.41514 11.7715 9.77169 10.7713 10.7719C9.77108 11.7721 8.41453 12.334 7.00004 12.334C4.05452 12.334 1.66671 9.94617 1.66671 7.00065C1.66671 4.05513 4.05452 1.66732 7.00004 1.66732Z"
        fill="#731414"
      />
    </svg>
  );
};
export default Attention;
