import * as Yup from "yup";

export const addDomainValidation = Yup.object().shape({
    value: Yup.string()
    // .matches('^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$', 'Invalid domain format')
    .matches(new RegExp('^(((?!\-))(xn\-\-)?[a-z0-9\-_@]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-@]{1,61}|[a-z0-9\-@]{1,30})\.[a-z]{2,}$'), 'Invalid domain format')
    .required("Required"),
});

export const initialAddDomain = {
    value: "",
};
