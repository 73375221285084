import React from "react";
import { AnimatePresence } from "framer-motion";
import SearchIcon from "../../assets/icons/search";
import CrossCircleIcon from "../../assets/icons/cross-circle";

interface SearchFieldProps {
  id?: string;
  placeholder?: string;
  label?: string;
  subLabel?: string;
  name: string;
  state: Record<string, string>;
  setState: React.Dispatch<React.SetStateAction<any>>;
}

const SearchField: React.FC<SearchFieldProps> = ({
  label,
  subLabel,
  name,
  state,
  setState,
  ...props
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setState({ ...state, [e.target.name]: e.target.value });
  return (
    <div className="flex w-full flex-col items-start">
      <label className={`w-full transition-all duration-300`}> {label} </label>
      <div className="flex flex-row w-full items-center justify-end">
        <input
          className={`field w-125 xs:w-full transition-all duration-300 bg-white px-4 py-2 text-black_primary border font-custom border-solid`}
          type="text"
          value={state ? state[name] : ""}
          onChange={handleChange}
          name={name}
          {...props}
        />
        {state[name] ? (
          <div
            className="cursor-pointer absolute mr-2"
            onClick={() => {
              setState({ ...state, [name]: "" });
            }}
          >
            <CrossCircleIcon color="#231F20" />
          </div>
        ) : (
          <div className="cursor-pointer absolute mr-2">
            <SearchIcon color="#231F20" />
          </div>
        )}
      </div>
      <AnimatePresence initial={false}>
        {subLabel && (
          <label className="text-gray1 mb-3 flex flex-wrap">{subLabel}</label>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SearchField;
