import React from "react";
import "./fallingDots.css";

const FallingDots = () => {
  return (
    <div className="container">
      <div className="grid-cols-3">
        <div className="dot-falling"></div>
      </div>
    </div>
  );
};

export default FallingDots;
