import React from "react";

const EyeStrike = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5867 5.67247C12.9727 4.5514 12.1827 3.53624 11.2467 2.66581L12.12 1.85247C12.3135 1.69558 12.4034 1.4439 12.353 1.19993C12.3027 0.955968 12.1205 0.760419 11.8807 0.692925C11.641 0.625431 11.3835 0.697253 11.2133 0.879141L10.18 1.80581C9.23641 1.18073 8.13182 0.842637 7 0.832474C4.48667 0.832474 2.15334 2.55247 0.420005 5.67247C0.300916 5.87874 0.300916 6.13287 0.420005 6.33914C0.959477 7.33279 1.63239 8.24795 2.42 9.05914L1.21334 10.1925C0.954856 10.4525 0.954856 10.8724 1.21334 11.1325C1.46409 11.4004 1.88423 11.4153 2.15334 11.1658L3.48667 9.93914C4.49818 10.71 5.72849 11.1396 7 11.1658C9.51334 11.1658 11.8533 9.44581 13.5867 6.32581C13.7007 6.12295 13.7007 5.87533 13.5867 5.67247ZM9.70667 4.07247C10.1136 4.63247 10.333 5.30688 10.3333 5.99914C10.327 7.28315 9.58366 8.44935 8.42239 8.99725C7.26113 9.54515 5.88841 9.37733 4.89334 8.56581L9.70667 4.07247ZM7 2.66581C5.15906 2.66581 3.66667 4.15819 3.66667 5.99914C3.67172 6.53244 3.80673 7.05646 4.06 7.52581L8.72667 3.16581C8.2092 2.84094 7.61099 2.66772 7 2.66581ZM2.45334 7.02581C2.21373 6.6952 1.99121 6.35252 1.78667 5.99914C1.99121 5.64576 2.21373 5.30309 2.45334 4.97247C2.29339 5.64757 2.29339 6.35071 2.45334 7.02581ZM12.2133 5.99914C12.0103 5.64269 11.7877 5.29772 11.5467 4.96581C11.7068 5.6454 11.7068 6.35289 11.5467 7.03247C11.7877 6.70056 12.0103 6.35559 12.2133 5.99914Z"
        fill="#231F20"
      />
    </svg>
  );
};
export default EyeStrike;
