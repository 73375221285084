import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { fetcher } from "../../../../logic/util/hooks";
import Button from "../../../primitives/Button";

interface PlatformData {
  [key: string]: any;
}

interface ReviewDomainsBannerProps {
  inboxFetchCompleted: boolean;
}

const ReviewDomainsBanner: React.FC<ReviewDomainsBannerProps> = ({
  inboxFetchCompleted,
}) => {
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.auth);
  const [data, setData] = useState<PlatformData | null>(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    fetcher(`/resources/platforms/${user?.id}`)
      .then((res) => {
        if (res) setData(res);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }, []);

  const handleReviewDomains = () => {
    if (error) {
      alert(
        "We were unable to fetch your email accounts, Please try again later",
      );
    } else if (data) {
      if (Array.isArray(data) && data?.length > 1) {
        navigate("/app/reReviewDomains");
      } else {
        localStorage.setItem("domainRereview", "true");
        console.log(data);
        if (Array.isArray(data) && data?.length == 1) {
          navigate(`/app/domains/${data[0].id}`, {
            state: { domain: { ...data[0] }, prevPage: "Approved Sender List" },
          });
        }
      }
    }
  };
  return (
    <div className="flex flex-row xs:flex-col justify-between my-4 bg-orange_bg w-full h-fit px-4 py-3 rounded-lg">
      <div className="flex flex-col">
        <p className="text-orange_dark font-custom text-xl font-bold">
          Review new domains
        </p>
        <p className="text-orange_dark font-custom text-sm font-normal">
          You have some domains that you haven’t made a decision to either
          approve or block.
        </p>
      </div>
      {inboxFetchCompleted ? (
        <Button
          format={{
            content: "Review now",
            type: "primary",
            isFilled: true,
            onclick: handleReviewDomains,
          }}
        />
      ) : (
        <button
          className="relative bg-gray_light rounded-full cursor-not-allowed font-bold w-full sm:w-60 py-4 flex justify-center"
          disabled={!inboxFetchCompleted}
        >
          <div className="dot-falling"></div>
        </button>
      )}
    </div>
  );
};

export const MemoizedReviewDomainsBanner = React.memo(ReviewDomainsBanner);
