import React from "react";

interface Props { color?: string; }

const ProfileIconXXL: React.FC<Props> = ({ color = '#181920' }) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M45.3335 18.6663C45.3335 11.3025 39.364 5.33301 32.0002 5.33301C24.6364 5.33301 18.6668 11.3025 18.6668 18.6663C18.6668 26.0301 24.6364 31.9997 32.0002 31.9997H29.3335C16.0787 31.9997 5.3335 42.7448 5.3335 55.9997C5.3335 57.4724 6.5274 58.6663 8.00016 58.6663H56.0002C57.4729 58.6663 58.6668 57.4724 58.6668 55.9997C58.6668 42.7448 47.9217 31.9997 34.6668 31.9997H32.0002C35.5364 31.9997 38.9278 30.5949 41.4283 28.0944C43.9287 25.5939 45.3335 22.2026 45.3335 18.6663ZM29.3335 37.333C20.0525 37.3306 12.1794 44.1472 10.8535 53.333H53.1468C51.821 44.1472 43.9478 37.3306 34.6668 37.333H29.3335ZM24.0002 18.6663C24.0002 23.0846 27.5819 26.6663 32.0002 26.6663C34.1219 26.6663 36.1567 25.8235 37.657 24.3232C39.1573 22.8229 40.0002 20.7881 40.0002 18.6663C40.0002 14.2481 36.4184 10.6663 32.0002 10.6663C27.5819 10.6663 24.0002 14.2481 24.0002 18.6663Z" fill={color}/>
    </svg>
  );
};

export default ProfileIconXXL;
