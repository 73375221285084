import * as Yup from "yup";

export const initialChangePassword = {
  currentPassword: "",
  password: "",
  confirm: "",
};
export const changePasswordValidation = Yup.object().shape({
  currentPassword: Yup.string().required("Required"),
  password: Yup.string()
    .required("Required")
    .notOneOf(
      [Yup.ref("currentPassword")],
      "New password cannot be the same as current password",
    ),
  confirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Error: Passwords don’t match")
    .required("Required"),
});
export const changePasswordValidationGoogle = Yup.object().shape({
  password: Yup.string()
    .required("Required")
    .notOneOf(
      [Yup.ref("currentPassword")],
      "New password cannot be the same as current password",
    ),
  confirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Error: Passwords don’t match")
    .required("Required"),
});
