import * as Yup from "yup";

export const initialForgetPasswordByLink = {
    password:"",
    confirm:"",
};
export const forgetPasswordByLinkValidation = Yup.object().shape({
    password: Yup.string().required("Required"),
    confirm: Yup.string().oneOf([Yup.ref('password'),null],"Error: Passwords don’t match")
      .required("Required"),
});