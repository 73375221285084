import React, { ChangeEvent, useState } from "react";
import { useField } from "formik";
import { AnimatePresence } from "framer-motion";
import ErrorMessage from "../ErrorMessage";
import Eye from "../../assets/icons/eye";
import EyeStrike from "../../assets/icons/eye-strike";

interface FieldProps {
  label?: string;
  subLabel?: string;
  name: string;
  type?: string;
  placeholder?: string;
  maxLength?: number;
}

const Field: React.FC<FieldProps> = ({ label, subLabel, ...props }) => {
  const [field, meta, width] = useField(props);
  // Helpers => {setValue: ƒ, setTouched: ƒ, setError: ƒ}
  return (
    <div className="flex w-full flex-col">
      <label
        className={`w-full transition-all duration-300 font-normal text-sm ${
          meta.touched && meta.error ? "text-red_error" : "text-gray1"
        }`}
      >
        {" "}
        {label}{" "}
      </label>
      <div className="flex flex-row w-full items-center justify-end">
        <input
          className={`field w-125 xs:w-full transition-all duration-300 ${
            meta.touched && meta.error ? "border-red_error" : "border-gray2"
          } bg-white ${width ? width : "w-full"} 
           px-4 py-2 text-black_primary border font-custom border-solid`}
          {...field}
          {...props}
        />
      </div>
      <AnimatePresence initial={false}>
        {meta.touched && meta.error ? (
          <ErrorMessage errors={meta.error} />
        ) : subLabel !== "" ? (
          <label className="text-gray1 mb-3 flex flex-wrap">{subLabel}</label>
        ) : null}
      </AnimatePresence>
    </div>
  );
};

export default Field;
