import { Form, Formik, Field as FormikField } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { login } from "../../../../features/auth/authSlice";
import useError from "../../../../hooks/useError";
import { post } from "../../../../logic/util/hooks";
import Button from "../../Button";
import StaticAlert from "../../atoms/StaticAlert";
import Field from "../Field";
import PasswordField from "../PasswordField";
import mixpanel from "mixpanel-browser";

interface SignInFormProps {
  content: string;
}

const initialSignIn = {
  email: "",
  password: "",
  // staySignIn: true
};
const signInValidation = Yup.object().shape({
  email: Yup.string()
    .email("Error: Enter a valid email address, for example mike@gmail.com")
    .required("Required"),
  password: Yup.string().required("Required"),
  // staySignIn: Yup.boolean().required()
});

const SignInForm: React.FC<SignInFormProps> = () => {
  const dispatch = useDispatch();
  const { error, mountError } = useError();

  let [searchParams] = useSearchParams();
  return (
    <Formik
      initialValues={initialSignIn}
      validationSchema={signInValidation}
      onSubmit={async (values) => {
        try {
          const searchParamName = searchParams.get("redirect");
          const { data } = await post("auth", "bpi/signin", values);
          sessionStorage.setItem("showInfoDialogue", JSON.stringify(null));
          mixpanel.track("Clicked: Sign-In with your email address 'Continue' button", {
            user_Id: data?.id,
            user_Email: data?.email,
            user_FirstName: data?.first,
            user_LastName: data?.last,
            user_Phone: data?.phone,
            user_Tier: data?.tier,
          });
          mixpanel.identify(data.email);
          mixpanel.people.set({
            $email: data?.email,
            $first_name: data?.firstName,
            $last_name: data?.lastName,
            $user_id: data.id,
            // Add more properties as needed
          });
          dispatch(login({ userData: data, token: data.token }));
          if (searchParamName !== null) {
            window.location.assign(`/app/${searchParamName}`);
          } else {
            window.location.assign("/app/email-accounts");
          }
        } catch (error) {
          console.log("error", error);

          mountError({
            response: {
              data: {
                errorMessage:
                  "Email or password doesn’t match our records. Please try again or reset your password below",
              },
            },
          });
        }
      }}
    >
      {(props) => {
        return (
          <React.Fragment>
            <Form className="flex flex-col gap-3.5 w-full">
              {error && <StaticAlert type="error" message={error} />}
              <Field
                name="email"
                key="email"
                label="Email"
                type="email"
                placeholder="Email"
              />
              <PasswordField
                name="password"
                key="password"
                label="Password"
                placeholder="Password"
              />
              <div>
                <Button
                  format={{
                    type: "submit",
                    id: "signIn",
                    isFilled: true,
                    content: `Continue`,
                    isLoading: props.isSubmitting,
                    width: "w-full",
                  }}
                />
              </div>
            </Form>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};

export default SignInForm;
