import React, { useEffect, useLayoutEffect, useState } from "react";
import gmailIcon from "../../assets/graphics/gmailIcon.svg";
import outlookIcon from "../../assets/graphics/outlookIcon.svg";
import Button from "../../primitives/Button";
import WhyNeedSignIn from "../../primitives/dialogContent/WhyNeedSignIn";
import PublicNav from "../landingPage/components/publicNav";
import { useDispatch } from "react-redux";
import { mountDialog } from "../../../features/util/utilSlice";
import { useLocation, useNavigate } from "react-router";
import jwtDecode from "jwt-decode";
import { fetcher } from "../../../logic/util/hooks";
import mixpanel from "mixpanel-browser";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface ConnectAccountCardProps {
  platformTitle?: "Gmail" | "Outlook";
}

function ConnectAccountCard({ platformTitle }: ConnectAccountCardProps) {
  const dispatch = useDispatch();
  // const token: string = localStorage.getItem("jwtToken") || "";
  // const [googleLink, setGoogleLink] = useState<string | null>(null);

  // useEffect(() => {
  // const { sub }: { sub: string } = jwtDecode(token);
  // const isAuthO = sub.startsWith("auth0");
  // if (isAuthO) {
  //   setGoogleLink(
  //     `${process.env.REACT_APP_API}/auth/google/signin?state=googleConnect`,
  //   );
  // } else {
  //   const { email }: { email: string } = JSON.parse(
  //     localStorage.getItem("userData") || "",
  //   );
  //   fetcher(`/auth/google/platform/${email}/scopes`).then((response) => {
  //     if(response?.scope?.includes('https://www.googleapis.com/auth/gmail.modify')) {
  //       setGoogleLink(response?.onboardingUrl);
  //     } else {
  //       setGoogleLink(
  //         `${process.env.REACT_APP_API}/auth/google/signin?state=googleConnect`,
  //       );
  //     }
  //   });
  // }
  // }, []);

  const dialog = () =>
    dispatch(
      mountDialog({
        component: <WhyNeedSignIn platformTitle={platformTitle} />,
        headline: `Why do I need to sign in?`,
      }),
    );
  const link = {
    outlook: process.env.REACT_APP_API + "/auth/microsoft/signin",
    google:
      process.env.REACT_APP_API + "/auth/google/signin?state=googleConnect",
  };
  const config = {
    icon: platformTitle === "Gmail" ? gmailIcon : outlookIcon,
    link: platformTitle === "Gmail" ? link.google : link.outlook,
    description:
      platformTitle === "Gmail"
        ? "If your email address is provided by Google"
        : "If your email service is provided by Microsoft (this includes Hotmail)",
  };
  return (
    <div
      className={`flex flex-col w-64 xs:w-full shadow-nav items-center px-6 py-6 gap-4 rounded-md bg-white`}
    >
      <div className="flex w-full flex-col text-center justify-center">
        <div className="w-full flex justify-center mb-4 h-12 items-center">
          <LazyLoadImage
            src={config.icon}
            width="42px"
            height="42px"
            alt={`${platformTitle}-icon`}
          />
        </div>
        <p className="text-xl font-bold text-black_primary">{platformTitle}</p>
        {/* <p className="text-base font-normal text-gray1">{config.description}</p> */}
        <p
          className={`text-base font-normal text-gray1 ${
            platformTitle === "Gmail" ? "" : "hidden"
          }`}
        >
          If your email address <br /> is provided by Google
        </p>
        <p
          className={`text-base font-normal text-gray1 ${
            platformTitle !== "Gmail" ? "" : "hidden"
          }`}
        >
          If your email service is <br /> provided by Microsoft <br /> (this
          includes Hotmail)
        </p>
      </div>
      <div
        className={`w-full sm:flex justify-center ${
          platformTitle === "Gmail" ? "mt-6" : ""
        }`}
      >
        <Button
          format={{
            id: `${platformTitle}btn`,
            content:
              platformTitle === "Gmail"
                ? "Sign in to Google"
                : "Sign in to Microsoft",
            link: config.link,
            width: "w-full",
            onclick: () => {
              const buttonText =
                platformTitle === "Gmail"
                  ? "Sign in to Googlet"
                  : "Sign in to Microsoft";
              mixpanel.track(`Clicked: "${buttonText}" (connect account page)`);
            },
            isFilled: false,
          }}
        />
      </div>
      <div className="w-full sm:flex justify-center text-base text-green_primary underline cursor-pointer xs:text-center">
        <a onClick={dialog}>Why do I need to sign in?</a>
      </div>
    </div>
  );
}

export default function LinkAccount() {
  const location = useLocation();
  const { isWelcomeBannerAuth0 } = location.state ?? false;
  const navigate = useNavigate();
  return (
    <div className="flex flex-col sm:pb-20">
      {isWelcomeBannerAuth0 ? (
        <div className="z-10 bg-green_bg text-green_primary_text flex flex-col gap-4 p-3">
          <div className="flex flex-col gap-1 sm:items-center sm:justify-center">
            <p className="text-2xl font-bold w-full text-center">
              Welcome to Bulletproof!
            </p>
            <p className="text-base font-normal w-full text-center">
              You've successfully created your account.
            </p>
          </div>
        </div>
      ) : (
        <PublicNav title="Get started" back={{ text: `` }} />
      )}
      {/* Adding div to avoid getting under the header */}
      <div
        className={`${
          isWelcomeBannerAuth0 ? "pt-8" : "pt-24"
        } flex xs:mx-6 xs:justify-center xs:text-center`}
      />
      <div className="flex justify-center xs:mx-6 xs:flex-col">
        <div className="flex flex-col align-middle">
          <div>
            <div className="flex justify-center">
              <p className="text-black_primary text-2xl font-bold">
                To get started, sign in to your email
              </p>
            </div>
            <div className="mt-1 flex justify-center">
              {/* <p className="text-gray1 text-base font-normal">Select the provider of the email address you’d like to connect. <a className="sm:hidden text-base"><a className="text-base underline" onClick={() => dialog()}>Why </a>do I need to sign in?</a></p> */}
              <p className="text-gray1 text-base font-normal">
                Select your email provider below.
              </p>
            </div>
          </div>
          <div className="flex flex-col xs:w-full mt-5">
            <div className="flex flex-row xs:flex-col justify-between xs:gap-3">
              <ConnectAccountCard platformTitle="Gmail" />
              <ConnectAccountCard platformTitle="Outlook" />
            </div>
            {/* <div className="xs:hidden mt-8 flex flex-col text-center">
                            <p>
                                Not using one of these - we’re working to add other providers.
                            </p>
                            <a className="underline text-green_primary mt-2 text-base" href="https://o44jesxx7d6.typeform.com/to/mHcWbdaL">
                                Join our waitlist
                            </a>
                        </div> */}
          </div>
          <div className="w-full flex flex-row mt-8 rounded-lg border-2 border-subdomain_grey xs:mb-28">
            <div className="flex flex-col p-5">
              <p className="text-black_primary text-base font-bold">
                Using another email provider?
              </p>
              <p className="text-gray1 text-base font-normal mt-2">
                Bulletproof currently works with Gmail and Outlook.
              </p>
            </div>
            {/* <div className="mt-3"> */}
            {/* </div> */}
            <div className="flex justify-center p-5">
              <a
                onClick={() => {
                  mixpanel.track(
                    `Clicked: 'Join our waitlist (Link account page)'`,
                  );
                }}
                className="underline text-green_primary mt-2 text-base"
                href="https://o44jesxx7d6.typeform.com/to/mHcWbdaL"
              >
                Join our waitlist
              </a>
            </div>
          </div>
        </div>
        <div>
          {/* <div className="bg-green_bg w-full p-4 rounded-lg mt-3 xs:hidden">
                    <div>
                        <p className="text-tag_green font-bold text-xl">
                            Why do I need to sign in?
                        </p>
                        <p className="text-green_primary">
                            Bulletproof needs access to your email account to run the app. Once you sign in, we will move your unwanted emails away from your inbox into the designated folders. This will reduce the clutter and help you find the messages you care about.
                        </p>
                    </div>
                    <div>
                        <p className="text-tag_green font-bold text-base mt-6">
                            We will never:
                        </p>
                        <p className="text-green_primary">
                            1. Read your email messages.
                        </p>
                        <p className="text-green_primary">
                            2. Delete emails, we only move them to folders based on your preferences.
                        </p>
                        <p className="text-green_primary">
                            3. Share any personal information; you control your inbox, we simply direct emails within your email account.
                        </p>
                    </div>
                    <div>
                        <p className="text-tag_green font-bold text-base mt-6">
                            You’re in control
                        </p>
                        <p className="text-green_primary">
                            You can disconnect from Bulletproof at any time with no impact to your email. If you <br /> would like to add Bulletproof protection to your email, you may sign in and begin the <br /> process.
                        </p>
                    </div>
                    <div className="mt-5">
                        <p className="text-green_primary">
                            To learn more, read our <a className="underline cursor-pointer" href="https://www.bulletproofinbox.com/frequently-asked-questions" target="_blank">FAQs</a>
                        </p>
                    </div>
                </div> */}
        </div>
      </div>
      <div className="fixed bottom-0 w-full items-center justify-center flex flex-row bg-white shadow overflow-hidden border-t border-gray_Xlight px-6 py-6">
        <button
          className="text-base text-green_primary underline cursor-pointer text-center"
          onClick={() => {
            navigate(`/app/email-accounts`);
            mixpanel.track(`Clicked: 'Skip for now'`);
          }}
        >
          Skip for now
        </button>
      </div>
    </div>
  );
}
