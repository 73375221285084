import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { post } from "../../../../logic/util/hooks";
import PublicNav from "../../landingPage/components/publicNav";
import CheckoutForm from "./atoms/CheckoutForm";

interface PaymentMethodProps {
  priceId?: string;
}

const PaymentMethod: React.FC<PaymentMethodProps> = () => {
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const { user } = useSelector((state: any) => state.auth);
  const { state } = useLocation();

  useEffect(() => {
    const fetchStripe = async () => {
      const stripeInstance = await loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
      );
      setStripePromise(stripeInstance);
    };
    fetchStripe();
  }, []);

  // Original Call to BULLETPROOF BACKEND

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await post("stripe", `paymentIntent/${user?.id}`);
        if (response?.data) {
          const { clientSecret } = response?.data;
          setClientSecret(clientSecret);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="flex flex-col">
      <PublicNav
        title="Setup subscription"
        back={{ text: "Back to Profile", to: "/app/profile" }}
      />
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm priceId={state?.priceId} />
        </Elements>
      )}
    </div>
  );
};
export default PaymentMethod;
