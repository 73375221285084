import React from 'react';
interface Props { color: string; }

const BellIcon: React.FC<Props> = ({color="#181920"}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M20.94 17H20V10C20 5.58172 16.4183 2 12 2C7.58171 2 3.99999 5.58172 3.99999 10V17H3.10999C2.5577 17 2.10999 17.4477 2.10999 18C2.10999 18.5523 2.5577 19 3.10999 19H8.99999C8.99999 20.6569 10.3431 22 12 22C13.6568 22 15 20.6569 15 19H20.94C21.4923 19 21.94 18.5523 21.94 18C21.94 17.4477 21.4923 17 20.94 17ZM12 20C11.4477 20 11 19.5523 11 19H13C13 19.5523 12.5523 20 12 20ZM5.99999 17H14H18V10C18 6.68629 15.3137 4 12 4C8.68628 4 5.99999 6.68629 5.99999 10V17Z" fill={color}/>
</svg>

    )
  }
export default BellIcon;