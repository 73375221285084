import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import { useDispatch } from "react-redux";
import { unMountDialog } from "../../../features/util/utilSlice";

interface SignInTitleProps {
  platformTitle?: "Gmail" | "Outlook";
}

function WhyNeedSignIn({ platformTitle }: SignInTitleProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="rounded-lg">
      <div className="mb-6">
        {/* <p className=" font-bold text-xl">
                    Why do I need to sign in?
                </p> */}
        <p className="">
          Bulletproof runs on top of your existing email account. We move
          messages into folders based on your preferences. To do that we need
          permission to access your{" "}
          email account.
        </p>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-1 sm:gap-2">
          <p className="font-bold">Bulletproof Inbox is:</p>
          <ul className="list-disc pl-5">
            <li>GDPR compliant</li>
            <li>Approved by Google, Microsoft, and other independent third-party security audits</li>
          </ul>
        </div>
        <div className="flex flex-col gap-1 sm:gap-2">
          <p className="font-bold">Bulletproof Inbox does not:</p>
          <ul className="list-disc pl-5">
            <li>Delete emails</li>
            <li>Use or message your contacts</li>
            <li>Share any personal information</li>
          </ul>
        </div>
        <div className="flex flex-col gap-1 sm:gap-2">
          <p className="font-bold">You're in control:</p>
          <p>You can completely disconnect your email account from Bulletproof anytime.</p>
        </div>
      </div>
      <div className="">
        <Button
          format={{
            id: "disconnectButton",
            type: "primary",
            isFilled: true,
            content: "Got it",
            width: "w-full",
            isLoading,
            onclick: () => {
              navigate("/app/connect-account");
              dispatch(unMountDialog());
            },
          }}
        />
      </div>
      {/* <div>
                <p >
                    To learn more, <a className="underline cursor-pointer text-base" href="https://www.bulletproofinbox.com/frequently-asked-questions" target="_blank">read our FAQs</a>
                </p>
            </div> */}
    </div>
  );
}

export default WhyNeedSignIn;
