import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import GooglePermissionImage from "../../assets/graphics/google_permissions.png";
import Button from "../../primitives/Button";
import DataPolicy from "../../primitives/dialogContent/DataPolicy";
import { useDispatch } from "react-redux";
import { mountDialog } from "../../../features/util/utilSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ConnectionProblem = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dialog = () =>
    dispatch(
      mountDialog({ component: <DataPolicy />, headline: "Data policy" }),
    );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col">
      {/* <PublicNav title="Connect email account" /> */}
      <div className="flex flex-col items-center h-fit xs:px-6 justify-center pt-24 bg-white">
        <p className="text-black_primary text-2xl font-bold">
          Connection problem
        </p>
        <div className="text-center wrap sm:w-5/12">
          <p className="text-black_primary text-base font-normal">
            We couldn’t connect to your Google account because all the needed
            permissions were not granted. Please try again and check all 3 of
            the permission settings.
          </p>
        </div>
        <div className="py-6">
          <LazyLoadImage
            src={GooglePermissionImage}
            alt="permissions"
            width="281px"
            height="159px"
          />
        </div>
        <div className="flex flex-col gap-6">
          <div className="w-full sm:flex justify-center">
            <Button
              format={{
                id: `go-back-btn`,
                content: "Go Back",
                isFilled: true,
                onclick: () => navigate("/app/connect-account"),
              }}
            />
          </div>
          <div className="flex flex-row text-center xs:flex-col gap-2">
            <p className="text-black_primary text-base font-normal">
              Why do we need access to this information?
            </p>
            <p
              className="text-green_primary text-base font-normal underline decoration-current cursor-pointer"
              onClick={() => dialog()}
            >
              Learn about our data policy
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectionProblem;
