import React from "react";

type TextAlign =
  | "start"
  | "end"
  | "left"
  | "right"
  | "center"
  | "justify"
  | "match-parent";

interface LabelFormat {
  id?: string;
  type?: "primary" | "secondary" | "nonUnderlined" | string;
  color?: string;
  backgroundColor?: string;
  fontFamily?: string;
  fontSize?: string;
  text?: string;
  lineHeight?: string;
  fontWeight?: string;
  fontStyle?: string;
  flex?: string;
  noWrap?: boolean;
  margintop?: string;
  marginBottom?: string;
  link?: string | null;
  textAlign?: TextAlign;
  margin?: string;
}

interface LabelProps {
  format: LabelFormat;
  onClick?: React.MouseEventHandler<HTMLLabelElement>;
  isClickable?: boolean;
}

function Label({
  format = {
    id: "empty_label",
    type: "primary",
    color: "text-black_primary",
    backgroundColor: "bg-inherit",
    fontFamily: "font-custom",
    fontSize: "text-sm",
    text: "label",
    lineHeight: "leading-6",
    fontWeight: "font-normal",
    fontStyle: "not-italic",
    flex: "flex",
    noWrap: false,
    margintop: "",
    marginBottom: "",
    link: null,
    textAlign: "left",
    margin: "",
  },
  onClick,
  isClickable,
}: LabelProps): JSX.Element | null {
  let {
    id,
    type = "primary",
    color,
    text,
    backgroundColor,
    fontFamily,
    fontWeight,
    fontStyle,
    noWrap,
    flex,
    fontSize,
    lineHeight,
    margintop,
    marginBottom,
    link = null,
    textAlign,
    margin,
  } = format;

  if (type === "primary") {
    return (
      <div
        style={{ margin: margin ? margin : "10px 5px", textAlign: textAlign }}
      >
        {isClickable ? (
          <label
            id={`${id}_label`}
            onClick={onClick}
            className={`${backgroundColor} ${color} font-custom ${fontSize} ${fontStyle} ${fontWeight} ${lineHeight} ${flex} underline decoration-current cursor-pointer items-center`}
          >
            {text}
          </label>
        ) : (
          <label
            id={`${id}_label`}
            className={`${backgroundColor} ${color} font-custom ${fontSize} ${fontStyle} ${fontWeight} ${lineHeight} ${flex} items-center`}
          >
            {text}
          </label>
        )}
      </div>
    );
  }
  if (type === "secondary") {
    return (
      <div>
        {isClickable ? (
          <label
            id={`${id}_label`}
            onClick={onClick}
            className={`${backgroundColor} ${color} font-custom ${fontSize} ${fontStyle} ${fontWeight} ${lineHeight}  ${flex} underline decoration-current cursor-pointer items-center`}
          >
            {text}
          </label>
        ) : (
          <div className={`${margintop} ${marginBottom}`}>
            <label
              id={`${id}_label`}
              className={`${backgroundColor} ${color} font-custom ${fontSize} ${fontStyle} ${fontWeight} ${lineHeight} ${flex} items-center`}
            >
              {text}
            </label>
          </div>
        )}
      </div>
    );
  }
  if (type === "nonUnderlined") {
    return (
      <div>
        {isClickable ? (
          <label
            id={`${id}_label`}
            onClick={onClick}
            className={`${backgroundColor} ${color} font-custom ${fontSize} ${fontStyle} ${fontWeight} ${lineHeight}  ${flex} decoration-current cursor-pointer items-center`}
          >
            {text}
          </label>
        ) : (
          <div className={`${margintop} ${marginBottom}`}>
            <label
              id={`${id}_label`}
              className={`${backgroundColor} ${color} font-custom ${fontSize} ${fontStyle} ${fontWeight} ${lineHeight} ${flex} items-center`}
            >
              {text}
            </label>
          </div>
        )}
      </div>
    );
  }
  return null;
}

export default Label;
