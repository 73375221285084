import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { setLoading, unsetLoading } from "../../../features/loader/loaderSlice";
import { del, fetcher, post } from "../../../logic/util/hooks";
import emailIcon from "../../assets/icons/email_xxl.svg";
import ExpiryMessageCardExpired from "../../primitives/ExpiryMessageCardExpired";
import ExpiryMessageTrialExpired from "../../primitives/ExpiryMessageTrialExpired";
import Footer from "../../primitives/Footer";
import { updateUserData } from "../../../features/auth/authSlice";
import { mountDialog, refetchBannerOn } from "../../../features/util/utilSlice";
import Label from "../../primitives/Label";
import ReconnectEmailAccount from "../../primitives/ReconnectEmailAccount";
import FreeTrialEndedModel from "../../primitives/dialogContent/freeTrialEnded";
import NoEmailAccounts from "../landingPage/components/NoEmailAccounts";
import ConnectPlatformCard from "./connectPlatformCard";
import PlatformCard from "./platformCard";
import mixpanel from "mixpanel-browser";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface PlatformData {
  ACCOUNT_REF: string;
  id: string;
  eval_paused: boolean;
  has_send_survey_enabled: boolean;
  is_domain_review_complete: boolean;
  is_folder_settings_set: boolean;
  is_request_form_settings_set: boolean;
  is_video_step_complete: boolean;
  email: string;
  platformID: string;
  platform: string;
  status: string;
  connected_since: string;
  add_to_contacts: boolean;
}

interface ReducerStateType {
  showTrialExpired: boolean;
  showCardExpired: boolean;
  showCardAboutToExpire: boolean;
  showReconnectText: boolean;
}

interface Domain {
  ACCOUNT_REF: string;
  PARENT_REF: string | null;
  id: string;
  status: "approved" | "blocked";
  value: string;
}

interface DomainType {
  key: string;
  name?: string;
  email: string;
  phone: string;
  reason: string;
  relationship: string;
  contactID: string;
  platform_ref: string;
  option?: any;
  status?: string;
  domain?: string;
  parentKey?: string;
  count?: number;
  subject: string;
  subDomains?: any;
  platformID?: string;
  parentDomain?: string;
}

interface DataType {
  status?: string | null;
  list: DomainType[];
  total?: number;
}

let token = localStorage.getItem("jwtToken");

function SettingsView() {
  const [showActionRequired, setShowActionRequired] = useState<boolean>(false);
  const [isTurningOn, setIsTurningOn] = useState<boolean>(false);
  const [maxConnectedPlatforms, setmaxConnectedPlatforms] = useState<
    number | undefined
  >(undefined);
  const [connectedPlatforms, setConnectedPlatforms] = useState<
    number | undefined
  >(undefined);
  const [userPlatforms, setUserPlatforms] = useState<PlatformData[]>([]);
  const [data, setData] = useState<PlatformData[] | null>(null);
  const [fetchComplete, setFetchComplete] = useState<boolean>(false);
  const [isBannerHidden, setIsBannerHidden] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const { user } = useSelector((state: any) => state.auth);
  const [paymentStatusObject, reducerState] = useOutletContext<any>();
  const {
    showTrialExpired,
    showCardExpired,
    showCardAboutToExpire,
    showReconnectText,
  } = reducerState;

  const navigate = useNavigate();

  const location = useLocation();
  const dispatch = useDispatch();

  // get state indicating whether email settings have been configured for initial platform. This is used for onboarding banner UI logic.
  const [platformData, setPlatformData] = useState<PlatformData | null>(null);
  const [setStep4Skipped, setIsStep4Skipped] = useState<boolean>(false);
  const [setStep3Skipped, setIsStep3Skipped] = useState<boolean>(false);
  const [isContactAdded, setIsContactAdded] = useState<boolean>(false);
  const isRequestFormEnabled = platformData?.has_send_survey_enabled;
  const isRequestFormSettingsSet = platformData?.is_request_form_settings_set;
  const isFolderSettingsSet = platformData?.is_folder_settings_set;
  const isDomainReviewComplete = platformData?.is_domain_review_complete;
  const isVideoStepComplete = platformData?.is_video_step_complete;

  const [isInitialPlatformOn, setIsInitialPlatformOn] = useState<
    boolean | undefined
  >(false);

  useLayoutEffect(() => {
    if (platformData) {
      const savedIsSwitchedOn = localStorage.getItem(
        `isSwitchedOn_${platformData?.id}`,
      );
      if (savedIsSwitchedOn !== null) {
        setIsInitialPlatformOn(JSON.parse(savedIsSwitchedOn));
      }
    }
  }, [platformData]);

  const [lastPlatformEmail, setLastPlatformEmail] = useState<string | null>(
    null,
  );
  const [lastPlatform, setLastPlatform] = useState<PlatformData | null>(null);
  const [lastPlatformStatus, setLastPlatformStatus] = useState<string | null>(
    null,
  );
  const isSubsequentPlatformOff = useMemo(() => {
    if (data && data.length > 1 && lastPlatformStatus === "configuration") {
      return true;
    } else return false;
  }, [data, lastPlatformStatus]);

  useLayoutEffect(() => {
    const checkConfigurationObject = data?.find(
      (item) => item.status !== "connected",
    );
    if (checkConfigurationObject) {
      const savedLastPlatform = checkConfigurationObject.email;
      setLastPlatformEmail(savedLastPlatform);
    }
  }, [data]);

  useLayoutEffect(() => {
    if (data && data.length === 1) {
      const initialPlatform = data[0];
      setPlatformData(initialPlatform);
      setIsInitialPlatformOn(initialPlatform?.status === "connected");
      localStorage.setItem(
        `isSwitchedOn_${initialPlatform?.id}`,
        JSON.stringify(initialPlatform?.status === "connected"),
      );
    } else if (data && data.length > 1) {
      const initialPlatform = data?.find(
        (platform: PlatformData) => platform.status === "connected",
      );
      setPlatformData(initialPlatform || null);
      setIsInitialPlatformOn(initialPlatform?.status === "connected");

      // set switched on platforms in local storage
      for (const platform of data.filter(
        (platform_) => platform_.status === "connected",
      )) {
        localStorage.setItem(
          `isSwitchedOn_${platform?.id}`,
          JSON.stringify(platform?.status === "connected"),
        );
      }
    }
    const lastPlatform_ = data?.find(
      (platform: PlatformData) =>
        platform.email === lastPlatformEmail && platform.status !== "connected",
    );

    if (lastPlatform_) {
      setLastPlatform(lastPlatform_);
      setLastPlatformStatus(lastPlatform_.status);
    }
  }, [user, lastPlatformEmail, data]);

  useLayoutEffect(() => {
    const savedIsStep4Skipped = localStorage.getItem(
      `isStep4Skipped_${platformData?.id}`,
    );
    if (savedIsStep4Skipped !== null) {
      setIsStep4Skipped(true);
    }
    const savedIsStep3Skipped = localStorage.getItem(
      `isStep3Skipped_${platformData?.id}`,
    );
    if (savedIsStep3Skipped !== null) {
      setIsStep3Skipped(true);
    }
    const isContactAdded = localStorage.getItem(
      `contactsAlreadyAdded_${platformData?.id}`,
    );
    const hideOnboardingBanner = platformData?.add_to_contacts;
    if (hideOnboardingBanner === true || isContactAdded !== null) {
      setIsContactAdded(true);
    }
  }, [platformData]);

  const [isOnboardBannerDismissed, setIsOnboardBannerDismissed] =
    useState<boolean>(false);
  useLayoutEffect(() => {
    const savedIsOnboardBannerDismissed = localStorage.getItem(
      "isOnboardBannerDismissed",
    );
    if (savedIsOnboardBannerDismissed !== null) {
      setIsOnboardBannerDismissed(JSON.parse(savedIsOnboardBannerDismissed));
    }
  }, []);

  // logic for calculating how many settings still haven't been configured. This is needed for the bubble counter in the onboarding banner UI.
  const numSettingsOutstanding = useMemo(() => {
    if (
      (isRequestFormEnabled && isRequestFormSettingsSet) ||
      setStep4Skipped ||
      setStep3Skipped
    ) {
      if (platformData?.platform === "google") {
        return [setStep4Skipped, setStep3Skipped].filter((item) => !item)
          .length;
      } else {
        return [setStep3Skipped].filter((item) => !item).length;
      }
    }
    if (platformData?.platform === "google") {
      return [isDomainReviewComplete, isContactAdded].filter((item) => !item)
        .length;
    } else {
      return [isDomainReviewComplete].filter((item) => !item).length;
    }
  }, [
    isContactAdded,
    isDomainReviewComplete,
    setStep4Skipped,
    setStep3Skipped,
  ]);

  useEffect(() => {
    if (
      paymentStatusObject.welcomeBack &&
      paymentStatusObject.welcomeBack === true
    ) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    try {
      const { state } = location;
      if (state?.userData) {
        dispatch(updateUserData(state?.userData));
        return;
      }
      let userData = localStorage.getItem("userData");
      if (!userData) {
        setError("User data not found in localStorage");
        return;
      }
      userData = JSON.parse(userData);
      dispatch(updateUserData(userData));
    } catch (error) {
      setError(error);
      return;
    }
  }, []);

  useEffect(() => {
    dispatch(setLoading());
    fetcher(`/resources/platforms/${user?.id}`)
      .then((res) => {
        if (res) setData(res);
        dispatch(unsetLoading());
      })
      .catch((err) => {
        console.log(err);
        setError(err);
        dispatch(unsetLoading());
      });
  }, []);
  useEffect(() => {
    window.dataLayer?.push({
      event: "pageview",
      page: {
        url: location,
        title: "Settings Page",
      },
    });
  });

  useEffect(() => {
    const shouldHideBanner = userPlatforms.some((platform) => {
      if (
        platform.platform === "microsoft" &&
        platform.status !== "disconnected" &&
        platform.is_domain_review_complete
      ) {
        return true;
      }
      if (
        platform.platform === "google" &&
        platform.status !== "disconnected" &&
        platform.is_domain_review_complete &&
        platform.add_to_contacts
      ) {
        return true;
      }
      const isStep3Skipped = localStorage.getItem(
        `isStep3Skipped_${platform.id}`,
      );
      const isStep4Skipped = localStorage.getItem(
        `isStep4Skipped_${platform.id}`,
      );
      if (platform.platform === "microsoft" && isStep3Skipped === "true") {
        return true;
      }
      if (
        platform.platform === "google" &&
        isStep3Skipped === "true" &&
        isStep4Skipped === "true"
      ) {
        return true;
      }
      return false;
    });

    setIsBannerHidden(shouldHideBanner);
  }, [userPlatforms]);

  const cardExpiryDialog = () =>
    dispatch(
      mountDialog({
        component: (
          <FreeTrialEndedModel
            paymentStatusObject={paymentStatusObject}
            reducerState={reducerState}
          />
        ),
        headline:
          showCardAboutToExpire &&
          "Your credit card on file is about to expire",
      }),
    );

  // const dialog = () =>
  //   dispatch(
  //     mountDialog({
  //       component: (
  //         <EmailAccountConnectedDialog
  //           email={localStorage.getItem("newAccountConnectedEmail")}
  //         />
  //       ),
  //       headline: "Email account connected!",
  //     }),
  //   );
  useLayoutEffect(() => {
    const fetchAccounts = async () => {
      window.scrollTo(0, 0);
      if (user?.id) {
        fetcher(`/resources/accounts/${user?.id}`)
          .then((res) => {
            setmaxConnectedPlatforms(res?.max_connected_platforms);
            setUserPlatforms(res?.platforms);
            setFetchComplete(true);
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
    };
    fetchAccounts();
  }, []);

  useLayoutEffect(() => {
    if (userPlatforms?.length > 0) {
      let filteredConnectedPlatforms = userPlatforms.filter(
        (i) => i.status === "connected",
      );
      setConnectedPlatforms(filteredConnectedPlatforms?.length);
    }
  }, [userPlatforms]);

  useEffect(() => {
    //call the popup if card is expired, about to expire or trail ended
    // if (showTrialExpired || showCardExpired || showCardAboutToExpire) {
    if (showCardAboutToExpire) {
      const infoDialogueToken = sessionStorage.getItem("showInfoDialogue");
      if (infoDialogueToken == "null") {
        cardExpiryDialog();
        sessionStorage.removeItem("showInfoDialogue");
      }
    }
  }, [showCardAboutToExpire]);

  if (localStorage.getItem("newAccountConnectedEmail")) {
    // dialog();
    localStorage.removeItem("newAccountConnectedEmail");
  }
  useEffect(() => {
    if (userPlatforms?.length > 0) {
      let filteredConnectedPlatforms = userPlatforms.filter(
        (i) => i.status === "connected",
      );
      setConnectedPlatforms(filteredConnectedPlatforms?.length);
    }
  }, [userPlatforms]);

  useEffect(() => {
    //call the popup if card is expired, about to expire or trail ended
    // if (showTrialExpired || showCardExpired || showCardAboutToExpire) {
    if (showCardAboutToExpire) {
      const infoDialogueToken = sessionStorage.getItem("showInfoDialogue");
      if (infoDialogueToken == "null") {
        cardExpiryDialog();
        sessionStorage.removeItem("showInfoDialogue");
      }
    }
  }, [showCardAboutToExpire]);

  if (localStorage.getItem("newAccountConnectedEmail")) {
    // dialog();
    localStorage.removeItem("newAccountConnectedEmail");
  }
  if (error) {
    return <p> Something went wrong </p>;
  }

  const showActionRequiredText = (flag: boolean) => {
    setShowActionRequired(flag);
  };

  const decideDesign = (platformData: PlatformData[]) => {
    if (
      maxConnectedPlatforms !== undefined &&
      connectedPlatforms !== undefined &&
      maxConnectedPlatforms > connectedPlatforms
    ) {
      const lengthCheck = platformData.length % 2 === 0;
      if (maxConnectedPlatforms > 2) {
        return lengthCheck ? "justify-end" : "justify-start";
      } else if (maxConnectedPlatforms <= 2) {
        return lengthCheck ? "justify-end hidden" : "justify-start";
      } else {
        return lengthCheck ? "justify-end" : "justify-start";
      }
    } else return "hidden";
  };

  const handleTurnOn = (platformData: PlatformData | null) => {
    setIsTurningOn(true);
    post("auth", `${platformData?.platform}/confirmConnection`, {
      platformID: platformData?.platformID,
      accountID: user?.id,
      email: platformData?.email,
    })
      .then((response) => {
        if (response.status === 200) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "email_connected",
            user_id: user.id,
          });
        }
        // console.log({ response });
        console.log("platform connected successfully!");
        window.localStorage.removeItem("sso_signup");
        setIsInitialPlatformOn(true);
        localStorage.setItem(
          `isSwitchedOn_${platformData?.id}`,
          JSON.stringify(true),
        );
        fetcher(
          `/onboarding/${platformData?.platform}/addContactsBySentMail/account/${user?.id}/platformID/${platformData?.platformID}`,
        )
          .then((response) => {
            console.log("Sent mail history added!");
            fetcher(`/resources/accounts/${user?.id}`)
              .then((response) => {
                setmaxConnectedPlatforms(response?.max_connected_platforms);
                setUserPlatforms(response?.platforms);
                setIsTurningOn(false);
                if (
                  platformData?.email === lastPlatformEmail &&
                  lastPlatformStatus === "configuration"
                ) {
                  setLastPlatformStatus("connected");
                }
                navigate("/app/email-accounts");
              })
              .catch((error) => {
                setIsTurningOn(false);
                console.log(error);
              });
          })
          .catch((error) => {
            setIsTurningOn(false);
            console.log(error);
          });
      })
      .catch((error) => {
        setIsTurningOn(false);
        console.log(error);
      });
  };

  const handleDifferentEmail = async () => {
    window.location.href = `/app/connect-account`;
  };

  return (
    <div className="h-full flex flex-col min-h-screen">
      {/* onboarding banner UI */}
      {(paymentStatusObject.trial_days > 15 ||
        paymentStatusObject.paymentMethodAttachedFreeTrial) &&
        numSettingsOutstanding > 0 &&
        data &&
        data.length > 0 &&
        !isOnboardBannerDismissed &&
        !isBannerHidden && (
          <div className=" p-6 bg-orange_bg text-orange_dark flex flex-col gap-4 sm:justify-center sm:items-center">
            <div className="flex flex-col">
              <div className="flex flex-row gap-2 items-center sm:justify-center">
                <Label
                  format={{
                    id: "Finish-setup",
                    text: "Finish setting up Bulletproof",
                    fontSize: `text-xl`,
                    fontWeight: "font-bold",
                    flex: "",
                  }}
                />
                {/* <div className="bg-orange_dark rounded-full h-6 w-6 text-white text-xs flex items-center justify-center">
                  {numSettingsOutstanding}
                </div> */}
              </div>
              <p className="pl-2 text-sm">
                Complete remaining actions to get the most out of Bulletproof
                and personalize your experience.
              </p>
            </div>
            <div className="flex flex-row gap-4">
              <button
                className="text-green_primary font-bold bg-white rounded-full py-3 w-full border-1 border-green_primary sm:w-72"
                onClick={() => {
                  mixpanel.track(`Clicked: 'Finish set up'`, {
                    user_Id: user?.id,
                    user_Email: user?.email,
                    user_FirstName: user?.first,
                    user_LastName: user?.last,
                    user_Phone: user?.phone,
                    user_Tier: user?.tier,
                  });
                  navigate(`/app/email-settings/${platformData?.id}`);
                }}
              >
                Finish set up
              </button>
              <button
                className="text-base text-green_primary underline cursor-pointer text-center"
                onClick={() => {
                  mixpanel.track(`Clicked: 'Dismiss' `, {
                    user_Id: user?.id,
                    user_Email: user?.email,
                    user_FirstName: user?.first,
                    user_LastName: user?.last,
                    user_Phone: user?.phone,
                    user_Tier: user?.tier,
                  });
                  setIsOnboardBannerDismissed(true);
                  localStorage.setItem(
                    "isOnboardBannerDismissed",
                    JSON.stringify(true),
                  );
                  dispatch(refetchBannerOn());
                  // window.location.reload();
                }}
              >
                Dismiss
              </button>
            </div>
          </div>
        )}
      <div>
        {/** Credit card expired */}
        <div
          className={`text-black_primary bg-white grid place-items-start mt-20 h-screen ${
            showCardExpired ? "" : "hidden"
          } `}
        >
          <ExpiryMessageCardExpired />
        </div>
        {/** free trail has expired */}
        <div
          className={`text-black_primary bg-white grid place-items-start mt-20 h-screen ${
            showTrialExpired && !paymentStatusObject.isWhiteListed
              ? ""
              : "hidden"
          } `}
        >
          <ExpiryMessageTrialExpired />
        </div>
        <div
          className={`text-black_primary bg-white grid place-items-start mt-20 h-screen ${
            showReconnectText ? "" : "hidden"
          } `}
        >
          <ReconnectEmailAccount />
        </div>

        <div
          className={`w-full h-full ${
            !isInitialPlatformOn &&
            data &&
            data.length > 0 &&
            lastPlatformStatus !== "unauthenticated"
              ? !isOnboardBannerDismissed
              : "hidden"
          }`}
        >
          <div className={`px-32 3xl:px-40 xs:px-6 pt-10 h-full min-h-screen`}>
            <div className="sm:hidden flex flex-col gap-2 px-1 mb-6">
              <div className="text-2xl font-bold">
                Finally, turn on Bulletproof
              </div>
              <div>
                To start filtering out the noise, simply turn on Bulletproof on
                your email account.
              </div>
            </div>
            <div className="w-full p-6 rounded-lg shadow border-2 border-green_primary flex-col justify-start items-center gap-6 inline-flex">
              <div className="self-stretch h-14 flex-col justify-start items-start gap-2 flex flex-1 xs:order-2">
                <div className="self-stretch text-xl font-bold leading-7 xs:hidden">
                  Next, turn on Bulletproof
                </div>
                <div className="self-stretch text-base font-normal leading-normal hidden sm:block">
                  To start filtering out the noise, simply turn on Bulletproof
                  on your email account. You can turn off Bulletproof at any
                  time.
                </div>
              </div>
              <div className="self-stretch justify-start items-center gap-3 inline-flex xs:flex-col">
                <div className="text-center text-xl font-bold leading-7 overflow-hidden">
                  {platformData?.email}
                </div>
                <div className="self-stretch text-base font-normal leading-normal sm:hidden">
                  You can turn off Bulletproof on your email at any time.
                </div>
              </div>
              <div className="self-stretch justify-start items-center gap-4 inline-flex xs:flex-col flex-1 order-3">
                {isTurningOn ? (
                  <button className="w-48 h-11 border-1 bg-green_primary border-green_primary text-green_primary font-bold rounded-full flex justify-center items-center">
                    <div className="dot-falling"></div>
                  </button>
                ) : (
                  <div className="h-11 w-48 py-2 border-1 border-green_primary text-green_primary  hover:text-white hover:bg-green_primary rounded-3xl justify-center items-center flex xs:w-full">
                    <button
                      className="text-lg font-bold xs:leading-4 leading-7 px-12"
                      onClick={() => {
                        mixpanel.track(
                          `Clicked: 'Turn on' (email accounts page)`,
                          {
                            user_Id: user?.id,
                            user_Email: user?.email,
                            user_FirstName: user?.first,
                            user_LastName: user?.last,
                            user_Phone: user?.phone,
                            user_Tier: user?.tier,
                          },
                        );
                        handleTurnOn(platformData);
                      }}
                    >
                      Turn on
                    </button>
                    {/* <a className="text-white text-lg font-bold leading-7" href={`${process.env.REACT_APP_API}/resources/platforms/account/${user.id}/allowPlatformAccess`}>Allow access</a> */}
                  </div>
                )}
                <div className="flex flex-col gap-2 justify-center items-center sm:flex-row sm:gap-6">
                  <button
                    className="text-green_primary text-base font-normal leading-normal underline"
                    onClick={() => {
                      mixpanel.track(
                        `Clicked: 'Review Settings' (email accounts page) `,
                        {
                          user_Id: user?.id,
                          user_Email: user?.email,
                          user_FirstName: user?.first,
                          user_LastName: user?.last,
                          user_Phone: user?.phone,
                          user_Tier: user?.tier,
                        },
                      );
                      navigate(`/app/email-settings/${platformData?.id}`);
                    }}
                  >
                    Review Settings
                  </button>
                  <button
                    className="text-green_primary text-base font-normal leading-normal underline"
                    onClick={() => {
                      mixpanel.track(`Clicked: 'Remove email' `, {
                        user_Id: user?.id,
                        user_Email: user?.email,
                        user_FirstName: user?.first,
                        user_LastName: user?.last,
                        user_Phone: user?.phone,
                        user_Tier: user?.tier,
                      });
                      del(
                        "platforms",
                        `account/${lastPlatform?.ACCOUNT_REF}/platform/${lastPlatform?.id}`,
                      )
                        .then(() => {
                          localStorage.removeItem(
                            `inboxData_${lastPlatform?.id}`,
                          );
                          localStorage.removeItem(
                            `inboxFetchError_${lastPlatform?.id}`,
                          );
                          localStorage.removeItem(
                            `isSwitchedOn_${lastPlatform?.id}`,
                          );
                          window.location.reload(); //////////////////// this is eaqual to window.location.reload(false); as it stores the cache when we reload the browser
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }}
                  >
                    Remove email
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/** if card is not expired and trail is not over */}
        <div
          className={`${
            !showReconnectText &&
            !showTrialExpired &&
            !showCardExpired &&
            // !isGoogleSignUp &&
            (isInitialPlatformOn ||
              (!isInitialPlatformOn &&
                lastPlatformStatus === "unauthenticated") ||
              data?.length === 0)
              ? ""
              : "hidden"
          }`}
        >
          <div
            className={`w-full ${
              data &&
              data.length > 0 &&
              "pt-8 pb-6 sm:pb-20 h-full min-h-screen"
            }`}
          >
            {isSubsequentPlatformOff && (
              <>
                <Label
                  format={{
                    id: "action-required",
                    color: "text-black_primary",
                    text: "Action Required: An email is not fully activated",
                    fontSize: `text-3xl xs:text-2xl px-6 md:px-32`,
                    fontWeight: "font-bold",
                    flex: "flex justify-center",
                  }}
                />
                <div className="px-32 3xl:px-40 xs:px-6 pt-10 mb-10">
                  <div className="w-full p-6 rounded-lg shadow border-2 border-green_primary flex-col justify-start items-center gap-6 inline-flex">
                    <div className="self-stretch h-14 flex-col justify-start items-start gap-2 flex flex-1 xs:order-2">
                      <div className="self-stretch text-xl font-bold leading-7 xs:hidden">
                        Next, turn on Bulletproof
                      </div>
                      <div className="self-stretch text-base font-normal leading-normal">
                        You can turn off Bulletproof any time.
                      </div>
                    </div>
                    <div className="self-stretch justify-start items-center gap-3 inline-flex xs:flex-col">
                      <div className="text-center text-xl font-bold leading-7 overflow-hidden">
                        {lastPlatform?.email}
                      </div>
                    </div>
                    <div className="self-stretch justify-start items-center gap-4 inline-flex xs:flex-col flex-1 order-3">
                      {isTurningOn ? (
                        <button className="w-48 h-11 border-1 bg-green_primary border-green_primary text-green_primary font-bold rounded-full flex justify-center items-center">
                          <div className="dot-falling"></div>
                        </button>
                      ) : (
                        <div className="h-11 w-48 py-2 border-1 border-green_primary text-green_primary  hover:text-white hover:bg-green_primary rounded-3xl justify-center items-center flex xs:w-full">
                          <button
                            className="text-lg font-bold xs:leading-4 leading-7 px-12"
                            onClick={() => {
                              mixpanel.track(
                                `Clicked: 'Turn on' (email accounts page)`,
                                {
                                  user_Id: user?.id,
                                  user_Email: user?.email,
                                  user_FirstName: user?.first,
                                  user_LastName: user?.last,
                                  user_Phone: user?.phone,
                                  user_Tier: user?.tier,
                                },
                              );
                              handleTurnOn(lastPlatform);
                            }}
                          >
                            Turn on
                          </button>
                          {/* <a className="text-white text-lg font-bold leading-7" href={`${process.env.REACT_APP_API}/resources/platforms/account/${user.id}/allowPlatformAccess`}>Allow access</a> */}
                        </div>
                      )}
                      <div className="flex flex-col gap-2 justify-center items-center sm:flex-row sm:gap-6">
                        <button
                          className="text-green_primary text-base font-normal leading-normal underline"
                          onClick={() => {
                            mixpanel.track(
                              `Clicked: 'Review Settings' (email accounts page)`,
                              {
                                user_Id: user?.id,
                                user_Email: user?.email,
                                user_FirstName: user?.first,
                                user_LastName: user?.last,
                                user_Phone: user?.phone,
                                user_Tier: user?.tier,
                              },
                            );
                            navigate(`/app/email-settings/${lastPlatform?.id}`);
                          }}
                        >
                          Review Settings
                        </button>
                        <button
                          className="text-green_primary text-base font-normal leading-normal underline"
                          onClick={() => {
                            mixpanel.track(
                              `Clicked: 'Remove email' (email accounts page)`,
                              {
                                user_Id: user?.id,
                                user_Email: user?.email,
                                user_FirstName: user?.first,
                                user_LastName: user?.last,
                                user_Phone: user?.phone,
                                user_Tier: user?.tier,
                              },
                            );
                            del(
                              "platforms",
                              `account/${lastPlatform?.ACCOUNT_REF}/platform/${lastPlatform?.id}`,
                            )
                              .then(() => {
                                localStorage.removeItem(
                                  `inboxData_${lastPlatform?.id}`,
                                );
                                localStorage.removeItem(
                                  `inboxFetchError_${lastPlatform?.id}`,
                                );
                                localStorage.removeItem(
                                  `isSwitchedOn_${lastPlatform?.id}`,
                                );
                                window.location.reload(); //////////////////// this is eaqual to window.location.reload(false); as it stores the cache when we reload the browser
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          }}
                        >
                          Remove email
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className={`flex flex-row xs:mx-0 mx-28 xs:px-6 3xl:pl-6 customMedium:mx-32 customExtraSmall:mx-24 ${
                      showActionRequired ? "hidden" : ""
                    }`}
                  >
                    <LazyLoadImage
                      src={emailIcon}
                      alt="email_icon"
                      height={16}
                      width={20}
                    />
                    <Label
                      format={{
                        id: "Email-connected",
                        color: "text-black_primary",
                        text: "Connected email accounts",
                        fontSize: `text-3xl xs:text-2xl`,
                        fontWeight: "font-bold",
                        flex: "",
                      }}
                    />
                  </div>
                  <div
                    className={`flex flex-col text-center px-32 xs:px-6 ${
                      showActionRequired ? "" : "hidden"
                    }`}
                  >
                    <Label
                      format={{
                        color: "text-black_primary",
                        text: "Action Required: Reconnect your email",
                        fontSize: `text-3xl xs:text-2xl`,
                        fontWeight: "font-bold",
                        flex: "",
                      }}
                    />
                    <div className={"xs:hidden"}>
                      <Label
                        format={{
                          color: "text-black_primary",
                          text: "To continue using Bulletproof, reconnect your email below:",
                          fontSize: `text-base xs:text-sm`,
                          fontWeight: "font-normal",
                          flex: "",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={`grid grid-cols-2 gap-6 mt-6 mb-10 sm:mx-32 xs:grid-cols-1 xs:px-6 auto-rows-fr`}
                  >
                    {data &&
                      data
                        .filter(
                          (platform: PlatformData) =>
                            platform.email !== lastPlatformEmail,
                        )
                        .map(
                          (
                            {
                              email,
                              id,
                              ACCOUNT_REF,
                              platform,
                              connected_since,
                              status,
                              eval_paused,
                              has_send_survey_enabled,
                            },
                            index,
                          ) => {
                            return (
                              <PlatformCard
                                address={email}
                                id={id}
                                ACCOUNT_REF={ACCOUNT_REF}
                                platform={platform}
                                key={id}
                                connectedSince={connected_since}
                                status={status}
                                showActionRequiredText={showActionRequiredText}
                                eval_paused={eval_paused}
                                has_send_survey_enabled={
                                  has_send_survey_enabled
                                }
                              />
                            );
                          },
                        )}
                  </div>
                </div>
              </>
            )}
            <div className={`${isSubsequentPlatformOff && "hidden"}`}>
              {data && data.length > 0 ? (
                <div>
                  <div
                    className={`flex flex-row xs:mx-0 mx-28 xs:px-6 3xl:pl-6 customMedium:mx-32 customExtraSmall:mx-24 
                     ${showActionRequired ? "hidden" : ""}`}
                  >
                    <LazyLoadImage
                      src={emailIcon}
                      alt="email_icon"
                      height={16}
                      width={20}
                    />
                    <Label
                      format={{
                        id: "Email-connected",
                        color: "text-black_primary",
                        text: "Connected email accounts",
                        fontSize: `text-3xl xs:text-2xl`,
                        fontWeight: "font-bold",
                        flex: "",
                      }}
                    />
                  </div>
                  <div
                    className={`flex flex-col text-center px-32 xs:px-6 ${
                      showActionRequired ? "" : "hidden"
                    }`}
                  >
                    <Label
                      format={{
                        color: "text-black_primary",
                        text: "Action Required: Reconnect your email",
                        fontSize: `text-3xl xs:text-2xl`,
                        fontWeight: "font-bold",
                        flex: "",
                      }}
                    />
                    <div className={"xs:hidden"}>
                      <Label
                        format={{
                          color: "text-black_primary",
                          text: "To continue using Bulletproof, reconnect your email below:",
                          fontSize: `text-base xs:text-sm`,
                          fontWeight: "font-normal",
                          flex: "",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={`grid grid-cols-2 gap-6 mt-6 mb-10 sm:mx-32 xs:grid-cols-1 xs:px-6 auto-rows-fr`}
                  >
                    {data &&
                      data.map(
                        (
                          {
                            email,
                            id,
                            ACCOUNT_REF,
                            platform,
                            connected_since,
                            status,
                            eval_paused,
                            has_send_survey_enabled,
                          },
                          index,
                        ) => {
                          return (
                            <PlatformCard
                              address={email}
                              id={id}
                              ACCOUNT_REF={ACCOUNT_REF}
                              platform={platform}
                              key={id}
                              connectedSince={connected_since}
                              status={status}
                              showActionRequiredText={showActionRequiredText}
                              eval_paused={eval_paused}
                              has_send_survey_enabled={has_send_survey_enabled}
                            />
                          );
                        },
                      )}
                    <div className={`flex ${decideDesign(data)}`}>
                      <ConnectPlatformCard />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {fetchComplete && (
                    <NoEmailAccounts
                      firstTime={
                        location.state && location.state.from === "register"
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
}

export default SettingsView;
