import { configureStore } from "@reduxjs/toolkit";
import loaderReducer from "./features/loader/loaderSlice";
import authReducer from "./features/auth/authSlice";
import utilReducer from "./features/util/utilSlice";

export const store = configureStore({
  reducer: {
    loader: loaderReducer,
    auth: authReducer,
    util: utilReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
