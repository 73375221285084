import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import Button from "../../primitives/Button";
import PublicNav from "../landingPage/components/publicNav";

interface ConnectAccountCardProps {
  platformTitle: string;
}

function ConnectAccountCard({ platformTitle }: ConnectAccountCardProps) {
  const navigate = useNavigate();

  const config = {
    description:
      platformTitle === "Monthly" ? "$10 per month" : "$100 per year",
  };

  const selectSubscriptionType = (title: string) => {
    if (title === "Monthly") {
      navigate("/app/payment", {
        state: { priceId: process.env.REACT_APP_MONTHLY_PRODUCT },
      });
    } else {
      navigate("/app/payment", {
        state: { priceId: process.env.REACT_APP_YEARLY_PRODUCT },
      });
    }
  };

  return (
    <div
      className={`flex flex-col w-56 xs:w-81 items-center px-6 py-6 gap-6 rounded-lg bg-white shadow-card mb-5`}
    >
      <div className="flex w-full flex-col text-center justify-center gap-y-2">
        <p className="text-xl font-bold text-black_primary">{platformTitle}</p>
        <div>
          <p className="text-base font-normal text-gray1">
            {config.description}
          </p>
          <p
            className={
              platformTitle === "Monthly"
                ? "invisible"
                : "text-base font-normal text-brown"
            }
          >
            (save 16%){" "}
          </p>
        </div>
      </div>
      <div className="w-full sm:flex justify-center">
        <Button
          format={{
            id: `${platformTitle}btn`,
            content: "Select",
            onclick: () => selectSubscriptionType(platformTitle),
            isFilled: false,
          }}
        />
      </div>
    </div>
  );
}

function Subscription() {
  const location = useLocation();
  useEffect(() => {
    window.dataLayer?.push({
      event: "pageview",
      page: {
        url: location,
        title: "Subscription",
      },
    });
  });
  return (
    <div className="flex flex-col ">
      <PublicNav
        title="Setup subscription"
        back={{ text: "Back to Profile", to: "/app/profile" }}
      />
      <div className="flex flex-col items-center h-fit xs:px-6 justify-center pt-24 bg-white mt-16">
        <p className="text-black_primary text-2xl font-bold mb-2">
          Select a frequency
        </p>
        <div className="text-center">
          <p className="text-gray1 text-base font-normal max-w-xs">
            You can receive a bill monthly or annually, pick what works best for
            you!
          </p>
        </div>
        <div className="flex flex-row mt-6 xs:flex-col gap-6">
          <ConnectAccountCard platformTitle="Monthly" />
          <ConnectAccountCard platformTitle="Annually" />
        </div>
      </div>
    </div>
  );
}

export default Subscription;
