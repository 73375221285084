import { Form, Formik } from "formik";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useError from "../../../../hooks/useError";
import { post } from "../../../../logic/util/hooks";
import Button from "../../Button";
import Label from "../../Label";
import StaticAlert from "../../atoms/StaticAlert";
import PasswordField from "../PasswordField";
import PasswordTick from "../registration/PasswordTick";
import {
  forgetPasswordByLinkValidation,
  initialForgetPasswordByLink,
} from "./schema.ResetPassword";
import * as Sentry from "@sentry/react";
interface DecodedToken {
  sub: string;
  exp: number;
}
interface ResetPasswordFormValues {
  password: string;
  confirm: string;
}

function ResetPasswordForm() {
  const {
    location: { pathname },
  } = window;
  const token = pathname.split("/")[2];
  const { error, mountError } = useError();
  const [passwordResetSuccess, setPasswordResetSuccess] =
    useState<boolean>(false);
  const [disableResetPassword, setDisableResetPassword] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const isTokenValid = () => {
    const decodedToken: DecodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    if (!isTokenValid()) {
      mountError({
        response: { data: { errorMessage: "Password reset link expired!" } },
      });
      setDisableResetPassword(true);
    }
  }, []);

  const onSubmitHandler = async (values: ResetPasswordFormValues) => {
    try {
      if (!isTokenValid()) {
        mountError({
          response: { data: { errorMessage: "Password reset link expired!" } },
        });
        setDisableResetPassword(true);
      } else {
        const { data } = await post(
          "auth",
          `bpi/resetPassword?token=${token}`,
          values,
        );
        setPasswordResetSuccess(true);
      }
    } catch (error) {
      Sentry.captureException(error);
      mountError(error);
    }
  };

  return (
    <Formik
      initialValues={initialForgetPasswordByLink}
      validationSchema={forgetPasswordByLinkValidation}
      onSubmit={onSubmitHandler}
    >
      {(props) => {
        return (
          <Form className="flex flex-col gap-2 max-w-md w-full">
            {error && <StaticAlert type="error" message={error} />}
            <div className={`gap-2 sm:flex sm:flex-col sm:justify-end`}>
              {passwordResetSuccess ? (
                <div>
                  <Label
                    format={{
                      text: "Password reset!",
                      color: "text-green_primary",
                      fontSize: "text-xl",
                      fontWeight: "font-bold",
                    }}
                  />
                  <Label
                    format={{
                      text: "Now, sign in to access your account",
                      color: "text-green_primary",
                      fontSize: "text-base",
                    }}
                  />
                  <Button
                    format={{
                      type: "primary",
                      id: "goToSignIn",
                      isFilled: true,
                      content: "Sign in",
                      isLoading: props.isSubmitting,
                      width: props.isSubmitting ? "w-40" : "px-5 w-fit",
                      onclick: () => navigate("/login"),
                    }}
                  />
                </div>
              ) : (
                <>
                  <Label
                    format={{
                      text: "Enter a new password below",
                      color: "text-black_primary",
                      fontSize: "text-base",
                    }}
                  />
                  <PasswordField
                    name="password"
                    key="password"
                    label="New Password"
                    placeholder=""
                    subLabel=""
                  />
                  <div className="my-1">
                    <PasswordTick field={props} />
                  </div>
                  <PasswordField
                    name="confirm"
                    key="confirm"
                    label="Confirm Password"
                    placeholder=""
                    subLabel=""
                  />
                  <Button
                    format={{
                      type: "submit",
                      id: "resetPassword",
                      isFilled: true,
                      content: "Reset password",
                      isLoading: props.isSubmitting,
                      width: props.isSubmitting ? "w-40" : "px-5 w-fit",
                    }}
                    isDisabled={disableResetPassword}
                  />
                </>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ResetPasswordForm;
