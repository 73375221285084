import { useState } from "react";

interface MyFormState {
  firstName?: string;
  lastName?: string;
  email?: string;
  status?: string;
  name?: string;
  passwordValidation?: string;
  password?: string;
  company?: string;
  phone?: string;
  relationship?: string;
  value?: string;
  service?: string;
  emailWithError?: string;
  passwordWithError?: string;
  seacrh?: string;
}

interface InputFieldProps {
  id?: string;
  name?:
    | "firstName"
    | "lastName"
    | "email"
    | "status"
    | "name"
    | "passwordValidation"
    | "password"
    | "company"
    | "phone"
    | "relationship"
    | "value"
    | "service"
    | "emailWithError"
    | "passwordWithError"
    | "seacrh";
  type?: "email" | "password" | "select" | "search" | string;
  label?: string;
  placeholder?: string;
  onFocus?: () => void;
  state: MyFormState;
  setState: React.Dispatch<
    React.SetStateAction<{
      firstName?: string;
      lastName?: string;
      email?: string;
      status?: string;
      name?: string;
      passwordValidation?: string;
      password?: string;
      company?: string;
      phone?: string;
      relationship?: string;
      service?: string;
      value?: string;
      emailWithError?: string;
      passwordWithError?: string;
      search?: string;
      searchWithError?: string;
    }>
  >;
  error?: boolean;
  required?: boolean;
  selectItems?: { domain: string }[];
  width?: string;
  height?: string;
  customStyle?: string;
}

export default function InputField({
  id,
  name,
  type,
  label,
  placeholder,
  onFocus,
  state,
  setState,
  error,
  required,
  selectItems,
  width,
  height,
  customStyle,
  ...props
}: InputFieldProps) {
  let inputStyle = `bg-black10 ${width ? width : "w-full"} ${
    height ? height : ""
  } py-1 px-2 border-2 border-white40 border-opacity-100 rounded-lg focus:outline-none focus:ring text-white100`;
  let inputErrorStyle =
    "bg-black10 w-full border-2 border-red40 py-1 px-2 rounded-lg mb-1 transition-all duration-300 text-white100";
  const [isErr, setIsErr] = useState<boolean>();
  const [errorMsg, setErrorMsg] = useState<string>();

  function validateInput(e: React.FormEvent<HTMLInputElement>) {
    if ((e.target as HTMLInputElement).value === "") {
      setIsErr(true);
      setErrorMsg("*This field is required");
    } else {
      setIsErr(false);
      setErrorMsg("");
    }

    if ((e.target as HTMLInputElement).name === "passwordValidation") {
      if (state.passwordValidation !== state.password) {
        setIsErr(true);
        setErrorMsg("Passwords Need To Match");
      } else {
        setIsErr(false);
        setErrorMsg("");
      }
    }
  }

  let handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setState((prevState) => ({
      ...prevState,
      [e.currentTarget.name]: e.currentTarget.value,
    }));
  };

  if (type === "email") {
    return (
      <div className={customStyle ? customStyle : ""}>
        <label htmlFor={id} className="text-white100 mb-3">
          {label}
        </label>
        <input
          required={required}
          className={isErr ? inputErrorStyle : inputStyle}
          name={name ? name : ""}
          value={name ? state?.[name] : ""}
          type={type}
          placeholder={placeholder}
          data-error={error}
          onChange={handleChange}
          onBlur={validateInput}
        />
        {isErr ? (
          <p id="error-message" className="text-red100 mb-6">
            {errorMsg}
          </p>
        ) : (
          <p id="error-message" className="text-red100 mb-6"></p>
        )}
      </div>
    );
  }
  if (type === "password") {
    return (
      <div>
        <label htmlFor={id} className="text-white100 mb-3">
          {label}
        </label>
        <input
          required={required}
          className={isErr ? inputErrorStyle : inputStyle}
          name={name ? name : ""}
          value={name ? state?.[name] : ""}
          type={type}
          placeholder={placeholder}
          data-error={error}
          onChange={handleChange}
          onBlur={validateInput}
        />
        {isErr && (
          <p id="error-message" className="text-red100 mb-6">
            {errorMsg}
          </p>
        )}
        {!isErr && <p id="error-message" className="text-red100 mb-6"></p>}
      </div>
    );
  }
  if (type === "select") {
    return (
      <div className={customStyle ? customStyle : ""}>
        <label htmlFor={id} className="text-white100 mb-3">
          {label}
        </label>
        {!selectItems && (
          <select
            id="lang"
            name={name ? name : name}
            onChange={handleChange}
            className={`my-3 ${
              state.status === "approved" ? "text-green100" : "text-red100"
            }`}
          >
            <option value="approved" className="text-green100">
              Approved
            </option>
            <option value="blocked" className="text-red100">
              Blocked
            </option>
          </select>
        )}
        {selectItems && (
          <select
            id="lang"
            // placeholder="Select your email provider"
            name={name ? name : name}
            onChange={handleChange}
            className={inputStyle}
          >
            <option value="" disabled selected>
              Select your email provider
            </option>
            {selectItems.map((option) => {
              return (
                <option
                  key={option.domain}
                  value={option.domain}
                  className="text-white100"
                >
                  {option.domain}
                </option>
              );
            })}
          </select>
        )}
      </div>
    );
  }

  if (type === "search") {
    return (
      <div className="w-full">
        <label htmlFor={id} className="text-white100 mb-3">
          {label}
        </label>
        <input
          className={inputStyle}
          name={name ? name : name}
          value={name ? state?.[name] : ""}
          type={type}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={validateInput}
        />
      </div>
    );
  }
  return (
    <div className="w-full">
      <label htmlFor={id} className="text-white100 mb-3">
        {label}
      </label>
      <input
        className={isErr ? inputErrorStyle : inputStyle}
        name={name ? name : name}
        value={name ? state?.[name] : ""}
        type={type}
        placeholder={placeholder}
        data-error={error}
        onChange={handleChange}
        onBlur={validateInput}
      />
      {isErr && (
        <p id="error-message" className="text-red100 mb-6">
          {errorMsg}
        </p>
      )}
      {!isErr && <p id="error-message" className="text-red100 mb-6"></p>}
    </div>
  );
}
