import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetcher, update } from "../../logic/util/hooks";
import { icons } from "../assets/icons";
import Toast, { notify } from "./Toast";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface StatusBtnProps {
  contactID?: string;
  platform_ref?: string;
}

function StatusBtn({ contactID, platform_ref }: StatusBtnProps) {
  const { user } = useSelector((state: any) => state.auth);
  const [data, setData] = useState<string | any>(null);
  useEffect(() => {
    fetcher(`/resources/platforms/${user?.id}?id=${platform_ref}`)
      .then((res) => {
        if (res) setData(res);
      })
      .catch((err) => console.log(err));
  }, []);
  let put = (status: any) =>
    update("contacts", `updateStatus/${user?.id}/${contactID}`, {
      status,
      platformID: data.platformID,
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  return (
    <div className="flex gap-x-2">
      <button
        onClick={() => {
          put("approved");
          notify("updateStatus", "approved", "green");
        }}
        className="w-1/2"
      >
        <LazyLoadImage src={icons.accept} alt="approve" />
      </button>
      <button
        onClick={() => {
          put("blocked");
          notify("updateStatus", "blocked", "red");
        }}
        className="w-1/2"
      >
        <LazyLoadImage src={icons.decline} alt="block" />
      </button>
      <Toast />
    </div>
  );
}

export default StatusBtn;
