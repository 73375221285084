import React, { MouseEvent } from "react";
import Button from "../../../primitives/Button";

interface StatusActionButtonsProps {
  value: {
    status?: string;
  };
  onClickHandler: (
    value: any,
    action: any,
    event: React.MouseEvent<any>,
  ) => void;
  options?: string[];
}

export function StatusActionButtons({
  value,
  onClickHandler,
}: StatusActionButtonsProps) {
  const status = value?.status ? value?.status?.toLowerCase() : "unknown";

  return (
    <div className="flex justify-start">
      <Button
        format={{
          id: "approveButton",
          type: "table",
          content: "Approve",
          color: "green",
          isFilled: true,
          isSelected: status == "approved" ? true : false,
          onclick: (event) => onClickHandler(value, "approved", event),
        }}
      />
      <div className="w-2"></div>
      <Button
        format={{
          id: "approveButton",
          type: "table",
          content: "Block",
          color: "red",
          isFilled: true,
          isSelected: status == "blocked" ? true : false,
          onclick: (event) => onClickHandler(value, "blocked", event),
        }}
      />
    </div>
  );
}
