import React from "react";
import { useDispatch } from "react-redux";
import { unMountDialog } from "../../../features/util/utilSlice";
import Button from "../Button";
import mixpanel from "mixpanel-browser";

function LearnAboutSurveys() {
  const dispatch = useDispatch();

  return (
    <>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <h3 className="font-bold text-lg">When are request forms sent?</h3>
          <p className="text-base">
            When an unknown sender sends you an email, we'll send them a short
            request form to learn why they want to get in touch with you.
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="font-bold text-lg">What do you need to do?</h3>
          <p className="text-base">
            We’ll let you know when an unknown sender responds to the request form so
            you can decide if they should be approved or blocked.
          </p>
        </div>
      </div>
      <Button
        format={{
          id: "cancelButton",
          type: "primary",
          isFilled: true,
          content: "Got it",
          width: "w-full",
          onclick: () => {
            mixpanel.track(`Clicked: 'Got it' (Learn about request form modal)`);
            dispatch(unMountDialog());
          },
        }}
      />
    </>
  );
}

export default LearnAboutSurveys;
