import React from "react";
import { Link } from "react-router-dom";
import BpiAppFooter from "../landingPage/components/BpiAppFooter";

function PrivacyPolicy() {
  return (
    <div>
      <div
        className={
          localStorage.getItem("jwtToken")
            ? "relative -mt-[5.75rem] overflow-hidden pt-[5.75rem]"
            : " -mt-[5.75rem] overflow-hidden pt-[5.75rem]"
        }
      >
        <div className="px-4 sm:px-6 lg:px-8">
          <div
            className={
              localStorage.getItem("jwtToken")
                ? "relative mx-auto max-w-[37.5rem] pt-20 text-center pb-24"
                : "mx-auto max-w-[37.5rem] pt-20 text-center pb-24"
            }
          >
            <h1 className="text-4xl font-extrabold tracking-tight text-white100 sm:text-5xl">
              Privacy Policy
            </h1>
          </div>
        </div>
        <div className="relative px-24 sm:px-6 lg:px-24 text-white100 leading-10">
          <div className="mx-auto max-w-[40rem] prose-sm prose prose-white100 prose-a:font-semibold prose-a:text-white100 hover:prose-a:text-sky-600 flex flex-col">
            <p className="my-4">
              Welcome to Bulletproof Inbox. The Service is owned and operated by
              Bulletproof Technologies, Inc. (“us”, “we”, or “our”). The service
              is an advanced productivity solution that saves you time and money
              by removing unknown senders from your inbox.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              Our Commitment To Privacy
            </h2>
            <p className="my-4">
              We value your trust and take our privacy obligations seriously. We
              have developed this Privacy Policy to provide you with clear
              answers to your questions so you can understand how your personal
              information and data is collected, held and processed by
              Bulletproof Inbox. In this Privacy Policy, we explain how we
              process personal data and the rights you have to limit our use of
              your personal data. If you have any questions or concerns about
              our Privacy Policy, or with the handling of your personal
              information, please contact our team at{" "}
              <Link
                to="mailto:protect@bulletproofinbox.com"
                className="text-blue100"
              >
                protect@bulletproofinbox.com
              </Link>
              .
            </p>
            <p className="my-4">
              By using our email inbox productivity application [and other
              related websites, applications or services] (the “Services”), you
              agree to our processing of personal data that you submit to the
              Services in accordance with this Privacy Policy. We may not
              collect or use all of the data described in this policy for any
              particular user. We may update this Privacy Policy from time to
              time by posting a new version on this web page. When we make an
              update to this page, we will update the “last modified” date below
              to reflect the date of the most recent change. By continuing to
              use our Services, you agree that you accept updates to this
              Privacy Policy.
            </p>
            <p className="mt-4 text-base leading-7 text-white100">
              Last Modified: August 26th, 2022
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              What information do we collect?
            </h2>
            <p className="my-4">
              This Privacy Policy applies to all personal data processed through
              our Services. Personal Data you provide:
              <ul className="list-decimal px-10">
                <li>&emsp;Your name </li>
                <li>&emsp;Your email address</li>
                <li>
                  &emsp;We may also collect any communications between you and
                  Bulletproof Inbox and any other information you provide to the
                  Bulletproof Inbox team.
                </li>
              </ul>
            </p>
            <p className="my-4">
              Bulletproof Inbox will also collect personal data from Senders via
              survey when trying to contact the Recipient. This Privacy Policy
              applies to all personal data collected via survey.
            </p>
            <p className="my-4">
              Personal Data Senders provide:
              <ul className="list-decimal px-10">
                <li>&emsp;Sender’s name</li>
                <li>&emsp;Sender’s email address</li>
                <li>&emsp;Sender’s phone number</li>
                <li>&emsp;Sender’s reason for contacting you</li>
              </ul>
            </p>
            <p className="my-4">
              We also have pages on social media sites like Facebook and Twitter
              ("Social Media Pages"). When you interact with our Social Media
              Pages, we may collect Personal Data that you elect to provide to
              us through your settings on the Social Media Site, such as your
              contact details. In addition, the companies that host our Social
              Media Pages may provide us with aggregate information and
              analytics regarding the use of our Social Media Pages.
            </p>
            <p className="my-4">
              Personal Data collected automatically
              <ul className="list-decimal px-10">
                <li>
                  &emsp;When you sign in to the Service, we collect and store
                  encrypted Gmail authentication tokens
                </li>
                <li>
                  &emsp;When you visit, use or interact with the Site or App, we
                  may receive certain information about your visit, use or
                  interactions.
                </li>
                <li>
                  &emsp;Log data: Information that your browser automatically
                  sends whenever you visit the Site ("log data"). Log data
                  includes your Internet Protocol ("IP") address (so we
                  understand which country you are connecting from when you
                  visit the Site), browser type and settings, the date and time
                  of your request, and how you interacted with the Site.
                </li>
                <li>
                  &emsp;Usage Information: We collect information about how you
                  use our Site and App, such as the types of content that you
                  view or engage with, the features you use, the actions you
                  take, and the time, frequency and duration of your activities.
                </li>
                <li>
                  &emsp;Device information: Includes the operating system and
                  browser you are using. Information collected may depend on the
                  type of device you use and its settings.
                </li>
              </ul>
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              Who is the data controller or processor?
            </h2>
            <p className="my-4">
              Data protection law in certain jurisdictions differentiates
              between the “controller” and “processor” of information. When you
              use our Bulletproof Inbox service, Bulletproof Inbox is the data
              controller.‍
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              ‍‍How do we use your personal data?
            </h2>
            <p className="my-4">
              We use personal data for the following purposes:
              <ul className="list-decimal px-10">
                <li>
                  &emsp;To enable your use of the features we provide through
                  our Services.
                </li>
                <li>&emsp;To communicate with you. </li>
                <li>&emsp;To diagnose technical problems with our Services.</li>
                <li>
                  We may also use non-identifying and aggregated data to help
                  improve our Services and our website.
                </li>
              </ul>
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              How do we interact with your email data?
            </h2>
            <p className="my-4">
              ‍All emails reside on your email provider’s server and are never
              held on our Service servers. We monitor contacts, incoming emails,
              sent emails, and other changes to your inbox or contacts and ask
              the email server to move emails accordingly. We do not store or
              read the body of your emails (the contents of the email message).
            </p>
            <p className="my-4">
              Bulletproof Inbox will store copies of email data in order to
              build your Approved Sender List, Blocked Sender List, Bulletproof
              contacts, reporting, and other Services. Bulletproof will also
              store copies of email senders and other metadata for reporting and
              analytics and to improve the performance of the Services. As
              previously stated, at no time will the content of the body of your
              emails be read or stored.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              Who owns your data?
            </h2>
            <p className="my-4">
              ‍You always own your personal data. If for any reason,
              BulletproofInbox Inc. decides to discontinue the Bulletproof Inbox
              Service,all users will be contacted by email and their account
              data will be securely deleted from our servers in a timely manner.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              How long do we retain your personal data?
            </h2>
            <p className="my-4">
              We will automatically delete all the personal data that you’ve
              previously submitted to the Service, when you (or your employer)
              cancels the Service or deletes your account. We log and keep
              information on active accounts about email movement and behavior
              in order to debug any current issues customers may report. Email
              activity logs are deleted 90 days after collection.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              What permissions do we need in order for Bulletproof Inbox to
              work?
            </h2>
            <p className="my-4">
              We need certain permissions in order for Bulletproof Inbox to
              function. Bulletproof Inbox uses:
              <ul className="list-disc mb-16 px-10">
                <li>
                  The Google Mail ‘modify’ API and/or Microsoft Graph API to
                  read which emails you have sent/received and to have the
                  ability to compose emails from Gmail and/or Outlook.
                </li>
                <li>
                  The Google Mail ‘userinfo.profile’ API and/or Microsoft Graph
                  API to collect your associated profile, email address, and
                  name.
                </li>
                <li>
                  The Google Mail ‘contacts.readonly’ API and/or Microsoft Graph
                  API to collect your contact data.
                </li>
              </ul>
              ‍Bulletproof Inbox's use and transfer to any other app of
              information received from Google APIs will adhere to{" "}
              <a
                href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                target="blank"
                className="text-blue100"
              >
                Google API Services User Data Policy
              </a>
              , including the Limited Use requirements. Bulletproof Inbox’s
              processing of any information received from Microsoft APIs will
              adhere to the{" "}
              <a
                href="https://docs.microsoft.com/en-us/legal/microsoft-apis/terms-of-use"
                className="text-blue100"
                target="blank"
              >
                Microsoft API Terms of Service
              </a>
              .
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              Cookies and tracking technologies
            </h2>
            <p className="my-4">
              A cookie is a small piece of data that a website or application
              stores on your computer or mobile device. We use the following
              types of cookies when providing the Services to you:
            </p>
            <p className="my-4">
              <span className="font-extrabold">User‑input cookies:</span> (i.e.,
              session ID) which are first‑party cookies that keep track of your
              input when filling online forms, and similar things. These cookies
              have a lifespan of not more than a few hours, typically.
            </p>
            <p className="my-4">
              <span className="font-extrabold">Authentication cookies:</span>
              which identify you once you’ve logged into our web application,
              and which last for the duration of your session. We may also use
              retargeting or re-marketing technologies to show you our ads on
              sites that you visit across the Internet. Bulletproof Inbox and
              third-party vendors, including Google, use cookies to inform,
              optimize, and serve ads based on your recent visits to our
              website. Note that we do not use any email account data for ad
              targeting or retargeting.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              What third parties do we share your personal data with?
            </h2>
            <p className="my-4">
              We will share your personal information with third parties only in
              the ways that are described in this Privacy Policy. To provide you
              with our Services we will need to disclose your personal
              information to our staff or the service providers we use to
              operate our business. Examples of our service providers include:
              Internet hosting and infrastructure services; project management
              software; email service providers; system monitoring services;
              customer support services; and website analytics. These companies
              are only authorized to use your personal information as necessary
              for us to provide our Services to you [and/or the customer]. Our
              service providers may be located outside of the United States.
              When we disclose your personal information to third parties such
              as our service providers, we sign confidentiality and data
              processing agreements with them to ensure they maintain
              confidentiality and have privacy and security standards to protect
              your personal information as required by applicable law.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              ‍In what other circumstances would we disclose your personal data?
            </h2>
            <p className="my-4">
              ‍We may disclose your personal data: (i) to the extent required by
              law or if we have a good-faith belief that such disclosure is
              necessary in order to comply with official investigations or legal
              proceedings initiated by governmental and/or law enforcement
              officials, or private parties, including but not limited to, in
              response to subpoenas, search warrants, or court orders, (ii) if
              we sell or transfer all or a portion of our company’s business
              interests, assets, or both, or in connection with a corporate
              merger, consolidation, restructuring, or other company change, or
              (iii) to our subsidiaries or affiliates only if necessary for
              business and operational purposes. We reserve the right to use and
              share aggregated de-identified data, which does not include any
              personal data, about our Service’s users as a group for any legal
              business purpose, such as analyzing usage trends. If we must
              disclose your personal data in order to comply with official
              investigations or legal proceedings initiated by governmental
              and/or law enforcement officials, we may not be able to ensure
              that such recipients of your personal data will maintain the
              privacy or security of your personal data.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              ‍How do we protect your personal data?
            </h2>
            <p className="my-4">
              ‍To prevent unauthorized access, maintain the accuracy of your
              personal data, and to ensure that you’re in control of your
              personal data, we have put in place appropriate physical,
              technical, and administrative controls to safeguard and secure the
              personal data we process.
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              How can you erase, access, update or transfer your personal data?
            </h2>
            <p className="my-4">
              If you live in certain countries or jurisdictions (for example,
              European Union member states or the State of California) you may
              have rights regarding your personal information, including the
              right to access, correct, delete, port, limit or stop the use or
              disclosure of your personal information. We will respond to
              requests to access and correct (if necessary) your personal
              information as soon as possible and in accordance with applicable
              law. To exercise such rights, please contact us using the
              information in the How to Contact Us section of this Privacy
              Policy. ‘Disclosure specific to California residents: If you
              reside in California, you may have legal rights with respect to
              your personal data, including those set forth under the California
              Consumer Privacy Act (“CCPA”). Subject to certain limitations, the
              CCPA provides California consumers (a term defined under the CCPA)
              the right to request to know more details about the specific
              pieces of personal information we collect, including how we use
              and disclose this information (we only use your information to
              filter your email), to delete their personal information (this
              happens automatically when you cancel your Bulletproof Inbox
              account), to opt out of any “sales” that may be occurring (we do
              not sell your personal data in any manner) and to not be
              discriminated against for exercising these rights. California
              consumers may make a request pursuant to their rights under the
              CCPA by contacting us at{" "}
              <Link
                to="mailto:protect@bulletproofinbox.com"
                className="text-blue100"
              >
                protect@bulletproofinbox.com
              </Link>
              .
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              ‍How to Contact Us
            </h2>
            <p className="my-4">
              ‍If you have any questions, concerns or complaints about this
              Privacy Policy or our data collection or data processing
              practices, or if you want to report any data privacy concerns or
              data security issues, please contact us at the address below,
              where we will assist or refer your question, concern or complaint
              to the appropriate party.
            </p>
            <p className="my-4">
              Bulletproof Technologies, Inc. Email:{" "}
              <Link
                to="mailto:protect@bulletproofinbox.com"
                className="text-blue100"
              >
                protect@bulletproofinbox.com
              </Link>
            </p>
            <h2 className="my-4 text-2xl font-extrabold underline">
              European Union compliance
            </h2>
            <p className="my-4">
              ‍If you live in the European Union and have any complaints
              regarding our compliance with our Privacy Policy, please contact
              us at{" "}
              <Link
                to="mailto:protect@bulletproofinbox.com"
                className="text-blue100"
              >
                protect@bulletproofinbox.com
              </Link>
              . However, if you are dissatisfied with our handling of your
              complaint, please contact the relevant EU Data Protection
              Authority in your EU member state.
            </p>
          </div>
        </div>
      </div>
      <BpiAppFooter />
    </div>
  );
}

export default PrivacyPolicy;
