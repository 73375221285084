import React from "react";
import { useDispatch } from "react-redux";
import Button from "../Button";
import { unMountDialog } from "../../../features/util/utilSlice";

function DataPolicy() {
  const dispatch = useDispatch();
  return (
    <div>
      <p className="text-black_primary text-xl font-bold">
        Why do we need this data?
      </p>
      <p className="text-black_primary text-base font-normal">
        Connecting an email address to Bulletproof Inbox means you are giving us
        authorization to access your email account so that we can provide our
        service.
      </p>
      <p className="text-black_primary text-base font-normal sm:mt-6">
        Bulletproof Inbox’s goal is to protect your time & enhance your
        security. We need access to your contacts, inbox, sent mail, and junk
        folder to build your approved senders list.
      </p>
      <p className="text-black_primary text-xl font-bold sm:mt-6">
        Permissions and Account Security
      </p>
      <ul className="list-disc pl-6 font-custom text-black_primary font-normal text-base">
        <li>
          We never read the body (the message content) of emails that are sent
          or received.
        </li>
        <li>We never sell email addresses or personal user information.</li>
        <li>You can delete your account and data at any time.</li>
      </ul>
      <div
        className={`flex justify-start items-center gap-2 xs:flex-col flex-row`}
      >
        <div className={`xs:w-full`}>
          <Button
            format={{
              id: "gotItButton",
              type: "primary",
              isFilled: true,
              content: "Got it",
              onclick: () => dispatch(unMountDialog()),
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default DataPolicy;
