import React from 'react'
import { useNavigate } from 'react-router';
import Button from './Button';

function ExpiryMessageCardExpired() {
    const navigate = useNavigate();
    return (
        <div className="w-full flex flex-col justify-center items-center">
            <p className="text-2xl font-bold text-black_primary font-custom">Uh oh. Your credit card on file has expired.</p>
            <div className='mt-6'>
            <p className="text-lg font-normal text-gray1 font-custom">Please enter your payment info to continue using Bulletproof Inbox.
            </p>
            </div>
            <div className='mt-2'>
            <Button
                format={{
                    type: "primary",
                    isFilled: true,
                    content: "Update Payment Info",
                    width: "w-48",
                    onclick: () => { navigate('/app/subscription') }
                }}
            />
            </div>
        </div>
    )
}

export default ExpiryMessageCardExpired