import React, { useEffect } from "react";
import Label from "../../../primitives/Label";
import ForgetPasswordForm from "../../../primitives/forms/resetPassword/ForgetPasswordForm";
import signInBackgroundImage from "./../../../assets/graphics/sign_in_background.png";

export default function ForgetPassword() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <PublicNav title="Reset password" /> */}
      <div
        className="text-black_primary bg-center bg-no-repeat bg-cover bg-orange_bg grid place-items-center h-screen xs:h-full"
        style={{ backgroundImage: `url(${signInBackgroundImage})` }}
      >
        <div
          className={`bg-white py-8 xs:h-full xs:w-full xs:pt-24 flex flex-col items-center shadow-nav rounded-lg`}
        >
          <div className={`w-125 px-12 xs:w-full xs:px-6`}>
            <Label
              format={{
                color: "text-green_primary",
                text: "Reset your password",
                fontSize: "text-4xl",
                flex: "",
              }}
            />
            <div className="inline-block w-10 mb-3 ml-1 h-1 bg-orange1" />
            <Label
              format={{
                text: "To reset your password, first enter your email address below. Within a few minutes we’ll send you an email with a link to reset your password.",
                color: "text-black_primary",
                fontSize: "text-base",
              }}
            />
            <div className="flex flex-col justify-items-center items-center">
              <ForgetPasswordForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
