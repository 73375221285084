import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Loader from "../../primitives/Loader/Loader";
import { fetcher, post } from "../../../logic/util/hooks";
import Button from "../../primitives/Button";
import { useDispatch } from "react-redux";
import { mountDialog, unMountDialog } from "../../../features/util/utilSlice";

interface TokenPayload {
  platformID: string;
  email: string;
  platform: string;
}
interface PlatformData {
  ACCOUNT_REF: string;
  PLATFORM_REF?: string;
  id: string;
  eval_paused: boolean;
  has_send_survey_enabled: boolean;
  email: string;
  platformID: string;
  platform: string;
}
export default function NewPlatformRedirect() {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);
  const [isError, setIsError] = useState<boolean>(false);
  const { id: jwt } = useParams();
  const { platformID, platform, email } = jwtDecode<TokenPayload>(
    jwt || localStorage.getItem("jwtToken") || "",
  );

  const navigate = useNavigate();

  const alreadyConnectedModal: (headline?: string) => void = (
    headline = "Unknown error occurred, please try again",
  ) =>
    dispatch(
      mountDialog({
        component: (
          <div>
            <p>
              You can email{" "}
              <a
                href="mailto:support@bulletproofinbox.com"
                className="underline text-base text-green_primary"
              >
                support@bulletproofinbox.com
              </a>{" "}
              for help.
            </p>
            <Button
              format={{
                id: "backHomeButton",
                type: "primary",
                isFilled: true,
                content: "Back",
                onclick: () => {
                  dispatch(unMountDialog());
                  navigate(`/app/email-accounts`);
                },
              }}
            />
          </div>
        ),
        headline,
        close: false,
      }),
    );

  useEffect(() => {
    if (platformID) {
      post("platforms", `${user?.id}/platform`, {
        platformType: platform,
        accountID: user.id,
        platformID,
        email,
      })
        .then((response) => {
          console.log({ data: response.data });
          const platformData = response.data;
          localStorage.setItem(
            `isSwitchedOn_${platformData.id}`,
            JSON.stringify(false),
          );
          localStorage.setItem("lastPlatformCreated", `${email}`);
          console.log("platform added successfully!");
          navigate(`/app/email-settings/${platformData.id}`);
        })
        .catch((error: any) => {
          setIsError(true);
          if (error.response.status === 400) {
            console.error(error.response.data.errorMessage);
            alreadyConnectedModal(
              "It appears this email is already connected to another account",
            );
            return;
          }
          alreadyConnectedModal();
        });
    }
  }, [platformID]);
  
  return (
    <>
      <div className="absolute top-0 w-full shadow-nav h-16 bg-white flex justify-center items-center text-2xl font-bold">
        Configure
      </div>
      <div className="absolute h-screen w-screen flex justify-center items-center z-0">
        {!isError && <Loader />}
      </div>
    </>
  );
}
