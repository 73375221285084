import { useNavigate } from "react-router";
import Button from "../Button";

interface CardDeclinedProps {
  onClose: () => void;
}

const CardDeclined: React.FC<CardDeclinedProps> = ({ onClose }) => {
  const navigate = useNavigate();
  return (
    <div className="mb-6">
      <div className="flex justify-center flex-col md:pr-8 pr-10 items-center gap-x-3 w-full xs:items-start content-center">
        {/* <p className={`font-custom text-base text-black_primary ${message ? '': 'hidden'}`}>{message ? "Error:":''} {message}.</p> */}
        <p className={"font-custom text-base text-black_primary "}>
          Please enter a valid credit card to continue using Bulletproof Inbox.
        </p>
      </div>
      <div className="flex justify-center pt-4">
        <Button
          format={{
            type: "primary",
            isFilled: true,
            content: "Got it",
            width: "w-48",
            onclick: onClose,
          }}
        />
      </div>
    </div>
  );
};

export default CardDeclined;
