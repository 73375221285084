import React from "react";
import PropTypes from "prop-types";
import ProfileIconXXL from "../../assets/icons/profile_xxl";
import Button from "../Button";

interface NoDataProps {
  image?: React.ReactNode;
  title?: string;
  description?: string;
  button?: React.ReactNode;
}

const NoData: React.FC<NoDataProps> = ({
  image = <ProfileIconXXL color="#D47C4A" />,
  title = "No data available",
  description = "",
  button = (
    <Button
      format={{
        type: "primary",
        content: "Go to home",
        isFilled: true,
        onclick: () => window.location.assign("/"),
      }}
    />
  ),
}) => {
  return (
    <div className="bg-orange_light w-full gap-2 px-4 py-6 flex flex-col items-center justify-center rounded-lg">
      {image}
      <p className="text-2xl font-bold text-black_primary font-custom">
        {title}
      </p>
      <p className="text-base font-normal text-black_primary whitespace-pre-line text-center font-custom">
        {description}
      </p>
      {button}
    </div>
  );
};
NoData.propTypes = {
  image: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.node,
};
export default NoData;
