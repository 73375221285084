
interface BannerState {
    showTrialRunning: boolean;
    showTrialRunningPaymentDone: boolean;
    showTrialExpired: boolean;
    showEnterPaymentInfoButton: boolean;
    showCardExpired: boolean;
    showCardAboutToExpire: boolean;
    showUpdatePaymentInfo: boolean;
    showReconnectText: boolean;
    isWhiteListedFlag: boolean;
}

// Define the action types
type BannerActionTypes =
    | { type: 'trialRunning' }
    | { type: 'trialRunningPaid' }
    | { type: 'trialExpired' }
    | { type: 'enterPaymentButton' }
    | { type: 'cardExpired' }
    | { type: 'cardAboutToExpire' }
    | { type: 'updatePaymentButton' }
    | { type: 'reconnectText' }
    | { type: 'isWhiteListedAccount' };

// initial state of the database
export const initialState : BannerState = { 
    showTrialRunning : false,
    showTrialRunningPaymentDone: false,
    showTrialExpired: false,
    showEnterPaymentInfoButton: false,
    showCardExpired: false,
    showCardAboutToExpire: false,
    showUpdatePaymentInfo: false,
    showReconnectText: false,
    isWhiteListedFlag: false
  };

export const bannerActions = {
    trialRunning: 'trialRunning',
    trialRunningPaid: 'trialRunningPaid',
    trialExpired: 'trialExpired',
    enterPaymentButton: 'enterPaymentButton',
    cardExpired:'cardExpired',
    cardAboutToExpire: 'cardAboutToExpire',
    updatePaymentButton: 'updatePaymentButton',
    reconnectText:'reconnectText',
    isWhiteListedAccount: 'isWhiteListedAccount' 
}

// API logic: how to update the database when the
// 'increment' API endpoint is called
export const trialBannerReducer = (state :BannerState, action : BannerActionTypes) => {
    switch(action.type){
        case bannerActions.trialRunning :
            return { ...state, showTrialRunning: true, showEnterPaymentInfoButton: true };    

        case bannerActions.trialRunningPaid:
            return { ...state, showTrialRunningPaymentDone: true, showEnterPaymentInfoButton: false };

        case bannerActions.trialExpired:
            return { ...state, showTrialExpired: true, showEnterPaymentInfoButton: true };
        
        // case bannerActions.enterPaymentButton:
        //     return { ...state, showEnterPaymentInfoButton: true };

        case bannerActions.cardExpired:
            return { ...state, showCardExpired: true, showUpdatePaymentInfo: true };
        
        case bannerActions.cardAboutToExpire:
            return { ...state, showCardAboutToExpire: true, showUpdatePaymentInfo: true };

        // case bannerActions.updatePaymentButton:
        //     return { ...state, showUpdatePaymentInfo: true };

        case bannerActions.reconnectText:
            return { ...state, showReconnectText: true, showUpdatePaymentInfo: false, showEnterPaymentInfoButton: false };
        
        case bannerActions.isWhiteListedAccount:
            return { ...state, isWhiteListedFlag: true };

        default:
        return state
    }
};
