import React from 'react'
import { useNavigate, useOutletContext } from 'react-router';
import Button from './Button';

function ReconnectEmailAccount() {
    const navigate = useNavigate();
//   const [statePrivate, statusObj, billedOnMillis, cardCompnayName, whitelisted, stripeMethods, welcomeBack] = useOutletContext();

    return (
        <div className="w-full flex flex-col justify-center items-center">
            <p className=" flex text-2xl font-bold text-black_primary font-custom text-center mx-4">Welcome back! Please reconnect your email.</p>
            <div className='mt-6 xs:mx-12 xs:text-center'>
            <p className="text-lg font-normal text-gray1 font-custom">Your approved sender list will be saved for you.
            </p>
            </div>
            <div className='mt-2'>
            <Button
                format={{
                    type: "primary",
                    isFilled: true,
                    content: "Reconnect my email",
                    width: "w-48",
                    onclick: () => { 
                        navigate('/app/connect-account')
                     }
                }}
            />
            </div>
        </div>
    )
}

export default ReconnectEmailAccount