import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { PRIVATE_MENUBAR_ITEMS } from "../../../constants/constants";
import { unMountDialog } from "../../../features/util/utilSlice";
import Button from "../../primitives/Button";
import InputField from "../../primitives/InputField";
import BpiNavbar from "../landingPage/components/BpiNavbar";
import PublicNav from "../landingPage/components/publicNav";
import TableOne from "./tables/TableOne";
import TableThree from "./tables/TableThree";
import TableTwo from "./tables/TableTwo";

interface Value {
  firstName?: string;
  lastName?: string;
  email?: string;
  status?: string;
  name?: string;
  passwordValidation?: string;
  password?: string;
  company?: string;
  phone?: string;
  relationship?: string;
  service?: string;
  value?: string;
  emailWithError?: string;
  passwordWithError?: string;
  search?: string;
  searchWithError?: string;
}

function ComponentLibrary() {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [values, setValues] = useState<Value>({
    email: "",
    emailWithError: "",
    password: "",
    passwordWithError: "",
    search: "",
    searchWithError: "",
  });

  return (
    <>
      <div className="w-full flex flex-col justify-center pl-8 pr-6 gap-y-10 bg-white100 py-10">
        <div className="mt-10">
          <div className="flex gap-x-10 flex-wrap mb-2">
            <div className="flex flex-col justify-center items-center">
              <label className="font-custom font-semibold">Filled Button</label>
              <Button format={{ content: "Filled Button", isFilled: true }} />
            </div>
            <div className="flex flex-col justify-center items-center">
              <label className="font-custom font-semibold ">
                Outlined Button
              </label>
              <Button
                format={{
                  content: "Outlined Button",
                  isFilled: false,
                  width: "100%",
                }}
              />
            </div>
            <div className="flex flex-col justify-center items-center">
              <label className="font-custom font-semibold ">
                Loading Button
              </label>
              <Button
                format={{
                  content: "Open Dialog",
                  isLoading: true,
                  isFilled: true,
                }}
              />
            </div>
            <div className="flex flex-col justify-center items-center">
              <label className="font-custom font-semibold ">
                Disabled Button
              </label>
              <Button
                format={{ content: "Open Dialog", disabled: true }}
                isDisabled={true}
              />
            </div>
            <div className="flex flex-col items-center gap-y-3">
              <label className="font-custom font-semibold">Delete Button</label>
              <Button
                format={{
                  content: "Delete Button",
                  type: "delete",
                  isFilled: true,
                }}
              />
            </div>
            <div className="flex flex-col justify-center items-center">
              <label className="font-custom font-semibold ">
                Primary Button
              </label>
              <Button
                format={{
                  content: "Primary Button",
                  type: "primary",
                  isFilled: true,
                }}
              />
            </div>
            <div className="flex flex-col justify-center items-center">
              <label className="font-custom font-semibold ">Icon Button</label>
              <Button format={{ type: "iconButton", icon: "delete" }} />
            </div>
            <div className="flex flex-row mt-10 justify-between">
              <div className="flex flex-col justify-center items-center  ">
                <label className="font-custom font-semibold mb-5">
                  Button Group
                </label>
                <div className="flex gap-x-2">
                  <Button
                    format={{
                      content: "Approve",
                      type: "table",
                      color: "green",
                    }}
                  />
                  <Button
                    format={{ content: "Block", type: "table", color: "red" }}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center items-center ml-10">
                <label className="font-custom font-semibold mb-5 ">
                  Status Buttons
                </label>
                <Button format={{ type: "statusBtn" }} />
              </div>
            </div>
          </div>

          <div className="mt-20 flex justify-center">
            <div>
              <div>
                <p className="font-semibold font-custom">
                  Primary Input Fields
                </p>
              </div>
              <div className="mt-5">
                <div className="ml-3">
                  <label className="font-medium font-custom ">
                    Without error
                  </label>
                  <InputField
                    type="email"
                    error={false}
                    id="email_data"
                    label="email"
                    name="email"
                    placeholder="email@email.com"
                    state={values}
                    setState={setValues}
                  />
                </div>
                <div className="ml-3">
                  <label className="font-medium font-custom ">With error</label>
                  <InputField
                    type="email"
                    id="emailWithError_data"
                    label="emailWithError"
                    name="emailWithError"
                    placeholder="email@email.com"
                    state={values}
                    setState={setValues}
                  />
                </div>
              </div>
            </div>
            <div className="ml-10">
              <div>
                <p className="font-semibold font-custom">
                  Password Input Fields
                </p>
              </div>
              <div className="mt-5">
                <div className="ml-3">
                  <label className="font-medium font-custom ">
                    Without error
                  </label>
                  <InputField
                    type="password"
                    id="password_data"
                    label="password"
                    name="password"
                    placeholder="password"
                    state={values}
                    setState={setValues}
                  />
                </div>
                <div className="ml-3">
                  <label className="font-medium font-custom ">With error</label>
                  <InputField
                    type="password"
                    id="passwordWithError_data"
                    label="passwordWithError"
                    name="passwordWithError"
                    placeholder="password with error"
                    state={values}
                    setState={setValues}
                  />
                </div>
              </div>
            </div>
            <div className="ml-10">
              <div>
                <p className="font-semibold font-custom">Search Input Fields</p>
              </div>
              <div className="mt-5">
                <div className="ml-3">
                  <label className="font-medium font-custom ">
                    Without error
                  </label>
                  <InputField
                    type="search"
                    id="search_data"
                    label="search"
                    name="seacrh"
                    placeholder="search"
                    state={values}
                    setState={setValues}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-24 mt-2 w-1/2">
            <p className="font-semibold font-custom">Public Navbar</p>
            <PublicNav
              title="Public Navbar"
              back={{ text: "Back to Parent Component", to: "#" }}
            />
          </div>
          <div className="mb-24 mt-2 w-1/2">
            <p className="font-semibold font-custom">Private Navbar</p>
            <BpiNavbar
              items={PRIVATE_MENUBAR_ITEMS}
              setOpenDrawer={setOpenDrawer}
            />
          </div>

          <h1 style={{ alignSelf: "center" }}>
            Add to your approved sender list
          </h1>
          <TableOne />
          <div
            style={{
              marginTop: "30px",
              marginBottom: "30px",
              height: "2px",
              backgroundColor: "#74767E",
            }}
          >
            {" "}
          </div>
          <h1 style={{ alignSelf: "center" }}>Approved sender list</h1>

          <TableTwo />
          <div
            style={{
              marginTop: "30px",
              marginBottom: "30px",
              height: "2px",
              backgroundColor: "#74767E",
            }}
          >
            {" "}
          </div>
          <h1 style={{ alignSelf: "center" }}>Requests</h1>

          <TableThree />
        </div>
      </div>
    </>
  );
}

function ConnectAccountDialog() {
  const dispatch = useDispatch();
  return (
    <div>
      <p>
        You’ve successfully connected mike.ellis@outlook.com to Bulletproof!{" "}
      </p>
      <br />
      <h3 className="font-bold">What’s next?</h3>
      <p>
        Checkout your Approved Sender list to see who will end up in your inbox
      </p>
      <Button
        format={{
          content: "Got it",
          isFilled: true,
          onclick: () => {
            dispatch(unMountDialog());
          },
        }}
      />
    </div>
  );
}
export default ComponentLibrary;
