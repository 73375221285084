import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import binIcon from "../../assets/icons/bin_xl.svg";
import evaluateicon from "../../assets/icons/evaluate-icon.svg";
import Button from "../../primitives/Button";
import Label from "../../primitives/Label";
import PauseEvaluating from "../../primitives/dialogContent/PauseEvaluating";
import DisconnectEmail from "../../primitives/dialogContent/disconnectEmail";
import PlatformTag from "./platformTag";
import { mountDialog } from "../../../features/util/utilSlice";
import mixpanel from "mixpanel-browser";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface PlatformCardProps {
  id: string;
  address: string;
  ACCOUNT_REF: string;
  platform: string;
  connectedSince: string;
  status: string;
  showActionRequiredText: (flag: boolean) => void;
  eval_paused: boolean;
  has_send_survey_enabled: boolean;
}

function PlatformCard({
  id,
  address,
  ACCOUNT_REF,
  platform,
  connectedSince,
  status,
  showActionRequiredText,
  eval_paused,
  has_send_survey_enabled,
}: PlatformCardProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dialog = () =>
    dispatch(
      mountDialog({
        component: (
          <DisconnectEmail
            address={address}
            id={id}
            ACCOUNT_REF={ACCOUNT_REF}
          />
        ),
        headline: `Are you sure you want to disconnect your email account?`,
      }),
    );
  const dialogReevaluate = () =>
    dispatch(
      mountDialog({
        component: (
          <PauseEvaluating
            address={address}
            id={id}
            ACCOUNT_REF={ACCOUNT_REF}
            eval_paused={eval_paused}
            has_send_survey_enabled={has_send_survey_enabled}
          />
        ),
        headline: `Email account settings`,
        close: true,
        customStyle: "xs:h-84 xs:overflow-y-scroll",
      }),
    );
  const [_, date, day, year] = new Date(connectedSince)
    .toDateString()
    .split(" ");
  useEffect(() => {
    if (status === "unauthenticated") {
      showActionRequiredText(true);
    }
  }, []);

  const navigateToOnboarding = () => {
    navigate("/app/connect-account", { state: { prevPage: "Home" } });
  };
  return (
    <div
      className={`flex flex-col w-full shadow-nav items-center px-6 py-6 gap-4 rounded-md bg-white`}
    >
      <div className="flex w-full flex-row justify-between xs:h-12">
        <div className="break-words w-3/4">
          <Label
            format={{
              text: address,
              fontSize: "text-xl xs:break-all xs:text-base",
              fontWeight: "font-bold",
              color: `${
                status === "unauthenticated"
                  ? "text-red_error"
                  : "text-black_primary"
              }`,
            }}
          />
        </div>
        <div className="flex items-start xs:pl-4">
          <LazyLoadImage
            src={binIcon}
            alt="disconnect icon: two wires becoming unplugged"
            className={`${
              status === "unauthenticated" ? `hidden` : "cursor-pointer py-3"
            }`}
            onClick={() => {
              mixpanel.track(
                `Clicked: 'Delete Icon' (email accounts page / Platform card)`,
                {
                  id,
                  address,
                  ACCOUNT_REF,
                  platform,
                  status,
                },
              );
              dialog();
            }}
          />
          <LazyLoadImage
            src={evaluateicon}
            alt="settings icon: a circular cog"
            className={`${
              status === "unauthenticated" ? `hidden` : "pl-2 cursor-pointer"
            }`}
            onClick={() => {
              mixpanel.track(
                `Clicked: 'Setting Icon' (email accounts page / Platform card)`,
                {
                  id,
                  address,
                  ACCOUNT_REF,
                  platform,
                  status,
                },
              );
              navigate(`/app/email-settings/${id}`, {
                state: { prevPage: "Home" },
              });
            }}
          />
        </div>
      </div>
      <div className="h-21 xs:h-12 w-full flex flex-col gap-12 sm:pl-2 xs:pl-1">
        <div className="h-fit w-fit">
          {/* <PlatformTag platformTitle={platform} /> */}
        </div>
      </div>
      <div
        className={`${
          status === "unauthenticated"
            ? "text-red_error flex flex-row justify-between w-full"
            : "flex flex-row justify-end w-full"
        }`}
      >
        <div className={`${status === "unauthenticated" ? "" : "hidden"}`}>
          <Button
            format={{
              type: "delete",
              content: "Reconnect",
              onclick: navigateToOnboarding,
            }}
          />
        </div>
        <div
          className={`${
            status === "unauthenticated"
              ? "hidden"
              : "h-fit text-right flex justify-end"
          }`}
        >
          <div className="w-fit bg-green_bg py-1 px-2 h-fit rounded my-3">
            <p className="text-green_primary">{`Connected since ${date} ${year}`}</p>
          </div>
        </div>
        <div
          className={`${
            status === "unauthenticated"
              ? "text-right flex justify-end"
              : "hidden"
          }`}
        >
          <div className="w-fit bg-red_error_bg py-1 px-2 mt-2 rounded">
            <p className="text-red_error">{`Disconnected email`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlatformCard;
