import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { MdOutlineClose } from "react-icons/md";
import { HiLightningBolt } from "react-icons/hi";


interface ToastProps {
  action?: string;
  status?: string;
  color?: string;
}

export const notify = (action: string,status : string,color : string) =>
  toast.custom(
    (t) => (
      action === "login" ?
      <div className={`bg-${color}100 py-2 px-4 rounded flex flex-row items-center justify-center w-96 px-4 py-6 text-white100 shadow-2xl hover:shadow-none transform-gpu translate-y-0 hover:translate-y-1 rounded-xl relative transition-all duration-1000 ease-in-out`}>
        <div className="text-sm mr-4">
          <HiLightningBolt className="fill-white100 text-xl"/>
        </div>
        <div className="flex flex-col items-start justify-center cursor-default">
          <h1 className="text-white100 capitalize text-base leading-none tracking-wider">{status}</h1>
            {status==="error" && <p className="text-white100 mt-2 leading-relaxed tracking-wider">Please provide correct email and password</p>}
            {status==="success" && <p className="text-white100 mt-2 leading-relaxed tracking-wider">Login was successful</p>}
        </div>
        <div className="cursor-pointer absolute top-2 right-2 cursor-pointer text-lg" onClick={() => toast.dismiss(t.id)}>
          <MdOutlineClose />
        </div>
      </div> :
      <div className={`bg-${color}100 py-2 px-4 rounded flex flex-row items-center justify-between w-96 px-4 py-6 text-white100 shadow-2xl hover:shadow-none transform-gpu translate-y-0 hover:translate-y-1 rounded-xl relative transition-all duration-1000 ease-in-out`}>
      <div className="text-sm">
        <HiLightningBolt className="fill-white100 text-xl"/>
      </div>
      <div className="flex flex-col items-start justify-center cursor-default">
        <h1 className="text-white100 capitalize text-base leading-none tracking-wider">{status}</h1>
        <p className="text-white100 mt-2 leading-relaxed tracking-wider">
          The email was {status} successfully. 
        </p>
      </div>
      <div className="cursor-pointer absolute top-2 right-2 cursor-pointer text-lg" onClick={() => toast.dismiss(t.id)}>
        <MdOutlineClose />
      </div>
    </div>
    ),
    { id: "unique-notification", position: "bottom-center", duration: 5000 }
  );

const Toast: React.FC<ToastProps> = () => {
  return (
    <div>
      <Toaster/>
    </div>
  );
};

export default Toast;