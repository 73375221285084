import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReactNode } from "react";

interface UtilState {
  toggle: boolean;
  headline: string;
  dialogComponent: ReactNode;
  close: boolean;
  customStyle: null | string;
  refetchBanner: boolean;
}
interface MountDialogPayload {
  component: ReactNode;
  headline: string;
  close?: boolean;
  customStyle?: null | string;
}
const initialState: UtilState = {
  toggle: false,
  headline: "",
  dialogComponent: "",
  close: true,
  customStyle: null,
  refetchBanner: false,
};

export const utilSlice = createSlice({
  name: "util",
  initialState,
  reducers: {
    mountDialog: (state, action: PayloadAction<MountDialogPayload>) => {
      const {
        component,
        headline,
        close = true,
        customStyle = null,
      } = action.payload;
      if (!component || !headline) {
        throw new Error("You need a component or a headline!");
      }
      state.toggle = true;
      state.dialogComponent = component;
      state.headline = headline;
      state.close = close;
      state.customStyle = customStyle;
    },
    unMountDialog: (state) => {
      state.toggle = false;
      state.dialogComponent = "";
      state.headline = "";
      state.customStyle = null;
    },
    refetchBannerOn: (state) => {
      state.refetchBanner = true;
    },
  },
});

export const { mountDialog, unMountDialog, refetchBannerOn } =
  utilSlice.actions;

export default utilSlice.reducer;
