import React, { useState } from "react";
import Contacts from "./atoms/contacts";
import Domains from "./atoms/domains";

interface ContactTabsProps {
  inboxFetchCompleted: boolean;
}

const ContactTabs: React.FC<ContactTabsProps> = ({ inboxFetchCompleted }) => {
  const tabs = ["Senders", "Domains"];
  const [activeTab, setActiveTab] = useState<string>("Senders");
  const changeTab = (tab: string) => {
    setActiveTab(tab);
  };
  return (
    <div className="tabs md:px-10 lg:px-24">
      <ul className=" flex px-4 pt-8 items-center xs:justify-center text-black_primary">
        {tabs.map((tab: string) => (
          <li
            onClick={() => changeTab(tab)}
            key={tab}
            className={
              tab === activeTab
                ? "active cursor-pointer py-2 px-4 text-base font-bold border-b-2 border-orange_border"
                : "cursor-pointer py-2 px-4 font-normal border-b border-gray1"
            }
          >
            {tab}
          </li>
        ))}
      </ul>
      <div className="tab-content">
        <div className="tab-child">
          {activeTab === "Senders" ? (
            <Contacts />
          ) : activeTab === "Domains" ? (
            <Domains inboxFetchCompleted={inboxFetchCompleted} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ContactTabs;
