export const data_table_one = [
    {
        key: "abc1",
        domain: "@linkedin.com",
        count: 535,
        status: "approved",
        timeStamp: "June 16 2022",
        subDomain: [
            {
                key: "xyz1",
                domain: "@notifications.linkedin.com",
                count: 413,
                status: "approved",
                timeStamp: "June 16 2022",
            },
            { key: "xyz2", domain: "@news.linkedin.com", count: 122, status: "approved", timeStamp: "June 16 2022" },
            { key: "xyz300", domain: "@news.linkedin.com", count: 122, status: "", timeStamp: "June 16 2022" },
            { key: "xyz400", domain: "@news.linkedin.com", count: 122, status: "", timeStamp: "June 16 2022" },
        ],
    },
    {
        key: "abc2",
        domain: "@facebook.com",
        count: 150,
        status: "approved",
        timeStamp: "July 3 2022",
        subDomain: [
            { key: "xyz3", domain: "@info.facebook.com", count: 100, status: "blocked", timeStamp: "July 3 2022" },
            { key: "xyz4", domain: "@newsletter.facebook.com", count: 50, status: "approved", timeStamp: "July 3 2022" },
        ],
    },
    {
        key: "abc3",
        domain: "@hello.com",
        count: 11,
        status: "approved",
        timeStamp: "Deccember 25 2022",
        subDomain: [
            { key: "xyz5", domain: "@world.hello.com", count: 11, status: "approved", timeStamp: "Deccember 25 2022" },
        ],
    },
    {
        key: "abc4",
        domain: "@google.com",
        count: 1000,
        status: "approved",
        timeStamp: "September 21 2022",
        subDomain: [],
    },
    {
        key: "abc5",
        domain: "@twitter.com",
        count: 50,
        status: "blocked",
        timeStamp: "Deccember 20 2022",
        subDomain: [
            { key: "xyz6", domain: "@marketing.twitter.com", count: 12, status: "approved", timeStamp: "Deccember 20 2022" },
            { key: "xyz7", domain: "@promotions.twitter.com", count: 24, status: "blocked", timeStamp: "Deccember 20 2022" },
            { key: "xyz8", domain: "@messages.twitter.com", count: 14, status: "approved", timeStamp: "Deccember 20 2022" },
        ],
    },
];

export const data_table_two = [
    {
        key: "abc1",
        name: "Trent Bolt",
        email: "bolt@gmail.com",
        status: "blocked",
    },
    {
        key: "abc2",
        name: "Jordan Henderson",
        email: "jordan@hotmail.com",
        status: "approved",

    },
    {
        key: "abc3",
        name: "Shaheen Afridi",
        email: "shaheen@yahoo.com",
        status: "approved",

    },
    {
        key: "abc4",
        name: "Usain Bolt",
        email: "usain@fast.com",
        status: "blocked",

    },
    {
        key: "abc5",
        name: "Kane Williamson",
        email: "williamson@twitter.com",
        status: "approved",
    },
];

export const data_table_three = [
    {
        key: "abc1",
        name: "Trent Bolt",
        email: "bolt@gmail.com",
        phone: "111-111-111",
        reason: "Athletic",
        relationship: "Fast",
        status: "blocked",
    },
    {
        key: "abc2",
        name: "Jordan Henderson",
        email: "jordan@hotmail.com",
        phone: "000-000-000",
        reason: "He's okayish",
        relationship: "Workaholic",
        status: "blocked",
    },
    {
        key: "abc3",
        name: "Shaheen Afridi",
        email: "shaheen@yahoo.com",
        phone: "999-999-999",
        reason: "Speedy beast",
        relationship: "Talented",
        status: "approved",
    },
    {
        key: "abc4",
        name: "Usain Bolt",
        email: "usain@fast.com",
        phone: "123-456-789",
        reason: "He's the fastest",
        relationship: "Fastest",
        status: "blocked",
    },
    {
        key: "abcp",
        name: "Jordan ",
        email: "jordanqws@hotmail.com",
        phone: "000-000-04500",
        reason: "He's sh",
        relationship: "Worka",
        status: "approved",
    },
    {
        key: "abcerw",
        name: "Usain ",
        email: "usainwer@fast.com",
        phone: "123-456-78947",
        reason: "He's the  ",
        relationship: "wer",
        status: "approved",
    },
    {
        key: "abcerw",
        name: "Usain ",
        email: "usainwer@fast.com",
        phone: "123-456-78947",
        reason: "He's the  ",
        relationship: "wer",
        status: "approved",
    },
];

export const svg = () => (<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.1001 9.50634L6.43346 13.5063C6.39937 13.5288 6.36373 13.5489 6.32679 13.5663L6.24679 13.6197C6.0884 13.6828 5.91186 13.6828 5.75346 13.6197L5.67346 13.5663C5.63653 13.5489 5.60088 13.5288 5.56679 13.5063L0.900127 9.50634C0.620303 9.26702 0.58747 8.84617 0.826794 8.56634C1.06612 8.28652 1.48697 8.25368 1.76679 8.49301L5.33346 11.553V0.999674C5.33346 0.631485 5.63194 0.333008 6.00013 0.333008C6.36832 0.333008 6.66679 0.631485 6.66679 0.999674V11.553L10.2335 8.49301C10.5133 8.25368 10.9341 8.28652 11.1735 8.56634C11.4128 8.84617 11.38 9.26702 11.1001 9.50634Z" fill="#181920" />
</svg>)

export const trashSvg = () => (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17 0H3C1.34315 0 0 1.34315 0 3V5C0 5.55228 0.447715 6 1 6H2V17C2 18.6569 3.34315 20 5 20H15C16.6569 20 18 18.6569 18 17V6H19C19.5523 6 20 5.55228 20 5V3C20 1.34315 18.6569 0 17 0ZM16 17C16 17.5523 15.5523 18 15 18H5C4.44772 18 4 17.5523 4 17V6H16V17ZM2 4H18V3C18 2.44772 17.5523 2 17 2H3C2.44772 2 2 2.44772 2 3V4ZM6 15V9C6 8.44771 6.44772 8 7 8C7.55228 8 8 8.44771 8 9V15C8 15.5523 7.55228 16 7 16C6.44772 16 6 15.5523 6 15ZM12 9V15C12 15.5523 12.4477 16 13 16C13.5523 16 14 15.5523 14 15V9C14 8.44771 13.5523 8 13 8C12.4477 8 12 8.44771 12 9Z" fill="#231F20" />
</svg>)
