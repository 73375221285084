import { useNavigate } from "react-router";
import Button from "../../../primitives/Button";

interface ReducerStateType {
  showTrialRunning: boolean;
  showTrialRunningPaymentDone: boolean;
  showTrialExpired: boolean;
  showEnterPaymentInfoButton: boolean;
  showCardExpired: boolean;
  showCardAboutToExpire: boolean;
  showUpdatePaymentInfo: boolean;
  showReconnectText: boolean;
  isWhiteListedFlag: boolean;
}
interface PaymentStatusObject {
  trial_days?: number;
}
interface TrailWarningBanner {
  paymentStatusObject: PaymentStatusObject;
  reducerState: ReducerStateType;
}
const TrailWarningBanner = ({
  paymentStatusObject,
  reducerState,
}: TrailWarningBanner) => {
  const { trial_days = 0 } = Object(paymentStatusObject);
  const {
    showTrialRunning,
    showTrialRunningPaymentDone,
    showTrialExpired,
    showEnterPaymentInfoButton,
    showCardExpired,
    showCardAboutToExpire,
    showUpdatePaymentInfo,
    showReconnectText,
    isWhiteListedFlag,
  } = reducerState;

  const navigate = useNavigate();
  const navigateToSubscriptions = () => {
    navigate("/app/subscription");
  };
  const navigateToUpdatePayment = () => {
    navigate("/app/payment");
  };
  const navigateToEmailAccounts = async () => {
    navigate("/app/connect-account");
  };

  return (
    <div
      className={`py-3 px-4 flex flex-col sm:flex-row gap-y-4 bg-orange_bg justify-between items-center ${
        isWhiteListedFlag ? "hidden" : ""
      }`}
    >
      {/** user has not bought the package and their trail period is runnings */}
      <div
        className={`flex flex-col gap-x-1 ${showTrialRunning ? "" : "hidden"}`}
      >
        <p className="m-0 p-0 font-custom font-bold text-xl text-orange_dark">
          Your free trial ends in {Number(trial_days)} day
          {trial_days > 1 ? `s` : null}
        </p>
        <p className="m-0 p-0 font-custom font-normal text-sm text-orange_dark text-left">
          Provide a payment method to continue using Bulletproof
        </p>
      </div>
      {/** trail period running but user has already bought the package */}
      <div
        className={`flex flex-col gap-x-1 ${
          showTrialRunningPaymentDone ? "" : "hidden"
        }`}
      >
        <p className="m-0 p-0 font-custom font-bold text-xl text-orange_dark">
          Your free trial ends in {Number(trial_days)} day
          {trial_days > 1 ? `s` : null}
        </p>
        <p className="m-0 p-0 font-custom font-normal text-sm text-orange_dark text-left">
          You’ve already entered your payment info. Thank you.
        </p>
      </div>
      <div
        className={`self-start pl-2 flex flex-col gap-x-1 ${
          showTrialExpired ? "" : "hidden"
        }`}
      >
        <p className="m-0 p-0 font-custom font-bold text-xl text-orange_dark">
          Your free trial has ended{" "}
        </p>
        <p className="m-0 p-0 font-custom font-normal text-sm text-orange_dark text-left">
          Provide a payment method to continue using Bulletproof
        </p>
      </div>
      <div
        className={`flex sm:mr-0 sm:ml-auto mr-auto ml-0 ${
          showEnterPaymentInfoButton ? "" : "hidden"
        }`}
      >
        <Button
          format={{
            type: "primary",
            isFilled: true,
            content: "Enter Payment Info",
            onclick: navigateToSubscriptions,
            width: "w-44",
          }}
        />
      </div>
      {/** user's credit card is about to expire */}
      <div
        className={`flex flex-col gap-x-1 ${
          showCardAboutToExpire ? "" : "hidden"
        }`}
      >
        <p className="m-0 p-0 font-custom font-bold text-xl text-orange_dark">
          Your credit card on file is about to expire
        </p>
        <p className="m-0 p-0 font-custom font-normal text-sm text-orange_dark text-left">
          Provide a payment method to continue using Bulletproof
        </p>
      </div>
      {/** user's credit card has expired */}
      <div
        className={`flex flex-col gap-x-1 ${showCardExpired ? "" : "hidden"}`}
      >
        <p className="m-0 p-0 font-custom font-bold text-xl text-orange_dark">
          Your credit card on file has expired
        </p>
        <p className="m-0 p-0 font-custom font-normal text-sm text-orange_dark text-left">
          Provide a payment method to continue using Bulletproof
        </p>
      </div>

      <div
        className={`flex mr-0 ml-auto xs:mr-auto xs:ml-0 ${
          showUpdatePaymentInfo ? "" : "hidden"
        }`}
      >
        <Button
          format={{
            type: "primary",
            isFilled: true,
            content: "Update Payment Info",
            onclick: navigateToUpdatePayment,
            width: "w-48",
          }}
        />
      </div>
      {/** users credit card about to expire */}
      <div
        className={`flex flex-col gap-x-1 ${showReconnectText ? "" : "hidden"}`}
      >
        <p className="m-0 p-0 font-custom font-bold text-xl text-orange_dark">
          Your email address is not connected
        </p>
        <p className="m-0 p-0 font-custom font-normal text-sm text-orange_dark text-left">
          Connect your email again to continue using Bulletproof
        </p>
      </div>
      <div
        className={`flex mr-0 ml-auto xs:mr-auto xs:ml-0 ${
          showReconnectText ? "" : "hidden"
        }`}
      >
        <Button
          format={{
            type: "primary",
            isFilled: true,
            content: "Reconnect my email",
            onclick: navigateToEmailAccounts,
            width: "w-48",
          }}
        />
      </div>
    </div>
  );
};

export default TrailWarningBanner;
