import React, { useState } from "react";
import { del } from "../../../logic/util/hooks";
import Button from "../Button";
import { useDispatch } from "react-redux";
import { unMountDialog } from "../../../features/util/utilSlice";
import mixpanel from "mixpanel-browser";

interface DisconnectEmailProps {
  address?: string;
  id?: string;
  ACCOUNT_REF?: string;
}

const DisconnectEmail: React.FC<DisconnectEmailProps> = ({
  address,
  id,
  ACCOUNT_REF,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  return (
    <div>
      <p className="text-white60 leading-7">
        Disconnecting your email will stop Bulletproof Inbox from running on this
        inbox.
      </p>
      <div
        className={`flex justify-end items-center sm:gap-2 xs:flex-col flex-row`}
      >
        <div className={`xs:w-full xs:mt-6`}>
          <Button
            format={{
              id: "disconnectButton",
              type: "delete",
              isFilled: true,
              content: "Yes, disconnect my account",
              width: isLoading ? "w-40" : "w-fit",
              isLoading,
              onclick: () => {
                mixpanel.track(`Clicked: "Yes, disconnect my account" (Delete modal)`, {
                  id,
                  address,
                  ACCOUNT_REF,
                });
                setIsLoading(true);
                del("platforms", `account/${ACCOUNT_REF}/platform/${id}`)
                  .then(() => {
                    setIsLoading(false);
                    localStorage.removeItem(`inboxData_${id}`);
                    localStorage.removeItem(`inboxFetchError_${id}`);
                    localStorage.removeItem(`isSwitchedOn_${id}`);
                    localStorage.removeItem(`isStep3Skipped_${id}`);
                    localStorage.removeItem(`isStep4Skipped_${id}`);
                    window.location.reload(); //////////////////// this is eaqual to window.location.reload(false); as it stores the cache when we reload the browser
                    dispatch(unMountDialog());
                  })
                  .catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                  });
              },
            }}
          />
        </div>
        <div className={`xs:w-full xs:-mt-3`}>
          <Button
            format={{
              id: "cancelButton",
              type: "primary",
              isFilled: false,
              content: "Cancel",
              onclick: () => { 
                mixpanel.track(`Clicked: "Cancel" (Delete modal)`, {
                  id,
                  address,
                  ACCOUNT_REF,
                });
                dispatch(unMountDialog());
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DisconnectEmail;
