import React from "react";
import { icons } from "../../assets/icons";
import Attention from "../../assets/icons/attention";

interface StaticAlertProps {
  type?: "success" | "error" | "info" | "warning" | "secondary";
  message?: React.ReactNode;
  closable?: boolean;
}

const StaticAlert: React.FC<StaticAlertProps> = ({ type = "success", message, closable = false }) => {
  const getTypeStyle = (): string | undefined => {
    switch (type) {
      case "success":
        return "border-green400 text-green700 bg-green100";
      case "error":
        return "border-red_error_border text-red_error_dark bg-red_error_bg";
      case "info":
        return "border-blue500 text-blue500 bg-blue500";
      case "warning":
        return "border-yellow500 text-yellow500 bg-yellow500";
      case "secondary":
        return "border-gray500 text-gray500 bg-gray500";
      default:
        break;
    }
  };

  const getTextColor = (): string | undefined => {
    switch (type) {
      case "success":
        return "text-green500";
      case "error":
        return "text-red_error_dark";
      case "info":
        return "text-blue500";
      case "warning":
        return "text-yellow500";
      case "secondary":
        return "text-gray500";
      default:
        break;
    }
  };

  return (
    <div
      className={`flex box-border ${getTypeStyle()} rounded relative`}
      role="alert"
    >
      <span
        className={`flex flex-row items-center gap-1  pt-1 pb-1.5 px-2 h-fit text-sm ${getTextColor()}`}
      >
        <Attention color={getTextColor()} />
        <p>{message}</p>
      </span>
      {closable && (
        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
          <svg
            className={`fill-current h-6 w-6 ${getTextColor()}`}
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      )}
    </div>
  );
};

export default StaticAlert;
