import React from "react";
import { motion, Variants } from "framer-motion";

interface ErrorMessageProps { 
  errors?: string | { message: string }[];
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ errors }) => {
  if (errors?.length && typeof errors === "object") {
    return (
      <>
        {errors.map(({ message }, index) => (
          <div key={index}>
            <p className="text-red_error font-semibold">• {message}</p>
          </div>
        ))}
      </>
    );
  }

  if (typeof errors === "string") {
    const motionVariants: Variants = {
      initial: { opacity: 0, y: 100 },
      animate: { opacity: 1, y: 0 },
      exit: { opacity: 0, y: -100, height: -1, padding: 0 },
    };

    return (
      <motion.p
        variants={motionVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        className="text-red_error font-semibold"
      >
        {errors}
      </motion.p>
    );
  }

  return <div />;
};

export default ErrorMessage;
