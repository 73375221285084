import React, { useEffect, useState } from "react";

interface PasswordTickProps {
  field?: any;
  keys?: any;
  requirments?: Record<string, boolean>;
}

function PasswordTick({ field, keys, requirments }: PasswordTickProps) {
  const fieldDescription: Record<string, string> = {
    capitalLetter: "Upper case letters (A-Z)",
    lowerCaseLetter: "Lower case letters  (a-z)",
    number: "Numbers  (0-9)",
    specialCharacter: "At least 1 special character",
    characterLimit: "At least 8 characters",
  };

  const renderPasswordRequirements = () => {
    if (requirments) {
      const requirmentsKeys = Object.keys(requirments);
      return requirmentsKeys.map((item) => {
        return passwordConditionSatisfied(item);
      });
    }
  };

  const passwordConditionSatisfied = (value: string) => {
    if (requirments && requirments[value] === true) {
      return (
        <div className="flex items-center gap-2 py-px">
          <div className="flex justify-center items-center py-0 px-px w-4 h-4">
            <svg
              width={14}
              height={11}
              viewBox="0 0 14 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4866 1.23159L4.81996 10.5649C4.69431 10.7004 4.51807 10.7777 4.33329 10.7783C4.15609 10.7793 3.98577 10.7097 3.85996 10.5849L0.526627 7.25159C0.265213 6.99017 0.265213 6.56634 0.526627 6.30492C0.788042 6.04351 1.21188 6.04351 1.47329 6.30492L4.33329 9.15159L12.5133 0.324921C12.6702 0.131434 12.9219 0.0415596 13.1658 0.0918992C13.4098 0.142239 13.6053 0.324394 13.6728 0.564181C13.7403 0.803969 13.6685 1.06138 13.4866 1.23159Z"
                fill="#36854C"
              />
            </svg>
          </div>
          <div className="text-green_primary font-custom leading-6 text-base">
            {fieldDescription[value]}
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex items-center gap-2 py-px">
          <div className="flex justify-center items-center p-px w-4 h-4">
            <svg
              width={14}
              height={15}
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4735 12.9695C13.5997 13.0947 13.6707 13.2651 13.6707 13.4428C13.6707 13.6206 13.5997 13.791 13.4735 13.9162C13.3483 14.0424 13.1779 14.1134 13.0001 14.1134C12.8224 14.1134 12.652 14.0424 12.5268 13.9162L7.00012 8.38285L1.47346 13.9162C1.34828 14.0424 1.17788 14.1134 1.00012 14.1134C0.822364 14.1134 0.651966 14.0424 0.526789 13.9162C0.40058 13.791 0.32959 13.6206 0.32959 13.4428C0.32959 13.2651 0.40058 13.0947 0.526789 12.9695L6.06012 7.44285L0.526789 1.91618C0.357683 1.74707 0.29164 1.5006 0.353537 1.2696C0.415434 1.03859 0.595867 0.858159 0.82687 0.796262C1.05787 0.734365 1.30435 0.800408 1.47346 0.969514L7.00012 6.50285L12.5268 0.969514C12.7882 0.708099 13.212 0.708099 13.4735 0.969514C13.7349 1.23093 13.7349 1.65477 13.4735 1.91618L7.94012 7.44285L13.4735 12.9695Z"
                fill="#953131"
              />
            </svg>
          </div>
          <div className="text-red_error font-custom leading-6 text-base">
            {fieldDescription[value]}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="flex gap-2 items-center">
      {requirments && (
        <div className="flex flex-col justify-center items-start gap-2 self-stretch py-3 px-4 rounded bg-gray4 xs:w-full w-full">
          <div className="text-black font-custom text-base font-normal leading-6">
            Your password must contain:
            <div className="flex flex-col gap-2 mt-2">{renderPasswordRequirements()}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PasswordTick;
