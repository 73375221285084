import React from "react";
import { IoMdHelpCircleOutline } from "react-icons/io";


const HelpLink = () => {
  return (
    <a
      className="flex items-center h-5 cursor-pointer"
      href="https://www.bulletproofinbox.com/new-member-onboarding-guide"
      target="_blank"
      rel="noreferrer"
    >
      <IoMdHelpCircleOutline className="w-6 h-6 text-green_primary" />
      <div className="h-5 -mt-2 xs:leading-3 xs:-mt-6">
        <p className="text-black_primary sm:text-green_primary font-custom capitalize pl-2 xs:pl-5 text-base sm:underline">
          Help
        </p>
        <div className="xs:-mt-1 sm:invisible sm:w-0">
          <p className="text-green_primary font-custom pl-5 text-xs underline">
            Help
          </p>
        </div>
      </div>
    </a>
  );
};

export default HelpLink;
