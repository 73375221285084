import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Widget } from "@typeform/embed-react";
import { useParams, useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/react";

const SurveyForm = () => {
  const { accountID, contactID, formID } = useParams();
  let [searchParams] = useSearchParams();
  const [isViewportAbove600, setIsViewportAbove600] = useState(false);

  const name = searchParams.get("name") ?? "";

  const handleResize = useCallback(() => {
    if (window.innerWidth > 614) {
      setIsViewportAbove600(true);
    } else {
      setIsViewportAbove600(false);
    }
  }, []);

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);
  return (
    <div className="h-screen">
      <Widget
        onSubmit={(event: any) => {
          axios
            .post(
              `${process.env.REACT_APP_API}/resources/responses/${accountID}/${contactID}`,
              { responseId: event.responseId, formID },
            )
            .then((res) => {})
            .catch((err) => Sentry.captureException(err));
        }}
        id={formID ? formID : ""}
        hidden={{ name }}
        style={{
          width: "100%",
          height: isViewportAbove600 ? "100%" : "80%",
          borderRadius: "0",
        }}
        className="my-form"
      />
    </div>
  );
};

export default SurveyForm;
