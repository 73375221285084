import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useOutletContext } from "react-router-dom";
import { monthInWord } from "../../../logic/util/calculatePaymentStatus";
import { fetcher } from "../../../logic/util/hooks";
import ArrowsCircleIcon from "../../assets/icons/arrows_circle";
import BinIcon from "../../assets/icons/bin";
import EditIcon from "../../assets/icons/edit";
import ProfileIcon from "../../assets/icons/profile_large";
import Button from "../../primitives/Button";
import Footer from "../../primitives/Footer";
import ChooseEmail from "../../primitives/dialogContent/ChooseEmail";
import DeleteAccount from "../../primitives/dialogContent/deleteAccount";
import EditProfileForm from "../../primitives/forms/profile/EditProfileForm";
import ChangePasswordForm from "../../primitives/forms/resetPassword/ChangePasswordForm";
import { mountDialog } from "../../../features/util/utilSlice";
import Label from "../../primitives/Label";
import mixpanel from "mixpanel-browser";

interface AutomaticallyBilledOn {
  month: string | number;
  day: string | number;
  year: string | number;
}
interface PlanNames {
  nameCapital: string;
  nameSmall: string;
  amount: string;
}

const Profile = () => {
  const { user } = useSelector((state: any) => state.auth);
  const { toggle } = useSelector((state: any) => state.util);
  const dispatch = useDispatch();
  const [paymentStatusObject, reducerState] = useOutletContext<any>();
  const navigate = useNavigate();
  const [automaticallyBilledOn, setAutomaticallyBilledOn] =
    useState<AutomaticallyBilledOn>({
      month: "",
      day: "",
      year: "",
    });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [platformId, setPlatformId] = useState<any | null>(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [planNames, setPlanNames] = useState<PlanNames>({
    nameCapital: "",
    nameSmall: "",
    amount: "",
  });

  const [data, setData] = useState<any>([]);

  const {
    trial_days,
    bill_status,
    payment_method,
    last_4_digit,
    next_bill_occurance,
    plan,
  } = Object(paymentStatusObject);

  let {
    showTrialRunning,
    showTrialRunningPaymentDone,
    showTrialExpired,
    showEnterPaymentInfoButton,
    showCardExpired,
    showCardAboutToExpire,
    showUpdatePaymentInfo,
    showReconnectText,
    isWhiteListedFlag,
  } = reducerState;

  const deleteAccountDialog = () =>
    dispatch(
      mountDialog({
        component: (
          <DeleteAccount
            paymentStatusObject={paymentStatusObject}
            reducerState={reducerState}
          />
        ),
        headline: "Are you sure you want to delete your account?",
      }),
    );
  const changePasswordDialog = () =>
    dispatch(
      mountDialog({
        component: <ChangePasswordForm />,
        headline: "Change password",
      }),
    );
  const editProfileDialog = () =>
    dispatch(
      mountDialog({
        component: <EditProfileForm />,
        headline: "Edit name",
      }),
    );
  const chooseEmailDialog = () =>
    dispatch(
      mountDialog({
        component: <ChooseEmail data={data} />,
        headline:
          data.length > 0
            ? "Choose Which Email"
            : "Please connect your first email to Bulletproof",
      }),
    );

  useEffect(() => {
    window.scrollTo(0, 0);
    fetcher(`/resources/platforms/${user?.id}`)
      .then((res) => {
        if (res) setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const { month, day, year } = automaticallyBilledOn;
  const { nameCapital, nameSmall, amount } = planNames;

  useEffect(() => {
    filterBillingDate();
    getPlanDetails();
  }, [next_bill_occurance, plan]);

  const onClickHandler = () => {
    navigate("/app/subscription");
  };

  const getPlanDetails = () => {
    if (plan == "month") {
      setPlanNames({
        nameCapital: "Monthly",
        nameSmall: "month",
        amount: "10",
      });
    } else if (plan == "year") {
      setPlanNames({
        nameCapital: "Yearly",
        nameSmall: "year",
        amount: "100",
      });
    }
  };

  const filterBillingDate = () => {
    if (next_bill_occurance) {
      const date = new Date(next_bill_occurance).getDate();
      const month = new Date(next_bill_occurance).getMonth() + 1;
      const year = new Date(next_bill_occurance).getFullYear();
      setAutomaticallyBilledOn({
        ...automaticallyBilledOn,
        month: month,
        day: date,
        year: year,
      });
    }
  };

  const getDateSuffix = (day: number) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const renderCardExpired = () => (
    <>
      <div
        className={
          showCardExpired
            ? `flex xs:flex-col xs:justify-start justify-between xs:gap-4`
            : "hidden"
        }
      >
        <div>
          <p
            className={
              " m-0 p-0 font-custom font-bold text-base text-orange_dark"
            }
          >
            Your credit card on file has expired
          </p>
        </div>
      </div>
      <div className={showCardExpired ? "" : "hidden"}>
        <div className="flex mr-0 ml-auto xs:mr-auto xs:ml-0">
          <Button
            format={{
              type: "primary",
              isFilled: true,
              content: "Update Payment Info",
              onclick: onClickHandler,
              width: "w-48",
            }}
          />
        </div>
      </div>

      <div
        className={
          showCardExpired
            ? `flex xs:flex-col xs:justify-start justify-between xs:gap-4`
            : "hidden"
        }
      >
        <div>
          <div className="flex">
            <p className=" font-custom  text-base font-normal text-red_error mr-1">
              EXPIRED
            </p>
            <p className=" font-custom  text-base font-normal text-gray1">
              Payment method
            </p>
          </div>
          <p className=" font-custom  text-base font-normal text-red_error ">
            {payment_method} ending in {paymentStatusObject?.last_four_digits}
          </p>
        </div>
        <p
          className=" font-custom  underline decoration-current cursor-pointer whitespace-nowrap text-green_primary text-base font-normal flex items-center xs:justify-start justify-center gap-1"
          onClick={() => navigate("/app/payment")}
        >
          <EditIcon color="#36854C" />
          Edit payment method
        </p>
      </div>
    </>
  );

  const renderCardInfo = () => (
    <>
      <div
        className={
          !showCardExpired
            ? `flex xs:flex-col xs:justify-start justify-between xs:gap-4`
            : "hidden"
        }
      >
        <div>
          <p className=" font-custom  text-base font-normal text-gray1">
            Payment method
          </p>
          <p className=" font-custom  text-base font-normal text-black_primary ">
            {payment_method} ending in {last_4_digit}
          </p>
        </div>
        <p
          className=" font-custom  underline decoration-current cursor-pointer whitespace-nowrap text-green_primary text-base font-normal flex items-center xs:justify-start justify-center gap-1"
          onClick={() => navigate("/app/payment")}
        >
          <EditIcon color="#36854C" />
          Edit payment method
        </p>
      </div>
    </>
  );

  const renderCardAboutToExpire = () => (
    <>
      <div
        className={
          showCardAboutToExpire
            ? `flex xs:flex-col xs:justify-start justify-between xs:gap-4`
            : "hidden"
        }
      >
        <div>
          <p
            className={
              " m-0 p-0 font-custom font-bold text-base text-orange_dark"
            }
          >
            Your credit card is about to expire
          </p>
        </div>
      </div>
    </>
  );

  const renderResourceSection = () => (
    <div className="flex flex-col gap-6">
      <p className=" font-custom  text-2xl font-bold text-black_primary">
        Resources
      </p>
      <a
        href="https://www.bulletproofinbox.com/new-member-onboarding-guide"
        target="_blank"
        className="underline decoration-current cursor-pointer text-green_primary text-base font-normal"
      >
        New Member Onboarding Guide
      </a>
    </div>
  );

  const renderFilterSettings = () => (
    <div className="flex flex-col gap-2">
      <p className=" font-custom  font-normal text-gray1">Filter Settings</p>
      <a
        onClick={() => {
          navigate("/app/email-settings");
        }}
        className="underline decoration-current cursor-pointer text-green_primary text-base font-normal"
      >
        Customize how Bulletproof works
      </a>
      {isModalOpen ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none bg-black10">
          <div className="relative w-auto max-w-2xl mx-auto my-6">
            <div className="border-0 rounded-lg px-8 relative flex flex-col w-full outline-none focus:outline-none z-50 bg-white">
              <div className="flex w-full justify-end items-center">
                <a
                  className="py-2 text-xl cursor-pointer"
                  onClick={() => closeModal()}
                >
                  X
                </a>
              </div>
              <div className="flex flex-col items-center justify-center p-2">
                <h3 className="text-3xl font-semibold">Choose which email</h3>
                <p className="text-center my-2">
                  Select the email address you’d like to <br /> customize the
                  filter settings for
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {data.map(
                  (item: { id: number; email: string; platformID: any }) => (
                    <div className="flex-auto" key={item.id}>
                      <div
                        className={`flex flex-col max-w-full shadow-nav items-center gap-4 rounded-md bg-white p-4`}
                      >
                        <Label
                          format={{
                            text: item.email,
                            fontSize: "text-xl xs:break-all xs:text-base",
                            fontWeight: "font-bold",
                          }}
                        />
                        <div className="w-32 xs:w-full">
                          <Button
                            format={{
                              type: "primary",
                              content: "Select",
                              width: "w-full",
                              isFilled: item.id === platformId ? true : false,
                              onclick: () => setPlatformId(item.id),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ),
                )}
              </div>
              <div className="flex items-center space-x-4 justify-start pt-6 pb-3">
                <Button
                  format={{
                    type: "primary",
                    isFilled: !platformId ? false : true,
                    content: "Go to Settings",
                    onclick: () => {
                      navigate("/app/unknown-sender-settings", {
                        state: {
                          platformID: platformId,
                        },
                      });
                    },
                  }}
                  isDisabled={!platformId}
                />
                <Button
                  format={{
                    id: "deleteButton",
                    type: "delete",
                    content: "Cancel",
                    width: "28px",
                    color: "green",
                    onclick: () => closeModal(),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </div>
      ) : null}
    </div>
  );

  // if (!toggle)
    return (
      <>
        <div
          className={`md:h-fit w-full pt-10 pb-8 flex flex-row xs:flex-col items-start xs:gap-18 sm:justify-around justify-center px-6 lg:px-0`}
        >
          <div className="flex flex-col gap-6 w-125 xs:w-full">
            <div
              className={`flex flex-row items-center gap-2 xs:justify-center`}
            >
              <ProfileIcon color="#B95C28" />
              <p className=" font-custom  text-3xl font-bold text-black_primary">
                My profile
              </p>
            </div>

            <div className="flex flex-col gap-6">
              <p className=" font-custom  text-2xl font-bold text-black_primary">
                Personal Info
              </p>
              <div
                className={`flex xs:flex-col xs:justify-start justify-between xs:gap-4`}
              >
                <div>
                  <p className=" font-custom  text-base font-normal text-gray1">
                    Name
                  </p>
                  <p className=" font-custom  text-base font-normal text-black_primary capitalize">
                    {user?.first} {user?.last}
                  </p>
                </div>
                <p
                  onClick={() => editProfileDialog()}
                  className="underline decoration-current cursor-pointer whitespace-nowrap text-green_primary text-base font-normal flex items-center xs:justify-start justify-center gap-1"
                >
                  <EditIcon color="#36854C" />
                  Edit Name
                </p>
              </div>
            </div>

            <div className={`flex flex-col xs:justify-start`}>
              <p className=" font-custom  text-base font-normal text-gray1">
                Bulletproof user name
              </p>
              <p className=" font-custom  text-base font-normal text-black_primary">
                {user?.email}
              </p>
            </div>

            {renderResourceSection()}
            {/* {renderFilterSettings()} */}

            {/* Billing Block  */}
            {isWhiteListedFlag == false && (
              <div className="flex flex-col gap-6 sm:pb-10">
                <p className=" font-custom  text-2xl font-bold text-black_primary">
                  Billing
                </p>
                {/** Paid BLOCK */}
                <div
                  className={
                    bill_status
                      ? "w-125 xs:w-full flex flex-col gap-6 md:mb-4"
                      : "hidden"
                  }
                >
                  <div
                    className={`flex xs:flex-col xs:justify-start justify-between xs:gap-4`}
                  >
                    <div>
                      <p className=" font-custom  text-base font-normal text-gray1">
                        Plan
                      </p>
                      <p className=" font-custom  text-base font-normal text-black_primary ">
                        {nameCapital} billing, ${amount} per {nameSmall}
                      </p>
                      <p className=" font-custom  text-base font-normal text-black_primary ">
                        Billed on the {day}
                        {getDateSuffix(day as number)} of each {nameSmall}.
                      </p>
                    </div>
                  </div>

                  <div
                    className={`flex xs:flex-col xs:justify-start justify-between xs:gap-4`}
                  >
                    <div>
                      <p className=" font-custom  text-base font-normal text-gray1">
                        Next payment
                      </p>
                      <p className=" font-custom  text-base font-normal text-black_primary ">
                        Automatically billed on {monthInWord(month as number)}{" "}
                        {day}, {year}
                      </p>
                    </div>
                  </div>
                  {/** If card is about to expire */}
                  {renderCardAboutToExpire()}
                  {/** If card has expired */}
                  {renderCardExpired()}

                  {/** If card has not expired */}
                  {renderCardInfo()}
                </div>
                {/** Trail period BLOCK */}
                <div
                  className={
                    showTrialRunning
                      ? "px-4  py-3 flex xs:flex-col xs gap-y-4 bg-orange_bg rounded-lg justify-between items-center md:mb-4"
                      : "hidden"
                  }
                >
                  <div className="flex flex-col gap-x-1 ">
                    <p className=" m-0 p-0 font-custom font-bold text-xl text-orange_dark">
                      {trial_days} day{trial_days > 1 ? `s` : null} left in your
                      free trial
                    </p>
                    <p className="m-0 p-0 font-custom font-normal text-sm text-orange_dark text-left">
                      When the trial ends you’ll need to provide a payment
                      method to continue.
                    </p>
                  </div>
                  <div className="flex mr-0 ml-auto xs:mr-auto xs:ml-0">
                    <Button
                      format={{
                        type: "primary",
                        isFilled: true,
                        content: "Enter Payment Info",
                        onclick: onClickHandler,
                        width: "w-44",
                      }}
                    />
                  </div>
                </div>
                {/** Trail but Payment made period BLOCK */}
                <div
                  className={
                    showTrialRunningPaymentDone
                      ? "px-4  py-3 flex xs:flex-col xs gap-y-4 bg-orange_bg rounded-lg justify-between items-center"
                      : "hidden"
                  }
                >
                  <div className="flex flex-col gap-x-1 ">
                    <p className=" m-0 p-0 font-custom font-bold text-xl text-orange_dark">
                      {trial_days} day{trial_days > 1 ? `s` : null} left in your
                      free trial
                    </p>
                    <p className="m-0 p-0 font-custom font-normal text-sm text-orange_dark text-left">
                      You’ve already entered your payment info. Thank you.
                    </p>
                    <p className="m-0 p-0 font-custom font-normal text-sm text-orange_dark text-left">
                      Your credit card will be automatically billed on{" "}
                      {monthInWord(month as number)} {day}, {year}{" "}
                    </p>
                  </div>
                </div>
                {/** free trail has expired */}
                <div
                  className={
                    showTrialExpired
                      ? "px-4  py-3 flex xs:flex-col xs gap-y-4 bg-orange_bg rounded-lg justify-between items-center"
                      : "hidden"
                  }
                >
                  <div className="flex flex-col gap-x-1 ">
                    <p className=" m-0 p-0 font-custom font-bold text-xl text-orange_dark">
                      Your free trial has ended.
                    </p>
                    <p className="m-0 p-0 font-custom font-normal text-sm text-orange_dark text-left">
                      Please enter your payment info to continue using
                      Bulletproof Inbox.
                    </p>
                  </div>
                  <div className="flex mr-0 ml-auto xs:mr-auto xs:ml-0">
                    <Button
                      format={{
                        type: "primary",
                        isFilled: true,
                        content: "Enter Payment Info",
                        onclick: onClickHandler,
                        width: "w-44",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-row xs:flex-col text-center gap-4 xs:w-full xs:pb-4">
            <div
              className={`invisible bg-gray3 w-0 h-0 xs:visible xs:h-px xs:w-full`}
            />
            <p
              onClick={() => changePasswordDialog()}
              className="xs:mt-4 underline decoration-current cursor-pointer whitespace-nowrap text-green_primary text-base font-normal flex items-center justify-center gap-1"
            >
              <ArrowsCircleIcon color="#36854C" />
              Change password
            </p>
            <p
              onClick={() => {
                mixpanel.track(`Clicked: 'Delete Account' (Profile page)`);
                deleteAccountDialog()
              }}
              className="underline decoration-current cursor-pointer whitespace-nowrap text-green_primary text-base font-normal flex items-center justify-center gap-1"
            >
              <BinIcon color="#36854C" />
              Delete Account
            </p>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </>
    );

  // return <div></div>;
};

export default Profile;
