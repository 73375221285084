import React, { useState, useEffect } from "react";
import ExpandableTable from "../../../primitives/atoms/GenericAntdTable/GenericAntdTable";
import Button from "../../../primitives/Button";
import { data_table_two, svg, trashSvg } from "../components/TableData";
import { StatusActionButtons } from "../components/StatusActionButtons";
import GenericMobileAntdTable from "../../../primitives/atoms/MobileSubdomainAntdTable/MobileSubdomainAntdTable";
import MobileActionAntdTable from "../../../primitives/atoms/MobileActionAntdTable/MobileActionAntdTable";

interface Records {
  key: string;
  name: string;
  email: string;
  phone: string;
  reason: string;
  relationship: string;
  contactID: string;
  platform_ref: string;
  option?: any;
  status?: string;
  domain?: string;
  parentKey?: string;
  count?: number;
  subject?: string;
  subDomain?: any;
}

// const getData = () => {
//     return [...data_table_two,];
// };
const getData = (): Records[] => {
  return data_table_two.map((data) => ({
    ...data,
    key: data.key,
    name: data.name,
    email: data.email,
    contactID: "",
    platform_ref: "",
    subject: "",
    phone: "",
    reason: "",
    relationship: "",
  }));
};

function TableTwo() {
  //fetching the data
  const [data, setData] = useState<Records[]>([]);
  const [errorInRowKey, seterrorInRowKey] = useState("");
  // In order to expand the rows. We save the keys of the rows we want to expand

  // fetch the data
  useEffect(() => {
    const data = getData();
    setData(data);
    return () => {
      //cleanup
      setData([]);
    };
  }, []);

  //Action button handler. If we select/unselect a domain or subdomain this function runs
  const actionButtonClickHandler = (value: Records, option: any) => {
    setData((data) =>
      data.map((data) => {
        if (data.key === value.key) {
          return { ...data, status: option };
        } else {
          return data;
        }
      }),
    );
  };

  const deleteRecord = (record: Records) => {
    console.log({ record });
    seterrorInRowKey(record.key);
  };

  const columns = [
    {
      title: () => (
        <p className="font-custom font-bold text-base text-black_primary leading-6 ">
          Name
        </p>
      ),
      width: "30%",
      dataIndex: "name",
      key: "name",
      render: (text: string, key: any) => (
        <a className="font-custom font-normal text-base text-black_primary leading-6 ">
          {text}
        </a>
      ),
    },
    {
      title: () => (
        <div className="flex gap-x-2 items-center">
          <p className="font-custom font-bold text-base text-black_primary leading-6 ">
            Email
          </p>{" "}
          <p>{svg()}</p>
        </div>
      ),
      width: "30%",
      dataIndex: "email",
      key: "email",
      render: (text: string, key: any) => (
        <a className="font-custom font-normal text-base text-black_primary leading-6 ">
          {text}
        </a>
      ),
    },
    {
      title: () => (
        <div className="font-custom font-bold text-base text-black_primary leading-6 ">
          Status
        </div>
      ),
      key: "status",
      width: "25%",
      render: (_: any, record: Records) => {
        return (
          <div className="py-4 font-custom font-bold text-base text-black_primary leading-6">
            <StatusActionButtons
              value={record}
              onClickHandler={actionButtonClickHandler}
              options={["approve", "block"]}
            />
          </div>
        );
      },
    },
    {
      title: () => (
        <div className=" flex justify-end  font-custom font-bold text-base text-black_primary leading-6 ">
          Actions
        </div>
      ),
      key: "actions",
      width: "15%",
      render: (_: any, record: Records) => {
        return (
          <div className=" flex justify-end mr-2">
            <Button
              format={{
                content: "",
                icon: "delete",
                type: "iconButton",
                onclick: () => deleteRecord(record),
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="block sm:hidden ">
        <MobileActionAntdTable
          data={data}
          actionButtonClickHandler={actionButtonClickHandler}
          deleteOptionVisible={true}
          deleteButtonClickHandler={deleteRecord}
        />
      </div>
      <div className="w-full sm:flex hidden  justify-center px-10 ">
        <ExpandableTable
          errorInRowKey={errorInRowKey}
          data={data}
          columns={columns}
          pagination={false}
        />
      </div>
    </>
  );
}

export default TableTwo;
