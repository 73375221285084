import { Form, Formik } from "formik";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../../../features/auth/authSlice";
import useError from "../../../../hooks/useError";
import { update } from "../../../../logic/util/hooks";
import Button from "../../Button";
import StaticAlert from "../../atoms/StaticAlert";
import Field from "../Field";
import {
  editProfileValidation,
  initialEditProfile,
} from "./schema.EditProfile";
import { unMountDialog } from "../../../../features/util/utilSlice";

interface MyToken {
  name: string;
  exp: number;
}
interface Values {
  first: string;
  last: string;
}

function EditProfileForm() {
  const { user } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const token = localStorage.getItem("jwtToken") || "";
  const { error, mountError } = useError();
  const [disableEditProfile, setDisableEditProfile] = useState<boolean>(false);
  const isTokenValid = () => {
    const decodedToken = jwtDecode<MyToken>(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    if (!isTokenValid()) {
      mountError({
        response: {
          data: {
            errorMessage: "Something went wrong! please sign in again...",
          },
        },
      });
      setDisableEditProfile(true);
    }
  }, []);

  const onSubmitHandler = async (values: Values) => {
    try {
      if (!isTokenValid()) {
        mountError({
          response: { data: { errorMessage: "Password reset link expired!" } },
        });
        setDisableEditProfile(true);
      } else {
        const { data } = await update("accounts", `${user.id}`, {
          first: values.first,
          last: values.last,
        });
        localStorage.setItem("userData", JSON.stringify(data));
        dispatch(updateUserData(data));
        dispatch(unMountDialog());
      }
    } catch (error) {
      console.log("error", error);
      mountError(error);
    }
  };

  return (
    <Formik
      initialValues={initialEditProfile(user)}
      validationSchema={editProfileValidation}
      enableReinitialize
      onSubmit={onSubmitHandler}
    >
      {(props) => {
        return (
          <Form className="flex flex-col max-w-md w-full">
            {error && <StaticAlert type="error" message={error} />}
            <div className={`flex flex-col gap-6`}>
              <Field
                name="first"
                key="first"
                label="First name"
                placeholder=""
                subLabel=""
              />
              <Field
                name="last"
                key="last"
                label="Last name"
                placeholder=""
                subLabel=""
              />
            </div>
            <div className="flex flex-row xs:flex-col xs:mt-6 gap-2 xs:gap-0 items-center justify-start xs:w-full">
              <div className="xs:w-full">
                <Button
                  format={{
                    type: "submit",
                    id: "saveChanges",
                    isFilled: true,
                    content: "Save changes",
                    isLoading: props.isSubmitting,
                  }}
                  isDisabled={disableEditProfile}
                />
              </div>
              <div className="xs:w-full xs:-mt-3">
                <Button
                  format={{
                    type: "primary",
                    id: "resetPassword",
                    isFilled: false,
                    content: "Cancel",
                    onclick: () => dispatch(unMountDialog()),
                  }}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default EditProfileForm;
