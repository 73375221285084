import React from "react";
import { NavLink, Link } from "react-router-dom";
import bpi from "../../../assets/graphics/bpiLogo.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

function BpiAppFooter() {
  return (
    <div>
      <footer className="mx-auto mt-32 w-full max-w-container px-4 sm:px-6 lg:px-8">
        <div className="border-t border-slate-900/5 py-10">
          <Link to={"/Requests"}>
            <span className="flex flex-row justify-center items-center">
              <LazyLoadImage src={bpi} alt="bullet proof inbox logo" />
              <p className="text-white100 pl-3">Bulletproof Inbox</p>
            </span>
          </Link>
          <p className="mt-5 text-center text-sm leading-6 text-white100">
            © 2022 Bulletproof Technologies, Inc. All rights reserved.
          </p>
          <div className="mt-16 flex items-center justify-center space-x-4 text-sm font-semibold leading-6 text-white100">
            <NavLink to="/privacy-policy">Privacy policy</NavLink>
            <div className="h-4 w-px bg-slate-500/20"></div>
            <NavLink to="/terms-of-service">Terms of service</NavLink>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default BpiAppFooter;
