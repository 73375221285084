import * as Yup from "yup";

export const addContactValidation = Yup.object().shape({
    email: Yup.string()
        .email("Error: Enter a valid email address, for example mike@gmail.com")
        .required("Required"),
});

export const initialAddContact = {
    firstName: "",
    lastName: "",
    email: "",
};
