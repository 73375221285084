import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Button from "../Button";
import { unMountDialog } from "../../../features/util/utilSlice";

interface FreeTrialEndedModelProps {
  paymentStatusObject: Record<string, unknown>;
  reducerState: {
    showCardAboutToExpire: boolean;
  };
}

function FreeTrialEndedModel({
  paymentStatusObject,
  reducerState,
}: FreeTrialEndedModelProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { welcomeBack, trial_days, bill_status } = Object(paymentStatusObject);
  const {
    // showTrialRunning,
    // showTrialRunningPaymentDone,
    // showTrialExpired,
    // showEnterPaymentInfoButton,
    // showCardExpired,
    showCardAboutToExpire,
    // showUpdatePaymentInfo,
    // showReconnectText,
    // isWhiteListedFlag
  } = reducerState;
  return (
    <div className="mb-6">
      <div className="flex justify-center flex-row  items-center gap-x-3 w-full xs:items-start content-center">
        {/* <p className={showTrialExpired ? "font-custom text-base text-black_primary " : "hidden"}>Please enter your payment info to continue using Bulletproof Inbox.</p>
                <p className={showCardExpired ? "font-custom  text-gray1 text-base" : "hidden"}>Please enter a new payment method to continue using Bulletproof Inbox.</p> */}
        <p
          className={
            showCardAboutToExpire
              ? "font-custom  text-gray1 text-base "
              : "hidden"
          }
        >
          Please enter a new payment method to continue using Bulletproof Inbox.
        </p>
      </div>
      <div className="flex justify-center pt-4">
        <Button
          format={{
            type: "primary",
            isFilled: true,
            content: "Update Payment Info",
            width: "w-48",
            onclick: () => {
              navigate("/app/payment");
              dispatch(unMountDialog());
            },
          }}
        />
      </div>
    </div>
  );
}

export default FreeTrialEndedModel;
