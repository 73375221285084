import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";

import PublicNav from "../landingPage/components/publicNav";
import Label from "../../primitives/Label";
import { mountDialog } from "../../../features/util/utilSlice";
import LearnAboutSurveys from "../../primitives/dialogContent/LearnAboutSurveys";
import Loader from "../../primitives/Loader/Loader";
import { fetcher, post, update } from "../../../logic/util/hooks";
import Button from "../../primitives/Button";
import { icons } from "../../assets/icons";
import toast from "react-hot-toast";
import mixpanel from "mixpanel-browser";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface PlatformData {
  ACCOUNT_REF: string;
  id: string;
  eval_paused: boolean;
  has_send_survey_enabled: boolean;
  is_domain_review_complete: boolean;
  is_folder_settings_set: boolean;
  is_request_form_settings_set: boolean;
  is_video_step_complete: boolean;
  email: string;
  platformID: string;
  platform: string;
}

const RequestFormSettings = ({
  setRefetch,
}: {
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  // request form settings has platform id as a url parameter, so that each platform has its own settings page
  const { id } = useParams();
  const { state: navState } = useLocation();
  const { user } = useSelector((state: any) => state.auth);

  const [platformData, setPlatformData] = useState<PlatformData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isRequestFormEnabled, setIsRequestFormEnabled] =
    useState<boolean>(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const surveyDialog = () => {
    dispatch(
      mountDialog({
        component: <LearnAboutSurveys />,
        headline: `Learn about request forms`,
        close: true,
      }),
    );
  };

  const handleSavePreference = (isEnabled: boolean) => {
    setIsRequestFormEnabled(isEnabled);
    post(
      "platforms",
      `account/${platformData?.ACCOUNT_REF}/platform/${platformData?.id}/updateSetting`,
      {
        platformSettings: isEnabled
          ? {
              has_send_survey_enabled: isEnabled,
              eval_paused: false,
            }
          : {
              has_send_survey_enabled: isEnabled,
            },
      },
    )
      .then(() => {
        update("platforms", `${user?.id}/platform/${platformData?.id}`, {
          is_request_form_settings_set: true,
        })
          .then(() => {
            console.log("post: success");
            setRefetch(true);
            toast.success("Settings saved");
          })
          .catch((err) => {
            console.log(err);
            toast.error("Please try again");
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useLayoutEffect(() => {
    setIsLoading(true);
    fetcher(`/resources/platforms/${user?.id}`)
      .then((response) => {
        console.log("fetch: success");
        const data = response.filter(
          (platform: PlatformData) => platform.id === id,
        )[0];
        setPlatformData(data);
        setIsRequestFormEnabled(data?.has_send_survey_enabled);
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user, id]);

  // const bodyTitle = "Would you like to send request forms to unknown senders?";
  // const bodySubtitle = (
  //   <p className="text-center text-base text-gray1">
  //     Ask unknown senders why they want to contact you by sending a short
  //     request form
  //   </p>
  // );
  // const surveyLink = (
  //   <div
  //     className="text-green_primary cursor-pointer text-base underline mt-4 w-fit m-auto"
  //     onClick={() => surveyDialog()}
  //   >
  //     Learn about request forms
  //   </div>
  // );

  // if (isLoading) {
  //   return (
  //     <>
  //       <PublicNav
  //         title="Request form settings"
  //         secondaryText={platformData?.email}
  //         back={{
  //           text: `Back ${navState ? "to " + navState.prevPage : ""}`,
  //         }}
  //       />
  //       <div className="absolute h-screen w-screen flex justify-center items-center z-0">
  //         <Loader />
  //       </div>
  //     </>
  //   );
  // }

  // if (isError) {
  //   return (
  //     <>
  //       <p className="absolute h-screen w-screen flex justify-center items-center z-0">
  //         Something went wrong.
  //       </p>
  //     </>
  //   );
  // }

  return (
    <div className="flex flex-col">
      {/* <PublicNav
        title="Request form settings"
        secondaryText={platformData?.email}
        back={{
          text: `Back ${navState ? "to " + navState.prevPage : ""}`,
        }}
      /> */}
      <div className="w-auto flex flex-col gap-6 sm:gap-10 sm:flex-row items-center justify-between">
        <>
          <div className="flex flex-col shadow-nav items-start px-6 py-6 gap-6 rounded-md bg-white w-full max-w-400">
            <div className="flex flex-col gap-2 flex-1">
              <div className="bg-green_bg py-1 px-2 rounded w-min">
                <p className="text-green_primary text-xs">Recommended</p>
              </div>
              <h3 className="text-black_primary text-xl font-bold">
                Send a request form
              </h3>
              <p className="text-base text-gray1 md:pr-2">
                Send to an unknown sender the first time they email you.
              </p>
            </div>
            <button
              className={`${
                isRequestFormEnabled
                  ? "bg-green_primary text-white"
                  : "bg-white text-green_primary border border-green_primary"
              }  h-10 rounded-md w-full`}
              onClick={() => {
                mixpanel.track(`Selected: 'Send a request form'`, {
                  user_Id: user?.id,
                  user_Email: user?.email,
                  user_FirstName: user?.first,
                  user_LastName: user?.last,
                  user_Phone: user?.phone,
                  user_Tier: user?.tier,
                });
                handleSavePreference(true);
              }}
            >
              <div className="flex flex-row items-center justify-center gap-3">
                {isRequestFormEnabled && (
                  <>
                    <LazyLoadImage
                      src={icons.checkWhite}
                      alt="lock icon"
                      width={15}
                    />
                  </>
                )}
                {isRequestFormEnabled ? "Selected" : "Select"}
              </div>
            </button>
          </div>
          <div className="flex flex-col shadow-nav items-start px-6 py-6 gap-6 rounded-md bg-white w-full max-w-400">
            <div className="flex flex-col gap-2 flex-1">
              <div className="bg-white py-1 px-2 rounded w-min hidden md:block">
                <p className="text-white text-xs">Blank</p>
              </div>
              <h3 className="text-black_primary text-xl font-bold">
                Don't send a request form
              </h3>
              <p className="text-base text-gray1">
                Unknown senders won’t get a <br />
                request form.
              </p>
            </div>
            <button
              className={`${
                isRequestFormEnabled
                  ? "bg-white text-green_primary border border-green_primary"
                  : "bg-green_primary text-white"
              }  h-10 rounded-md w-full`}
              onClick={() => {
                mixpanel.track(`Selected: 'Don't send a request form'`, {
                  user_Id: user?.id,
                  user_Email: user?.email,
                  user_FirstName: user?.first,
                  user_LastName: user?.last,
                  user_Phone: user?.phone,
                  user_Tier: user?.tier,
                });
                handleSavePreference(false);
              }}
            >
              <div className="flex flex-row items-center justify-center gap-3">
                {!isRequestFormEnabled && (
                  <>
                    <LazyLoadImage
                      src={icons.checkWhite}
                      alt="lock icon"
                      width={15}
                    />
                  </>
                )}
                {isRequestFormEnabled ? "Select" : "Selected"}
              </div>
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

export default RequestFormSettings;
