import React from "react";

interface Props { color: string; }

const ArrowsCircleIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.09961 7.71392C2.09961 8.08211 2.39809 8.38059 2.76628 8.38059C3.12406 8.36987 3.40952 8.07852 3.41294 7.72059C3.44021 6.17524 4.35533 4.78388 5.7635 4.14679C7.17167 3.50969 8.82088 3.74087 9.99961 4.74059H9.78628C9.41809 4.74059 9.11961 5.03907 9.11961 5.40726C9.11961 5.77545 9.41809 6.07392 9.78628 6.07392H11.6729C12.0411 6.07392 12.3396 5.77545 12.3396 5.40726V3.52059C12.3396 3.1524 12.0411 2.85392 11.6729 2.85392C11.3048 2.85392 11.0063 3.1524 11.0063 3.52059V3.81392C9.44971 2.40885 7.21424 2.04629 5.29338 2.88739C3.37253 3.72848 2.12279 5.61712 2.09961 7.71392ZM11.8929 8.18726C11.8929 7.81907 12.1914 7.52059 12.5596 7.52059C12.9226 7.5242 13.216 7.81759 13.2196 8.18059C13.2419 9.61526 12.6786 10.9971 11.6596 12.0073C9.65105 14.0057 6.43046 14.0847 4.32628 12.1873V12.4806C4.32628 12.8488 4.0278 13.1473 3.65961 13.1473C3.29142 13.1473 2.99294 12.8488 2.99294 12.4806V10.5939C2.99294 10.2257 3.29142 9.92726 3.65961 9.92726H5.56628C5.93447 9.92726 6.23294 10.2257 6.23294 10.5939C6.23294 10.9621 5.93447 11.2606 5.56628 11.2606H5.33294C6.52536 12.2538 8.18463 12.4677 9.58992 11.8093C10.9952 11.1509 11.8929 9.73912 11.8929 8.18726Z" fill={color}/>
    </svg>
  );
};

export default ArrowsCircleIcon;
