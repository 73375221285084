import React, { ReactNode } from "react";
import Spinner from "./atoms/spinner";
import iconSet from "./iconSet";
import FallingDots from "./atoms/fallingDots";
import StatusBtn from "./StatusBtn";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface ButtonProps {
  format?: {
    id?: string;
    type?:
      | "primary"
      | "main"
      | "submit"
      | "signup"
      | "Cancel"
      | "delete"
      | "table"
      | "iconButton"
      | "statusBtn"
      | "noBg";
    icon?: string | null | boolean | ReactNode;
    isFilled?: boolean;
    content?: string;
    onclick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    link?: string | null;
    isLoading?: boolean;
    width?: string | null;
    color?: string | null;
    isSelected?: boolean | null;
    disabled?: boolean;
  };
  isDisabled?: boolean;
}

function Button({
  format = {
    id: "empty_BTN",
    type: "primary",
    icon: null,
    isFilled: true,
    content: "empty button",
    onclick: (event) => {},
    link: null,
    isLoading: false,
    width: null,
    color: null,
    isSelected: null,
  },
  isDisabled,
}: ButtonProps) {
  let {
    id,
    type = "primary",
    icon,
    isFilled,
    content,
    onclick,
    link = null,
    isLoading,
    width,
    color,
    isSelected,
  } = format;
  const filledStyle = `${width ?? "w-40"} px-5 xs:w-full h-11 ${
    !isDisabled ? `bg-green_primary` : "bg-gray_light"
  } text-white font-custom font-bold text-base rounded-3xl text-sm flex items-center justify-center`;
  const outlinedStyle = `${
    width ?? "w-40"
  } px-5 xs:w-full h-11 bg-transparent text-green_primary  font-custom font-bold text-base transition-colors duration-150 hover:bg-green_primary font-semibold border border-green_primary hover:border-transparent hover:text-white rounded-3xl text-sm flex items-center justify-center`;

  if (type === "primary") {
    return (
      <a
        href={link as string}
        style={{ margin: "10px 5px" }}
        className={`${width} `}
      >
        {isDisabled ? (
          <button
            id={`${id}_BTN`}
            onClick={onclick}
            className={`${
              isFilled ? filledStyle : outlinedStyle
            } cursor-not-allowed font-custom `}
            disabled={true}
          >
            {isLoading && (
              <div className="flex">
                <FallingDots />
              </div>
            )}
            {!isLoading && icon ? (
              <LazyLoadImage
                src={iconSet[icon as keyof typeof iconSet]}
                alt={`${icon} icon`}
              />
            ) : (
              ""
            )}
            {!isLoading && content}
          </button>
        ) : (
          <button
            id={`${id}_BTN`}
            onClick={onclick}
            className={isFilled ? filledStyle : outlinedStyle}
            disabled={isLoading}
          >
            {isLoading && (
              <div className="flex">
                <FallingDots />
              </div>
            )}
            {!isLoading && icon
              ? icon
              : // <img src={iconSet[icon as keyof typeof iconSet]} alt={`${icon} icon`} />
                ""}
            {!isLoading && content}
          </button>
        )}
      </a>
    );
  }

  if (type === "main") {
    return (
      <a href={link as string} style={{ margin: "30px 5px" }}>
        <button
          id={`${id}_BTN`}
          onClick={onclick}
          className={isFilled ? filledStyle : outlinedStyle}
          disabled={isLoading}
        >
          {isLoading && <FallingDots />}
          {!isLoading && icon ? (
            <LazyLoadImage
              src={iconSet[icon as keyof typeof iconSet]}
              alt={`${icon} icon`}
            />
          ) : (
            ""
          )}
          {!isLoading && content}
        </button>
      </a>
    );
  }

  if (type === "submit") {
    return (
      <button
        id={`${id}_BTN`}
        onClick={onclick}
        className={`${isFilled ? filledStyle : outlinedStyle} ${
          isDisabled && "cursor-not-allowed text-red80"
        }`}
        disabled={isLoading || isDisabled}
        type={type}
      >
        {isLoading && (
          <div className="flex">
            <FallingDots />
          </div>
        )}
        {!isLoading && icon ? (
          <LazyLoadImage
            src={iconSet[icon as keyof typeof iconSet]}
            alt={`${icon} icon`}
          />
        ) : (
          ""
        )}
        {!isLoading && content}
      </button>
    );
  }
  if (type === "signup") {
    return (
      <button
        id={`${id}_BTN`}
        onClick={onclick}
        className={`${
          width ?? "w-40"
        } px-6 py-4 xs:w-full bg-transparent text-black_primary text-sm font-custom font-normal transition-colors duration-150 border border-gray3 hover:border-gray2 rounded-full flex items-center gap-x-3 sm:mt-4 ${
          isDisabled && "cursor-not-allowed text-red80"
        }`}
        disabled={isLoading || isDisabled}
        type={type as "submit"}
      >
        {isLoading && (
          <div className="flex">
            <FallingDots />
          </div>
        )}
        {!isLoading && icon ? (
          <LazyLoadImage
            src={iconSet[icon as keyof typeof iconSet]}
            alt={`${icon} icon`}
            width={18}
          />
        ) : (
          ""
        )}
        {!isLoading && content}
      </button>
    );
  }
  if (type === "Cancel") {
    return (
      <button
        id={`${id}_BTN`}
        onClick={onclick}
        className={isFilled ? filledStyle : outlinedStyle}
        disabled={isLoading}
      >
        {content ? (
          <p className={`text-green_primary font-semibold mr-4 outline-none`}>
            {" "}
            {content}{" "}
          </p>
        ) : (
          ""
        )}
      </button>
    );
  }

  if (type === "delete") {
    return (
      <a href={link as string}>
        <button
          id={`${id}_BTN`}
          onClick={onclick}
          className={
            isFilled
              ? `${
                  width ?? "w-40"
                } whitespace-nowrap xs:w-full h-11 bg-red_error text-white font-bold text-base px-5 py-2 rounded-3xl text-sm flex items-center justify-center`
              : `xs:w-full ${
                  width ?? "w-40"
                } h-11 bg-transparent text-red_error font-bold text-base px-5 py-2 transition-colors duration-150 hover:bg-red_error font-semibold border border-red_error hover:border-transparent hover:text-white rounded-3xl text-sm flex items-center justify-center`
          }
          disabled={isLoading}
        >
          {isLoading && (
            <div className="flex">
              <FallingDots />
            </div>
          )}
          {!isLoading && icon ? (
            <LazyLoadImage
              src={iconSet[icon as keyof typeof iconSet]}
              alt={`${icon} icon`}
            />
          ) : (
            ""
          )}
          {!isLoading && content}
        </button>
      </a>
    );
  }
  if (type === "table") {
    let buttonBorderColor;
    let buttonTextColor;
    let buttonBgColor;
    if (color) {
      let redHighlight = color == "red";
      buttonBorderColor = redHighlight
        ? "border-red_error"
        : "border-green_primary";
      buttonTextColor = redHighlight ? "text-red_error" : "text-green_primary";
      buttonBgColor = redHighlight ? "bg-red_error" : "bg-green_primary";
    }
    return (
      <a href={link as string}>
        {isLoading ? (
          <Spinner />
        ) : (
          <button
            id={`${id}_BTN`}
            onClick={onclick}
            className={
              isFilled
                ? `${
                    isSelected ? "text-white" : buttonTextColor
                  } text-sm font-semibold inline hover:text-white lg:w-fit w-full h-10 border ${buttonBorderColor} hover:${buttonBgColor} ${
                    isSelected ? buttonBgColor : ""
                  } px-3 py-2 rounded`
                : `w-full ${
                    width ?? "lg:w-fit"
                  } h-11 bg-transparent ${buttonTextColor} font-bold text-base px-3 py-2 transition-colors duration-150 hover:${buttonBgColor} font-semibold border ${buttonBorderColor} hover:border-transparent hover:text-white rounded text-sm flex items-center justify-center`
            }
            disabled={isLoading}
          >
            {icon ? (
              <LazyLoadImage
                src={iconSet[icon as keyof typeof iconSet]}
                alt={`${icon} icon`}
              />
            ) : (
              ""
            )}
            <p>{content}</p>
          </button>
        )}
      </a>
    );
  }
  if (type === "iconButton") {
    return (
      <a href={link as string}>
        {isLoading ? (
          <Spinner />
        ) : (
          <button
            id={`${id}_BTN`}
            onClick={onclick}
            className={`px-5 py-2 cursor-pointer`}
            disabled={isLoading}
          >
            {icon ? (
              <LazyLoadImage
                src={iconSet[icon as keyof typeof iconSet]}
                alt={`${icon} icon`}
              />
            ) : (
              ""
            )}
          </button>
        )}
      </a>
    );
  }
  if (type === "statusBtn") {
    return (
      <a href={link as string}>
        <StatusBtn />
      </a>
    );
  }
  return null;
}

export default Button;
