import React from "react";
import { useNavigate } from "react-router";
import ArrowLeft from "../../../assets/icons/arrow_left";
interface PublicNavProps {
  title?: null | string;
  back?: {
    text: string;
    to?: string | null;
  };
  secondaryText?: string;
}

export default function PublicNav({
  title = null,
  back = { text: "Back", to: null},
  secondaryText,
}: PublicNavProps) {
  const {
    location: { pathname },
  } = window;
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`${
          pathname === "/" && "invisible"
        } grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 content-center w-screen h-18 isolate font-custom shadow-nav m-auto bg-white absolute z-20`}
      >
        {back !== null && (
          <div
            onClick={() => {
              if (back.to) {
                navigate(back.to);
              } else {
                window.history.back();
              }
            }}
            className="text-green_primary cursor-pointer text-base font-semibold flex items-center underline"
          >
            <div className="pl-5">
              <ArrowLeft color="#36854C" />
            </div>
            <p className="pl-1 invisible sm:visible w-0 sm:w-auto whitespace-nowrap">
              {back.text}
            </p>
          </div>
        )}
        <div className="flex flex-col justify-self-center sm:items-start sm:pr-5 sm:justify-self-end md:pr-0 md:items-center md:justify-self-center">
          <p
            className={`text-2xl sm:text-3xl whitespace-nowrap font-bold text-center`}
          >
            {title}
          </p>
          {secondaryText ? <p className="w-full text-center text-xs text-gray1 font-normal lg:self-end lg:pb-1">
            {secondaryText}
          </p> :
          <p className="w-full text-center text-xs invisible lg:pb-1">
            placeholder
          </p>}
        </div>
      </div>
      <div className="bg-white z-10 absolute h-16 w-full" />
    </>
  );
}
