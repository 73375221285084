import * as Yup from "yup";

export const personalize = Yup.object().shape({
	first: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	last: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	phone: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.notRequired(),
	referral_email: Yup.string().notRequired()
});

export const initialPersonalize = {
  first: "",
  last: "",
  referral_email: "",
  phone: "",
  user_id:""
};
