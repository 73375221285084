import React from "react";
import Button from "../Button";
import Label from "../Label";
import cross from "./../../assets/icons/cross.svg";

interface AccountCreationSuccessModalProps {
  onSubmit: () => void;
  onClose: () => void;
}

const AccountCreationSuccessModal: React.FC<
  AccountCreationSuccessModalProps
> = ({ onSubmit, onClose }) => {
  return (
    <div
      className=" place-items-center max-w-md h-full relative mb-4"
      id="Account-created"
    >
      <div>
        <div>
          <Label
            format={{
              text: "You’ve successfully created a Bulletproof Inbox account and begun your 10 day free trial. You now have full access to all the benefits of Bulletproof Inbox.",
              color: "text-gray1",
              fontSize: "text-lg",
            }}
          />

          <Label
            format={{
              text: "What’s next?",
              color: "text-green_primary",
              fontSize: "text-base",
              fontWeight: "font-bold",
              type: "secondary",
              margintop: "mt-5",
            }}
          />
          <div>
            <div className="flex ml-5 gap-2">
              <div className="rounded-full bg-gray1 w-2 h-1 mt-3 xs:w-3"></div>
              <Label
                format={{
                  text: "You’ll need to connect one or more of your email accounts to Bulletproof Inbox. Click “Connect an email account” to start.",
                  color: "text-gray1",
                  fontSize: "text-lg",
                  type: "secondary",
                }}
              />
            </div>
            <div className="flex ml-5 gap-2 mb-3">
              <div className="rounded-full bg-gray1 w-2 h-1 mt-3 xs:w-4"></div>
              <Label
                format={{
                  text: "When your free trial ends you’ll need to provide a payment method to continue. We’ll let you know when you’re nearing the end of your trial.",
                  color: "text-gray1",
                  fontSize: "text-lg",
                  type: "secondary",
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={`flex justify-start xs:justify-center gap-0 flex-row xs:flex-col`}
        >
          <Button
            format={{
              isFilled: true,
              content: "Connect an email address",
              onclick: onSubmit,
              width: "w-64 xs:w-full",
            }}
          />
          <Button
            format={{
              isFilled: false,
              content: "Skip for now",
              onclick: onClose,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountCreationSuccessModal;
