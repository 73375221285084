import React from "react";
import { useNavigate } from "react-router";
import Button from "./Button";

function ExpiryMessageTrialExpired() {
    const navigate = useNavigate();
    return (
        <div className="w-full flex flex-col justify-center items-center">
            <p className="text-2xl font-bold text-black_primary font-custom">Your free trial has ended.</p>
            <div className='mt-6 mx-6 xs:mx-12 sm:mx-0 text-center sm:text-left'>
                <p className="text-lg font-normal text-gray1 font-custom">Please enter your payment info to continue using Bulletproof Inbox.</p>
            </div>
            <div className='mt-2'>
                <Button
                    format={{
                        type: "primary",
                        isFilled: true,
                        content: "Enter Payment Info",
                        width: "w-48",
                        onclick: () => { navigate('/app/subscription') }
                    }}
                />
            </div>
        </div>
    )
}

export default ExpiryMessageTrialExpired;
