import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compareValues } from "../../../../logic/util/helpers";
import { fetcher, update } from "../../../../logic/util/hooks";
import PlusIcon from "../../../assets/icons/plus_lg";
import Button from "../../../primitives/Button";
import ExpandableTable from "../../../primitives/atoms/GenericAntdTable/GenericAntdTable";
import MobileActionAntdTable from "../../../primitives/atoms/MobileActionAntdTable/MobileActionAntdTable";
import NoData from "../../../primitives/atoms/noData";
import DeleteContact from "../../../primitives/dialogContent/deleteContact";
import SearchField from "../../../primitives/forms/SearchField";
import AddContactForm from "../../../primitives/forms/approvedSenderList/AddContactForm";
import { StatusActionButtons } from "../../ComponentLibrary/components/StatusActionButtons";
import { mountDialog } from "../../../../features/util/utilSlice";

interface Contact {
  key: string;
  name: string;
  email: string;
  phone: string;
  reason: string;
  relationship: string;
  contactID: string;
  platform_ref: string;
  option?: any;
  status?: string;
  domain?: string;
}

interface Data {
  id: string;
  ACCOUNT_REF: string;
  PLATFORM_REF: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  status: string;
  survey_sent: boolean;
}

const cleanupData = (data: Data[] = []) => {
  const dataNoBlanks = data.filter(
    (contact) => contact.email && contact.email.length !== 0,
  );
  const dataNoQuotes = dataNoBlanks.map((contact) => {
    if (
      contact.email &&
      contact.email.startsWith('"') &&
      contact.email.endsWith('"')
    ) {
      return { ...contact, email: contact.email.slice(1, -1) };
    } else {
      return contact;
    }
  });
  const uniqueData: { [key: string]: boolean } = {};
  const dataDeduped = dataNoQuotes.filter((contact) => {
    if (contact.email && !uniqueData[contact.email]) {
      uniqueData[contact.email] = true;
      return true;
    }
    return false;
  });
  return dataDeduped;
};

export default function Contacts() {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [search, setSearch] = useState<{ value: string }>({ value: "" });
  const [data, setData] = useState<any[] | null>(null);
  // console.log({data});
  const { user } = useSelector((state: any) => state.auth);
  const { toggle } = useSelector((state: any) => state.util);
  const dispatch = useDispatch();

  const [errorInRowKey, seterrorInRowKey] = useState<string>("");
  useEffect(() => {
    refetchContacts();
  }, []);

  const refetchContacts = () => {
    fetcher(`/resources/contacts/${user?.id}`)
      .then((res) => {
        if (res) {
          console.log({ res });
          const modified = res.filter(
            (item: any) => !item?.email?.includes("bulletproofinbox"),
          );
          setData(cleanupData(modified));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addContactDialog = () =>
    // setRequestSource("manually added");
    dispatch(
      mountDialog({
        component: <AddContactForm refetchContacts={refetchContacts} />,
        headline: "Add a new sender",
      }),
    );
  const deleteContactDialog = (id: string) =>
    dispatch(
      mountDialog({
        component: (
          <DeleteContact contactId={id} refetchContacts={refetchContacts} />
        ),
        headline: "Are you sure you want to delete this sender?",
      }),
    );

  function properName(first: string, last: string) {
    if (first && last) return `${first} ${last}`;
    if (first && !last) return `${first}`;
    if (!first && last) return `${last}`;
    if (!first && !last) return "--";
  }
  function replaceQuotes(text = "") {
    if (
      text.charAt(0) &&
      text.charAt(0) === '"' &&
      text.charAt(text.length - 1) === '"'
    ) {
      return text.slice(1, -1);
    }
    return text;
  }
  useEffect(() => {
    if (data) {
      const seralizeData = data.reduce((structure, data) => {
        if (search.value) {
          if (
            compareValues(data.firstName, search.value) ||
            compareValues(data.lastName, search.value) ||
            compareValues(data.email, search.value)
          ) {
            structure.push({
              key: data.id,
              name: replaceQuotes(properName(data.firstName, data.lastName)),
              email: data.email,
              status: data.status,
            });
          }
        } else {
          structure.push({
            key: data.id,
            name: replaceQuotes(properName(data.firstName, data.lastName)),
            email: data.email,
            status: data.status,
          });
        }

        return structure;
      }, []);
      setContacts(seralizeData);
    }
  }, [data, search]);

  const actionButtonClickHandler = async (value: Contact, option: string) => {
    // updating contact for immediate visual effect
    setContacts((data) =>
      data.map((data) => {
        if (data.key === value.key) {
          return { ...data, status: option };
        } else {
          return data;
        }
      }),
    );
    // API call to set status
    await update("contacts", `updateContact/${user?.id}/${value.key}`, {
      status: option,
      requestFrom: "approved sender list"
    });
  };

  const deleteRecord = (record: Contact) => {
    deleteContactDialog(record.key);
    // seterrorInRowKey(record.key);
  };

  const columns = [
    {
      title: () => <p>Name</p>,
      width: "30%",
      dataIndex: "name",
      key: "name",
      render: (text: string, key: any) => (
        <a className={key.parentKey ? `ml-4 p-1` : "font-normal ml-2"}>
          {text}
        </a>
      ),
    },
    {
      title: () => (
        <div style={{ flexDirection: "row", display: "flex" }}>
          <p className="pr-4">Email</p> <p className="mt-1"></p>
        </div>
      ),
      width: "30%",
      dataIndex: "email",
      key: "email",
      render: (text: string, key: any) => (
        <a className={key.parentKey ? `ml-1` : ""}>{text}</a>
      ),
    },
    {
      title: () => <p>Status</p>,
      key: "status",
      width: "25%",
      render: (_: any, record: Contact) => {
        return (
          <div className="py-4">
            <StatusActionButtons
              value={record}
              onClickHandler={actionButtonClickHandler}
              options={["approved", "blocked"]}
            />
          </div>
        );
      },
    },
    {
      title: () => <p>Actions</p>,
      key: "actions",
      width: "15%",
      render: (_: any, record: Contact) => {
        return (
          <div>
            <Button
              format={{
                content: "",
                icon: "delete",
                type: "iconButton",
                onclick: () => deleteRecord(record),
              }}
            />
          </div>
        );
      },
    },
  ];

  if (data && contacts) {
    if (!toggle) {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="h-full"
        >
          <div>
            <div className="flex justify-end gap-8 items-center sm:float-right sm:-mt-18 xs:mt-4 w-1/2 xs:invisible xs:h-0 xs:w-0">
              <SearchField
                id="contactSearch"
                name="value"
                placeholder="Search senders"
                state={search}
                setState={setSearch}
              />
              <Button
                format={{
                  id: "NewContactButton",
                  type: "primary",
                  icon: <PlusIcon color="#36854C" />,
                  content: "Add sender",
                  isFilled: false,
                  onclick: addContactDialog,
                }}
              />
            </div>
            <div className="flex justify-center items-center mt-4 w-full sm:invisible sm:w-0 sm:h-0">
              <SearchField
                id="contactSearch"
                name="value"
                placeholder="Search senders"
                state={search}
                setState={setSearch}
              />
              <div className="bottom-0 w-full items-center justify-center flex flex-col fixed bg-white">
                <div className="w-full h-px bg-gray_Xlight shadow "></div>
                <Button
                  format={{
                    id: "NewContactButton",
                    type: "primary",
                    icon: <PlusIcon color="#36854C" />,
                    content: "Add sender",
                    isFilled: false,
                    onclick: addContactDialog,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-grow overflow-y-auto justify-center w-full items-center mt-2 pb-12 xs:pb-28 h-full text-black_primary">
            {data.length > 0 && contacts.length > 0 ? (
              <>
                <div className="block sm:hidden w-full ">
                  <MobileActionAntdTable
                    data={contacts}
                    actionButtonClickHandler={actionButtonClickHandler}
                    deleteOptionVisible={true}
                    deleteButtonClickHandler={deleteRecord}
                  />
                </div>
                <div className="w-full sm:flex hidden  justify-center ">
                  <ExpandableTable
                    errorInRowKey={errorInRowKey}
                    data={contacts}
                    columns={columns}
                    pagination={false}
                  />
                </div>
              </>
            ) : (
              <NoData
                title="No senders"
                description="Try adding a sender for someone whose emails you want to block."
                button={
                  <Button
                    format={{
                      content: "Add sender",
                      isFilled: true,
                      type: "primary",
                      icon: <PlusIcon color="white" />,
                      onclick: addContactDialog,
                    }}
                  />
                }
              />
            )}
          </div>
          <div className="flex flex-col z-0 fixed bottom-0 justify-center xs:mb-0 items-center w-screen bg-white xs:-mx-6 sm:invisible sm:h-0 sm:w-0 xs:hidden">
            {/* horizontal line */}
            <div className={`bg-gray3 h-px xs:w-full`} />
            <Button
              format={{
                id: "NewContactButtonMobileView",
                type: "primary",
                icon: <PlusIcon color="#36854C" />,
                content: "Add sender",
                isFilled: false,
                onclick: addContactDialog,
              }}
            />
          </div>
        </motion.div>
      );
    }
    return <div></div>;
  }

  return <div></div>;
}
