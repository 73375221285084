import React from 'react';

interface Props { color?: string; }

const ListIcon: React.FC<Props> = ({color="#181920"}) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.30615 3.41016C3.75285 3.41016 2.49365 4.66936 2.49365 6.22266V18.5977C2.49365 20.151 3.75285 21.4102 5.30615 21.4102H18.6937C20.247 21.4102 21.5062 20.151 21.5062 18.5977V6.22266C21.5062 4.66936 20.247 3.41016 18.6937 3.41016H5.30615ZM5.27612 5.44266C4.86191 5.44266 4.52612 5.77844 4.52612 6.19266V18.6614C4.52612 19.0756 4.86191 19.4114 5.27612 19.4114H18.7761C19.1903 19.4114 19.5261 19.0756 19.5261 18.6614V6.19266C19.5261 5.77844 19.1903 5.44266 18.7761 5.44266H5.27612Z" fill={color}/>
        <path d="M16.4949 9.41113H7.49487C6.94259 9.41113 6.49487 8.96342 6.49487 8.41113C6.49487 7.85885 6.94259 7.41113 7.49487 7.41113H16.4949C17.0472 7.41113 17.4949 7.85885 17.4949 8.41113C17.4949 8.96342 17.0472 9.41113 16.4949 9.41113Z" fill={color}/>
    </svg>
    )
  }
export default ListIcon;