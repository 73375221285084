import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { del } from "../../../logic/util/hooks";
import Button from "../Button";
import { unMountDialog } from "../../../features/util/utilSlice";

interface DeleteDomainProps {
  domainId?: string;
  refetchContacts: Function;
}

const DeleteDomain: React.FC<DeleteDomainProps> = ({
  domainId,
  refetchContacts,
}) => {
  const { user } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      <p className="text-gray1">
        Deleting this domain will treat emails from any one at this company as
        if they are a new sender. If they email you, we’ll send a survey to them
        asking why they want to contact you (If you have the survey feature
        enabled).
      </p>
      <div
        className={`flex justify-end items-center sm:gap-2 xs:flex-col flex-row`}
      >
        <div className={`xs:w-full xs:mt-6`}>
          <Button
            format={{
              id: "DeleteDomainButton",
              type: "delete",
              content: "Yes, delete this domain",
              isFilled: true,
              isLoading,
              width: !isLoading ? "w-fit" : "w-40",
              onclick: () => {
                setIsLoading(true);
                del("domains", `account/${user?.id}/domain/${domainId}`)
                  .then(() => {
                    setIsLoading(false);
                    dispatch(unMountDialog());
                    refetchContacts();
                  })
                  .catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                  });
              },
            }}
          />
        </div>
        <div className={`xs:w-full xs:-mt-3`}>
          <Button
            format={{
              id: "cancelButton",
              type: "primary",
              isFilled: false,
              content: "Cancel",
              onclick: () => dispatch(unMountDialog()),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DeleteDomain;
