import React from "react";
import Button from "../../Button";

interface Record {
  key: string;
  name: string;
  email: string;
  phone: string;
  reason: string;
  relationship: string;
  contactID: string;
  platform_ref: string;
  option?: any;
  status?: string;
  domain?: string;
}

interface MobileActionAntdTableProps {
  data: Record[] | null;
  deleteOptionVisible?: boolean;
  deleteButtonClickHandler: (record: Record) => void;
  actionButtonClickHandler: (record: Record, action: string) => void;
}

function MobileActionAntdTable({
  data,
  deleteButtonClickHandler,
  actionButtonClickHandler,
}: MobileActionAntdTableProps) {
  return (
    <>
      {data?.map((record) => {
        const status = record?.status
          ? record?.status?.toLowerCase()
          : "unknown";

        return (
          <div className="mt-8">
            {/** main content of the table */}
            <div className="flex flex-col">
              <div className="flex flex-row w-full justify-between p-2\5">
                <div className="flex flex-col w-2/3 justify-between">
                  {/**Displaying data */}

                  <div>
                    <div className="font-bold font-custom break-all">
                      {record.name ?? record.domain}
                    </div>
                    <div className="text-gray1 font-custom break-all">
                      {record.email}
                    </div>
                  </div>
                  <div className="flex items-center  ">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.6663 1.33398H3.33301C2.22844 1.33398 1.33301 2.22941 1.33301 3.33398V4.66732C1.33301 5.03551 1.63148 5.33398 1.99967 5.33398H2.66634V12.6673C2.66634 13.7719 3.56177 14.6673 4.66634 14.6673H11.333C12.4376 14.6673 13.333 13.7719 13.333 12.6673V5.33398H13.9997C14.3679 5.33398 14.6663 5.03551 14.6663 4.66732V3.33398C14.6663 2.22941 13.7709 1.33398 12.6663 1.33398ZM11.9997 12.6673C11.9997 13.0355 11.7012 13.334 11.333 13.334H4.66634C4.29815 13.334 3.99967 13.0355 3.99967 12.6673V5.33398H11.9997V12.6673ZM2.66634 4.00065H13.333V3.33398C13.333 2.96579 13.0345 2.66732 12.6663 2.66732H3.33301C2.96482 2.66732 2.66634 2.96579 2.66634 3.33398V4.00065ZM5.33301 11.334V7.33398C5.33301 6.96579 5.63148 6.66732 5.99967 6.66732C6.36786 6.66732 6.66634 6.96579 6.66634 7.33398V11.334C6.66634 11.7022 6.36786 12.0007 5.99967 12.0007C5.63148 12.0007 5.33301 11.7022 5.33301 11.334ZM9.33301 7.33398V11.334C9.33301 11.7022 9.63148 12.0007 9.99967 12.0007C10.3679 12.0007 10.6663 11.7022 10.6663 11.334V7.33398C10.6663 6.96579 10.3679 6.66732 9.99967 6.66732C9.63148 6.66732 9.33301 6.96579 9.33301 7.33398Z"
                        fill="#36854C"
                      />
                    </svg>
                    <button
                      className="font-normal text-base text-green_primary border-b-px border-b-green_primary font-custom"
                      onClick={() => deleteButtonClickHandler(record)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
                {/** For the action buttons */}
                <div className="w-23">
                  <Button
                    format={{
                      id: "approveButton",
                      onclick: () =>
                        actionButtonClickHandler(record, "approved"),
                      type: "table",
                      content: "Approve",
                      color: "green",
                      isFilled: true,
                      isSelected: status == "approved",
                    }}
                  />
                  <div className="h-1"></div>
                  <Button
                    format={{
                      id: "blockButton",
                      onclick: () =>
                        actionButtonClickHandler(record, "blocked"),
                      type: "table",
                      content: "Block",
                      color: "red",
                      isFilled: true,
                      isSelected: status == "blocked",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default MobileActionAntdTable;
