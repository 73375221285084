import React from 'react';

interface Props { color : string}

const CrossCircleIcon: React.FC<Props> = ({color="#181920"}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19.0702 19.0702C15.1464 22.8599 8.90932 22.8057 5.05198 18.9484C1.19464 15.091 1.14044 8.85396 4.93017 4.93017C8.85396 1.14044 15.091 1.19464 18.9484 5.05198C22.8057 8.90932 22.8599 15.1464 19.0702 19.0702ZM16.2602 9.16017L13.4102 12.0002L16.2602 14.8402C16.4495 15.0279 16.556 15.2835 16.556 15.5502C16.556 15.8168 16.4495 16.0724 16.2602 16.2602C16.0714 16.4474 15.816 16.5517 15.5502 16.5502C15.2844 16.5517 15.0289 16.4474 14.8402 16.2602L12.0002 13.4102L9.16017 16.2602C8.97145 16.4474 8.71597 16.5517 8.45017 16.5502C8.18436 16.5517 7.92889 16.4474 7.74017 16.2602C7.55085 16.0724 7.44437 15.8168 7.44437 15.5502C7.44437 15.2835 7.55085 15.0279 7.74017 14.8402L10.5902 12.0002L7.74017 9.16017C7.48651 8.90651 7.38744 8.53679 7.48029 8.19029C7.57313 7.84378 7.84379 7.57313 8.19029 7.48029C8.53679 7.38744 8.90651 7.48651 9.16017 7.74017L12.0002 10.5902L14.8402 7.74017C15.0938 7.48651 15.4635 7.38744 15.81 7.48029C16.1565 7.57313 16.4272 7.84378 16.52 8.19029C16.6129 8.53679 16.5138 8.90651 16.2602 9.16017ZM6.34856 6.34436C9.47135 3.22041 14.5351 3.21853 17.6602 6.34017C19.1748 7.8341 20.0274 9.87276 20.0274 12.0002C20.0274 14.1276 19.1748 16.1662 17.6602 17.6602C14.5351 20.7818 9.47135 20.7799 6.34856 17.656C3.22576 14.532 3.22576 9.46832 6.34856 6.34436Z" fill={color}/>
    </svg>
    )
  }
export default CrossCircleIcon;