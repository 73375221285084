import React from "react";
import { StatusActionButtons } from "../../../views/ComponentLibrary/components/StatusActionButtons";
import Button from "../../Button";
import { ColumnType as AntColumnType, ColumnGroupType } from "antd/lib/table";

interface Record {
  key: string;
  name?: string;
  email: string;
  phone: string;
  reason: string;
  relationship: string;
  contactID: string;
  platform_ref: string;
  option?: any;
  status?: string;
  domain?: string;
  parentKey?: string;
  count?: number;
  subject: string;
}
interface SubDomainRecord {
  parentId?: string;
  child?: Record[];
}
interface DisplaySubDomainProps {
  record: Record;
  dataForChildTable: SubDomainRecord[] | null;
  actionButtonClickHandler: (record: Record, action: string) => void;
}

interface MobileSubdomainAntdTableProps {
  data: Record[] | null;
  columns?: (AntColumnType<any> | ColumnGroupType<any>)[];
  actionButtonClickHandler: (record: Record, action: string) => void;
  dataForChildTable: SubDomainRecord[] | null;
  deleteOptionVisible?: boolean;
}

function MobileSubdomainAntdTable({
  data,
  actionButtonClickHandler,
  dataForChildTable,
}: MobileSubdomainAntdTableProps) {
  return (
    <>
      {data?.map((record) => {
        const { status, subject, domain, count } = record;
        const statusLowerCased = status ? status?.toLowerCase() : "unknown";

        return (
          <div className="mt-10 w-full">
            {/** main content of the table */}
            <div className="flex flex-col">
              <div className="grid grid-cols-4 place-items-center">
                <div className="flex flex-col justify-between place-self-start col-span-3 pr-4">
                  {/**Displaying data */}

                  <div>
                    <div
                      className="break-all text-md font-custom"
                      style={{ fontWeight: "bold" }}
                    >
                      {domain}{" "}
                    </div>
                    <div
                      className="text-sm font-custom"
                      style={{ color: "#74767E" }}
                    >
                      {count === 1 ? `${count} email` : `${count} emails`}
                    </div>
                    {/* <div className='text-sm font-custom' style={{ color: "#74767E" }}> Last Recieved {record.lastEmail}</div> */}
                    <div
                      className="text-sm font-custom"
                      style={{ color: "#74767E" }}
                    >
                      "{subject?.charAt(0)?.toUpperCase() + subject?.slice(1)}"
                    </div>
                  </div>
                </div>
                {/** For the action buttons */}

                <div className="w-full lg:ml-0 lg:w-23 md:flex md:flex-col">
                  <Button
                    format={{
                      id: "approveButton",
                      onclick: () =>
                        actionButtonClickHandler(record, "approved"),
                      type: "table",
                      content: "Approve",
                      color: "green",
                      isFilled: true,
                      isSelected: statusLowerCased == "approved",
                    }}
                  />
                  <div className="h-1"></div>
                  <Button
                    format={{
                      id: "blockButton",
                      onclick: () =>
                        actionButtonClickHandler(record, "blocked"),
                      type: "table",
                      content: "Block",
                      color: "red",
                      isFilled: true,
                      isSelected: statusLowerCased == "blocked",
                    }}
                  />
                </div>
              </div>
            </div>

            {/** sub tables */}
            <div className="pt-4"></div>
            <DisplaySubDomain
              record={record}
              dataForChildTable={dataForChildTable}
              actionButtonClickHandler={actionButtonClickHandler}
            />
          </div>
        );
      })}
    </>
  );
}

const DisplaySubDomain = ({
  record,
  dataForChildTable,
  actionButtonClickHandler,
}: DisplaySubDomainProps) => {
  const found = dataForChildTable?.find(
    (domain) => domain.parentId === record.key,
  );

  if (found && found.child?.length) {
    return (
      <>
        {found?.child?.map((child) => {
          return (
            <div
              className="border-l-4 w-full pt-2 grid grid-cols-4 place-items-center"
              style={{ borderLeftColor: "#DEDFE7" }}
              key={child.domain || child.email}
            >
              {/**Displaying data */}
              <div className="mt-2 pl-4 place-self-start col-span-3 pr-4">
                <p className="break-all text-md font-custom">{child.domain}</p>
                <p className="text-sm font-custom" style={{ color: "#74767E" }}>
                  {child.count} emails
                </p>
                <p className="text-sm font-custom" style={{ color: "#74767E" }}>
                  "{child.subject ? `${child.subject}` : "No Subject Found"}"
                </p>
              </div>

              <div className="w-full lg:ml-0 lg:w-23 md:flex md:flex-col mt-2">
                <Button
                  format={{
                    id: "approveButton",
                    onclick: () => actionButtonClickHandler(child, "approved"),
                    type: "table",
                    content: "Approve",
                    color: "green",
                    isFilled: true,
                    isSelected: child.status == "approved",
                  }}
                />
                <div className="h-1"></div>
                <Button
                  format={{
                    id: "blockButton",
                    onclick: () => actionButtonClickHandler(child, "blocked"),
                    type: "table",
                    content: "Block",
                    color: "red",
                    isFilled: true,
                    isSelected: child.status == "blocked",
                  }}
                />
              </div>
            </div>
          );
        })}
      </>
    );
  } else {
    return null;
  }
};

export default MobileSubdomainAntdTable;
