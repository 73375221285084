import React, { useEffect, useState } from "react";
import { useField } from "formik";
import { AnimatePresence } from "framer-motion";
import ErrorMessage from "../ErrorMessage";
import Eye from "../../assets/icons/eye";
import EyeStrike from "../../assets/icons/eye-strike";
import PasswordTick from "./registration/PasswordTick";

interface FieldRecord {
  values: {
    email:string;
    password: string;
  };
  touched: {
    email?:boolean;
    password?: boolean;
  };
}

interface PasswordFieldCopyProps {
  label?: string;
  subLabel ?: string;
  additionalFields: FieldRecord;
  name:string;
  confirmField?: boolean;
  placeholder?: string;
}

const PasswordFieldCopy: React.FC<PasswordFieldCopyProps> = ({ label, subLabel, additionalFields,...props }) => {
  const [field, meta, helpers] = useField(props);
  const errorString = "New password cannot be the same as current password";
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setshowError] = useState(false);
  const [requirments, setRequirments] = useState({
    capitalLetter: false,
    lowerCaseLetter: false,
    number: false,
    specialCharacter: false,
    characterLimit: false,
  });
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    // One Capital Letter
    if (
      additionalFields?.values.password !==
      additionalFields?.values.password.toLowerCase()
    ) {
      setRequirments((prev) => {
        return { ...prev, capitalLetter: true };
      });
    } else {
      setRequirments((prev) => {
        return { ...prev, capitalLetter: false };
      });
    }

    // One lowercase letter
    if (
      additionalFields?.values.password !==
      additionalFields?.values.password.toUpperCase()
    ) {
      setRequirments((prev) => {
        return { ...prev, lowerCaseLetter: true };
      });
    } else {
      setRequirments((prev) => {
        return { ...prev, lowerCaseLetter: false };
      });
    }

    // One Number
    if (additionalFields && /[0-9]/.test(additionalFields.values.password)) {
      setRequirments((prev) => {
        return { ...prev, number: true };
      });
    } else {
      setRequirments((prev) => {
        return { ...prev, number: false };
      });
    }

    // Special Char
    if (
      additionalFields &&
      /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
        additionalFields.values.password,
      )
    ) {
      setRequirments((prev) => {
        return { ...prev, specialCharacter: true };
      });
    } else {
      setRequirments((prev) => {
        return { ...prev, specialCharacter: false };
      });
    }

    // At least 8 chars
    if (additionalFields?.values.password.length > 7) {
      setRequirments((prev) => {
        return { ...prev, characterLimit: true };
      });
    } else {
      setRequirments((prev) => {
        return { ...prev, characterLimit: false };
      });
    }
  }, [additionalFields?.values.password]);

  let keys = "";
  Object.entries(requirments).forEach(([key, value]) => {
    if (!value && additionalFields?.touched.password) {
      keys += `${key}, `;
    }
  });

  return (
    <div className="flex w-full flex-col items-start">
      <label
        className={`w-full transition-all duration-300 font-normal text-sm ${
          keys.length > 0 ? "text-red_error" : "text-gray1"
        }`}
      >
        {" "}
        {label}{" "}
      </label>
      <div className="flex flex-row w-full items-center justify-end">
        <input
          className={`field w-125 xs:w-full transition-all duration-300 ${
            keys.length > 0 ? "border-red_error" : "border-gray2"
          } bg-white px-4 py-2 text-black_primary border font-custom border-solid`}
          type={showPassword ? "text" : "password"}
          {...field}
          {...props}
        />
        <div
          onClick={toggleShowPassword}
          className="cursor-pointer absolute mr-2"
        >
          {showPassword ? <EyeStrike /> : <Eye />}
        </div>
      </div>
      <AnimatePresence initial={false}>
        {(props?.confirmField && meta.touched) ||
        keys?.length > 0 ||
        meta.error === errorString ? (
          <>
            <div className="mt-4 w-full xs:w-full">
              <PasswordTick
                field={additionalFields}
                keys={keys}
                requirments={requirments}
              />
            </div>
            {/* <ErrorMessage errors={meta.error} /> */}
          </>
        ) : (
          <>
            {/* <label className="text-gray1 mb-3 flex flex-wrap">{subLabel}</label> */}
          </>
        )}
      </AnimatePresence>
    </div>
  );
}

export default PasswordFieldCopy;
