import React from "react";

interface Props { color: string; }

const ArrowLeft: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6667 5.99939C13.6667 6.36758 13.3682 6.66606 13 6.66606H2.44671L5.50671 10.2327C5.74603 10.5126 5.7132 10.9334 5.43337 11.1727C5.15355 11.4121 4.7327 11.3792 4.49337 11.0994L0.493374 6.43273C0.469491 6.3995 0.449368 6.36373 0.433374 6.32606C0.413309 6.30099 0.395464 6.27422 0.380041 6.24606C0.351154 6.16693 0.335391 6.08361 0.333374 5.99939C0.335391 5.91518 0.351154 5.83186 0.380041 5.75273C0.395464 5.72456 0.413309 5.6978 0.433374 5.67273C0.449368 5.63506 0.469491 5.59929 0.493374 5.56606L4.49337 0.899395C4.7327 0.61957 5.15355 0.586738 5.43337 0.826061C5.7132 1.06538 5.74603 1.48624 5.50671 1.76606L2.44671 5.33273H13C13.3682 5.33273 13.6667 5.63121 13.6667 5.99939Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowLeft;
