import React from "react";

const Eye = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0.833984C9.51334 0.833984 11.8533 2.55398 13.5867 5.67398C13.7058 5.88025 13.7058 6.13438 13.5867 6.34065C11.8533 9.46065 9.51334 11.1807 7 11.1807C4.48667 11.1807 2.15334 9.46065 0.420005 6.34065C0.300916 6.13438 0.300916 5.88025 0.420005 5.67398C2.15334 2.55398 4.48667 0.833984 7 0.833984ZM1.78667 5.98065C1.99121 6.33403 2.21373 6.67671 2.45334 7.00732C2.29339 6.33222 2.29339 5.62908 2.45334 4.95398C2.21373 5.2846 1.99121 5.62727 1.78667 5.98065ZM7 9.33398C5.15906 9.33398 3.66667 7.8416 3.66667 6.00065C3.66667 4.1597 5.15906 2.66732 7 2.66732C8.84095 2.66732 10.3333 4.1597 10.3333 6.00065C10.3333 7.8416 8.84095 9.33398 7 9.33398ZM12.2133 6.00065C12.0103 5.6442 11.7877 5.29923 11.5467 4.96732C11.7068 5.64691 11.7068 6.3544 11.5467 7.03398C11.7877 6.70207 12.0103 6.3571 12.2133 6.00065Z"
        fill="#231F20"
      />
    </svg>
  );
};

export default Eye;
