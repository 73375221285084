import { useSelector, useDispatch } from "react-redux";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";

import Button from "../../primitives/Button";
import PublicNav from "../landingPage/components/publicNav";
import { fetcher, post, update } from "../../../logic/util/hooks";
import Loader from "../../primitives/Loader/Loader";
import { icons } from "../../assets/icons";
import gmailVideo from "../../assets/videos/introduction_gmail.mp4";
import outlookVideo from "../../assets/videos/introduction_outlook.mp4";
import ReactPlayer from "react-player";
import RequestFormSettings from "../pauseEvaluatingView/RequestFormSettings";
import FolderSettings from "../pauseEvaluatingView/FolderSettings";
import toast from "react-hot-toast";
import LearnAboutSurveys from "../../primitives/dialogContent/LearnAboutSurveys";
import { mountDialog, unMountDialog } from "../../../features/util/utilSlice";
import * as Sentry from "@sentry/react";
import { setLoading, unsetLoading } from "../../../features/loader/loaderSlice";
import jwtDecode from "jwt-decode";
import mixpanel from "mixpanel-browser";
import sheildIcon from "../../assets/icons/shieldIcon.svg";
import calendarIcon from "../../assets/icons/calendarIcon.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface PlatformData {
  ACCOUNT_REF: string;
  id: string;
  eval_paused: boolean;
  has_send_survey_enabled: boolean;
  is_domain_review_complete: boolean;
  is_folder_settings_set: boolean;
  is_request_form_settings_set: boolean;
  is_video_step_complete: boolean;
  email: string;
  platformID: string;
  platform: string;
  status: string;
}

interface DecodedToken {
  sub: string;
  exp: number;
}

// interface TokenPayload {
//   platformID: string;
//   email: string;
//   platform: string;
// }

interface Domain {
  ACCOUNT_REF: string;
  PARENT_REF: string | null;
  id: string;
  status: "approved" | "blocked";
  value: string;
}

interface DomainType {
  key: string;
  name?: string;
  email: string;
  phone: string;
  reason: string;
  relationship: string;
  contactID: string;
  platform_ref: string;
  option?: any;
  status?: string;
  domain?: string;
  parentKey?: string;
  count?: number;
  subject: string;
  subDomains?: any;
  platformID?: string;
  parentDomain?: string;
}

interface DataType {
  status?: string | null;
  list: DomainType[];
  total?: number;
}

const removeDomainDuplications = (domainsData: any[] = []) => {
  const uniqueDomains: { [key: string]: boolean } = {};
  return domainsData.filter((domain) => {
    if (!uniqueDomains[domain.id]) {
      uniqueDomains[domain.id] = true;
      return true;
    }
    return false;
  });
};

const removeSubDomainDuplications = (domainsData: any[] = []) => {
  const domainsDeduped = removeDomainDuplications(domainsData);
  return domainsDeduped.map((domain: DomainType) => {
    return {
      ...domain,
      subDomains: domain.subDomains?.filter(
        (subDomain: DomainType) => subDomain.domain !== domain.domain,
      ),
    };
  });
};

const ConfirmExitModal = ({
  platform,
  platformID,
  email,
  accountId,
}: {
  platform: string;
  platformID: string;
  email: string;
  accountId: string;
}) => {
  const [isTurningOn, setIsTurningOn] = useState<boolean>(false);
  const { user } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTurnOn = () => {
    setIsTurningOn(true);
    post("auth", `${platform}/confirmConnection`, {
      platformID: platformID,
      accountID: user?.id,
      email,
    })
      .then((response) => {
        if (response.status === 200) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "email_connected",
            user_id: user.id,
          });
        }
        // console.log({ response });
        console.log("platform connected successfully!");
        window.localStorage.removeItem("sso_signup");
        localStorage.setItem(`isSwitchedOn_${accountId}`, JSON.stringify(true));
        fetcher(
          `/onboarding/${platform}/addContactsBySentMail/account/${user?.id}/platformID/${platformID}`,
        )
          .then((response) => {
            console.log("Sent mail history added!");
            setIsTurningOn(false);
            dispatch(unMountDialog());
            navigate("/app/email-accounts");
          })
          .catch((error) => {
            setIsTurningOn(false);
            console.log(error);
          });
      })
      .catch((error) => {
        setIsTurningOn(false);
        console.log(error);
      });
  };

  return (
    <div className="flex flex-col gap-4 pb-4 pt-2">
      <div className="pb-2 flex flex-col gap-6">
        <p>Bulletproof Inbox is not on for your {email} email account.</p>
        <p>You can turn it on any time from your Bulletproof dashboard.</p>
        <p>Ready to turn Bulletproof on now?</p>
      </div>
      <div className="flex flex-col gap-2">
        <button
          className="w-full border-1 border-green_primary text-green_primary font-bold rounded-full py-3 hover:bg-green_primary hover:text-white"
          onClick={() => {
            mixpanel.track(
              `Clicked: 'Not yet' button for platform: ${platform} on (turn on modal)`,
              {
                user_Id: user?.id,
                user_Email: user?.email,
                user_FirstName: user?.first,
                user_LastName: user?.last,
                user_Phone: user?.phone,
                user_Tier: user?.tier,
              },
            );
            dispatch(unMountDialog());
            navigate("/app/email-accounts");
          }}
        >
          Not yet
        </button>
        {isTurningOn ? (
          <button className="w-full h-12 border-1 bg-green_primary border-green_primary text-green_primary font-bold rounded-full flex justify-center items-center">
            <div className="dot-falling"></div>
          </button>
        ) : (
          <button
            className="w-full h-12 border-1 border-green_primary text-green_primary font-bold rounded-full py-3 hover:bg-green_primary hover:text-white"
            onClick={() => {
              mixpanel.track(
                `Clicked: 'Turn on Bulletproof' button on (turn on modal) for platform:${platform}`,
                {
                  user_Id: user?.id,
                  user_Email: user?.email,
                  user_FirstName: user?.first,
                  user_LastName: user?.last,
                  user_Phone: user?.phone,
                  user_Tier: user?.tier,
                },
              );
              handleTurnOn();
            }}
          >
            Turn on Bulletproof
          </button>
        )}
      </div>
    </div>
  );
};

const EmailSettings = (props: any) => {
  // email settings has platform id as a url parameter, so that each platform has its own settings page
  const { id } = useParams();

  const { user } = useSelector((state: any) => state.auth);
  const videoCardRef: any = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  let timeoutId: NodeJS.Timeout | undefined;

  const [initialPlatformData, setInitialPlatformData] =
    useState<PlatformData | null>(null);
  const [platformData, setPlatformData] = useState<PlatformData | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTurningOn, setIsTurningOn] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const [domains, setDomains] = useState<Domain[]>([]);
  const [totalContact, setTotalContact] = useState<any[]>([]);
  const approvedDomains = domains.filter(
    (domain) => domain?.status === "approved",
  );
  const blockedDomains = domains.filter(
    (domain) => domain?.status === "blocked",
  );
  const [inboxData, setInboxData] = useState<DataType | null>(null);

  const navigate = useNavigate();

  const [isVideoStepComplete, setIsVideoStepComplete] =
    useState<boolean>(false);
  const [isStep3Skipped, setIsStep3Skipped] = useState<boolean>(false);
  const [isStep4Skipped, setIsStep4Skipped] = useState<boolean>(false);
  const [isContactsAdded, setIscontactsAdded] = useState<boolean>(false);
  const [isGoogleEmail, setisGoogleEmail] = useState<boolean>(false);
  const [email, setemail] = useState<string | undefined>();
  const [isEvalPaused, setisEvalPaused] = useState<boolean | undefined>(false);
  const [isRequestFormEnabled, setisRequestFormEnabled] = useState<
    boolean | undefined
  >(false);
  const [isRequestFormSettingsSet, setisRequestFormSettingsSet] = useState<
    boolean | undefined
  >(false);
  const [isFolderSettingsSet, setisFolderSettingsSet] = useState<
    boolean | undefined
  >(false);
  const [isDomainReviewComplete, setisDomainReviewComplete] = useState<
    boolean | undefined
  >(false);
  const [platform, setplatform] = useState<string | undefined>();
  const [platformID, setplatformID] = useState<string | undefined>();
  const [refetch, setRefetch] = useState(true);

  const [jwtDecoded, setJwtDecoded] = useState<DecodedToken>(
    jwtDecode<DecodedToken>(localStorage.getItem("jwtToken") || ""),
  );
  const isAuth0Signup = useMemo(
    () => jwtDecoded.sub.startsWith("auth0"),
    [jwtDecoded],
  );
  // console.log({isAuth0Signup});

  useEffect(() => {
    const jwtDecoded = jwtDecode<DecodedToken>(
      localStorage.getItem("jwtToken") || "",
    );
    setJwtDecoded(jwtDecoded);
  }, []);

  const [isSwitchedOn, setIsSwitchedOn] = useState<boolean>(false);
  useLayoutEffect(() => {
    const savedIsSwitchedOn = localStorage.getItem(`isSwitchedOn_${id}`);
    if (savedIsSwitchedOn !== null) {
      setIsSwitchedOn(JSON.parse(savedIsSwitchedOn));
    }
  }, [id]);
  useLayoutEffect(() => {
    const savedIsStep3Skipped = localStorage.getItem(
      `isStep3Skipped_${platformData?.id}`,
    );
    if (savedIsStep3Skipped !== null) {
      setIsStep3Skipped(JSON.parse(savedIsStep3Skipped));
    }
  }, [platformData]);
  useLayoutEffect(() => {
    const savedIsStep4Skipped = localStorage.getItem(
      `isStep4Skipped_${platformData?.id}`,
    );
    if (savedIsStep4Skipped !== null) {
      setIsStep4Skipped(JSON.parse(savedIsStep4Skipped));
    }
  }, [platformData]);

  const handleTurnOn = () => {
    setIsTurningOn(true);
    post("auth", `${platform}/confirmConnection`, {
      platformID: platformID,
      accountID: user?.id,
      email,
    })
      .then((response) => {
        console.log({ response });
        if (response.status === 200) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "email_connected",
            user_id: user.id,
          });
        }
        console.log("platform connected successfully!");
        window.localStorage.removeItem("sso_signup");
        localStorage.setItem(`isSwitchedOn_${id}`, JSON.stringify(true));
        fetcher(
          `/onboarding/${platform}/addContactsBySentMail/account/${user?.id}/platformID/${platformID}`,
        )
          .then((response) => {
            console.log("Sent mail history added!");
            setIsTurningOn(false);
            navigate("/app/email-accounts");
          })
          .catch((error) => {
            setIsTurningOn(false);
            console.log(error);
          });
      })
      .catch((error) => {
        setIsTurningOn(false);
        console.log(error);
      });
  };

  const confirmExitDialog = () => {
    dispatch(
      mountDialog({
        component: (
          <ConfirmExitModal
            platform={platform ?? ""}
            platformID={platformID ?? ""}
            email={email ?? ""}
            accountId={id ?? ""}
          />
        ),
        headline: `Sure you want to exit?`,
        close: true,
      }),
    );
  };

  useEffect(() => {
    dispatch(setLoading());
    fetcher(`/resources/platforms/${user?.id}`)
      .then((res) => {
        // if (res) setData(res);
        dispatch(unsetLoading());
      })
      .catch((err) => {
        console.log(err);
        // setError(err);
        dispatch(unsetLoading());
      });
  }, []);

  useEffect(() => {
    setisGoogleEmail(platformData?.email.split("@")[1] === "gmail.com");
    setemail(platformData?.email);
    setisEvalPaused(platformData?.eval_paused);
    setisRequestFormEnabled(platformData?.has_send_survey_enabled);
    setisRequestFormSettingsSet(platformData?.is_request_form_settings_set);
    setisFolderSettingsSet(platformData?.is_folder_settings_set);
    setisDomainReviewComplete(platformData?.is_domain_review_complete);
    setplatform(platformData?.platform);
    setplatformID(platformData?.platformID);
  }, [platformData]);

  const [isDomainsToastOn, setIsDomainsToastOn] = useState<boolean>(false);
  useLayoutEffect(() => {
    const savedIsDomainsToastOn = localStorage.getItem(`isDomainsToastOn`);
    if (savedIsDomainsToastOn !== null) {
      setIsDomainsToastOn(JSON.parse(savedIsDomainsToastOn));
    }
  }, []);

  useLayoutEffect(() => {
    // console.log({isDomainsToastOn});
    if (isDomainsToastOn) {
      toast.success("Senders added");
      localStorage.setItem("isDomainsToastOn", JSON.stringify(false));
      setIsDomainsToastOn(false);
    }
  }, [isDomainsToastOn]);

  const handleVideoEnd = () => {
    update("platforms", `${user?.id}/platform/${platformData?.id}`, {
      is_video_step_complete: true,
    })
      .then(() => {
        console.log("post: success");
        setIsVideoStepComplete(true);
        setIsVideoPlaying(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useLayoutEffect(() => {
    if (
      !platformData?.is_request_form_settings_set &&
      !platformData?.is_folder_settings_set &&
      platformData?.id !== initialPlatformData?.id &&
      initialPlatformData
    ) {
      setIsLoading(true);
      post(
        "platforms",
        `/account/${platformData?.ACCOUNT_REF}/platform/${platformData?.id}/updateSetting`,
        {
          platformSettings: {
            has_send_survey_enabled:
              initialPlatformData.has_send_survey_enabled,
            eval_paused: initialPlatformData.eval_paused,
          },
        },
      )
        .then(() => {
          update("platforms", `${user?.id}/platform/${platformData?.id}`, {
            is_request_form_settings_set: true,
            is_folder_settings_set: true,
          })
            .then(() => {
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setIsLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [initialPlatformData, platformData, user]);

  // fetching platform data
  useLayoutEffect(() => {
    if (refetch) {
      if (user?.email)
        fetcher(`/resources/platforms/${user?.id}`)
          .then((response) => {
            console.log("fetch platform info: success");
            // console.log({ response });
            // pick up platform dynamically based on platform id from url param
            const data = response?.filter(
              (platform: PlatformData) => platform.id === id,
            )[0];
            // console.log({ data });
            setPlatformData(data);
            setIsVideoStepComplete(data?.is_video_step_complete);
            const initialPlatform = response?.filter(
              (platform: PlatformData) => platform.email === user?.email,
            )[0];
            // console.log({initialPlatform});
            setInitialPlatformData(initialPlatform);

            fetcher(`/resources/domains/account/${user?.id}`)
              .then((response) => {
                console.log("fetch domains info: success");
                setDomains(response);
              })
              .catch((err) => {
                console.log(err);
                setIsError(true);
              });
          })
          .catch((err) => {
            console.log(err);
            setIsError(true);
          })
          .finally(() => {});
      setRefetch(false);
    }
  }, [user, id, refetch]);

  const handleNavigateToDomains = () => {
    if (platformData?.id === initialPlatformData?.id) {
      navigate(`/app/domains/${platformData?.id}`, {
        state: { prevPage: "Email Settings" },
      });
    } else {
      navigate(`/app/reReviewDomains`, {
        state: { prevPage: "Email Settings" },
      });
    }
  };
  const dispatch = useDispatch();

  const surveyDialog = () => {
    dispatch(
      mountDialog({
        component: <LearnAboutSurveys />,
        headline: `Learn about request forms`,
        close: true,
      }),
    );
  };

  const [inboxFetchCompleted, setInboxFetchCompleted] =
    useState<boolean>(false);
  const intervalRef = useRef<any>(null);

  // pre-fetch inbox domains data, to display in the domains settings page (this is to avoid excessive load time when you navigate to domains settings page)
  useLayoutEffect(() => {
    const getInboxData = () => {
      if (platform && platformID && platformData?.id) {
        fetcher(
          `/onboarding/${platform}/reviewInboxForDomains/account/${user?.id}/platformID/${platformID}`,
        )
          .then((response) => {
            if (response && response.list) {
              clearInterval(intervalRef.current);
              const list = response?.list?.map((item: DomainType) => {
                return {
                  ...item,
                  platformID,
                  status: item.status || "evaluating",
                  subDomains: item?.subDomains?.map((subItem: DomainType) => {
                    return {
                      ...subItem,
                      platformID,
                      status: subItem.status || "evaluating",
                    };
                  }),
                };
              });
              // save inbox domains data in local storage
              localStorage.setItem(
                `inboxData_${platformData?.id}`,
                JSON.stringify({
                  status: "complete",
                  list: removeSubDomainDuplications(list),
                  total: response?.total,
                }),
              );
              setInboxData({
                status: "complete",
                list: removeSubDomainDuplications(list),
                total: response?.total,
              });
              localStorage.setItem(
                `inboxFetchError_${platformData?.id}`,
                JSON.stringify(null),
              );
              setInboxFetchCompleted(true);
              console.log("fetch inbox data: success");
            }
          })
          .catch((err) => {
            localStorage.setItem(
              `inboxData_${platformData?.id}`,
              JSON.stringify(null),
            );
            localStorage.setItem(
              `inboxFetchError_${platformData?.id}`,
              JSON.stringify(err),
            );
            console.log(err);
            setInboxFetchCompleted(true);
          });
      }
    };

    intervalRef.current = setInterval(getInboxData, 3000);

    return () => {
      clearInterval(intervalRef.current); // Clear interval on component unmount
    };
  }, [user.id, platform, platformID, platformData?.id, inboxFetchCompleted]);

  useEffect(() => {
    if (!isVideoStepComplete) {
      videoCardRef.current?.scrollIntoView({ behavior: "instant" });
    }
  }, [isVideoStepComplete]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (platformID) {
      addContactsBySentMailCall();
      if (platform === "google") {
        fetcher(`/auth/google/platform/${platformID}/scopes`).then(
          (response) => {
            if (
              response?.scope?.includes(
                "https://www.googleapis.com/auth/contacts.readonly",
              )
            ) {
              setIscontactsAdded(true);
              if (localStorage.getItem("addingContacts")) {
                addContactsCall();
                localStorage.removeItem("addingContacts");
                toast.success("Contacts added");
                localStorage.setItem("contactsAlreadyAdded", "true");
              }
            }
          },
        );
      } else {
        addContactsCall();
      }
    }
  }, [platformID, platform]);

  const addContactsCall = () => {
    post("auth", `${platform}/addContactsByContactList`, { email })
      .catch((err) => Sentry.captureException(err))
      .then((response) => {
        setTotalContact(response?.data);
      });
  };

  const addContactsBySentMailCall = () => {
    fetcher(
      `/onboarding/${platform}/addContactsBySentMail/account/${user?.id}/platformID/${platformID}`,
    ).catch((err) => console.log(err));
  };
  const handleButtonClick = () => {
    localStorage.setItem("addingContacts", "true");
  };

  if (isLoading) {
    return (
      <>
        {!isSwitchedOn && id === initialPlatformData?.id ? (
          <div className="absolute w-full shadow-nav h-16 bg-white flex justify-center items-center text-2xl font-bold">
            Configure
          </div>
        ) : (
          <PublicNav
            title="Configure"
            secondaryText={platformData?.email}
            back={{ text: "" }}
          />
        )}
        <div className="absolute h-screen w-screen flex justify-center items-center z-0">
          <Loader />
        </div>
      </>
    );
  }

  if (isError) {
    return (
      <>
        {!isSwitchedOn && id === initialPlatformData?.id ? (
          <div className="absolute w-full shadow-nav h-16 bg-white flex justify-center items-center text-2xl font-bold">
            Configure
          </div>
        ) : (
          <PublicNav
            title="Configure"
            secondaryText={platformData?.email}
            back={{ text: "" }}
          />
        )}
        <div className="mb-6 max-w-lg text-center">
          <div className="flex justify-center flex-row md:pr-8 pr-10 items-center gap-x-3 w-full xs:items-start content-center">
            <p className={"font-custom text-base text-black_primary "}>
              Couldn't connect this email to your account. It's currently used
              by another account. Select back and try another email.
            </p>
          </div>
          <div className="flex justify-center pt-4">
            <Button
              format={{
                type: "primary",
                isFilled: true,
                content: "Back",
                width: "w-48",
                onclick: () => {
                  dispatch(unMountDialog());
                  navigate("/app/email-accounts");
                  window.location.reload();
                },
              }}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="flex flex-col sm:pb-20 overflow-x-hidden">
      {!isSwitchedOn && id === initialPlatformData?.id ? (
        <div className="absolute w-full shadow-nav h-16 bg-white flex justify-center items-center text-2xl font-bold">
          Configure
        </div>
      ) : (
        <PublicNav
          title="Configure"
          secondaryText={platformData?.email}
          back={{ text: "" }}
        />
      )}
      <div className="pt-16 flex mx-6 sm:mx-0 justify-center sm:justify-start text-center sm:text-left" />
      <div className="flex justify-center flex-col">
        <div className="flex flex-col align-middle">
          <div className="flex flex-col pt-8 px-6 pb-28 gap-10 mb-32 sm:mb-10 sm:max-w-4xl sm:mx-auto">
            <div className="flex flex-col gap-1 sm:items-center sm:justify-center">
              <p className="text-black_primary text-2xl font-bold text-center">
                Next, choose your setup
              </p>
              <p className="text-base font-normal sm:max-w-xs sm:text-center pb-4 sm:pb-6 text-center">
                {`Optimize Bulletproof in `}
                <span className="font-semibold italic">
                  {platform === "microsoft"
                    ? "3 simple steps"
                    : "4 simple steps"}
                </span>
              </p>
              <div className="max-w-sm w-full bg-green_bg p-3 rounded-md">
                <div className="flex justify-start items-center">
                  <LazyLoadImage
                    src={sheildIcon}
                    className="w-4 h-4 mr-1"
                    alt="Sheild Icon"
                  />
                  <p className="text-green_primary font-semibold font-lg">
                    Or Schedule a Setup Call{" "}
                  </p>
                </div>
                <div className="text-green_primary justify-start text-sm">
                  <a
                    href="https://calendly.com/jack-bulletproof-inbox/30min"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer font-semibold mr-1 underline hover:no-underline"
                  >
                    Click here to book a personalized onboarding call
                  </a>
                  {`and make sure you're getting the most out of Bulletproof Inbox.`}
                </div>
              </div>
            </div>
            {isEvalPaused ? (
              <div className="flex flex-row gap-6 justify-between py-3 px-6 bg-gray_Xlight rounded-lg">
                <div className="flex flex-col">
                  <p className="text-xs text-gray1 pb-2">Step 1</p>
                  <p className="font-bold text-gray1">
                    Don't send a request form
                  </p>
                  <p className="text-gray1">
                    Preference set by your decision on step 2. Can only be
                    modified when Step 2: Filter unknown senders is "Send to
                    evaluating folder"
                  </p>
                </div>
                <LazyLoadImage
                  src={icons.lock}
                  alt="lock icon"
                  className="w-9"
                />
              </div>
            ) : (
              <div
                className={`flex flex-col gap-6 p-6 justify-between rounded-lg shadow-nav`}
              >
                <p className="sm:hidden text-lg font-bold text-green_primary -mb-3">
                  Step 1
                </p>
                <div className="relative flex justify-center items-center sm:space-x-10">
                  <p className="hidden sm:block text-lg font-bold text-green_primary absolute top-0 left-0">
                    Step 1
                  </p>
                  <div className="flex flex-col justify-center items-center w-full gap-2 sm:gap-0">
                    <p className="text-black_primary text-xl font-bold text-center sm:text-left">
                      What should happen when an unknown sender emails you?
                    </p>
                    <button
                      className="text-base text-green_primary underline cursor-pointer text-center"
                      onClick={() => {
                        mixpanel.track(`Clicked: 'Learn about request forms'`, {
                          user_Id: user?.id,
                          user_Email: user?.email,
                          user_FirstName: user?.first,
                          user_LastName: user?.last,
                          user_Phone: user?.phone,
                          user_Tier: user?.tier,
                        });
                        surveyDialog();
                      }}
                    >
                      Learn about request forms
                    </button>
                  </div>
                </div>
                <RequestFormSettings setRefetch={setRefetch} />
              </div>
            )}

            {isRequestFormEnabled && isRequestFormSettingsSet ? (
              <div className="flex flex-row gap-6 justify-between py-3 px-6 bg-gray_Xlight rounded-lg">
                <div className="flex flex-col">
                  <p className="text-lg font-bold text-gray1 pb-2">Step 2</p>
                  <p className="font-bold text-gray1">
                    Send to evaluating folder
                  </p>
                  <p className="text-gray1">
                    Preference set by your decision on step 1. Can only be
                    modified when Step 1: Request Form is "don't send a request
                    form"
                  </p>
                </div>
                <LazyLoadImage
                  src={icons.lock}
                  alt="lock icon"
                  className="w-9"
                />
              </div>
            ) : (
              <div
                className={`flex flex-col gap-6 p-6 justify-start rounded-lg shadow-nav`}
              >
                <p className="sm:hidden text-lg font-bold text-green_primary -mb-3">
                  Step 2
                </p>
                <div className="relative flex justify-start items-center sm:space-x-10">
                  <div>
                    <p className="hidden sm:block absolute top-0 left-0 text-lg font-bold text-green_primary">
                      Step 2
                    </p>
                  </div>
                  <div className="flex flex-col justify-center items-center w-full gap-2 sm:gap-0">
                    <p className="text-black_primary text-xl font-bold text-center sm:text-left">
                      How would you like to sort emails from unknown senders?
                    </p>
                    <p className="text-base text-gray2 text-center sm:text-left">
                      Choose how you want to handle email from people you don’t
                      know
                    </p>
                  </div>
                </div>
                <FolderSettings setRefetch={setRefetch} />
              </div>
            )}

            <div className="flex flex-col gap-1 sm:items-center sm:justify-center mt-4">
              <p className="text-black_primary text-lg font-bold text-center px-2">
                Customize your approved senders
              </p>
              <p className="text-base font-normal sm:max-w-xs sm:text-center pb-4 sm:pb-6 text-center">
                (optional but recommended)
              </p>
            </div>

            {isGoogleEmail ? (
              <div className="shadow-nav flex flex-col gap-10 px-6 py-8 rounded-lg">
                {isDomainReviewComplete || isStep3Skipped ? (
                  <div className="flex flex-row gap-6 justify-between py-3 px-6 bg-green_bg rounded-lg">
                    <div className="flex flex-col">
                      <p className="text-lg font-bold text-green_primary pb-2">
                        {isDomainReviewComplete
                          ? "Step 3"
                          : isStep3Skipped
                            ? "Step 3 - Skipped"
                            : "Step 3"}
                      </p>
                      <p
                        className={`font-bold ${
                          isStep3Skipped && !isDomainReviewComplete
                            ? "mb-5"
                            : ""
                        }`}
                      >
                        {isDomainReviewComplete
                          ? "Approve or block senders"
                          : isStep3Skipped
                            ? "No companies added to approved sender list"
                            : "Approve or block senders"}
                      </p>
                      <div className="text-green_primary_text">
                        {inboxData && inboxData.list.length === 0 ? (
                          <p>No domains to review</p>
                        ) : (
                          <>
                            {isDomainReviewComplete && (
                              <div>
                                <p>
                                  Approved {approvedDomains.length}{" "}
                                  {approvedDomains.length === 1
                                    ? "sender"
                                    : "senders"}
                                </p>
                                <p>
                                  Blocked {blockedDomains.length}{" "}
                                  {blockedDomains.length === 1
                                    ? "sender"
                                    : "senders"}
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <button
                      className={`focus-within:text-base  text-center underline whitespace-nowrap ${
                        inboxFetchCompleted
                          ? "text-green_primary cursor-pointer"
                          : "cursor-not-allowed text-gray1"
                      }`}
                      disabled={!inboxFetchCompleted}
                      onClick={() => {
                        mixpanel.track(
                          `Clicked: ${
                            isDomainReviewComplete ? `Modify` : `Review Senders`
                          } button for 'platform: ${platform}' to 'Approve or block senders'`,
                          {
                            user_Id: user?.id,
                            user_Email: user?.email,
                            user_FirstName: user?.first,
                            user_LastName: user?.last,
                            user_Phone: user?.phone,
                            user_Tier: user?.tier,
                          },
                        );
                        handleNavigateToDomains();
                      }}
                    >
                      {isDomainReviewComplete ? `Modify` : `Review Senders`}
                    </button>
                  </div>
                ) : (
                  <div
                    className={`flex flex-col gap-2 p-6 justify-between rounded-lg shadow-nav`}
                  >
                    <p className="text-lg font-bold text-green_primary">
                      Step 3
                    </p>
                    <div className="flex flex-col gap-6 justify-between sm:ml-12">
                      <div className="flex flex-col gap-2">
                        <p className="text-black_primary text-xl font-bold">
                          Approve or block senders who email you frequently
                        </p>
                        <p>
                          Approve those you want email from and block ones you
                          don’t.
                        </p>
                        <p className="text-gray1">Time: 5-10 minutes</p>
                      </div>
                      <div className="flex flex-col gap-4 rounded-lg bg-orange_bg text-orange_dark py-3 px-4">
                        <p className="font-bold">Why do this now?</p>
                        <p className="text-sm">
                          Investing a few minutes will immediately begin to
                          reduce the number of emails that land in your primary
                          inbox.
                        </p>
                      </div>
                      <div className="flex justify-center md:justify-start flex-wrap gap-3 sm:gap-6">
                        {inboxFetchCompleted ? (
                          <Button
                            format={{
                              type: "submit",
                              id: "signIn",
                              isFilled: false,
                              content: `Review senders`,
                              width: "sm:w-60 w-full ",
                              onclick: () => {
                                mixpanel.track(
                                  `Clicked: 'Review senders' button for 'platform: ${platform}'`,
                                  {
                                    user_Id: user?.id,
                                    user_Email: user?.email,
                                    user_FirstName: user?.first,
                                    user_LastName: user?.last,
                                    user_Phone: user?.phone,
                                    user_Tier: user?.tier,
                                  },
                                );
                                handleNavigateToDomains();
                              },
                            }}
                          />
                        ) : (
                          <button
                            className="bg-gray_Xlight text-gray1 rounded-full cursor-not-allowed font-bold px-16 py-2.5"
                            disabled={!inboxFetchCompleted}
                          >
                            Domains loading...
                          </button>
                        )}
                        <button
                          className="text-base text-green_primary underline cursor-pointer text-left sm:text-center"
                          onClick={() => {
                            mixpanel.track(
                              `Clicked: 'Not interested' button for 'platform: ${platform}' (Review senders)`,
                              {
                                user_Id: user?.id,
                                user_Email: user?.email,
                                user_FirstName: user?.first,
                                user_LastName: user?.last,
                                user_Phone: user?.phone,
                                user_Tier: user?.tier,
                              },
                            );
                            setIsStep3Skipped(true);
                            localStorage.setItem(
                              `isStep3Skipped_${platformData?.id}`,
                              JSON.stringify(true),
                            );
                          }}
                        >
                          Not interested
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {isContactsAdded || isStep4Skipped ? (
                  <div className="flex flex-row gap-6 justify-between py-3 px-6 bg-green_bg rounded-lg">
                    <div className="flex flex-col">
                      <p className="text-lg font-bold text-green_primary pb-2">
                        {isStep4Skipped ? "Step 4 - Skipped" : "Step 4"}
                      </p>
                      <p
                        className={`font-bold ${isStep4Skipped ? "pb-5" : ""}`}
                      >
                        {isStep4Skipped
                          ? "No Gmail contacts added to approved sender list"
                          : "Gmail contacts added"}
                      </p>
                      <div className="text-green_primary_text">
                        {inboxData && inboxData.list.length === 0 ? (
                          <p>No domains to review</p>
                        ) : (
                          <>
                            {!isStep4Skipped && (
                              <p>
                                {totalContact.length > 0 ? (
                                  <span>
                                    Approved {totalContact?.length}{" "}
                                    {totalContact?.length === 1
                                      ? "sender"
                                      : "senders"}
                                  </span>
                                ) : (
                                  <span>Contacts already added</span>
                                )}
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {isStep4Skipped && (
                      <button
                        className={`focus-within:text-base  text-center underline text-green_primary whitespace-nowrap`}
                        disabled={!inboxFetchCompleted}
                        onClick={() => {
                          mixpanel.track(
                            `Clicked: 'Add now' button for 'platform: ${platform} (Add my contcts)'`,
                            {
                              user_Id: user?.id,
                              user_Email: user?.email,
                              user_FirstName: user?.first,
                              user_LastName: user?.last,
                              user_Phone: user?.phone,
                              user_Tier: user?.tier,
                            },
                          );
                          setIsStep4Skipped(false);
                          setIscontactsAdded(false);
                          localStorage.setItem(
                            `isStep4Skipped_${platformData?.id}`,
                            JSON.stringify(false),
                          );
                        }}
                      >
                        Add now
                      </button>
                    )}
                  </div>
                ) : (
                  <div
                    className={`flex flex-col gap-2 p-6 justify-between rounded-lg shadow-nav`}
                  >
                    <p className="text-lg font-bold text-green_primary">
                      Step 4
                    </p>
                    <div className="flex flex-col gap-6 justify-between sm:ml-12">
                      <div className="flex flex-col gap-2">
                        <p className="text-black_primary text-xl font-bold">
                          Add your Gmail contacts
                        </p>
                        <p>
                          This ensures messages from your contacts go to your
                          inbox
                        </p>
                        <p className="text-gray1">Time: &lt; 1 minute</p>
                      </div>
                      <div className="flex flex-col gap-4 rounded-lg bg-orange_bg text-orange_dark py-3 px-4">
                        <p className="font-bold">
                          Your privacy & security is our top priority
                        </p>
                        <ul className="pl-5">
                          <li className="font-normal list-disc">
                            We only gather the name & email address of your
                            contacts.{" "}
                          </li>
                          <li className="font-normal list-disc">
                            We never view any other fields or data.
                          </li>
                          <li className="font-normal list-disc">
                            We never message your contacts.
                          </li>
                        </ul>
                      </div>
                      <div className="flex flex-wrap justify-center md:justify-start gap-3 sm:gap-6 m-0">
                        {inboxFetchCompleted ? (
                          <Button
                            format={{
                              id: "reviewDomainsButton",
                              type: "primary",
                              isFilled: false,
                              content: "Add my contacts",
                              width: "sm:w-60 w-full",
                              onclick: () => {
                                mixpanel.track(
                                  `Clicked: 'Add my contacts' button for 'platform: ${platform}'`,
                                  {
                                    user_Id: user?.id,
                                    user_Email: user?.email,
                                    user_FirstName: user?.first,
                                    user_LastName: user?.last,
                                    user_Phone: user?.phone,
                                    user_Tier: user?.tier,
                                  },
                                );
                                handleButtonClick();
                              },
                              link: `${process.env.REACT_APP_API}/auth/google/signin?consentFlow=contact&state=contactAccess&email=${platformData?.email}`,
                            }}
                          />
                        ) : (
                          <button
                            className="bg-gray_Xlight text-gray1 rounded-full cursor-not-allowed font-bold px-16 py-2.5"
                            disabled={!inboxFetchCompleted}
                          >
                            Domains loading...
                          </button>
                        )}
                        <button
                          className="text-base text-green_primary underline cursor-pointer text-left sm:text-center"
                          onClick={() => {
                            mixpanel.track(
                              `Clicked: 'Not interested' button for 'platform: ${platform}' (Add my contacts)`,
                              {
                                user_Id: user?.id,
                                user_Email: user?.email,
                                user_FirstName: user?.first,
                                user_LastName: user?.last,
                                user_Phone: user?.phone,
                                user_Tier: user?.tier,
                              },
                            );
                            setIsStep4Skipped(true);
                            localStorage.setItem(
                              `isStep4Skipped_${platformData?.id}`,
                              JSON.stringify(true),
                            );
                          }}
                        >
                          Not interested
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : isDomainReviewComplete || isStep3Skipped ? (
              <div className="flex flex-row gap-6 justify-between py-3 px-6 bg-green_bg rounded-lg">
                <div className="flex flex-col">
                  <p className="text-lg font-bold text-green_primary pb-2">
                    {isDomainReviewComplete
                      ? "Step 3"
                      : isStep3Skipped
                        ? "Step 3 - Skipped"
                        : "Step 3"}
                  </p>
                  <p
                    className={`font-bold ${
                      isStep3Skipped && !isDomainReviewComplete ? "mb-5" : ""
                    }`}
                  >
                    {isDomainReviewComplete
                      ? "Approve or block senders"
                      : isStep3Skipped
                        ? "No companies added to approved sender list"
                        : "Approve or block senders"}
                  </p>
                  <div className="text-green_primary_text">
                    {inboxData && inboxData.list.length === 0 ? (
                      <p>No domains to review</p>
                    ) : (
                      <>
                        {isDomainReviewComplete && (
                          <div>
                            <p>
                              Approved {approvedDomains.length}{" "}
                              {approvedDomains.length === 1
                                ? "sender"
                                : "senders"}
                            </p>
                            <p>
                              Blocked {blockedDomains.length}{" "}
                              {blockedDomains.length === 1
                                ? "sender"
                                : "senders"}
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <button
                  className={`focus-within:text-base  text-center underline whitespace-nowrap ${
                    inboxFetchCompleted
                      ? "text-green_primary cursor-pointer"
                      : "cursor-not-allowed text-gray1"
                  }`}
                  disabled={!inboxFetchCompleted}
                  onClick={handleNavigateToDomains}
                >
                  {isDomainReviewComplete ? `Modify` : `Review Senders`}
                </button>
              </div>
            ) : (
              <div
                className={`flex flex-col gap-2 p-6 justify-between rounded-lg shadow-nav`}
              >
                <p className="text-lg font-bold text-green_primary">Step 3</p>
                <div className="flex flex-col gap-6 justify-between sm:ml-12">
                  <div className="flex flex-col gap-2">
                    <p className="text-black_primary text-xl font-bold">
                      Approve or block senders who email you frequently
                    </p>
                    <p>
                      Approve those you want email from and block ones you
                      don’t.
                    </p>
                    <p className="text-gray1">Time: 5-10 minutes</p>
                  </div>
                  <div className="flex flex-col gap-4 rounded-lg bg-orange_bg text-orange_dark py-3 px-4">
                    <p className="font-bold">Why do this now?</p>
                    <p className="text-sm">
                      Investing a few minutes will immediately begin to reduce
                      the number of emails that land in your primary inbox.
                    </p>
                  </div>
                  <div className="flex gap-3 sm:gap-6 whitespace-nowrap">
                    {inboxFetchCompleted ? (
                      <Button
                        format={{
                          type: "submit",
                          id: "signIn",
                          isFilled: false,
                          content: `Review senders`,
                          width: "px-0 sm:px-16",
                          onclick: () => {
                            mixpanel.track(
                              `Clicked: 'Review senders' button for 'platform: ${platform}'`,
                              {
                                user_Id: user?.id,
                                user_Email: user?.email,
                                user_FirstName: user?.first,
                                user_LastName: user?.last,
                                user_Phone: user?.phone,
                                user_Tier: user?.tier,
                              },
                            );
                            handleNavigateToDomains();
                          },
                        }}
                      />
                    ) : (
                      <button
                        className="bg-gray_Xlight text-gray1 rounded-full cursor-not-allowed font-bold px-16 py-2.5"
                        disabled={!inboxFetchCompleted}
                      >
                        Domains loading...
                      </button>
                    )}
                    <button
                      className="text-base text-green_primary underline cursor-pointer text-left sm:text-center"
                      onClick={() => {
                        mixpanel.track(
                          `Clicked: 'Not interested' button for 'platform: ${platform}' (Review senders)`,
                          {
                            user_Id: user?.id,
                            user_Email: user?.email,
                            user_FirstName: user?.first,
                            user_LastName: user?.last,
                            user_Phone: user?.phone,
                            user_Tier: user?.tier,
                          },
                        );
                        setIsStep3Skipped(true);
                        localStorage.setItem(
                          `isStep3Skipped_${platformData?.id}`,
                          JSON.stringify(true),
                        );
                      }}
                    >
                      Not interested
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-col gap-1 sm:items-center sm:justify-center mt-4">
              <p className="text-black_primary text-lg font-bold text-center">
                Still have questions?
              </p>
            </div>

            {isVideoStepComplete ? (
              <div className="flex flex-row gap-6 justify-between py-3 px-6 bg-green_bg rounded-lg transition-all duration-700 ease-linear">
                <div className="flex flex-col">
                  <p className="text-xs text-green_primary pb-2">Step 5</p>
                  <p className="font-bold">Bulletproof Inbox 101</p>
                  <p className="text-green_primary_text">Video completed.</p>
                </div>
                <button
                  className="text-base text-green_primary underline cursor-pointer text-center"
                  onClick={() => {
                    mixpanel.track(
                      `Clicked: 'Watch Again' button for 'platform: ${platform}' on Email Setting screen`,
                      {
                        user_Id: user?.id,
                        user_Email: user?.email,
                        user_FirstName: user?.first,
                        user_LastName: user?.last,
                        user_Phone: user?.phone,
                        user_Tier: user?.tier,
                      },
                    );
                    setIsVideoStepComplete(false);
                    if (timeoutId) clearTimeout(timeoutId);
                    timeoutId = setTimeout(() => {
                      setIsVideoPlaying(true);
                    }, 1000);
                  }}
                >
                  Watch Again
                </button>
              </div>
            ) : (
              <div
                className={`flex flex-col gap-6 p-6 justify-between rounded-lg shadow-nav`}
                ref={videoCardRef}
              >
                <div className="flex flex-col gap-2">
                  {/* <p className="text-xs text-green_primary">Step 5</p> */}
                  <p className="text-black_primary text-xl font-bold">
                    Bulletproof Inbox 101
                  </p>
                  <p>
                    Watch a short video to learn everything you need to know
                    about Bulletproof Inbox.
                  </p>
                  <p
                    className={`text-gray1 transition-all duration-700 ease-linear h-auto`}
                  >
                    Time: 2.5 minutes
                  </p>
                  <div
                    className={`w-full h-full transition-all duration-700 ease-linear mt-0 flex justify-center`}
                  >
                    <ReactPlayer
                      url={isGoogleEmail ? gmailVideo : outlookVideo}
                      playing={isVideoPlaying}
                      playsinline={true}
                      controls={true}
                      muted={false}
                      height={"auto"}
                      onEnded={handleVideoEnd}
                      onPlay={() => {
                        mixpanel.track(
                          `Clicked: Play onboarding video for Platform: ${platform}`,
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 w-full items-center justify-center flex flex-row bg-white shadow overflow-hidden border-t border-gray_Xlight px-6 py-6 gap-6">
        {!isSwitchedOn ? (
          <div className="flex w-full flex-col md:flex-row">
            <div className="w-full flex justify-center space-x-10">
              <button
                className="text-base text-green_primary underline cursor-pointer text-center whitespace-nowrap"
                onClick={() => {
                  mixpanel.track(
                    `Clicked: 'Turn on later' button (onboarding page) for 'platform: ${platform}'`,
                    {
                      user_Id: user?.id,
                      user_Email: user?.email,
                      user_FirstName: user?.first,
                      user_LastName: user?.last,
                      user_Phone: user?.phone,
                      user_Tier: user?.tier,
                    },
                  );
                  mixpanel.identify(user.email);
                  confirmExitDialog();
                }}
              >
                Turn on later
              </button>
              {isTurningOn ? (
                <button className="border-1 bg-green_primary border-green_primary text-green_primary font-bold rounded-full h-11 w-52 sm:w-64 flex justify-center items-center">
                  <div className="dot-falling"></div>
                </button>
              ) : (
                <button
                  className="border-1 bg-green_primary border-green_primary text-white font-bold rounded-full h-11 w-52 sm:w-64 flex justify-center items-center"
                  onClick={() => {
                    mixpanel.track(
                      `Clicked: 'Turn on Bulletproof' button for 'platform: ${platform}' (onboarding page)`,
                      {
                        user_Id: user?.id,
                        user_Email: user?.email,
                        user_FirstName: user?.first,
                        user_LastName: user?.last,
                        user_Phone: user?.phone,
                        user_Tier: user?.tier,
                      },
                    );

                    handleTurnOn();
                  }}
                >
                  Turn on Bulletproof
                </button>
              )}
            </div>
            <a
              className="hidden md:flex max-w-fit justify-start items-center text-base px-2 text-green_primary underline cursor-pointer text-center whitespace-nowrap"
              href="https://calendly.com/jack-bulletproof-inbox/30min"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                mixpanel.track(
                  `Clicked: 'Schedule Setup Call' link (onboarding page)`,
                  {
                    user_Id: user?.id,
                    user_Email: user?.email,
                    user_FirstName: user?.first,
                    user_LastName: user?.last,
                    user_Phone: user?.phone,
                    user_Tier: user?.tier,
                  },
                );
                mixpanel.identify(user.email);
              }}
            >
              <LazyLoadImage
                src={calendarIcon}
                className="w-4 h-4 mr-1"
                alt="Calendar Icon"
              />
              Schedule Setup Call
            </a>
          </div>
        ) : (
          <div className="flex w-full flex-col md:flex-row">
            <div className="w-full flex justify-center space-x-10">
              <button
                className="text-base text-green_primary underline cursor-pointer text-center"
                onClick={() => {
                  mixpanel.track(
                    `Clicked: 'I need help' button on Email Setting screen`,
                    {
                      user_Id: user?.id,
                      user_Email: user?.email,
                      user_FirstName: user?.first,
                      user_LastName: user?.last,
                      user_Phone: user?.phone,
                      user_Tier: user?.tier,
                    },
                  );
                  window.open(
                    "https://www.bulletproofinbox.com/new-member-onboarding-guide",
                    "_blank",
                  );
                }}
              >
                I need help
              </button>
              <button
                className="border-1 hover:bg-green_primary border-green_primary hover:text-white text-green_primary font-bold rounded-full h-11 w-52 sm:w-64 flex justify-center items-center"
                onClick={() => {
                  mixpanel.track(
                    `Clicked: 'I'm done' button on Email Setting screen`,
                    {
                      user_Id: user?.id,
                      user_Email: user?.email,
                      user_FirstName: user?.first,
                      user_LastName: user?.last,
                      user_Phone: user?.phone,
                      user_Tier: user?.tier,
                    },
                  );
                  navigate("/app/email-accounts");
                }}
              >
                I'm done
              </button>
            </div>
            <a
              className="hidden md:flex max-w-fit justify-start items-center text-base md:px-2 text-green_primary underline cursor-pointer text-center whitespace-nowrap"
              href="https://calendly.com/jack-bulletproof-inbox/30min"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                mixpanel.track(
                  `Clicked: 'Schedule Setup Call' link (onboarding page)`,
                  {
                    user_Id: user?.id,
                    user_Email: user?.email,
                    user_FirstName: user?.first,
                    user_LastName: user?.last,
                    user_Phone: user?.phone,
                    user_Tier: user?.tier,
                  },
                );
                mixpanel.identify(user.email);
              }}
            >
              <LazyLoadImage
                src={calendarIcon}
                className="w-4 h-4 mr-1"
                alt="Calendar Icon"
              />
              Schedule Setup Call
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailSettings;
